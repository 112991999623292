import {
	DETAIL_PLAN,
} from './universal';

import {
	createTariff,
} from './tariff';



const PAGES_POINTS = [
	1_000,
	2_000,
	3_000,
	4_000,
	5_000,
	6_000,
	7_000,
	8_000,
	9_000,
	10_000,
	15_000,
	20_000,
	25_000,
	50_000,
	75_000,
	100_000,
	125_000,
	150_000,
	200_000,
	250_000,
	375_000,
	500_000,
	625_000,
	750_000,
	875_000,
	1_000_000,
	1_500_000,
	2_000_000,
	2_500_000,
	3_000_000,
	3_500_000,
	4_000_000,
	4_500_000,
	5_000_000,
	6_000_000,
	7_000_000,
	8_000_000,
	9_000_000,
	10_000_000,
	11_000_000,
	12_000_000,
	13_000_000,
	14_000_000,
	15_000_000,
	16_000_000,
	17_000_000,
	18_000_000,
	19_000_000,
	20_000_000,
	21_000_000,
	22_000_000,
	23_000_000,
	24_000_000,
	25_000_000,
	30_000_000,
	35_000_000,
	40_000_000,
	45_000_000,
	50_000_000,
	55_000_000,
	60_000_000,
	65_000_000,
	70_000_000,
	75_000_000,
	80_000_000,
	85_000_000,
	90_000_000,
	95_000_000,
	100_000_000,
];



export default createTariff({
	choosePlan: ({ billingCycle, details }) => {
		return details[DETAIL_PLAN] + '.' + billingCycle;
	},
	convertDetailsToAddons: () => ({}),
	convertPagesToAddons: (pageBundle) => {
		for (const pagesPoint of PAGES_POINTS) {
			if (pageBundle <= pagesPoint) {
				return {
					['pages.' + pagesPoint]: 1,
				};
			}
		}

		return {};
	},
	normalizePageCapacity: (numberOfPages) => {
		return Math.ceil(numberOfPages / 1_000) * 1_000;
	},
	normalizePagesAmount: (numberOfPages) => {
		for (const pagesPoint of PAGES_POINTS) {
			if (numberOfPages <= pagesPoint) {
				return pagesPoint;
			}
		}

		return null;
	},
});
