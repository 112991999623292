import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AdobeAnalyticsConnectionModal from '~/components/app/AdobeAnalyticsConnectionModal';
import AttachedElement from '~/components/patterns/structuredValues/AttachedElement';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import CancelButton from '~/components/app/CancelButton';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import DisabledContent from '~/components/patterns/content/DisabledContent';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Emphasis from '~/components/patterns/typography/Emphasis';
import Form, {
	type FormRef,
} from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import HelpHint from '~/components/patterns/hints/HelpHint';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';
import SelectField from '~/components/atoms/forms/components/SelectField';
import Spinner from '~/components/patterns/loaders/Spinner';
import StaticText from '~/components/atoms/forms/components/StaticText';
import SubmitButton from '~/components/app/SubmitButton';
import SwitchField from '~/components/app/SwitchField';
import TextPreloader, {
	TextPreloaderSize,
} from '~/components/patterns/loaders/TextPreloader';

import {
	validateField,
	validateFields,
} from './validations';

import {
	useAdobeAnalyticsIntegrationQuery,
	useConnectAdobeAnalyticsAccountToWebsiteMutation,
	useDisableAdobeAnalyticsForWebsiteMutation,
} from './AdobeAnalyticsIntegrationForm.gql';

import useIsAllowedWithWebsite from '~/hooks/useIsAllowedWithWebsite';
import useOpenModal from '~/hooks/useOpenModal';
import useWebsiteAccountId from '~/hooks/useWebsiteAccountId';
import useWebsiteIntegrations from '~/hooks/useWebsiteIntegrations';



const messages = defineMessages({
	available: {
		id: 'ui.websites.form.api.status.available',
	},
	caution: {
		id: 'ui.general.caution',
	},
	connectAnother: {
		id: 'ui.websites.form.api.adobeAnalytics.connectAnother',
	},
	connectLink: {
		id: 'ui.websites.form.api.adobeAnalytics.connectLink',
	},
	disabled: {
		id: 'ui.websites.form.api.status.disabled',
	},
	enabled: {
		id: 'ui.websites.form.api.status.enabled',
	},
	eVar: {
		id: 'ui.websites.form.api.adobeAnalytics.eVar',
	},
	expiredCredentialsWarning: {
		id: 'ui.websites.form.api.adobeAnalytics.expiredCredentialsWarning',
	},
	matchingMethod: {
		id: 'ui.websites.form.api.adobeAnalytics.matchingMethod',
	},
	matchingMethodExplanation: {
		id: 'ui.websites.form.api.adobeAnalytics.matchingMethod.explanation',
	},
	matchingMethodExplanationTitle: {
		id: 'ui.websites.form.api.adobeAnalytics.matchingMethod.explanation.title',
	},
	matchingMethodFullUrl: {
		id: 'ui.websites.form.api.adobeAnalytics.matchingMethod.fullUrl',
	},
	matchingMethodPageTitle: {
		id: 'ui.websites.form.api.adobeAnalytics.matchingMethod.pageTitle',
	},
	noDimensionsInReportSuite: {
		id: 'ui.websites.form.api.adobeAnalytics.noDimensionsInReportSuite',
	},
	noDimensionsInReportSuiteTitle: {
		id: 'ui.websites.form.api.adobeAnalytics.noDimensionsInReportSuite.title',
	},
	noReportSuitesInAccount: {
		id: 'ui.websites.form.api.adobeAnalytics.noReportSuitesInAccount',
	},
	noReportSuitesInAccountTitle: {
		id: 'ui.websites.form.api.adobeAnalytics.noReportSuitesInAccount.title',
	},
	project: {
		id: 'ui.websites.form.api.adobeAnalytics.project',
	},
	projectHint: {
		id: 'ui.websites.form.api.adobeAnalytics.project.hint',
	},
	reportSuite: {
		id: 'ui.websites.form.api.adobeAnalytics.reportSuite',
	},
	reportSuiteHint: {
		id: 'ui.websites.form.api.adobeAnalytics.reportSuite.hint',
	},
	saveButton: {
		id: 'ui.websites.form.api.adobeAnalytics.saveButton',
	},
	title: {
		id: 'ui.websites.form.api.adobeAnalytics.title',
	},
});



type Props = {
	websiteId: CK.WebsiteId,
};

const AdobeAnalyticsIntegrationForm: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const websiteAccountId = useWebsiteAccountId(websiteId);

	const websiteIntegrations = useWebsiteIntegrations(websiteId);

	const isAllowedToEdit = useIsAllowedWithWebsite(
		websiteId,
		GraphQL.ActionWithWebsite.ManageSettings,
	);

	const { data } = useAdobeAnalyticsIntegrationQuery({
		skip: websiteAccountId === null,
		variables: {
			accountId: websiteAccountId ?? -1,
			websiteId,
		},
	});

	const integrationStatus = websiteIntegrations.getStatus(GraphQL.WebsiteIntegrationType.AdobeAnalytics);
	const isIntegrationOwned = websiteIntegrations.isNotOwned(GraphQL.WebsiteIntegrationType.AdobeAnalytics) === false;

	const showExpiredCredentialsWarning = (
		data?.website?.adobeAnalyticsIntegration?.adobeAnalyticsAccount.status === GraphQL.AdobeAnalyticsAccountStatus.Expired
	);

	return (
		<EditableFormWrapper
			isAllowed={isAllowedToEdit}
			isReadOnly={integrationStatus !== GraphQL.WebsiteIntegrationStatus.Available && integrationStatus !== GraphQL.WebsiteIntegrationStatus.FeatureNotEnabled && !isIntegrationOwned}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<DisplayPart>
				<FormRows>
					<FormRow
						label={(
							<FormattedMessage {...messages.title} />
						)}
					>
						<StaticText>
							{(integrationStatus === GraphQL.WebsiteIntegrationStatus.Available || integrationStatus === GraphQL.WebsiteIntegrationStatus.FeatureNotEnabled) ? (
								<FormattedMessage {...messages.available} />
							) : integrationStatus === GraphQL.WebsiteIntegrationStatus.Enabled ? (
								<FormattedMessage {...messages.enabled} />
							) : integrationStatus === GraphQL.WebsiteIntegrationStatus.Disabled ? (
								<FormattedMessage {...messages.disabled} />
							) : null}
						</StaticText>
					</FormRow>

					{showExpiredCredentialsWarning && (
						<FormRow>
							<CalloutMessage
								borders={true}
								message={(<FormattedMessage {...messages.caution} />)}
								status={CalloutMessageStatus.Critical}
							>
								<Copy
									{...messages.expiredCredentialsWarning}
									values={{
										linkArticle: linkExternal('https://www.contentkingapp.com/support/adobe-analytics/'),
									}}
								/>
							</CalloutMessage>
						</FormRow>
					)}
				</FormRows>
			</DisplayPart>

			<EditablePart>
				{websiteAccountId !== null && (
					<AdobeAnalyticsIntegrationEdit
						integrationStatus={integrationStatus}
						websiteAccountId={websiteAccountId}
						websiteId={websiteId}
					/>
				)}
			</EditablePart>
		</EditableFormWrapper>
	);
};



const validations = {
	validateAdobeAnalyticsAccountId: validateField(
		'adobeAnalyticsAccountId',
		(f) => ([
			f.whenOtherField(
				'enabled',
				({ value }) => value === true,
			),
			f.validateNonEmpty(),
		]),
	),
	validateDimensionId: validateFields(
		['dataMatchingMethod', 'dimensionId'],
		{
			dimensionId: (f) => ([
				f.whenOtherField(
					'enabled',
					({ value }) => value === true,
				),
				f.whenOtherField(
					'dataMatchingMethod',
					({ value }) => value === GraphQL.AdobeAnalyticsDataMatchingMethod.FullUrl,
				),
				f.validateNonEmpty(),
			]),
		},
	),
	validateReportSuiteId: validateField(
		'reportSuiteId',
		(f) => ([
			f.whenOtherField(
				'enabled',
				({ value }) => value === true,
			),
			f.validateNonEmpty(),
		]),
	),
};



const AdobeAnalyticsIntegrationEdit: React.FC<{
	integrationStatus: GraphQL.WebsiteIntegrationStatus | null,
	websiteAccountId: CK.AccountId,
	websiteId: CK.WebsiteId,
}> = (props) => {
	const {
		integrationStatus,
		websiteAccountId,
		websiteId,
	} = props;

	const formRef = React.useRef<FormRef>(null);

	const [connectAdobeAnalyticsAccountToWebsite] = useConnectAdobeAnalyticsAccountToWebsiteMutation();
	const [disableAdobeAnalyticsForWebsite] = useDisableAdobeAnalyticsForWebsiteMutation();

	const {
		data,
		loading,
	} = useAdobeAnalyticsIntegrationQuery({
		variables: {
			accountId: websiteAccountId,
			websiteId,
		},
	});

	const integration = data?.website?.adobeAnalyticsIntegration || null;
	const connectedAccounts = React.useMemo(
		() => data?.account?.connectedAdobeAnalyticsAccounts || [],
		[
			data,
		],
	);

	const openConnectModal = useOpenModal(
		() => {
			const onSuccess = (values) => {
				setTimeout(() => {
					formRef.current?.setValues({
						adobeAnalyticsAccountId: values.adobeAnalyticsAccountId.toString(),
						enabled: true,
					});
				});
			};

			return (
				<AdobeAnalyticsConnectionModal
					onSuccess={onSuccess}
				/>
			);
		},
		[],
	);

	const handleFormChange = React.useCallback(
		(name: string, value: any) => {
			if (name === 'adobeAnalyticsAccountId') {
				if (value === 'connect_account') {
					formRef.current?.setValue('adobeAnalyticsAccountId', null);
				}

				formRef.current?.setValues({
					reportSuiteId: null,
					dimensionId: null,
				});
			}

			if (name === 'reportSuiteId') {
				formRef.current?.setValues({
					dimensionId: null,
				});
			}
		},
		[],
	);

	const handleFormSubmit = React.useCallback(
		async (values) => {
			if (values.enabled) {
				await connectAdobeAnalyticsAccountToWebsite({
					variables: {
						adobeAnalyticsAccountId: values.adobeAnalyticsAccountId,
						dataMatchingMethod: values.dataMatchingMethod,
						dimensionId: (
							values.dataMatchingMethod === GraphQL.AdobeAnalyticsDataMatchingMethod.FullUrl ? values.dimensionId : null
						),
						reportSuiteId: values.reportSuiteId,
						websiteId,
					},
				});
			} else {
				await disableAdobeAnalyticsForWebsite({
					variables: {
						websiteId,
					},
				});
			}
		},
		[
			connectAdobeAnalyticsAccountToWebsite,
			disableAdobeAnalyticsForWebsite,
			websiteId,
		],
	);

	const adobeAnalyticsAccountIdOptions = React.useMemo(
		() => {
			return [
				...connectedAccounts.map(
					(connectedAdobeAccount) => ({
						label: (
							<>
								{connectedAdobeAccount.name} {connectedAdobeAccount.isJwt && (
									<Emphasis>(JWT)</Emphasis>
								)}
							</>
						),
						name: connectedAdobeAccount.id.toString(),
					}),
				),
				{
					label: (
						<Emphasis>
							<InternalLink
								onClickCallback={openConnectModal}
								style={InternalLinkStyle.Decent}
							>
								<FormattedMessage {...messages.connectAnother} />
							</InternalLink>
						</Emphasis>
					),
					name: 'connect_account',
				},
			];
		},
		[
			connectedAccounts,
			openConnectModal,
		],
	);

	return (
		<PremiumFeatureSituation
			featureName={GraphQL.AccountFeature.AdobeAnalyticsIntegration}
			hideIfUnattainable={false}
			style={PremiumFeatureSituationStyle.Ribbon}
		>
			{({ isFeatureEnabled, premiumAnnotation }) => (
				<Form
					defaultValues={{
						adobeAnalyticsAccountId: integration?.adobeAnalyticsAccount.id.toString(),
						dataMatchingMethod: integration?.dataMatchingMethod || GraphQL.AdobeAnalyticsDataMatchingMethod.PageTitle,
						dimensionId: integration?.dimensionId,
						enabled: integrationStatus === GraphQL.WebsiteIntegrationStatus.Enabled,
						reportSuiteId: integration?.reportSuiteId,
					}}
					ignoreFieldUnmounts={true}
					isDisabled={isFeatureEnabled === false}
					key={loading ? 'loading' : 'ready'}
					onChangeCallback={handleFormChange}
					onSuccess={handleFormSubmit}
					ref={formRef}
					validations={validations}
				>
					{({ values }) => {
						const adobeAnalyticsAccount = connectedAccounts.find(
							(account) => account.id.toString() === values.adobeAnalyticsAccountId,
						) ?? null;

						const reportSuites = adobeAnalyticsAccount?.reportSuites ?? [];

						const dimensions = reportSuites
							.find((suite) => suite.rsid === values.reportSuiteId)
							?.dimensions || [];

						const showNoReportSuitesInSelectedAccount = (
							!!values.adobeAnalyticsAccountId
							&& reportSuites.length === 0
							&& adobeAnalyticsAccount?.status === GraphQL.AdobeAnalyticsAccountStatus.Initializing
						);

						const showNoDimensionsInSelectedReportSuites = (
							!!values.adobeAnalyticsAccountId
							&& !!values.reportSuiteId
							&& values.dataMatchingMethod === GraphQL.AdobeAnalyticsDataMatchingMethod.FullUrl
							&& dimensions.length === 0
						);

						const showDataMatchingExplanation = (
							showNoReportSuitesInSelectedAccount === false
							&& showNoDimensionsInSelectedReportSuites === false
						);

						const isEnabled = values.enabled && isFeatureEnabled;

						return (
							<>
								<FormRows>
									<FormRow
										label={(
											<FormattedMessage {...messages.title} />
										)}
									>
										{loading ? (
											<Spinner />
										) : (isFeatureEnabled === false || connectedAccounts.length === 0) ? (
											<DisabledContent
												disabledContent={!isFeatureEnabled}
												disabledOverlay={!isFeatureEnabled && premiumAnnotation}
											>
												<StaticText>
													<InternalLink onClickCallback={openConnectModal}>
														<FormattedMessage {...messages.connectLink} />
													</InternalLink>
												</StaticText>
											</DisabledContent>
										) : (
											<SwitchField
												activeStateLabel={(
													<FormattedMessage {...messages.enabled} />
												)}
												inactiveStateLabel={(
													<FormattedMessage {...messages.disabled} />
												)}
												name="enabled"
											/>
										)}
									</FormRow>

									{isEnabled && (
										<>
											<FormRow
												htmlFor="adobeAnalyticsAccountId"
												label={(
													<AttachedElement
														element={(
															<HelpHint
																message={(
																	<FormattedMessage {...messages.projectHint} />
																)}
															/>
														)}
													>
														<FormattedMessage {...messages.project} />
													</AttachedElement>
												)}
											>
												<SelectField
													dropdownWidth={295}
													isDisabled={loading}
													name="adobeAnalyticsAccountId"
													options={adobeAnalyticsAccountIdOptions}
												/>
											</FormRow>

											{showNoReportSuitesInSelectedAccount ? (
												<CalloutMessage
													borders={true}
													message={(
														<FormattedMessage {...messages.noReportSuitesInAccountTitle} />
													)}
													status={CalloutMessageStatus.Critical}
												>
													<FormattedMessage {...messages.noReportSuitesInAccount} />
												</CalloutMessage>
											) : (
												<>
													<FormRow
														htmlFor="reportSuiteId"
														label={(
															<AttachedElement
																element={(
																	<HelpHint
																		message={(
																			<FormattedMessage {...messages.reportSuiteHint} />
																		)}
																	/>
																)}
															>
																<FormattedMessage {...messages.reportSuite} />
															</AttachedElement>
														)}
													>
														{adobeAnalyticsAccount?.status === GraphQL.AdobeAnalyticsAccountStatus.Initializing ? (
															<TextPreloader size={TextPreloaderSize.Small} />
														) : (
															<SelectField
																isDisabled={!values.adobeAnalyticsAccountId}
																name="reportSuiteId"
																options={reportSuites.map((suite) => ({
																	label: suite.name,
																	name: suite.rsid,
																}))}
															/>
														)}
													</FormRow>

													<FormRow
														htmlFor="dataMatchingMethod"
														label={(
															<FormattedMessage {...messages.matchingMethod} />
														)}
													>
														<SelectField
															isDisabled={!values.reportSuiteId}
															name="dataMatchingMethod"
															options={[
																{
																	label: <FormattedMessage {...messages.matchingMethodPageTitle} />,
																	name: GraphQL.AdobeAnalyticsDataMatchingMethod.PageTitle,
																},
																{
																	label: <FormattedMessage {...messages.matchingMethodFullUrl} />,
																	name: GraphQL.AdobeAnalyticsDataMatchingMethod.FullUrl,
																},
															]}
														/>
													</FormRow>

													{values.dataMatchingMethod === GraphQL.AdobeAnalyticsDataMatchingMethod.FullUrl && (
														<FormRow
															htmlFor="dimensionId"
															label={(
																<FormattedMessage {...messages.eVar} />
															)}
														>
															<SelectField
																isDisabled={!values.reportSuiteId || dimensions.length === 0}
																name="dimensionId"
																options={dimensions.map((dimension) => ({
																	label: `${dimension.title}`,
																	name: dimension.id,
																}))}
																searchable={dimensions.length > 7}
															/>
														</FormRow>
													)}
												</>
											)}

											{showNoDimensionsInSelectedReportSuites && (
												<FormRow>
													<CalloutMessage
														borders={true}
														message={(
															<FormattedMessage {...messages.noDimensionsInReportSuiteTitle} />
														)}
														status={CalloutMessageStatus.Critical}
													>
														<Copy
															{...messages.noDimensionsInReportSuite}
															values={{
																linkArticle: linkExternal('https://www.contentkingapp.com/support/adobe-analytics/'),
															}}
														/>
													</CalloutMessage>
												</FormRow>
											)}

											{showDataMatchingExplanation && (
												<CalloutMessage
													borders={true}
													message={(
														<FormattedMessage {...messages.matchingMethodExplanationTitle} />
													)}
													status={CalloutMessageStatus.Normal}
												>
													<Copy
														{...messages.matchingMethodExplanation}
														values={{
															dataMatchingMethod: values.dataMatchingMethod,
															linkArticle: linkExternal('https://www.contentkingapp.com/support/adobe-analytics/'),
														}}
													/>
												</CalloutMessage>
											)}
										</>
									)}
								</FormRows>

								<ButtonsLayout>
									<CancelButton />

									<SubmitButton>
										<FormattedMessage {...messages.saveButton} />
									</SubmitButton>
								</ButtonsLayout>
							</>
						);
					}}
				</Form>
			)}
		</PremiumFeatureSituation>
	);
};



export default AdobeAnalyticsIntegrationForm;
