import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import BillingCycleSwitchLabel from '~/components/patterns/pricing/BillingCycleSwitchLabel';
import SwitchField from '~/components/patterns/forms/fields/SwitchField';

import {
	BillingCycleComparison,
	compareBillingCycles,
} from '~/model/pricing/billingCycle';



const messages = defineMessages({
	[GraphQL.Term.Annually]: {
		id: 'ui.billing.cycleDescription.annually',
	},
	[GraphQL.Term.Monthly]: {
		id: 'ui.billing.cycleDescription.monthly',
	},
	[GraphQL.Term.Quarterly]: {
		id: 'ui.billing.cycleDescription.quarterly',
	},
});



type Props = {
	allowedBillingCycles: readonly [GraphQL.Term, GraphQL.Term],
	billingCycle: GraphQL.Term,
	longerBillingCycleBenefit?: React.ReactNode,
	name: string,
	onChangeCallback: (billingCycle: GraphQL.Term) => void,
};

const BillingCycleSwitch: React.FC<Props> = (props) => {
	const {
		allowedBillingCycles,
		billingCycle,
		longerBillingCycleBenefit,
		onChangeCallback,
		name,
	} = props;

	const allowedBillingCyclesComparison = compareBillingCycles(allowedBillingCycles[0], allowedBillingCycles[1]);

	const longerAllowedBillingCycle = allowedBillingCyclesComparison === BillingCycleComparison.Shorter
		? allowedBillingCycles[0]
		: allowedBillingCycles[1];

	const shorterAllowedBillingCycle = allowedBillingCyclesComparison === BillingCycleComparison.Shorter
		? allowedBillingCycles[1]
		: allowedBillingCycles[0];

	const handleChange = React.useCallback(
		(_, checked) => {
			onChangeCallback(checked ? longerAllowedBillingCycle : shorterAllowedBillingCycle);
		},
		[
			longerAllowedBillingCycle,
			onChangeCallback,
			shorterAllowedBillingCycle,
		],
	);

	const isLongerBillingCycleChosen = billingCycle === longerAllowedBillingCycle;

	return (
		<SwitchField
			isChecked={isLongerBillingCycleChosen}
			label={(
				isLongerBillingCycleChosen ? (
					<BillingCycleSwitchLabel
						additionalInfo={longerBillingCycleBenefit}
						billingCycleLabel={(
							<FormattedMessage {...messages[longerAllowedBillingCycle]} />
						)}
					/>
				) : (
					<BillingCycleSwitchLabel
						billingCycleLabel={(
							<FormattedMessage {...messages[shorterAllowedBillingCycle]} />
						)}
					/>
				)
			)}
			name={name}
			onChangeCallback={handleChange}
		/>
	);
};



export default BillingCycleSwitch;
