import type CK from '~/types/contentking';

import {
	useAccountPremiumTrialOfferQuery,
} from './useAccountPremiumTrialOffer.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountPremiumTrialOffer(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountPremiumTrialOfferQuery,
		accountId,
	);

	return data?.account?.premiumTrialOffer ?? null;
}



export default useAccountPremiumTrialOffer;
