import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import BillingCycleSwitch from '../formFields/BillingCycleSwitch';
import BillingCycleSwitchBox from '~/components/patterns/pricing/BillingCycleSwitchBox';

import GraphQL from '~/types/graphql';

import useAccountSignup from '~/hooks/useAccountSignup';
import useAccountTariff from '~/hooks/useAccountTariff';
import useAllowedBillingCycles from '~/hooks/useAllowedBillingCycles';
import useAllowedPlans from '~/hooks/useAllowedPlans';

import {
	Tariff,
} from '~/model/pricing/tariffs';



const messages = defineMessages({
	twoMonthsForFree: {
		id: 'ui.billing.cycleDescription.annuallyDiscount',
	},
});



type Props = {
	accountId: CK.AccountId | null,
};

const BillingCycleConfigurator: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const accountAllowedPlans = useAllowedPlans(accountId);
	const accountSignup = useAccountSignup(accountId);
	const accountTariff = useAccountTariff(accountId);
	const allowedBillingCycles = useAllowedBillingCycles(accountId);

	const handleChange = React.useCallback(
		(nextBillingCycle: GraphQL.Term) => {
			accountSignup.setProperty('billingCycle', nextBillingCycle);
		},
		[
			accountSignup,
		],
	);

	if (
		accountSignup.billingCycle === null
		|| allowedBillingCycles === null
		|| allowedBillingCycles.length !== 2
		|| accountAllowedPlans === null
	) {
		return null;
	}

	const annuallyIsTwoMonthsForFree = (
		(
			accountTariff === Tariff.V4
			&& accountAllowedPlans.includes(GraphQL.AccountPlan.Enterprise) === false
		)
		|| accountTariff === Tariff.C2
	);

	return (
		<BillingCycleSwitchBox>
			<BillingCycleSwitch
				allowedBillingCycles={allowedBillingCycles as any}
				billingCycle={accountSignup.billingCycle}
				longerBillingCycleBenefit={annuallyIsTwoMonthsForFree && (
					<FormattedMessage {...messages.twoMonthsForFree} />
				)}
				name="billingCycle"
				onChangeCallback={handleChange}
			/>
		</BillingCycleSwitchBox>
	);
};



export default BillingCycleConfigurator;
