import type CK from '~/types/contentking';

import {
	useAccountPlanQuery,
} from './useAccountPlan.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountPlan(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountPlanQuery,
		accountId,
	);

	return data?.account?.plan ?? null;
}



export default useAccountPlan;
