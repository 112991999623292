import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import Backlink from '~/components/logic/links/Backlink';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import ExportSnapshotDownloadButton, {
	SnapshotType,
} from '~/components/app/ExportSnapshotDownloadButton';
import HeaderButtonsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderButtonsLayout';
import HeaderTitle, {
	SIZE_SMALL as TITLE_SIZE_SMALL,
} from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitle';
import HeaderTitleLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderTitleLayout';
import HighlightedDate from '~/components/patterns/time/HighlightedDate';
import RobotsTxtViewerHeaderLayout from '~/components/patterns/screens/robotsTxtViewer/header/RobotsTxtViewerHeader';
import SearchEngineActivityBadges, {
	SearchEngineActivityBadgesWording,
} from '~/components/app/SearchEngineActivityBadges';
import SelectedDateOverview from '~/components/patterns/screens/robotsTxtViewer/sections/SelectedDateOverview';
import SimpleNavigation from '~/components/patterns/navigations/simpleNavigation/SimpleNavigation';
import SimpleNavigationItem from '~/components/patterns/navigations/simpleNavigation/SimpleNavigationItem';
import SquareSkeleton from '~/components/patterns/loaders/SquareSkeleton';
import SplitButton, {
	SplitButtonStyle,
} from '~/components/patterns/buttons/SplitButton';

import useViewportType from '~/hooks/useViewportType';
import useViewportWidth from '~/hooks/useViewportWidth';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	packRobotsTxtRevisionsParameter,
} from '~/model/robotsTxt';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';
import {
	assert,
	isNumber,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	backLink: {
		id: 'ui.robotsTxtViewerHeader.backLink',
	},
	compareLatest: {
		id: 'ui.robotsTxtViewerHeader.compareLatest',
	},
	compareLatestVersion: {
		id: 'ui.robotsTxtViewerHeader.compareLatestVersion',
	},
	compareNextVersion: {
		id: 'ui.robotsTxtViewerHeader.compareNextVersion',
	},
	comparePrevious: {
		id: 'ui.robotsTxtViewerHeader.comparePrevious',
	},
	comparePreviousVersion: {
		id: 'ui.robotsTxtViewerHeader.comparePreviousVersion',
	},
	comparisonUnavailable: {
		id: 'ui.robotsTxtViewerHeader.comparisonUnavailable',
	},
	downloadUnavailable: {
		id: 'ui.robotsTxtViewerHeader.downloadUnavailable',
	},
	liveVersion: {
		id: 'ui.robotsTxtViewerHeader.liveVersion',
	},
	openOnWebsite: {
		id: 'ui.robotsTxtViewerHeader.openOnWebsite',
	},
	revisionsAgo: {
		id: 'ui.robotsTxtViewerHeader.revisionsAgo',
	},
	title: {
		id: 'ui.robotsTxtViewerHeader.title',
	},
});



type RevisionItem = {
	id: GraphQL.RobotsTxtRevision['id'],
};

type Props = {
	backlink?: string,
	closeCallback?: () => void,
	isCrawlingPaused?: boolean,
	isLatestRevision?: boolean,
	onMobileFilterButtonClick: () => void,
	revisionsList?: ReadonlyArray<RevisionItem> | null,
	searchEngineActivity: ReadonlyArray<GraphQL.SearchEngineActivityAtMoment> | null,
	selectedRevisionCreatedAt: CK.Timestamp | null,
	selectedRevisionId: CK.ID,
	selectedRevisionIsDownloadable?: boolean,
	url: string | null,
};

const RobotsTxtViewerHeader: React.FC<Props> = (props) => {
	const {
		backlink,
		closeCallback,
		isCrawlingPaused = false,
		isLatestRevision = false,
		onMobileFilterButtonClick,
		revisionsList = null,
		searchEngineActivity,
		selectedRevisionCreatedAt,
		selectedRevisionId,
		selectedRevisionIsDownloadable,
		url,
	} = props;

	const timestamp = selectedRevisionCreatedAt !== null ? new Date(selectedRevisionCreatedAt) : null;

	const websiteId = useWebsiteId();
	const viewportType = useViewportType();
	const viewportWidth = useViewportWidth();

	const revisionsAgo = revisionsList?.findIndex((revision) => revision.id === selectedRevisionId);

	function renderComparisonButton() {
		if (revisionsList === null || revisionsList.length <= 1) {
			return (
				<Button
					compact={viewportType.isSmall || viewportType.isMedium}
					disabled={true}
					icon={(
						<BasicIcon
							type={BasicIconType.History}
						/>
					)}
					style={ButtonStyle.History}
					tooltip={(
						<FormattedMessage {...messages.comparisonUnavailable} />
					)}
				>
					<FormattedMessage {...messages.comparePrevious} />
				</Button>
			);
		}

		const selectedRevisionIndex = selectedRevisionId !== 'latest'
			? revisionsList.findIndex((revision) => revision.id === selectedRevisionId)
			: 0;

		assert(selectedRevisionIndex !== -1, `Selected revision not found in the revisions list: ${selectedRevisionId}`);

		const latestRevisionId = getArrayItemAtSafeIndex(revisionsList, 0).id;
		const nextRevisionId = revisionsList[selectedRevisionIndex - 1]?.id ?? null;
		const previousRevisionId = revisionsList[selectedRevisionIndex + 1]?.id ?? null;

		if (isLatestRevision) {
			return (
				<Button
					compact={viewportType.isSmall || viewportType.isMedium}
					icon={(
						<BasicIcon
							type={BasicIconType.History}
						/>
					)}
					iconColor="#ffffff"
					linkRouteName="website.platform.robotsTxtComparison"
					linkRouteParams={{
						revisions: packRobotsTxtRevisionsParameter(latestRevisionId, previousRevisionId as CK.ID),
						websiteId,
					}}
					style={ButtonStyle.History}
				>
					<FormattedMessage {...messages.comparePrevious} />
				</Button>
			);
		}

		const dropdown: Array<React.ReactNode> = [
			(
				<SimpleNavigationItem
					key="comparisonToLatest"
					linkRouteName="website.platform.robotsTxtComparison"
					linkRouteParams={{
						revisions: packRobotsTxtRevisionsParameter(latestRevisionId, selectedRevisionId),
						websiteId,
					}}
				>
					<FormattedMessage {...messages.compareLatestVersion} />
				</SimpleNavigationItem>
			),
		];

		if (nextRevisionId !== null) {
			dropdown.push(
				<SimpleNavigationItem
					key="comparisonToNext"
					linkRouteName="website.platform.robotsTxtComparison"
					linkRouteParams={{
						revisions: packRobotsTxtRevisionsParameter(nextRevisionId, selectedRevisionId),
						websiteId,
					}}
				>
					<FormattedMessage {...messages.compareNextVersion} />
				</SimpleNavigationItem>,
			);
		}

		if (previousRevisionId !== null) {
			dropdown.push(
				<SimpleNavigationItem
					key="comparisonToPrevious"
					linkRouteName="website.platform.robotsTxtComparison"
					linkRouteParams={{
						revisions: packRobotsTxtRevisionsParameter(selectedRevisionId, previousRevisionId),
						websiteId,
					}}
				>
					<FormattedMessage {...messages.comparePreviousVersion} />
				</SimpleNavigationItem>,
			);
		}

		return (
			<SplitButton
				compact={viewportType.isSmall || viewportType.isMedium}
				dropdown={(
					<SimpleNavigation>
						{dropdown}
					</SimpleNavigation>
				)}
				dropdownWidth={240}
				icon={(
					<BasicIcon
						type={BasicIconType.History}
					/>
				)}
				iconColor="#ffffff"
				linkRouteName="website.platform.robotsTxtComparison"
				linkRouteParams={{
					revisions: packRobotsTxtRevisionsParameter(latestRevisionId, selectedRevisionId),
					websiteId,
				}}
				style={SplitButtonStyle.History}
			>
				<FormattedMessage {...messages.compareLatest} />
			</SplitButton>
		);
	}

	return (
		<RobotsTxtViewerHeaderLayout
			buttons={(
				<HeaderButtonsLayout>
					{isLatestRevision && (
						<Button
							compact={viewportWidth < 1250}
							disabled={url === null}
							href={url ?? undefined}
							icon={(
								<BasicIcon type={BasicIconType.ExternalLink} />
							)}
						>
							<FormattedMessage {...messages.openOnWebsite} />
						</Button>
					)}

					{renderComparisonButton()}

					<ExportSnapshotDownloadButton
						disabledExplanation={(!selectedRevisionIsDownloadable) && (
							<FormattedMessage {...messages.downloadUnavailable} />
						)}
						isDisabled={url === null || !selectedRevisionIsDownloadable}
						revisionId={selectedRevisionId}
						snapshotType={SnapshotType.RobotsTxt}
						timestamp={selectedRevisionCreatedAt}
						url={url}
						websiteId={websiteId}
					/>
				</HeaderButtonsLayout>
			)}
			onMobileFilterButtonClickCallback={onMobileFilterButtonClick}
			selectedDateOverview={(
				<SelectedDateOverview
					flag={(
						isLatestRevision && !isCrawlingPaused ? (
							<FormattedMessage {...messages.liveVersion} />
						) : !isLatestRevision && isNumber(revisionsAgo) ? (
							<FormattedMessage
								{...messages.revisionsAgo}
								values={{ revisionsAgo }}
							/>
						) : null
					)}
					searchEngineActivityStates={(
						<SearchEngineActivityBadges
							searchEngineActivity={searchEngineActivity}
							showFeatureNotEnabled={false}
							showUpsellOrNudge={true}
							wording={SearchEngineActivityBadgesWording.SeenBySearchEngine}
						/>
					)}
				>
					{timestamp !== null ? (
						<HighlightedDate timestamp={timestamp} />
					) : (
						<SquareSkeleton
							height={20}
							width={150}
						/>
					)}
				</SelectedDateOverview>
			)}
			title={(
				<HeaderTitleLayout>
					{!viewportType.isSmall && backlink && closeCallback && (
						<Backlink
							callback={closeCallback}
							label={backlink}
						/>
					)}
					<HeaderTitle size={TITLE_SIZE_SMALL}>
						<FormattedMessage {...messages.title} />
					</HeaderTitle>
				</HeaderTitleLayout>
			)}
		/>
	);
};



export default RobotsTxtViewerHeader;
