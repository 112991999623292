import classNames from 'classnames';
import React from 'react';
import {
	Link,
} from 'react-router5';

import ButtonContext from '~/components/atoms/buttons/ButtonContext';
import Hint, {
	HintAttachment,
	HintPopupSkin,
} from '~/components/patterns/hints/hint/Hint';
import Spinner, {
	SpinnerSize,
	SpinnerStyle,
} from '~/components/patterns/loaders/Spinner';

import useContextProps from '~/hooks/useContextProps';



export enum ButtonSize {
	XXSmall = 'xxsmall',
	XSmall = 'xsmall',
	Small = 'small',
	Medium = 'medium',
	Default = 'default',
	Large = 'large',
	XLarge = 'xlarge',
	XXLarge = 'xxlarge',
}

export enum ButtonStyle {
	Action = 'action',
	Alert = 'alert',
	Highlight = 'highlight',
	History = 'history',
	Hollow = 'hollow',
	HollowHighlight = 'hollow-highlight',
	Inactive = 'inactive',
	Link = 'link',
}

export enum ButtonType {
	Button = 'button',
	Reset = 'reset',
	Submit = 'submit',
}

export enum ButtonTooltipSkin {
	Dark = 'dark',
	Premium = 'premium',
}

const tooltipSkinMapping = {
	[ButtonTooltipSkin.Dark]: HintPopupSkin.Dark,
	[ButtonTooltipSkin.Premium]: HintPopupSkin.Premium,
};

export enum ButtonWidth {
	Auto = 'next', // width defined by length of label in it
	Default = 'default', // width defined by length of label in it for smaller buttons and predefined min-width for bigger buttons
	Fullwidth = 'fullwidth',
	SubmitButton = 'submit-button', // predefined widths for submit buttons
}



type Props = {
	/** Make buttons absolutely positioned. They will be above button content and icons won't reserve space. */
	absoluteIcons?: boolean,
	/** Button label */
	children?: React.ReactNode,
	className?: string,
	/** Show button in his compact version (icon only with tooltip visible on hover) */
	compact?: boolean,
	/** When button in in-progress state we can replace default spinner by own component */
	customProgressLoader?: React.ReactNode,
	disabled?: boolean,
	ellipsis?: boolean,
	/** Visually hidden button (still reserve space) */
	hidden?: boolean,
	/** When defined basis of whole button will be HTML link with defined href */
	href?: string,
	icon?: React.ReactElement,
	iconColor?: string | false,
	iconSize?: number,
	/** By setting routeName we can change this component to Link instance */
	linkRouteName?: string,
	linkRouteParams?: {},
	/** Make button muted (applied greyscale fiter) and make it normal on press, hover or focus */
	muted?: boolean,
	/** Callback called when clicking on whole item */
	onClick?: (e: any) => void,
	onMouseEnter?: () => void,
	onMouseLeave?: () => void,
	pressed?: boolean,
	progress?: boolean,
	size?: ButtonSize,
	style?: ButtonStyle,
	suffixIcon?: React.ReactNode,
	tabIndex?: number,
	testId?: string,
	tooltip?: React.ReactNode,
	tooltipSkin?: ButtonTooltipSkin,
	type?: ButtonType,
	uppercase?: boolean,
	width?: ButtonWidth,
};

export type ButtonProps = Props;



const Button: React.FC<Props> = (props) => {
	const {
		absoluteIcons,
		children,
		className,
		customProgressLoader,
		disabled = false,
		ellipsis = false,
		hidden = false,
		href,
		icon: propIcon,
		linkRouteName,
		linkRouteParams,
		muted = false,
		onClick,
		onMouseEnter,
		onMouseLeave,
		pressed = false,
		progress = false,
		suffixIcon,
		tabIndex,
		testId,
		tooltip: propTooltip,
		tooltipSkin = ButtonTooltipSkin.Dark,
		type = ButtonType.Button,
		width = ButtonWidth.Default,
	} = props;

	const {
		compact,
		iconColor,
		iconSize,
		size,
		style,
		uppercase,
	} = useContextProps(ButtonContext, props, {
		compact: false,
		iconColor: undefined,
		iconSize: undefined,
		size: ButtonSize.Default,
		style: ButtonStyle.Hollow,
		uppercase: false,
	});

	let icon = propIcon;

	if (icon) {
		const customAttributes = {};

		if (iconSize) {
			customAttributes['size'] = iconSize;
		}

		if (iconColor) {
			customAttributes['color'] = iconColor;
		}

		if (!iconColor && disabled) {
			customAttributes['color'] = '#989da4';
		}

		if (Object.keys(customAttributes).length > 0) {
			icon = React.cloneElement(
				icon,
				customAttributes,
			);
		}
	}

	const tooltip = propTooltip || (compact && children);

	const buttonProps = React.useMemo(
		() => {
			const buttonProps = {};

			if (disabled) {
				buttonProps['disabled'] = 'disabled';
			}

			if (onClick && !disabled) {
				buttonProps['onClick'] = onClick;
			}

			if (onMouseEnter) {
				buttonProps['onMouseEnter'] = onMouseEnter;
			}

			if (onMouseLeave) {
				buttonProps['onMouseLeave'] = onMouseLeave;
			}

			if (tabIndex) {
				buttonProps['tabIndex'] = tabIndex;
			}

			if (testId) {
				buttonProps['data-testid'] = testId;
			}

			return buttonProps;
		},
		[
			disabled,
			onClick,
			onMouseEnter,
			onMouseLeave,
			tabIndex,
			testId,
		],
	);


	const componentClasses = classNames({
		'button': true,
		'button--in-progress': progress,
		'button--has-icon': icon || suffixIcon,

		// Special styles
		'button--is-disabled': disabled,
		'button--is-pressed': pressed,
		'button--is-muted': muted,
		'button--uppercase': uppercase,
		'button--ellipsis': ellipsis,
		'button--absolute-icons': absoluteIcons,
		'button--hidden': hidden,

		// Widths
		'button--auto-width': width === ButtonWidth.Auto,
		'button--fullwidth': width === ButtonWidth.Fullwidth,
		'button--submit': width === ButtonWidth.SubmitButton,

		// Left aligned text when button is fullwidth and has icon
		'button--left-aligned-text': width === ButtonWidth.Fullwidth && icon,

		// Sizes
		'button--xxsmall': size === ButtonSize.XXSmall,
		'button--xsmall': size === ButtonSize.XSmall,
		'button--small': size === ButtonSize.Small,
		'button--medium': size === ButtonSize.Medium,
		'button--large': size === ButtonSize.Large,
		'button--xlarge': size === ButtonSize.XLarge,
		'button--xxlarge': size === ButtonSize.XXLarge,

		// Styles
		'button--action': style === ButtonStyle.Action,
		'button--alert': style === ButtonStyle.Alert,
		'button--highlight': style === ButtonStyle.Highlight,
		'button--history': style === ButtonStyle.History,
		'button--hollow': style === ButtonStyle.Hollow,
		'button--hollow-highlight': style === ButtonStyle.HollowHighlight,
		'button--inactive': style === ButtonStyle.Inactive,
		'button--link': style === ButtonStyle.Link,
	}, className);

	const contentClasses = classNames({
		'button__content': true,
		'button__content--hidden': progress,
	});

	const shadowContentClasses = classNames({
		'button__content': true,
		'button__content--shadow-hidden': true,
	});

	const content = !compact && children;

	const bodyContent: Array<React.ReactNode> = [];

	if (progress && customProgressLoader) {
		bodyContent.push((
			<React.Fragment key="custom-progress-loader">
				{customProgressLoader}
			</React.Fragment>
		));
	} else {
		if (progress) {
			bodyContent.push(
				<div
					className="button__preloader"
					key="button-preloader"
				>
					<Spinner
						size={(size === ButtonSize.XXSmall || size === ButtonSize.XSmall || size === ButtonSize.Small) ? SpinnerSize.Small : SpinnerSize.Default}
						style={style === ButtonStyle.Hollow ? SpinnerStyle.Dark : SpinnerStyle.White}
					/>
				</div>,
			);
		}

		bodyContent.push(
			<span
				className={contentClasses}
				key="button-content"
			>
				{icon && (
					<div className="button__icon">
						{icon}
					</div>
				)}
				{content && (
					<span className="button__label">
						<span className="button__text">{content}</span>
					</span>
				)}
				{suffixIcon && (
					<div className="button__icon">
						{suffixIcon}
					</div>
				)}
			</span>,
		);
	}

	bodyContent.push(
		<span
			className={shadowContentClasses}
			key="shadow-button-content"
		>
			{icon && (
				<div className="button__icon">
					{icon}
				</div>
			)}
			{content && (
				<span className="button__label">
					<span className="button__text">{content}</span>
				</span>
			)}
			{suffixIcon && (
				<div className="button__icon">
					{suffixIcon}
				</div>
			)}
		</span>,
	);

	let buttonBody = (
		<span className="button__gaps">
			{bodyContent}
		</span>
	);

	if (tooltip) {
		buttonBody = (
			<Hint
				attachment={HintAttachment.Left}
				blurDelay={100}
				className="button__tooltip"
				popup={tooltip}
				popupSkin={tooltipSkinMapping[tooltipSkin]}
				popupZIndex={2500}
			>
				{buttonBody}
			</Hint>
		);
	}

	if (href && !disabled) {
		return (
			<a
				className={componentClasses}
				href={href}
				target="_blank"
				{...buttonProps}
			>
				{buttonBody}
			</a>
		);
	}

	if (linkRouteName && !disabled) {
		return (
			<Link
				className={componentClasses}
				routeName={linkRouteName}
				routeOptions={{
					force: true,
				}}
				routeParams={linkRouteParams}
				{...buttonProps}
			>
				{buttonBody}
			</Link>
		);
	}

	return (
		<button
			className={componentClasses}
			type={type}
			{...buttonProps}
		>
			{buttonBody}
		</button>
	);
};



export default Button;
