export enum Benefit {
	AdminAndAccessControlFeatures = 'admin_and_access_control_features',
	AdobeAnalytics = 'adobe_analytics',
	Alerting = 'alerting',
	AlertsForCustomElements = 'alerts_for_custom_elements',
	AnalyticsAndGSCIntegrations = 'analytics_and_gsc_integrations',
	AuditLog = 'audit_log',
	BodySnapshots = 'body_snapshots',
	ChangeTracking = 'change_tracking',
	ChangeTrackingAndAlerting = 'change_tracking_and_alerting',
	ChatPlatformIntegrations = 'chat_platform_integrations',
	ChatSupport = 'chat_support',
	ClientAccess = 'client_access',
	Cobranding = 'cobranding',
	CoreWebVitalsMonitoring = 'core_web_vitals_monitoring',
	CoreWebVitalsOriginSummary = 'core_web_vitals_origin_summary',
	CustomAlertTrigger = 'custom_alert_trigger',
	CustomElementExtraction = 'custom_element_extraction',
	CustomElements = 'custom_elements',
	CustomMonitoringLocation = 'custom_monitoring_location',
	CustomTraining = 'custom_training',
	CWVMonitoringAndJSRendering = 'cwv_monitoring_and_js_rendering',
	DataEnrichmentAPI = 'data_enrichment_api',
	DataRetention = 'data_retention',
	DataRetentionMonths = 'data_retention_months',
	DataRetentionDays = 'data_retention_days',
	DataStudioAndAPIs = 'data_studio_and_ap_is',
	DedicatedSuccessManager = 'dedicated_success_manager',
	EmailSupport = 'email_support',
	Enforce2FA = 'enforce2fa',
	GoogleAnalytics = 'google_analytics',
	GoogleChromeExtension = 'google_chrome_extension',
	GoogleLookerStudio = 'google_looker_studio',
	GoogleSearchConsole = 'google_search_console',
	GuidesAndVideos = 'guides_and_videos',
	HttpBodySnapshot = 'http_body_snapshot',
	HttpHeaderSnapshot = 'http_header_snapshot',
	InvoiceBilling = 'invoice_billing',
	IssuesConfiguration = 'issues_configuration',
	JsRendering = 'js_rendering',
	LighthouseMonitoring = 'lighthouse_monitoring',
	LightspeedMonitoring = 'lightspeed_monitoring',
	LogFileAnalysis = 'log_file_analysis',
	LogFileExplorer = 'log_file_explorer',
	MicrosoftTeams = 'microsoft_teams',
	MonitoringFromUSUKEU = 'monitoring_from_usukeu',
	NonStopMonitoring = 'non_stop_monitoring',
	OnboardingAndCustomTraining = 'onboarding_and_custom_training',
	OrganizationAdminRole = 'organization_admin_role',
	PageBundles = 'page_bundles',
	PhoneSupport = 'phone_support',
	PrioritySupport = 'priority_support',
	RealtimeAuditing = 'realtime_auditing',
	RealtimeIndexNow = 'realtime_index_now',
	Rechecks = 'rechecks',
	Rendering = 'rendering',
	ReportingApi = 'reporting_api',
	RestrictEmailDomains = 'restrict_email_domains',
	Slack = 'slack',
	UnlimitedUsers = 'unlimited_users',
	UnlimitedUsersRechecks = 'unlimited_users_rechecks',
	UnlimitedUsersWebsitesRechecks = 'unlimited_users_websites_rechecks',
	UnlimitedWebsites = 'unlimited_websites',
	Users = 'users',
	WebsiteAuditing = 'website_auditing',
	Websites = 'websites',
}

export enum BenefitSection {
	AccessControl = 'accessControl',
	AdvancedAnalysis = 'advancedAnalysis',
	AdvancedFeatures = 'advancedFeatures',
	Collaboration = 'collaboration',
	MainFeatures = 'MainFeatures',
	Integrations = 'integrations',
	MonitoringSettings = 'monitoringSettings',
	Services = 'services',
}
