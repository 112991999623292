/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PrioritizePageMutationVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type PrioritizePageMutation = { readonly __typename?: 'Mutation', readonly PrioritizePage: { readonly __typename?: 'PrioritizePageResult', readonly query: { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly isPrioritized: boolean, readonly lastCheckedAt: CK.Timestamp | null } | null } } };


export const PrioritizePageDocument = gql`
    mutation PrioritizePage($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  PrioritizePage(urlId: $legacyUrlId, websiteId: $websiteId) {
    query {
      lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
        id
        isPrioritized
        lastCheckedAt
      }
    }
  }
}
    `;
export type PrioritizePageMutationFn = Apollo.MutationFunction<PrioritizePageMutation, PrioritizePageMutationVariables>;

/**
 * __usePrioritizePageMutation__
 *
 * To run a mutation, you first call `usePrioritizePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrioritizePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prioritizePageMutation, { data, loading, error }] = usePrioritizePageMutation({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function usePrioritizePageMutation(baseOptions?: Apollo.MutationHookOptions<PrioritizePageMutation, PrioritizePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PrioritizePageMutation, PrioritizePageMutationVariables>(PrioritizePageDocument, options);
      }
export type PrioritizePageMutationHookResult = ReturnType<typeof usePrioritizePageMutation>;
export type PrioritizePageMutationResult = Apollo.MutationResult<PrioritizePageMutation>;
export type PrioritizePageMutationOptions = Apollo.BaseMutationOptions<PrioritizePageMutation, PrioritizePageMutationVariables>;