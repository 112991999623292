import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import FilterFieldLayout from '~/components/patterns/filtering/FilterFieldLayout';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import IssuesFilterSection from '~/components/atoms/filters/IssuesFilterSection';
import MultiselectField, {
	MultiselectFieldSize,
} from '~/components/atoms/forms/components/MultiselectField';
import SegmentSelectField, {
	type SegmentSelectFieldRef,
	SegmentSelectFieldSize,
} from '~/components/atoms/forms/components/SegmentSelectField';
import TableLabel from '~/components/patterns/tables/datatables/parts/TableLabel';

import useViewportType from '~/hooks/useViewportType';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	updateFilter,
} from '~/actions/websiteIssuesComparison';

import {
	CHANGE_BETTER,
	CHANGE_WORSE,
} from '~/model/websiteIssuesComparison';

import {
	filterSelector,
} from '~/state/websiteIssuesComparison/selectors';

import {
	isEquivalent,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	segmentLabel: {
		id: 'ui.issuesOverview.filter.segment.label',
	},
	stateImproved: {
		id: 'ui.historicalIssues.overview.filter.improved',
	},
	stateLabel: {
		id: 'ui.historicalIssues.overview.filter.label',
	},
	stateWorsened: {
		id: 'ui.historicalIssues.overview.filter.worsened',
	},
});

const stateOptions = [
	{
		name: CHANGE_WORSE,
		title: (
			<FormattedMessage {...messages.stateWorsened} />
		),
	},
	{
		name: CHANGE_BETTER,
		title: (
			<FormattedMessage {...messages.stateImproved} />
		),
	},
];



type Props = {
	inline?: boolean,
};

const ComparisonFilter: React.FC<Props> = (props) => {
	const {
		inline = false,
	} = props;

	const websiteId = useWebsiteId();

	const dispatch = useDispatch();
	const filter = useSelector(filterSelector);
	const segments = useWebsiteSegmentDefinitions(websiteId);
	const viewportType = useViewportType();

	const segmentFieldRef = React.useRef<SegmentSelectFieldRef>(null);
	const stateFieldRef = React.useRef<any>(null);

	React.useEffect(
		() => {
			segmentFieldRef.current?.changeValue(
				filter.get('scope'),
				false,
			);

			stateFieldRef.current.changeValue(
				filter.get('change').toJS(),
				false,
			);
		},
		[
			filter,
		],
	);

	const handleFilterChange = React.useCallback(
		(name, value) => {
			const nextFilter = filter.set(name, value);

			if (isEquivalent(filter, nextFilter)) {
				return;
			}

			dispatch(
				updateFilter(
					nextFilter,
				),
			);
		},
		[
			dispatch,
			filter,
		],
	);

	const scope = filter.get('scope');

	const defaultValues = {
		change: filter.get('change').toJS(),
		scope,
	};

	let componentWidth = 280;
	let dropdownWidth = 280;

	if (viewportType.isSmall === false && inline) {
		dropdownWidth = 250;
		componentWidth = 190;
	}

	const segmentField = (
		<SegmentSelectField
			dropdownWidth={dropdownWidth}
			inTrackedChanges={true}
			name="scope"
			ref={segmentFieldRef}
			segments={segments.listAll()}
			size={viewportType.isSmall ? SegmentSelectFieldSize.Default : SegmentSelectFieldSize.Small}
			websiteScopeName="website"
			width={componentWidth}
		/>
	);

	const segmentFieldIcon = (
		<BasicIcon
			size={14}
			type={BasicIconType.Segment}
		/>
	);

	const segmentFieldLabel = (
		<FormattedMessage {...messages.segmentLabel} />
	);

	const stateField = (
		<MultiselectField
			dropdownWidth={dropdownWidth}
			isOnlyLinkVisible={true}
			name="change"
			options={stateOptions}
			ref={stateFieldRef}
			size={viewportType.isSmall ? MultiselectFieldSize.Default : MultiselectFieldSize.Small}
			width={componentWidth}
		/>
	);

	const stateFieldLabel = (
		<FormattedMessage {...messages.stateLabel} />
	);

	if (viewportType.isSmall) {
		return (
			<Form
				defaultValues={defaultValues}
				onChangeCallback={handleFilterChange}
			>
				<FormRow
					label={stateFieldLabel}
				>
					{stateField}
				</FormRow>

				<FormRow
					label={segmentFieldLabel}
					labelIcon={segmentFieldIcon}
				>
					{segmentField}
				</FormRow>
			</Form>
		);
	}

	return (
		<Form
			defaultValues={defaultValues}
			onChangeCallback={handleFilterChange}
		>
			<IssuesFilterSection inline={inline}>
				<FilterFieldLayout
					field={stateField}
					label={(
						<TableLabel
							label={stateFieldLabel}
						/>
					)}
				/>

				<FilterFieldLayout
					field={segmentField}
					label={(
						<TableLabel
							label={segmentFieldLabel}
							labelIcon={segmentFieldIcon}
						/>
					)}
				/>
			</IssuesFilterSection>
		</Form>
	);
};



export default ComparisonFilter;
