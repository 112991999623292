import type GraphQL from '~/types/graphql';



type Definition = {
	choosePlan: (input: { billingCycle: GraphQL.Term, details: Record<string, any> }) => string,
	convertDetailsToAddons: (input: Record<string, any>) => {},
	convertPagesToAddons: (pagesAmount: number) => Record<string, number>,
	normalizePageCapacity: (pageCapacity: number) => number,
	normalizePagesAmount: (pagesAmount: number) => number | null,
};



class Tariff {

	definition: Definition;



	constructor(definition: Definition) {
		this.definition = definition;
	}



	choosePlan(billingCycle: GraphQL.Term, details: Record<string, any>): string {
		return this.definition.choosePlan({
			billingCycle,
			details,
		});
	}



	convertDetailsToAddons(details: Record<string, any>): {} {
		return this.definition.convertDetailsToAddons(details);
	}



	convertPagesToAddons(numberOfPages: number): Record<string, any> {
		return this.definition.convertPagesToAddons(numberOfPages);
	}



	normalizePageCapacity(pageCapacity: number): number {
		return this.definition.normalizePageCapacity(
			Math.max(1, pageCapacity),
		);
	}



	normalizePagesAmount(numberOfPages: number): number | null {
		return this.definition.normalizePagesAmount(numberOfPages);
	}

}



export function createTariff(definition: Definition) {
	return new Tariff(definition);
}
