/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DiagnoseExternalDomainQueryVariables = GraphQL.Exact<{
  domain: GraphQL.Scalars['String']['input'];
}>;


export type DiagnoseExternalDomainQuery = { readonly __typename?: 'Query', readonly diagnoseExternalDomain: { readonly __typename?: 'DiagnoseExternalDomainResult', readonly externalDomainInfo: ReadonlyArray<{ readonly __typename?: 'ExternalDomainInfo', readonly averageFetchingRatePerCycle: number, readonly countAll: number, readonly createdAt: CK.Timestamp, readonly fetchingConnectTimeout: number, readonly fetchingCookies: ReadonlyArray<string>, readonly fetchingHostIpAddress: string | null, readonly fetchingHttpHeaders: ReadonlyArray<Record<string, any>>, readonly fetchingIpPool: GraphQL.FetchingIpPool, readonly fetchingLocation: string, readonly fetchingSizeLimit: number, readonly fetchingTimeout: number, readonly fetchingUserAgent: string | null, readonly isCrawlingStopped: boolean, readonly websiteId: CK.WebsiteId }> } | null };


export const DiagnoseExternalDomainDocument = gql`
    query DiagnoseExternalDomain($domain: String!) {
  diagnoseExternalDomain(domain: $domain) {
    externalDomainInfo {
      averageFetchingRatePerCycle
      countAll
      createdAt
      fetchingConnectTimeout
      fetchingCookies
      fetchingHostIpAddress
      fetchingHttpHeaders
      fetchingIpPool
      fetchingLocation
      fetchingSizeLimit
      fetchingTimeout
      fetchingUserAgent
      isCrawlingStopped
      websiteId
    }
  }
}
    `;

/**
 * __useDiagnoseExternalDomainQuery__
 *
 * To run a query within a React component, call `useDiagnoseExternalDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiagnoseExternalDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiagnoseExternalDomainQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useDiagnoseExternalDomainQuery(baseOptions: Apollo.QueryHookOptions<DiagnoseExternalDomainQuery, DiagnoseExternalDomainQueryVariables> & ({ variables: DiagnoseExternalDomainQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiagnoseExternalDomainQuery, DiagnoseExternalDomainQueryVariables>(DiagnoseExternalDomainDocument, options);
      }
export function useDiagnoseExternalDomainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiagnoseExternalDomainQuery, DiagnoseExternalDomainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiagnoseExternalDomainQuery, DiagnoseExternalDomainQueryVariables>(DiagnoseExternalDomainDocument, options);
        }
export function useDiagnoseExternalDomainSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DiagnoseExternalDomainQuery, DiagnoseExternalDomainQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DiagnoseExternalDomainQuery, DiagnoseExternalDomainQueryVariables>(DiagnoseExternalDomainDocument, options);
        }
export type DiagnoseExternalDomainQueryHookResult = ReturnType<typeof useDiagnoseExternalDomainQuery>;
export type DiagnoseExternalDomainLazyQueryHookResult = ReturnType<typeof useDiagnoseExternalDomainLazyQuery>;
export type DiagnoseExternalDomainSuspenseQueryHookResult = ReturnType<typeof useDiagnoseExternalDomainSuspenseQuery>;
export type DiagnoseExternalDomainQueryResult = Apollo.QueryResult<DiagnoseExternalDomainQuery, DiagnoseExternalDomainQueryVariables>;