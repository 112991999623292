import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import BasicIcon from '~/components/patterns/icons/BasicIcon';
import FakeLink from '~/components/patterns/links/FakeLink';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import SelectField, {
	type SelectFieldRef,
} from '~/components/atoms/forms/components/SelectField';

import useMessagingAppChannels from '~/hooks/useMessagingAppChannels';
import useMessagingAppDefinitions from '~/hooks/useMessagingAppDefinitions';
import usePremiumFeatureSituation from '~/hooks/usePremiumFeatureSituation';
import useRegisterSlackMessagingAppChannel from '~/hooks/useRegisterSlackMessagingAppChannel';

import matchAndReturn from '~/utilities/matchAndReturn';



const messages = defineMessages({
	addMicrosoftTeamsChannel: {
		id: 'ui.messagingApps.addMicrosoftTeamsChannel',
	},
	addSlackChannel: {
		id: 'ui.messagingApps.addSlackChannel',
	},
	connect: {
		id: 'ui.slack.connect',
	},
	connectNudge: {
		id: 'ui.alertsConfiguration.messagingApp.connectNudge',
	},
	label: {
		id: 'ui.alertsConfiguration.fields.messagingApp',
	},
	none: {
		id: 'ui.alertsConfiguration.messagingApp.none',
	},
});



export type MessagingAppChannelFieldRowRef = {
	setMessageChannelId: (messageChannelId: string) => void,
};



type Props = {
	name: string,
	onInitiateAddMicrosoftChannel: () => void,
};

const MessagingAppChannelFieldRow = React.forwardRef<MessagingAppChannelFieldRowRef, Props>((props, ref) => {
	const {
		name,
		onInitiateAddMicrosoftChannel,
	} = props;

	const messagingAppChannels = useMessagingAppChannels();
	const messagingAppDefinitions = useMessagingAppDefinitions();
	const microsoftTeamsPremiumFeatureSituation = usePremiumFeatureSituation(GraphQL.AccountFeature.MicrosoftTeams);
	const slackPremiumFeatureSituation = usePremiumFeatureSituation(GraphQL.AccountFeature.Slack);
	const registerSlackMessagingAppChannel = useRegisterSlackMessagingAppChannel();

	const fieldRef = React.useRef<SelectFieldRef>(null);

	React.useImperativeHandle(ref, () => ({
		setMessageChannelId: (messageChannelId: string) => {
			fieldRef.current?.setValue(messageChannelId);
		},
	}));

	const handleAddSlackChannel = React.useCallback(
		async () => {
			const { registeredMessagingAppChannelId } = await registerSlackMessagingAppChannel();

			fieldRef.current?.setValue(registeredMessagingAppChannelId);
		},
		[
			fieldRef,
			registerSlackMessagingAppChannel,
		],
	);

	const options = React.useMemo(
		() => {
			const result: Array<any> = [];

			result.push({
				label: (
					<FormattedMessage {...messages.none} />
				),
				name: 'none',
			});

			messagingAppDefinitions.listAll().forEach((messagingAppType) => {
				const messagingAppPremiumFeatureSituation = matchAndReturn(messagingAppType, {
					[GraphQL.MessagingAppType.MicrosoftTeams]: microsoftTeamsPremiumFeatureSituation,
					[GraphQL.MessagingAppType.Slack]: slackPremiumFeatureSituation,
				});

				if (messagingAppPremiumFeatureSituation.isFeatureAttainable === false) {
					return;
				}

				result.push({
					divider: true,
				});

				const areMessagingAppChannelsDisabled = messagingAppPremiumFeatureSituation.isFeatureEnabled === false;

				messagingAppChannels.listByType(messagingAppType).forEach((messagingAppChannel) => {
					result.push({
						disabled: areMessagingAppChannelsDisabled,
						icon: (
							<BasicIcon type={messagingAppDefinitions.getIcon(messagingAppType)} />
						),
						label: messagingAppChannel.label,
						labelNative: messagingAppDefinitions.getLabel(messagingAppType) + ': ' + messagingAppChannel.label,
						name: messagingAppChannel.id,
					});
				});

				if (messagingAppType === GraphQL.MessagingAppType.Slack) {
					result.push({
						icon: (
							<BasicIcon type={messagingAppDefinitions.getIcon(messagingAppType)} />
						),
						label: (
							<FakeLink>
								<FormattedMessage {...messages.addSlackChannel} />
							</FakeLink>
						),
						name: 'addSlackChannel',
						onClickCallback: handleAddSlackChannel,
					});
				}

				if (messagingAppType === GraphQL.MessagingAppType.MicrosoftTeams) {
					result.push({
						icon: (
							<BasicIcon type={messagingAppDefinitions.getIcon(messagingAppType)} />
						),
						label: (
							<FakeLink>
								<FormattedMessage {...messages.addMicrosoftTeamsChannel} />
							</FakeLink>
						),
						name: 'addMicrosoftTeamsChannel',
						onClickCallback: onInitiateAddMicrosoftChannel,
					});
				}
			});

			return result;
		},
		[
			handleAddSlackChannel,
			onInitiateAddMicrosoftChannel,
			messagingAppChannels,
			messagingAppDefinitions,
			microsoftTeamsPremiumFeatureSituation,
			slackPremiumFeatureSituation,
		],
	);

	const isEmpty = messagingAppChannels.isLoaded && messagingAppChannels.count === 0;

	return (
		<FormRow
			description={isEmpty && (
				<FormattedMessage {...messages.connectNudge} />
			)}
			htmlFor={name}
			label={(
				<FormattedMessage {...messages.label} />
			)}
		>
			<SelectField
				name={name}
				options={options}
				ref={fieldRef}
			/>
		</FormRow>
	);
});



export default React.memo(MessagingAppChannelFieldRow);
