import type CK from '~/types/contentking';

import {
	useWebsiteQuotasQuery,
} from './useWebsiteQuotas.gql';



function useWebsiteQuotas(websiteId: CK.WebsiteId | null) {
	const { data } = useWebsiteQuotasQuery({
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	return data?.website?.quotas ?? null;
}



export default useWebsiteQuotas;
