import React from 'react';

import CountSliderContext from '~/components/atoms/forms/components/countSliders/abstractBuilders/CountSliderContext';

import {
	type TailoredTariff,
} from '~/model/pricing/tailoredTariff';

import {
	createPageBundleRange,
} from '~/model/rangeChoices';

import {
	type RenderProp,
} from '~/utilities/renderProp';



type Props = {
	children?: RenderProp<any>,
	minimumNumberOfPages: number,
	maximumPageBundle?: number | null,
	name: string,
	showExtraStep: boolean,
	tariff: TailoredTariff,
	value: number,
};

const PageBundleFieldContext: React.FC<Props> = (props) => {
	const {
		children,
		minimumNumberOfPages,
		maximumPageBundle = null,
		name,
		showExtraStep,
		tariff,
		value,
	} = props;

	const roundValue = React.useCallback(
		(pageBundle: number) => {
			return tariff.normalizePagesAmount(pageBundle);
		},
		[
			tariff,
		],
	);

	let allPossibilities = tariff.listAllPossiblePagesAmounts();

	if (showExtraStep) {
		const lastStep = allPossibilities[allPossibilities.length - 1];
		const nextStep = tariff.normalizePagesAmountOrNull(lastStep + 1);

		if (nextStep !== null) {
			allPossibilities = [
				...allPossibilities,
				nextStep,
			];
		}
	}

	const maxValue = allPossibilities[allPossibilities.length - 1];
	const minValue = allPossibilities[0];

	const maxLimit = maximumPageBundle ?? maxValue;
	const minLimit = tariff.normalizePagesAmount(minimumNumberOfPages);

	const choices = createPageBundleRange(allPossibilities);

	return (
		<CountSliderContext
			ceilTo={roundValue}
			maxLimit={maxLimit}
			maxValue={maxValue}
			minLimit={minLimit}
			minValue={minValue}
			name={name}
			numberOfSteps={choices.size()}
			stepGetter={choices.getStepForValue}
			value={value}
			valueGetter={choices.getValueOfStep}
		>
			{children}
		</CountSliderContext>
	);
};



export default PageBundleFieldContext;
