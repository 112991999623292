import React from 'react';
import {
	FormattedList,
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AdditionalCustomerDetailsSignupModalStep from '~/components/app/SignupModal/AdditionalCustomerDetailsSignupModalStep';
import CustomerDetailsSignupModalStep from '~/components/app/SignupModal/CustomerDetailsSignupModalStep';
import DecentText, {
	DecentTextAlignment,
	DecentTextSize,
} from '~/components/patterns/typography/DecentText';
import FeaturesInUseSignupModalStep from '~/components/app/SignupModal/FeaturesInUseSignupModalStep';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import LegalDocumentPopup, {
	LegalDocument,
} from '~/components/app/LegalDocumentPopup';
import LoadingDots from '~/components/patterns/loaders/LoadingDots';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import MultiStepModal from '~/components/patterns/modals/MultiStepModal';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';
import PaymentMethodSignupModalStep from '~/components/app/SignupModal/PaymentMethodSignupModalStep';
import SignupModalSidebar from './SignupModalSidebar';
import SuccessSignupModalStep from './SuccessSignupModalStep';

import {
	useSignupMutation,
} from './SignupModal.gql';

import useAccountFeaturesUsage from '~/hooks/useAccountFeaturesUsage';
import useAccountId from '~/hooks/useAccountId';
import useAccountIsDeliveryAddressEnabled from '~/hooks/useAccountIsDeliveryAddressEnabled';
import useAccountIsExtraInvoiceDetailsEnabled from '~/hooks/useAccountIsExtraInvoiceDetailsEnabled';
import useAccountSignup from '~/hooks/useAccountSignup';
import useEffectiveAllowedPaymentMethods from '~/hooks/useEffectiveAllowedPaymentMethods';
import useLegalDocumentRequirement from '~/hooks/useLegalDocumentRequirement';
import useNavigation from '~/hooks/useNavigation';
import useSignupCurrency from '~/hooks/useSignupCurrency';

import {
	notEmpty,
} from '~/utilities/typeCheck';



enum FeaturesInUse {
	No = 'no',
	Solved = 'solved',
	Yes = 'yes',
}

const STEP_BILLING_DETAILS = 'billingDetails';
const STEP_FEATURES_IN_USE = 'featuresInUse';
const STEP_INVOICE_DETAILS = 'invoiceDetails';
const STEP_PAYMENT_METHODS = 'paymentMethods';
const STEP_SUCCESS = 'success';



const messages = defineMessages({
	[LegalDocument.DataProcessingAgreement]: {
		id: 'ui.signup.legal.dataProcessingAgreement',
	},
	[LegalDocument.PrivacyPolicy]: {
		id: 'ui.signup.legal.privacyPolicy',
	},
	[LegalDocument.TermsOfUse]: {
		id: 'ui.signup.legal.termsOfUse',
	},
	billingDetailsTitle: {
		id: 'ui.upgradeToPaid.billingDetailsStep.title',
	},
	featuresInUseTitle: {
		id: 'ui.signup.featuresInUse.title',
	},
	invoiceDetailsTitle: {
		id: 'ui.teamDetail.billing.invoiceDetailsTitle',
	},
	legalAgreement: {
		id: 'ui.signup.legal.agreement',
	},
	paymentMethodsTitle: {
		id: 'ui.upgradeToPaid.paymentMethodStep.title',
	},
	successTitle: {
		id: 'ui.upgradeToPaid.congratulations.title',
	},
});



type Props = {
	accountId: CK.AccountId | null,
	closeCallback: () => void,
};

const SignupModal: React.FC<Props> = (props) => {
	const {
		accountId,
		closeCallback,
	} = props;

	const [signup] = useSignupMutation();

	const accountFeaturesUsage = useAccountFeaturesUsage(accountId);
	const accountSignup = useAccountSignup(accountId);
	const isDeliveryAddressEnabled = useAccountIsDeliveryAddressEnabled(accountId) ?? false;
	const isExtraInvoiceDetailsEnabled = useAccountIsExtraInvoiceDetailsEnabled(accountId) ?? false;
	const navigation = useNavigation();
	const signupCurrency = useSignupCurrency(accountId);

	const [showLegalDocument, setShowLegalDocument] = React.useState<LegalDocument | null>(null);

	const dataProcessingAgreementRequirement = useLegalDocumentRequirement(
		accountId,
		GraphQL.LegalDocumentType.Dpa,
	);

	const paymentMethods = useEffectiveAllowedPaymentMethods(
		accountId,
		(
			accountSignup.customerDetails.country
			?? accountSignup.defaultCountry
			?? ''
		),
	);

	const [assumeVatNumberWillBeGiven, setVatNumberAssumption] = React.useState(true);

	let isPickedPlanAvailable: boolean | null = null;

	if (accountFeaturesUsage !== null && accountSignup.plan !== null) {
		isPickedPlanAvailable = accountFeaturesUsage
			.getPlanAvailability(accountSignup.plan)
			.isAvailable;
	}

	const [
		showFeaturesInUseStep,
		setShowFeaturesInUseStep,
	] = React.useState<FeaturesInUse>(isPickedPlanAvailable === false
		? FeaturesInUse.Yes
		: FeaturesInUse.No,
	);

	const handleFinishedSignup = React.useCallback(
		() => {
			navigation.navigate({
				routeName: 'home',
			});
		},
		[
			navigation,
		],
	);

	const isDataProcessingAgreementRequired = (
		dataProcessingAgreementRequirement.document?.requirement === GraphQL.LegalDocumentRequirementType.Required
		|| dataProcessingAgreementRequirement.document?.requirement === GraphQL.LegalDocumentRequirementType.RequiredWithUrgency
	);

	const handlePaymentMethodSubmit = React.useCallback(
		() => {
			if (
				accountId === null
				|| accountSignup.billingCycle === null
				|| accountSignup.pageBundle === null
				|| accountSignup.plan === null
				|| signupCurrency === null
			) {
				return Promise.reject();
			}

			const acceptedLegalDocuments = [
				'tou',
			];

			if (isDataProcessingAgreementRequired) {
				acceptedLegalDocuments.push('dpa');
			}

			return signup({
				variables: {
					acceptedLegalDocuments,
					accountId,
					addons: [],
					billingCycle: accountSignup.billingCycle,
					currency: signupCurrency,
					pageBundle: accountSignup.pageBundle,
					plan: accountSignup.plan,
				},
			});
		},
		[
			accountId,
			accountSignup,
			isDataProcessingAgreementRequired,
			signup,
			signupCurrency,
		],
	);

	React.useEffect(
		() => {
			if (
				accountFeaturesUsage === null
				|| accountSignup.plan === null
				|| showFeaturesInUseStep !== FeaturesInUse.No
			) {
				return;
			}

			const isPickedPlanAvailable = accountFeaturesUsage
				.getPlanAvailability(accountSignup.plan)
				.isAvailable;

			setShowFeaturesInUseStep(
				isPickedPlanAvailable ? FeaturesInUse.No : FeaturesInUse.Yes,
			);
		},
		[
			accountFeaturesUsage,
			accountSignup.plan,
			showFeaturesInUseStep,
		],
	);

	const renderSidebar = React.useCallback(
		({ step }) => {
			if (accountId === null) {
				return null;
			}

			return (
				<SignupModalSidebar
					accountId={accountId}
					allowCustomization={step.name !== STEP_PAYMENT_METHODS}
					assumeVatNumberWillBeGiven={(
						assumeVatNumberWillBeGiven
						&& (step.name === STEP_FEATURES_IN_USE || step.name === STEP_BILLING_DETAILS)
					)}
					customerDetailsProvided={(
						step.name === STEP_INVOICE_DETAILS
						|| step.name === STEP_PAYMENT_METHODS
					)}
				/>
			);
		},
		[
			accountId,
			assumeVatNumberWillBeGiven,
		],
	);

	function renderLegalDocumentLinks() {
		function showDocument(document) {
			return (chunks) => (
				<InternalLink
					onClickCallback={() => setShowLegalDocument(document)}
					style={InternalLinkStyle.Decent}
				>
					{chunks}
				</InternalLink>
			);
		}

		const documents = (
			<FormattedList
				type="conjunction"
				value={[
					<FormattedMessage
						{...messages[LegalDocument.TermsOfUse]}
						key="tou"
						values={{
							showDocument: showDocument(LegalDocument.TermsOfUse),
						}}
					/>,
					isDataProcessingAgreementRequired ? (
						<FormattedMessage
							{...messages[LegalDocument.DataProcessingAgreement]}
							key="dpa"
							values={{
								showDocument: showDocument(LegalDocument.DataProcessingAgreement),
							}}
						/>
					) : null,
				].filter(notEmpty)}
			/>
		);

		return (
			<ModalTextSection centered={true}>
				<DecentText
					alignment={DecentTextAlignment.Center}
					size={DecentTextSize.Small}
				>
					<FormattedMessage
						{...messages.legalAgreement}
						values={{
							documents,
						}}
					/>
				</DecentText>
				<DecentText
					alignment={DecentTextAlignment.Center}
					size={DecentTextSize.Small}
				>
					<FormattedMessage
						{...messages[LegalDocument.PrivacyPolicy]}
						values={{ showDocument: showDocument(LegalDocument.PrivacyPolicy) }}
					/>
				</DecentText>
			</ModalTextSection>
		);
	}

	const areAdditionalCustomerDetailsEnabled = isDeliveryAddressEnabled || isExtraInvoiceDetailsEnabled;

	return (
		<MultiStepModal
			disabledContentOverlay={(showLegalDocument !== null && (
				<LegalDocumentPopup
					onClose={() => setShowLegalDocument(null)}
					type={showLegalDocument}
				/>
			))}
			isContentDisabled={showLegalDocument !== null}
			preloader={accountId === null && (
				<LoadingDots isStretched={true} />
			)}
		>
			{(showFeaturesInUseStep !== FeaturesInUse.No) && (
				<MultiStepModalStep
					breadcrumb={(
						<FormattedMessage {...messages.featuresInUseTitle} />
					)}
					name={STEP_FEATURES_IN_USE}
					sidebar={renderSidebar}
					title={(
						<FormattedMessage {...messages.featuresInUseTitle} />
					)}
				>
					{({ goToNextStep }) => (
						<>
							{accountId !== null && (
								<FeaturesInUseSignupModalStep
									accountId={accountId}
									onCancelCallback={closeCallback}
									onChooseBetterPlan={() => {
										setShowFeaturesInUseStep(FeaturesInUse.Solved);
										goToNextStep();
									}}
									onKeepCurrentPlan={goToNextStep}
								/>
							)}
						</>
					)}
				</MultiStepModalStep>
			)}

			<MultiStepModalStep
				breadcrumb={(
					<FormattedMessage {...messages.billingDetailsTitle} />
				)}
				name={STEP_BILLING_DETAILS}
				sidebar={renderSidebar}
				title={(
					<FormattedMessage {...messages.billingDetailsTitle} />
				)}
			>
				{({ goToNextStep, goToPreviousStep }) => (
					<>
						{accountId !== null && (
							<CustomerDetailsSignupModalStep
								accountId={accountId}
								hasInvoiceDetailsStep={areAdditionalCustomerDetailsEnabled}
								onCancelCallback={showFeaturesInUseStep === FeaturesInUse.Yes ? goToPreviousStep : closeCallback}
								onSuccessCallback={goToNextStep}
								setVatNumberAssumption={setVatNumberAssumption}
								showBack={showFeaturesInUseStep === FeaturesInUse.Yes}
							/>
						)}
					</>
				)}
			</MultiStepModalStep>

			{areAdditionalCustomerDetailsEnabled && (
				<MultiStepModalStep
					breadcrumb={(
						<FormattedMessage {...messages.invoiceDetailsTitle} />
					)}
					name={STEP_INVOICE_DETAILS}
					sidebar={renderSidebar}
					title={(
						<FormattedMessage {...messages.invoiceDetailsTitle} />
					)}
				>
					{({ goToNextStep, isActive }) => (
						<>
							{accountId !== null && (
								<AdditionalCustomerDetailsSignupModalStep
									accountId={accountId}
									isDeliveryAddressEnabled={isDeliveryAddressEnabled}
									isExtraInvoiceDetailsEnabled={isExtraInvoiceDetailsEnabled}
									isModalStepActive={isActive}
									onSuccessCallback={goToNextStep}
								/>
							)}
						</>
					)}
				</MultiStepModalStep>
			)}

			<MultiStepModalStep
				breadcrumb={(
					<FormattedMessage {...messages.paymentMethodsTitle} />
				)}
				name={STEP_PAYMENT_METHODS}
				sidebar={renderSidebar}
				title={(
					<FormattedMessage {...messages.paymentMethodsTitle} />
				)}
			>
				{({ goToPreviousStep, goToNextStep, isActive }) => (
					<>
						{accountId !== null && paymentMethods !== null && (
							<PaymentMethodSignupModalStep
								accountId={accountId}
								cancelCallback={goToPreviousStep}
								email={accountSignup.email}
								isModalStepActive={isActive}
								legalDocumentsLinks={renderLegalDocumentLinks()}
								onFinishedSignupCallback={goToNextStep}
								onPaymentMethodSubmitCallback={handlePaymentMethodSubmit}
								paymentMethods={paymentMethods}
							/>
						)}
					</>
				)}
			</MultiStepModalStep>

			<MultiStepModalStep
				name={STEP_SUCCESS}
				title={(
					<FormattedMessage {...messages.successTitle} />
				)}
			>
				{({ isActive }) => (
					<SuccessSignupModalStep
						isActive={isActive}
						onCloseCallback={handleFinishedSignup}
					/>
				)}
			</MultiStepModalStep>
		</MultiStepModal>
	);
};

const SignupModalWrapper = (props) => {
	const accountId = useAccountId();

	return (
		<SignupModal
			accountId={accountId}
			{...props}
		/>
	);
};



export default SignupModalWrapper;
