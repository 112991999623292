import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import BasicIcon, {
	AWSCloudFrontIconVariants,
	AkamaiIconVariants,
	BasicIconType,
	CloudflareIconVariants,
	FastlyIconVariants,
} from '~/components/patterns/icons/BasicIcon';
import Hint from '~/components/patterns/hints/hint/Hint';



const messages = defineMessages({
	[GraphQL.LogSourceProvider.Akamai]: {
		id: 'ui.logSourceProvider.akamai',
	},
	[GraphQL.LogSourceProvider.Cloudflare]: {
		id: 'ui.logSourceProvider.cloudflare',
	},
	[GraphQL.LogSourceProvider.CloudflareLogpush]: {
		id: 'ui.logSourceProvider.cloudflare',
	},
	[GraphQL.LogSourceProvider.Cloudfront]: {
		id: 'ui.logSourceProvider.cloudfront',
	},
	[GraphQL.LogSourceProvider.Fastly]: {
		id: 'ui.logSourceProvider.fastly',
	},
	[GraphQL.LogSourceStatus.Available]: {
		id: 'ui.logSourceStatus.available',
	},
	[GraphQL.LogSourceStatus.Enabled]: {
		id: 'ui.logSourceStatus.enabled',
	},
	[GraphQL.LogSourceStatus.NotAvailable]: {
		id: 'ui.logSourceStatus.notAvailable',
	},
	tooltip: {
		id: 'ui.logSourceProviderIcon.tooltip',
	},
});



type IconMapping = {
	[provider in GraphQL.LogSourceProvider]: {
		[status in GraphQL.LogSourceStatus]: React.ReactNode
	}
};

const iconMapping: IconMapping = {
	[GraphQL.LogSourceProvider.Akamai]: {
		[GraphQL.LogSourceStatus.Enabled]: (
			<BasicIcon
				type={BasicIconType.Akamai}
				variant={AkamaiIconVariants.Default}
			/>
		),
		[GraphQL.LogSourceStatus.Available]: (
			<BasicIcon
				type={BasicIconType.Akamai}
				variant={AkamaiIconVariants.NotUsed}
			/>
		),
		[GraphQL.LogSourceStatus.NotAvailable]: (
			<BasicIcon
				type={BasicIconType.Akamai}
				variant={AkamaiIconVariants.NotAvailable}
			/>
		),
	},
	[GraphQL.LogSourceProvider.Cloudflare]: {
		[GraphQL.LogSourceStatus.Enabled]: (
			<BasicIcon
				type={BasicIconType.Cloudflare}
				variant={CloudflareIconVariants.Default}
			/>
		),
		[GraphQL.LogSourceStatus.Available]: (
			<BasicIcon
				type={BasicIconType.Cloudflare}
				variant={CloudflareIconVariants.NotUsed}
			/>
		),
		[GraphQL.LogSourceStatus.NotAvailable]: (
			<BasicIcon
				type={BasicIconType.Cloudflare}
				variant={CloudflareIconVariants.NotAvailable}
			/>
		),
	},
	[GraphQL.LogSourceProvider.CloudflareLogpush]: {
		[GraphQL.LogSourceStatus.Enabled]: (
			<BasicIcon
				type={BasicIconType.Cloudflare}
				variant={CloudflareIconVariants.Default}
			/>
		),
		[GraphQL.LogSourceStatus.Available]: (
			<BasicIcon
				type={BasicIconType.Cloudflare}
				variant={CloudflareIconVariants.NotUsed}
			/>
		),
		[GraphQL.LogSourceStatus.NotAvailable]: (
			<BasicIcon
				type={BasicIconType.Cloudflare}
				variant={CloudflareIconVariants.NotAvailable}
			/>
		),
	},
	[GraphQL.LogSourceProvider.Cloudfront]: {
		[GraphQL.LogSourceStatus.Enabled]: (
			<BasicIcon
				type={BasicIconType.AWSCloudFront}
				variant={AWSCloudFrontIconVariants.Default}
			/>
		),
		[GraphQL.LogSourceStatus.Available]: (
			<BasicIcon
				type={BasicIconType.AWSCloudFront}
				variant={AWSCloudFrontIconVariants.NotUsed}
			/>
		),
		[GraphQL.LogSourceStatus.NotAvailable]: (
			<BasicIcon
				type={BasicIconType.AWSCloudFront}
				variant={AWSCloudFrontIconVariants.NotAvailable}
			/>
		),
	},
	[GraphQL.LogSourceProvider.Fastly]: {
		[GraphQL.LogSourceStatus.Enabled]: (
			<BasicIcon
				type={BasicIconType.Fastly}
				variant={FastlyIconVariants.Default}
			/>
		),
		[GraphQL.LogSourceStatus.Available]: (
			<BasicIcon
				type={BasicIconType.Fastly}
				variant={FastlyIconVariants.NotUsed}
			/>
		),
		[GraphQL.LogSourceStatus.NotAvailable]: (
			<BasicIcon
				type={BasicIconType.Fastly}
				variant={FastlyIconVariants.NotAvailable}
			/>
		),
	},
};



type Props = {
	provider: GraphQL.LogSourceProvider,
	status: GraphQL.LogSourceStatus,
};

const LogSourceProviderIcon: React.FC<Props> = (props) => {
	const {
		provider,
		status,
	} = props;

	const icon = iconMapping[provider][status];

	const popup = (
		<FormattedMessage
			{...messages.tooltip}
			values={{
				provider: <FormattedMessage {...messages[provider]} />,
				status: (
					<FormattedMessage
						{...messages[status]}
						values={{ provider }}
					/>
				),
			}}
		/>
	);

	return (
		<Hint
			popup={popup}
			popupOffsetSkidding={5}
		>
			{icon}
		</Hint>
	);
};



export default LogSourceProviderIcon;
