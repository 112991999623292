import type Immutable from 'immutable';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import CK from '~/types/contentking';

import AlertStatusCellValue from '../datatables/cellValues/AlertStatusCellValue';
import ColumnName from '~/components/names/ColumnName';
import ExpandableFilterOperatorHeaderLabel from '~/components/logic/filters/ExpandableFilterOperatorHeaderLabel';
import ExtraFieldFormatter from '~/components/logic/alertPages/ExtraFieldFormatter';
import ImportanceFieldFilter from '../datatables/ImportanceFieldFilter';
import NoSearchResults from '~/components/patterns/messages/embedded/NoSearchResults';
import PowerStaticTextFieldFilter from '../datatables/PowerStaticTextFieldFilter';
import RelevanceCellValue from '../datatables/cellValues/RelevanceCellValue';
import ResizableDatatable, {
	type ResizableDatatableColumnDefinition,
	ResizableDatatableRowHeight,
} from '../datatables/ResizableDatatable';
import StatusFilter from './components/StatusFilter';
import UrlCellValue from '../datatables/cellValues/UrlCellValue';

import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	updateFilter,
	updateSortBy,
} from '~/actions/alertPages';

import {
	listApplicableStatuses,
} from '~/model/alertPages';

import {
	copyUrlIdToClipboardItem,
	copyUrlToClipboardItem,
	openPageDetailItem,
	openPageOnWebsiteItem,
} from '~/model/contextMenu';

import {
	getColumnDefaultWidth,
	isColumn,
} from '~/model/pagesColumns';

import goTo from '~/routing/goTo';
import {
	getRouter,
} from '~/routing/router';

import {
	currentDataSelector,
	currentFilterSelector as filterSelector,
	loadingSelector,
	currentSortBySelector as sortBySelector,
} from '~/state/alertPages/selectors';

import {
	isString,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	alertStatusHeading: {
		id: 'ui.contentOverview.tableHeading.alertStatus',
	},
});



export type AlertPagesDatatableColumn = CK.PagesColumn | {
	name: string,
	render: {
		cell: (input: { row: Immutable.Map<string, any> }) => React.ReactNode,
		filter?: (input: { filterName: string, filterWidth: number, ref: any }) => React.ReactNode,
		label: () => React.ReactNode,
		labelFilterOperator?: () => React.ReactNode,
	},
	resizable?: boolean,
	sortOrder?: boolean,
	width: number,
};

export type AlertPagesDatatableIncident = Immutable.Map<string, any>;

export type AlertPagesDatatableRef = {
	scrollToColumn: (columnName: string) => void,
};



type Props = {
	extraColumns: Array<AlertPagesDatatableColumn>,
	incident: AlertPagesDatatableIncident,
	linkRouteName?: string,
	linkRouteParams?: Record<string, any>,
	scrollCallback: () => void,
	showImportance: boolean,
};

const AlertPagesDatatable = React.forwardRef<AlertPagesDatatableRef, Props>((props, ref) => {
	const {
		extraColumns,
		incident,
		linkRouteName = 'website.pages.detail.history',
		linkRouteParams = {},
		scrollCallback,
		showImportance,
	} = props;

	const alertPages = useSelector(currentDataSelector);
	const dataLoading = useSelector(loadingSelector);
	const dispatch = useDispatch();
	const filter = useSelector(filterSelector);
	const kingdomAdminFeatures = useKingdomAdminFeatures();
	const sortBy = useSelector(sortBySelector);
	const websiteId = useWebsiteId();

	const datatableRef = React.useRef<any>();

	React.useImperativeHandle(ref, () => ({
		scrollToColumn: (columnName) => {
			if (datatableRef.current) {
				datatableRef.current.getWrappedInstance().scrollToColumn(columnName);
			}
		},
	}));

	const contextMenuEntries = React.useCallback(
		({ row }) => {
			const pageDetailUrl = getRouter().buildPath(linkRouteName, {
				websiteId,
				id: row.get('id'),
				...linkRouteParams,
			});

			const result = [
				openPageDetailItem(pageDetailUrl),
				openPageOnWebsiteItem(row.get('url')),
				copyUrlToClipboardItem(row.get('url')),
			];

			if (kingdomAdminFeatures.areVisible) {
				result.push(
					copyUrlIdToClipboardItem(row.get('unique_id')),
				);
			}

			return result;
		},
		[
			kingdomAdminFeatures,
			linkRouteName,
			linkRouteParams,
			websiteId,
		],
	);

	const handleFilterChange = React.useCallback(
		(filter) => {
			dispatch(
				updateFilter(filter),
			);
		},
		[
			dispatch,
		],
	);

	const handleRowClick = React.useCallback(
		({ row }, event) => {
			goTo(
				event,
				linkRouteName,
				{
					id: row.get('id'),
					websiteId,
					...linkRouteParams,
				},
			);
		},
		[
			linkRouteName,
			linkRouteParams,
			websiteId,
		],
	);

	const handleSort = React.useCallback(
		({ key, direction }) => {
			dispatch(
				updateSortBy({
					key,
					direction,
				}),
			);
		},
		[
			dispatch,
		],
	);

	const rowGetter = React.useCallback(
		({ rowIndex }) => {
			if (!alertPages.get('range').get(rowIndex)) {
				return false;
			}

			return alertPages.get('pages').get(
				alertPages.get('range').get(rowIndex).toString(),
			);
		},
		[
			alertPages,
		],
	);

	const renderBlankSlate = React.useCallback(
		() => (
			<NoSearchResults />
		),
		[],
	);

	const incidentSettings = incident.get('settings');
	const incidentType = incident.get('type');

	const states = React.useMemo(
		() => {
			return listApplicableStatuses(
				incidentType,
				incidentSettings,
			);
		},
		[
			incidentSettings,
			incidentType,
		],
	);

	const columns = React.useMemo(
		() => {
			const result: Array<ResizableDatatableColumnDefinition<any>> = [
				{
					name: CK.PagesCommonColumn.Url,
					render: {
						cell({ row }) {
							return (
								<UrlCellValue value={row.get(CK.PagesCommonColumn.Url)} />
							);
						},
						filter: ({ filterName, filterWidth, ref }) => (
							<PowerStaticTextFieldFilter
								name={filterName}
								ref={ref}
								width={filterWidth}
							/>
						),
						label: () => (
							<ColumnName column={CK.PagesCommonColumn.Url} />
						),
						labelFilterOperator: () => (
							<ExpandableFilterOperatorHeaderLabel
								value={filter.get(CK.PagesCommonColumn.Url)}
							/>
						),
					},
					sortOrder: true,
					width: 400,
				},
				{
					filterName: 'status',
					name: 'alert_status',
					render: {
						cell({ row }) {
							return (
								<AlertStatusCellValue value={row.get('status')} />
							);
						},
						filter: ({ filterName, filterWidth, ref }) => (
							<StatusFilter
								name={filterName}
								ref={ref}
								states={states}
								width={filterWidth}
							/>
						),
						label: () => (
							<FormattedMessage {...messages.alertStatusHeading} />
						),
					},
					sortName: 'status',
					sortOrder: true,
					width: 250,
				},
			];

			if (showImportance) {
				result.push({
					name: CK.PagesCommonColumn.Relevance,
					render: {
						cell({ row }) {
							return (
								<RelevanceCellValue value={row.get(CK.PagesCommonColumn.Relevance)} />
							);
						},
						filter: ({ filterName, filterWidth, ref }) => (
							<ImportanceFieldFilter
								name={filterName}
								ref={ref}
								width={filterWidth}
							/>
						),
						label: () => (
							<ColumnName column={CK.PagesCommonColumn.Relevance} />
						),
					},
					sortOrder: true,
					width: 150,
				});
			}

			return [
				...result,
				...extraColumns.map(
					(extraColumn) => {
						if (isString(extraColumn) && isColumn(extraColumn)) {
							return {
								name: extraColumn,
								render: {
									cell: ({ row }) => (
										<ExtraFieldFormatter
											column={extraColumn}
											row={row}
										/>
									),
									label: () => (
										<ColumnName column={extraColumn} />
									),
								},
								resizable: true,
								width: getColumnDefaultWidth(extraColumn),
							};
						}

						return extraColumn;
					},
				),
			];
		},
		[
			extraColumns,
			filter,
			showImportance,
			states,
		],
	);

	return (
		<ResizableDatatable
			blankSlate={renderBlankSlate}
			columns={columns as any}
			contextMenuEntries={contextMenuEntries}
			filter={filter}
			isLoading={dataLoading}
			onFilterChange={handleFilterChange}
			onRowClick={handleRowClick}
			onScroll={scrollCallback}
			onSortChangeCallback={handleSort}
			ref={datatableRef}
			rowGetter={rowGetter}
			rowHeightStyle={ResizableDatatableRowHeight.Medium}
			rowsCount={alertPages.get('total') || 0}
			sortBy={sortBy}
		/>
	);
});



export default AlertPagesDatatable;
