/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InvalidRobotsTxtSyntaxTableQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type InvalidRobotsTxtSyntaxTableQuery = { readonly __typename?: 'Query', readonly robotsTxtRevisionLatest: { readonly __typename?: 'RobotsTxtRevision', readonly id: CK.ID, readonly linesWithInvalidSyntax: ReadonlyArray<{ readonly __typename?: 'RobotsTxtRevisionLineWithInvalidSyntax', readonly lineNumber: number, readonly lineContent: string }> } | null };


export const InvalidRobotsTxtSyntaxTableDocument = gql`
    query InvalidRobotsTxtSyntaxTable($websiteId: WebsiteId!) {
  robotsTxtRevisionLatest(websiteId: $websiteId) {
    id
    linesWithInvalidSyntax {
      lineNumber
      lineContent
    }
  }
}
    `;

/**
 * __useInvalidRobotsTxtSyntaxTableQuery__
 *
 * To run a query within a React component, call `useInvalidRobotsTxtSyntaxTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvalidRobotsTxtSyntaxTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvalidRobotsTxtSyntaxTableQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useInvalidRobotsTxtSyntaxTableQuery(baseOptions: Apollo.QueryHookOptions<InvalidRobotsTxtSyntaxTableQuery, InvalidRobotsTxtSyntaxTableQueryVariables> & ({ variables: InvalidRobotsTxtSyntaxTableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvalidRobotsTxtSyntaxTableQuery, InvalidRobotsTxtSyntaxTableQueryVariables>(InvalidRobotsTxtSyntaxTableDocument, options);
      }
export function useInvalidRobotsTxtSyntaxTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvalidRobotsTxtSyntaxTableQuery, InvalidRobotsTxtSyntaxTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvalidRobotsTxtSyntaxTableQuery, InvalidRobotsTxtSyntaxTableQueryVariables>(InvalidRobotsTxtSyntaxTableDocument, options);
        }
export function useInvalidRobotsTxtSyntaxTableSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InvalidRobotsTxtSyntaxTableQuery, InvalidRobotsTxtSyntaxTableQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InvalidRobotsTxtSyntaxTableQuery, InvalidRobotsTxtSyntaxTableQueryVariables>(InvalidRobotsTxtSyntaxTableDocument, options);
        }
export type InvalidRobotsTxtSyntaxTableQueryHookResult = ReturnType<typeof useInvalidRobotsTxtSyntaxTableQuery>;
export type InvalidRobotsTxtSyntaxTableLazyQueryHookResult = ReturnType<typeof useInvalidRobotsTxtSyntaxTableLazyQuery>;
export type InvalidRobotsTxtSyntaxTableSuspenseQueryHookResult = ReturnType<typeof useInvalidRobotsTxtSyntaxTableSuspenseQuery>;
export type InvalidRobotsTxtSyntaxTableQueryResult = Apollo.QueryResult<InvalidRobotsTxtSyntaxTableQuery, InvalidRobotsTxtSyntaxTableQueryVariables>;