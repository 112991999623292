import type Immutable from 'immutable';
import React from 'react';

import Hint, {
	HintAttachment,
} from '~/components/patterns/hints/hint/Hint';
import SegmentDefinitionIdentifier, {
	SegmentDefinitionIdentifierChange,
	SegmentDefinitionIdentifierSize,
} from '~/components/logic/segments/SegmentDefinitionIdentifier';
import SegmentIdentifierPlaceholder, {
	SegmentIdentifierPlaceholderSize,
} from '~/components/patterns/segments/SegmentIdentifierPlaceholder';
import SegmentsList, {
	SIZE_DEFAULT as LIST_SIZE_DEFAULT,
	SIZE_SMALL as LIST_SIZE_SMALL,
} from '~/components/atoms/segments/SegmentsList';

import {
	type SegmentDefinition,
	filterSegmentDefinitionsByNames,
} from '~/model/segments';

import matchAndReturn from '~/utilities/matchAndReturn';

import {
	isImmutableList,
} from '~/utilities/typeCheck';



type Props = {
	segmentChanges?: Record<string, SegmentDefinitionIdentifierChange>,
	segmentDefinitions: ReadonlyArray<SegmentDefinition>,
	segmentNames: Immutable.List<string> | ReadonlyArray<string>,
	size?: SegmentDefinitionIdentifierSize,
	width?: number,
};

const SegmentLabelThumbList: React.FC<Props> = (props) => {
	const {
		segmentChanges = {},
		segmentDefinitions,
		segmentNames,
		size = SegmentDefinitionIdentifierSize.Default,
		width = 250,
	} = props;

	const segments = filterSegmentDefinitionsByNames(
		segmentDefinitions,
		isImmutableList(segmentNames)
			? segmentNames.toArray()
			: segmentNames,
	);

	const getVisibleThumbsCount = () => {
		if (segments.length === 0) {
			return 0;
		}

		const COLUMN_PADDING = 40;
		const SEGMENT_THUMB_PADDING = size === SegmentDefinitionIdentifierSize.Default ? 6 : 4;
		const SEGMENT_THUMB_WIDTH = size === SegmentDefinitionIdentifierSize.Default ? 28 : 22;

		const maxVisibleSegmentsCount = Math.max(
			segments.length === 1 ? 1 : 0,
			Math.floor((width - COLUMN_PADDING) / (SEGMENT_THUMB_WIDTH + SEGMENT_THUMB_PADDING)) - 1,
		);

		return segments.length > (maxVisibleSegmentsCount + 1) ? maxVisibleSegmentsCount : segments.length;
	};

	const visibleThumbsCount = getVisibleThumbsCount();

	const renderVisibleSegmentThumbs = () => {
		const renderableSegments = segments.slice(0, visibleThumbsCount);

		return renderableSegments.map((segmentDefinition) => (
			<SegmentDefinitionIdentifier
				change={segmentChanges[segmentDefinition.name]}
				key={segmentDefinition.name}
				onlyThumb={true}
				segmentDefinition={segmentDefinition}
				size={size}
			/>
		));
	};

	const renderHiddenSegments = () => {
		const renderableSegments = segments.slice(visibleThumbsCount, segments.length);

		return (
			<SegmentsList>
				{renderableSegments.map((segmentDefinition) => (
					<SegmentDefinitionIdentifier
						change={segmentChanges[segmentDefinition.name]}
						key={segmentDefinition.name}
						segmentDefinition={segmentDefinition}
						showCriteria={false}
					/>
				))}
			</SegmentsList>
		);
	};

	const renderHiddenSegmentThumbs = () => {
		if (segments.length <= visibleThumbsCount) {
			return false;
		}

		return (
			<Hint
				attachment={HintAttachment.Right}
				blurDelay={250}
				popup={renderHiddenSegments()}
				popupOffsetSkidding={5}
				popupZIndex={10000}
			>
				<SegmentIdentifierPlaceholder
					label={'+' + (segments.length - visibleThumbsCount)}
					size={matchAndReturn(size, {
						[SegmentDefinitionIdentifierSize.Default]: SegmentIdentifierPlaceholderSize.Default,
						[SegmentDefinitionIdentifierSize.Small]: SegmentIdentifierPlaceholderSize.Small,
					})}
				/>
			</Hint>
		);
	};

	return (
		<SegmentsList
			inline={true}
			size={matchAndReturn(size, {
				[SegmentDefinitionIdentifierSize.Default]: LIST_SIZE_DEFAULT,
				[SegmentDefinitionIdentifierSize.Small]: LIST_SIZE_SMALL,
			})}
		>
			{renderVisibleSegmentThumbs()}
			{renderHiddenSegmentThumbs()}
		</SegmentsList>
	);
};



export default React.memo(SegmentLabelThumbList);

export {
	SegmentDefinitionIdentifierChange as SegmentLabelThumbListChange,
	SegmentDefinitionIdentifierSize as SegmentLabelThumbListSize,
};
