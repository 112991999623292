import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import useAccountDetailedDiscounts from '~/hooks/useAccountDetailedDiscounts';
import useAccountSignup from './useAccountSignup';
import useAccountTariff from './useAccountTariff';
import useAccountTaxRate from '~/hooks/useAccountTaxRate';
import useAllowedPlans from '~/hooks/useAllowedPlans';
import useCalculateTax from '~/hooks/useCalculateTax';
import useSignupCurrency from '~/hooks/useSignupCurrency';
import useTariffs from './useTariffs';

import {
	applyBillingCycleToCost,
} from '~/model/pricing/billingCycle';

import {
	calculatePurchaseCostDetails,
} from '~/model/pricing/costs';

import {
	DETAIL_PLAN,
} from '~/model/pricing/universal';



type CalculatePrice = ({ plan }: { plan: GraphQL.AccountPlan }) => {
	billingCycle: GraphQL.Term,
	discounts: Array<any>,
	discountPercentage: number,
	discountSum: number,
	priceWithoutDiscounts: number,
	priceWithoutDiscountsMonthly: number,
	priceWithDiscounts: number,
	priceWithDiscountsMonthly: number,
	tax: number,
	taxIsEstimate: boolean,
	taxRate: number,
	taxType: GraphQL.TaxType | null,
	totalPrice: number,
};

type ReturnType = {
	isReady: false,
	billingCycle: null,
	calculatePrice: null,
	currency: GraphQL.Currency,
} | {
	isReady: true,
	billingCycle: GraphQL.Term,
	calculatePrice: CalculatePrice,
	currency: GraphQL.Currency,
};

function useSignupPrice(
	accountId: CK.AccountId,
	customerDetailsProvided: boolean,
	assumeVatNumber?: boolean,
): ReturnType {
	const accountDiscounts = useAccountDetailedDiscounts(accountId);
	const accountSignup = useAccountSignup(accountId);
	const accountTariff = useAccountTariff(accountId);
	const accountTax = useAccountTaxRate(accountId);
	const allowedPlans = useAllowedPlans(accountId);
	const currency = useSignupCurrency(accountId);

	const {
		billingCycle,
		customerDetails,
		defaultCountry,
		pageBundle,
	} = accountSignup;

	const country = customerDetails.country ?? defaultCountry;
	let vatNumber = customerDetails.vatNumber;

	if (!vatNumber && (assumeVatNumber || country === 'GB')) {
		vatNumber = 'foo';
	}

	const calculatedTax = useCalculateTax(
		country,
		customerDetails.state,
		accountTariff,
		vatNumber,
	);

	const tariffs = useTariffs();

	if (
		accountDiscounts === null
		|| accountTariff === null
		|| allowedPlans === null
		|| billingCycle === null
		|| currency === null
		|| pageBundle === null
		|| tariffs.isReady === false
	) {
		return {
			isReady: false,
			billingCycle: null,
			calculatePrice: null,
			currency: GraphQL.Currency.Eur,
		};
	}

	return {
		isReady: true,
		billingCycle,
		calculatePrice: ({ plan }: { plan: GraphQL.AccountPlan }) => {
			const discounts = accountDiscounts.map((discount) => {
				if (discount.type === GraphQL.DiscountType.Dollars) {
					if (discount.amount === null) {
						throw new Error(`discount.amount can't be null on Dollars discount`);
					}

					return {
						amount: Object.fromEntries(
							discount.amount.map(({ currency, value }) => [currency, value / 100]),
						) as Record<GraphQL.Currency, number>,
						code: discount.coupon,
						type: discount.type,
					};
				}

				if (discount.percentage === null) {
					throw new Error(`discount.rate can't be null on Percent discount`);
				}

				return {
					code: discount.coupon,
					rate: discount.percentage / 100,
					type: discount.type,
				};
			});

			const effectiveBillingCycle = (plan === GraphQL.AccountPlan.Enterprise && !allowedPlans.includes(plan))
				? GraphQL.Term.Annually
				: billingCycle;

			const costDetails = calculatePurchaseCostDetails({
				billingCycle: effectiveBillingCycle,
				currency,
				discounts,
				purchases: [
					{
						details: {
							[DETAIL_PLAN]: plan,
						},
						numberOfPages: pageBundle,
					},
				],
				tariff: accountTariff,
				tariffs: tariffs.tariffs,
				taxRate: customerDetailsProvided
					? (accountTax?.rate ?? 0.0)
					: (calculatedTax?.rate ?? 0.0),
				taxType: null,
			});

			let discountPercentage = 1;
			let discountSum = 0;

			costDetails.discounts.forEach((discount) => {
				if (discount.type === GraphQL.DiscountType.Percent) {
					discountPercentage = discountPercentage * (1 - discount.rate);
				} else {
					discountSum += discount.amount;
				}
			});

			discountPercentage = 1 - discountPercentage;

			return {
				billingCycle: effectiveBillingCycle,
				discounts: costDetails.discounts,
				discountPercentage,
				discountSum,
				priceWithoutDiscounts: costDetails.cost,
				priceWithoutDiscountsMonthly: applyBillingCycleToCost({
					baseBillingCycle: effectiveBillingCycle,
					cost: costDetails.cost,
					newBillingCycle: GraphQL.Term.Monthly,
				}),
				priceWithDiscounts: costDetails.subtotal,
				priceWithDiscountsMonthly: applyBillingCycleToCost({
					baseBillingCycle: effectiveBillingCycle,
					cost: costDetails.subtotal,
					newBillingCycle: GraphQL.Term.Monthly,
				}),
				tax: costDetails.tax,
				taxIsEstimate: calculatedTax?.isEstimate ?? false,
				taxRate: costDetails.taxRate,
				taxType: calculatedTax?.type ?? null,
				totalPrice: costDetails.total,
			};
		},
		currency,
	};
}



export default useSignupPrice;
