import React from 'react';

import type CK from '~/types/contentking';

import useAccountBillingEntity from '~/hooks/useAccountBillingEntity';
import useAccountCanSignup from '~/hooks/useAccountCanSignup';
import useAccountSignup from '~/hooks/useAccountSignup';
import useAccountTariff from '~/hooks/useAccountTariff';
import useBillingEntities from '~/hooks/useBillingEntities';



function useSignupBillingEntity(accountId: CK.AccountId | null): string | null {
	const accountBillingEntity = useAccountBillingEntity(accountId);
	const accountCanSignup = useAccountCanSignup(accountId);
	const accountSignup = useAccountSignup(accountId);
	const accountTariff = useAccountTariff(accountId);
	const billingEntities = useBillingEntities();

	const country = accountSignup.customerDetails.country;

	return React.useMemo(
		() => {
			if (accountCanSignup === false) {
				return null;
			}

			if (accountTariff !== null && billingEntities !== null && country !== null) {
				return billingEntities.getByTariffAndCountry(accountTariff, country);
			}

			return accountBillingEntity;
		},
		[
			accountBillingEntity,
			accountCanSignup,
			accountTariff,
			billingEntities,
			country,
		],
	);
}



export default useSignupBillingEntity;
