import type React from 'react';

import GraphQL from '~/types/graphql';

import useAccountEnabledFeatures from '~/hooks/useAccountEnabledFeatures';
import useAccountId from '~/hooks/useAccountId';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';

import {
	isFunction,
} from '~/utilities/typeCheck';



type ChildProps = {
	isFeatureEnabled: boolean,
};

type Props = {
	children?: RenderProp<ChildProps>,
};

const WithCustomElementsFeature: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	const accountId = useAccountId();
	const accountEnabledFeatures = useAccountEnabledFeatures(accountId);

	if (accountEnabledFeatures === null) {
		return null;
	}

	const isFeatureEnabled = accountEnabledFeatures.includes(GraphQL.AccountFeature.CustomElements);

	if (
		isFunction(children) === false
		&& isFeatureEnabled === false
	) {
		return null;
	}

	return renderProp(
		children,
		{
			isFeatureEnabled,
		},
	);
};



export default WithCustomElementsFeature;
