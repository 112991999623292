import React from 'react';

import GraphQL from '~/types/graphql';

import useAccountEnabledFeatures from '~/hooks/useAccountEnabledFeatures';
import useAccountFeaturesAvailability from '~/hooks/useAccountFeaturesAvailability';
import useAccountId from '~/hooks/useAccountId';
import useAccountPhase from '~/hooks/useAccountPhase';
import useAccountState from '~/hooks/useAccountState';
import useAccountTariff from '~/hooks/useAccountTariff';
import useFindRequiredPlan from '~/hooks/useFindRequiredPlan';

import {
	Tariff,
} from '~/model/pricing/tariffs';

import {
	getTariffPlans,
	isFeatureAvailable,
} from '~/model/universal';



function findPlanRequiredForFeatureRegardlessOfAccount(
	accountFeaturesAvailability: ReadonlyArray<{
		accountFeature: GraphQL.AccountFeature,
		amount: number,
		plan: GraphQL.AccountPlan,
		status: GraphQL.AccountFeatureAvailabilityStatus,
		tariff: string,
	}>,
	featureAmount: number,
	featureName: GraphQL.AccountFeature,
) {
	for (const tariff of [Tariff.C2, Tariff.C1, Tariff.V4]) {
		for (const plan of getTariffPlans(tariff)) {
			if (isFeatureAvailable(accountFeaturesAvailability, tariff, plan, featureName, featureAmount)) {
				return {
					plan,
					tariff,
				};
			}
		}
	}

	return null;
}



type ReturnType = {
	isFeatureAttainable: boolean,
	isFeatureEnabled: boolean,
	requiredPlan: {
		plan: GraphQL.AccountPlan,
		tariff: Tariff,
	} | null,
	requiredUniversalPlan: {
		plan: GraphQL.AccountPlan,
		tariff: Tariff,
	} | null,
	shouldBeOfferedIfAttainable: boolean,
};



function usePremiumFeatureSituation(
	featureName: GraphQL.AccountFeature,
	featureAmount: number = 1,
): ReturnType {
	const accountId = useAccountId();
	const accountEnabledFeatures = useAccountEnabledFeatures(accountId);
	const accountFeaturesAvailability = useAccountFeaturesAvailability();
	const accountPhase = useAccountPhase(accountId);
	const accountState = useAccountState(accountId);
	const accountTariff = useAccountTariff(accountId);

	const findRequiredPlan = useFindRequiredPlan(accountId);

	const shouldBeOfferedIfAttainable = (
		accountPhase !== GraphQL.AccountPhase.Customer
		|| accountState !== GraphQL.AccountState.Churned
	);

	return React.useMemo(
		() => {
			if (
				accountId === null
				|| accountEnabledFeatures === null
				|| accountFeaturesAvailability === null
				|| accountTariff === null
			) {
				return {
					isFeatureAttainable: false,
					isFeatureEnabled: false,
					requiredPlan: null,
					requiredUniversalPlan: null,
					shouldBeOfferedIfAttainable,
				};
			}

			const isFeatureEnabled = accountEnabledFeatures.includes(featureName);

			if (isFeatureEnabled) {
				return {
					isFeatureAttainable: true,
					isFeatureEnabled,
					requiredPlan: null,
					requiredUniversalPlan: null,
					shouldBeOfferedIfAttainable,
				};
			}

			const requiredPlan = findRequiredPlan(featureName, featureAmount);
			const requiredUniversalPlan = requiredPlan === null
				? findPlanRequiredForFeatureRegardlessOfAccount(accountFeaturesAvailability, featureAmount, featureName)
				: null;

			return {
				isFeatureAttainable: requiredPlan !== null,
				isFeatureEnabled: false,
				requiredPlan: requiredPlan !== null ? {
					plan: requiredPlan,
					tariff: accountTariff,
				} : null,
				requiredUniversalPlan,
				shouldBeOfferedIfAttainable,
			};
		},
		[
			accountEnabledFeatures,
			accountFeaturesAvailability,
			accountId,
			accountTariff,
			featureAmount,
			featureName,
			findRequiredPlan,
			shouldBeOfferedIfAttainable,
		],
	);
}



export default usePremiumFeatureSituation;
