import type CK from '~/types/contentking';

import {
	useAccountIsOutOfBandQuery,
} from './useAccountIsOutOfBand.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountIsOutOfBand(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountIsOutOfBandQuery,
		accountId,
	);

	return data?.account?.isOutOfBand ?? null;
}



export default useAccountIsOutOfBand;
