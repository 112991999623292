import React from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AttachedIcon, {
	AttachedIconPosition,
} from '~/components/patterns/structuredValues/AttachedIcon';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import ChangeSummary, {
	PAGES_DIFF_BUNDLE,
} from '~/components/logic/pricing/ChangeSummary';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import PageCapacityField from '~/components/app/PageCapacityField';
import PaymentAuthorizationContext from '~/components/logic/PaymentAuthorizationContext';
import PaymentAuthorizationError from '~/components/logic/PaymentAuthorizationError';
import PaymentCancelButton from '~/components/logic/PaymentCancelButton';
import PaymentSubmitButton from '~/components/logic/PaymentSubmitButton';
import StaticText from '~/components/atoms/forms/components/StaticText';
import SubscriptionChangeSummary from '~/components/app/SubscriptionChangeSummary';
import WebsiteStatus from '~/components/app/WebsiteStatus';
import WithPermission from '~/components/logic/access/WithPermission';

import useAccountBillingCycle from '~/hooks/useAccountBillingCycle';
import useAccountBillingEntity from '~/hooks/useAccountBillingEntity';
import useAccountCurrency from '~/hooks/useAccountCurrency';
import useAccountPaymentStatus from '~/hooks/useAccountPaymentStatus';
import useAccountPhase from '~/hooks/useAccountPhase';
import useAccountState from '~/hooks/useAccountState';
import useAccountTariffCalculator from '~/hooks/useAccountTariffCalculator';
import useAllowedBillingCycles from '~/hooks/useAllowedBillingCycles';
import usePageBundle from '~/hooks/usePageBundle';
import useAccountPlan from '~/hooks/useAccountPlan';
import useAccountWebsiteSizes from '~/hooks/useAccountWebsiteSizes';
import useCalculatePrice from '~/hooks/useCalculatePrice';
import useWebsiteAccountId from '~/hooks/useWebsiteAccountId';
import useWebsitePageCapacity from '~/hooks/useWebsitePageCapacity';
import useWebsitePagesCount from '~/hooks/useWebsitePagesCount';

import {
	ObjectType,
} from '~/model/permissions';



const messages = defineMessages({
	cannotSubmitDueToPaymentIssues: {
		id: 'ui.subscriptionForm.cannotSubmitDueToPaymentIssues',
	},
	pagesLabel: {
		id: 'ui.websiteSettings.plan.pageCapacity',
	},
	saveButton: {
		id: 'ui.general.saveButton',
	},
	title: {
		id: 'ui.websiteSettings.plan.header.agency',
	},
});



type Props = {
	submitCallback: (values: {
		threeDSecureToken: string | null,
		values: {
			pageCapacity: number,
		},
	}) => Promise<void>,
	websiteId: CK.WebsiteId,
};

const EditWebsiteSubscriptionForm: React.FC<Props> = (props) => {
	const {
		submitCallback,
		websiteId,
	} = props;

	const websiteAccountId = useWebsiteAccountId(websiteId);

	const accountAllowedBillingCycles = useAllowedBillingCycles(websiteAccountId);
	const accountBillingCycle = useAccountBillingCycle(websiteAccountId);
	const accountBillingEntity = useAccountBillingEntity(websiteAccountId);
	const accountCurrency = useAccountCurrency(websiteAccountId);
	const accountPageBundle = usePageBundle(websiteAccountId);
	const accountPhase = useAccountPhase(websiteAccountId);
	const accountPaymentStatus = useAccountPaymentStatus(websiteAccountId);
	const accountPlan = useAccountPlan(websiteAccountId);
	const accountState = useAccountState(websiteAccountId);
	const accountTariffCalculator = useAccountTariffCalculator(websiteAccountId);
	const accountWebsiteSizes = useAccountWebsiteSizes(websiteAccountId);
	const calculatePrice = useCalculatePrice(websiteAccountId);
	const websitePageCapacity = useWebsitePageCapacity(websiteId);
	const websitePagesCount = useWebsitePagesCount(websiteId);

	const [selectedPageCapacity, setSelectedPageCapacity] = React.useState<number | null>(null);

	const handleFormChange = React.useCallback(
		(field: string, value: number) => {
			if (field === 'pageCapacity') {
				setSelectedPageCapacity(value);
			}
		},
		[],
	);

	const handleToggleEditMode = React.useCallback(
		(editMode) => {
			if (editMode) {
				setSelectedPageCapacity(
					(state) => state ?? websitePageCapacity,
				);
			} else {
				setSelectedPageCapacity(null);
			}
		},
		[
			websitePageCapacity,
		],
	);

	const priceOld = React.useMemo(
		() => {
			return calculatePrice();
		},
		[
			calculatePrice,
		],
	);

	const {
		priceNew,
		showChangeSummary,
	} = React.useMemo(
		() => {
			if (
				accountPageBundle === null
				|| accountPhase !== GraphQL.AccountPhase.Customer
				|| accountTariffCalculator.isReady === false
				|| accountWebsiteSizes === null
				|| selectedPageCapacity === null
				|| websitePageCapacity === null
			) {
				return {
					priceNew: null,
					showChangeSummary: false,
				};
			}

			const pageBundleUsage = accountWebsiteSizes.totalPageCapacity - websitePageCapacity;

			if (accountPageBundle >= (pageBundleUsage + selectedPageCapacity)) {
				return {
					priceNew: null,
					showChangeSummary: false,
				};
			}

			const necessaryExtraPageBundle = accountTariffCalculator.tariff.normalizePagesAmount(pageBundleUsage + selectedPageCapacity) - accountPageBundle;

			return {
				priceNew: calculatePrice({
					pageBundle: accountPageBundle + necessaryExtraPageBundle,
				}),
				showChangeSummary: true,
			};
		},
		[
			accountPageBundle,
			accountPhase,
			accountTariffCalculator,
			accountWebsiteSizes,
			calculatePrice,
			selectedPageCapacity,
			websitePageCapacity,
		],
	);

	const renderChangesOverview = () => {
		if (
			accountAllowedBillingCycles === null
			|| accountBillingCycle === null
			|| accountCurrency === null
			|| accountPageBundle === null
			|| accountPlan === null
			|| accountTariffCalculator.isReady === false
			|| accountWebsiteSizes === null
			|| priceNew === null
			|| priceOld === null
			|| selectedPageCapacity === null
			|| websiteAccountId === null
			|| websitePageCapacity === null
		) {
			return null;
		}

		const pageBundleUsage = accountWebsiteSizes.totalPageCapacity - websitePageCapacity;

		const newPageBundle = accountTariffCalculator.tariff.normalizePagesAmount(pageBundleUsage + selectedPageCapacity);

		if (accountBillingEntity !== 'old') {
			return (
				<SubscriptionChangeSummary
					accountId={websiteAccountId}
					hidePrice={accountPhase !== GraphQL.AccountPhase.Customer && accountState !== GraphQL.AccountState.Expired}
					pageBundle={newPageBundle}
				/>
			);
		}

		return (
			<ChangeSummary
				accountId={websiteAccountId}
				allowedBillingCycles={accountAllowedBillingCycles}
				billingCycleNew={accountBillingCycle}
				billingCycleOld={accountBillingCycle}
				currency={accountCurrency}
				hidePrice={accountPhase !== GraphQL.AccountPhase.Customer && accountState !== GraphQL.AccountState.Expired}
				pagesDiff={PAGES_DIFF_BUNDLE}
				pagesNew={newPageBundle}
				pagesOld={accountPageBundle}
				planNew={accountPlan}
				planOld={accountPlan}
				priceNew={priceNew.subtotal}
				priceOld={priceOld.subtotal}
				taxType={priceNew.tax > 0 ? GraphQL.TaxType.Vat : null}
			/>
		);
	};

	const defaultValues = React.useMemo(
		() => ({
			pageCapacity: websitePageCapacity,
		}),
		[
			websitePageCapacity,
		],
	);

	return (
		<WithPermission
			action={GraphQL.ActionWithWebsite.UpdatePageCapacity}
			objectId={websiteId}
			objectType={ObjectType.Website}
			showMessage={false}
		>
			{({ isAllowed }) => (
				<EditableFormWrapper
					isAllowed={isAllowed}
					onEnterEditModeCallback={handleToggleEditMode}
					title={(
						<FormattedMessage {...messages.title} />
					)}
				>
					<DisplayPart>
						<FormRows>
							<FormRow
								label={(
									<FormattedMessage {...messages.pagesLabel} />
								)}
							>
								<StaticText>
									<AttachedIcon
										ellipsis={false}
										icon={(
											<WebsiteStatus
												showStatus={[GraphQL.WebsiteProblem.CapacityReached, GraphQL.WebsiteProblem.NearCapacity]}
												websiteId={websiteId}
											/>
										)}
										iconPosition={AttachedIconPosition.Suffix}
									>
										{websitePagesCount !== null && websitePageCapacity !== null && (
											<>
												<FormattedNumber value={Math.min(websitePagesCount, websitePageCapacity)} />
													&nbsp;/&nbsp;
												<FormattedNumber value={websitePageCapacity} />
													&nbsp;
												<span className="percentage">
													({(
														<FormattedNumber
															maximumFractionDigits={2}
															minimumFractionDigits={1}
															style="percent"
															value={Math.min(websitePagesCount, websitePageCapacity) / websitePageCapacity}
														/>
													)})
												</span>
											</>
										)}
									</AttachedIcon>
								</StaticText>
							</FormRow>
						</FormRows>
					</DisplayPart>

					<EditablePart>
						<PaymentAuthorizationContext
							accountId={websiteAccountId}
							onSubmit={submitCallback}
							useModal={true}
						>
							{({ formRef, handleFormSuccess }) => (
								<Form
									defaultFocus="pageCapacity"
									defaultValues={defaultValues}
									onChangeCallback={handleFormChange}
									onSuccess={handleFormSuccess}
									ref={formRef}
								>
									<FormRows>
										<FormRow
											htmlFor="pageCapacity"
											label={(
												<FormattedMessage {...messages.pagesLabel} />
											)}
										>
											<PageCapacityField
												accountId={websiteAccountId}
												name="pageCapacity"
												numberOfPages={
													(websitePagesCount !== null && websitePageCapacity !== null)
														? Math.min(websitePagesCount, websitePageCapacity)
														: undefined
												}
											/>
										</FormRow>

										{showChangeSummary && renderChangesOverview()}
									</FormRows>

									<PaymentAuthorizationError />

									<ButtonsLayout>
										<PaymentCancelButton />

										<PaymentSubmitButton
											isDisabled={isAllowed.yes === false && accountPaymentStatus?.isOkay === false}
											tooltip={accountPaymentStatus?.isOkay === false && (
												<FormattedMessage {...messages.cannotSubmitDueToPaymentIssues} />
											)}
										>
											<FormattedMessage {...messages.saveButton} />
										</PaymentSubmitButton>
									</ButtonsLayout>
								</Form>
							)}
						</PaymentAuthorizationContext>
					</EditablePart>
				</EditableFormWrapper>
			)}
		</WithPermission>
	);
};



export default EditWebsiteSubscriptionForm;
