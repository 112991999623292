import type CK from '~/types/contentking';

import {
	useAccountIsShadowQuery,
} from './useAccountIsShadow.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountIsShadow(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountIsShadowQuery,
		accountId,
	);

	return data?.account?.isShadow ?? null;
}



export default useAccountIsShadow;
