import React from 'react';

import AttachedNote, {
	AttachedNoteAlignment,
} from '~/components/patterns/structuredValues/AttachedNote';
import Grid, {
	GridGapsSize,
} from '~/components/patterns/structures/Grid';
import RichText from '~/components/patterns/typography/RichText';

import useViewportType from '~/hooks/useViewportType';



type Props = {
	choiceAButton: React.ReactNode,
	choiceANote: React.ReactNode,
	choiceBButton: React.ReactNode,
	choiceBNote: React.ReactNode,
};

const TwinButtons: React.FC<Props> = (props) => {
	const {
		choiceAButton,
		choiceANote,
		choiceBButton,
		choiceBNote,
	} = props;

	const viewportType = useViewportType();

	return (
		<Grid
			columnsCount={viewportType.isSmall ? 1 : 2}
			gapsSize={GridGapsSize.Large}
		>
			<AttachedNote
				alignment={AttachedNoteAlignment.Center}
				note={(
					<RichText>
						{choiceANote}
					</RichText>
				)}
			>
				{choiceAButton}
			</AttachedNote>

			<AttachedNote
				alignment={AttachedNoteAlignment.Center}
				note={(
					<RichText>
						{choiceBNote}
					</RichText>
				)}
			>
				{choiceBButton}
			</AttachedNote>
		</Grid>
	);
};



export default TwinButtons;
