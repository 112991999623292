import React from 'react';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useAccountFeaturesUsageQuery,
} from './useAccountFeaturesUsage.gql';

import useAccountFeaturesAvailability from '~/hooks/useAccountFeaturesAvailability';
import useAccountPlan from '~/hooks/useAccountPlan';
import useAccountTariff from '~/hooks/useAccountTariff';

import {
	getPlanAvailability,
	getTariffPlans,
} from '~/model/universal';



type FeaturesUsage = {
	enabledFeatures: ReadonlyArray<GraphQL.AccountFeature>,
	featuresUsage: ReadonlyArray<{
		featureAmount: number,
		featureName: GraphQL.AccountFeature,
	}>,
	getFeatureUsedAmount: (featueType: GraphQL.AccountFeature) => number,
	getNecessaryPlan: () => GraphQL.AccountPlan | null,
	getPlanAvailability: (plan: GraphQL.AccountPlan) => ReturnType<typeof getPlanAvailability>,
	usedFeatures: ReadonlyArray<GraphQL.AccountFeature>,
};



function useAccountFeaturesUsage(accountId: CK.AccountId | null): FeaturesUsage | null {
	const accountFeaturesAvailability = useAccountFeaturesAvailability();
	const accountPlan = useAccountPlan(accountId);
	const accountTariff = useAccountTariff(accountId);

	const { data } = useAccountFeaturesUsageQuery({
		skip: accountId === null,
		variables: {
			accountId: accountId ?? 0,
		},
	});

	const features = data?.account?.features ?? null;

	return React.useMemo(
		() => {
			if (
				accountFeaturesAvailability === null
				|| accountPlan === null
				|| accountTariff === null
				|| features === null
			) {
				return null;
			}

			const featuresUsage = features
				.filter((feature) => feature.amountInUse > 0)
				.map((feature) => ({
					featureAmount: feature.amountInUse,
					featureName: feature.feature,
				}));

			const usedFeatures = featuresUsage.map(({ featureName }) => featureName);

			const boundGetPlanAvailability = (plan: GraphQL.AccountPlan) => {
				return getPlanAvailability(
					accountFeaturesAvailability,
					accountPlan,
					accountTariff,
					featuresUsage,
					plan,
				);
			};

			return {
				enabledFeatures: features.map((feature) => feature.feature),
				featuresUsage,
				getFeatureUsedAmount: (featureName: GraphQL.AccountFeature) => featuresUsage.find(
					(usedFeature) => usedFeature.featureName === featureName,
				)?.featureAmount ?? 0,
				getNecessaryPlan: (): GraphQL.AccountPlan | null => {
					for (const plan of getTariffPlans(accountTariff)) {
						const planAvailability = boundGetPlanAvailability(plan);

						if (planAvailability.isAvailable) {
							return plan;
						}
					}

					return null;
				},
				getPlanAvailability: boundGetPlanAvailability,
				usedFeatures,
			};
		},
		[
			accountFeaturesAvailability,
			accountPlan,
			accountTariff,
			features,
		],
	);
}



export default useAccountFeaturesUsage;
