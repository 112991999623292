import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import PlanFeaturesList from '~/components/logic/account/PlanFeaturesList';

import useAccountFeaturesAvailability from '~/hooks/useAccountFeaturesAvailability';
import useAccountFeaturesUsage from '~/hooks/useAccountFeaturesUsage';
import useAccountPlan from '~/hooks/useAccountPlan';
import useAccountTariff from '~/hooks/useAccountTariff';

import {
	PLAN_LOWER,
} from '~/model/plans';

import {
	type Benefit,
} from '~/model/benefits';

import {
	Tariff,
} from '~/model/pricing/tariffs';

import {
	comparePlans,
	getPlanBenefits,
} from '~/model/universal';



type Props = {
	accountId: CK.AccountId,
	compact?: boolean,
	plan: GraphQL.AccountPlan,
};

const PlanFeatures: React.FC<Props> = (props) => {
	const {
		accountId,
		compact = false,
		plan,
	} = props;

	const accountFeaturesAvailability = useAccountFeaturesAvailability();
	const accountFeaturesUsage = useAccountFeaturesUsage(accountId);
	const accountTariff = useAccountTariff(accountId);
	const basePlan = useAccountPlan(accountId);

	const benefits = accountTariff !== null ? getPlanBenefits(accountTariff, plan) : [];
	const lostBenefits: Array<Benefit> = [];

	if (
		accountFeaturesAvailability === null
		|| benefits.length === 0
	) {
		return null;
	}

	const isDowngrade = basePlan && comparePlans(basePlan, plan) === PLAN_LOWER;

	const benefitsForPlanFeaturesList = benefits.map((benefit) => {
		if (benefit.feature === undefined) {
			return benefit;
		}

		const planFeatureAvailability = accountFeaturesAvailability.find(
			(featureAvailability) => (
				featureAvailability.accountFeature === benefit.feature
				&& featureAvailability.plan === plan
				&& featureAvailability.tariff === accountTariff
			),
		);

		if (planFeatureAvailability === undefined) {
			return benefit;
		}

		const correctAmount = planFeatureAvailability.amount;
		const withAddon = (
			accountTariff === Tariff.C2
			&& plan === GraphQL.AccountPlan.C2Enterprise
			&& [GraphQL.AccountFeature.LighthouseMonitoring, GraphQL.AccountFeature.Rendering].includes(benefit.feature)
		);

		if (
			isDowngrade
			&& correctAmount < planFeatureAvailability.amount
			&& accountFeaturesUsage !== null
			&& accountFeaturesUsage.getFeatureUsedAmount(benefit.feature) > planFeatureAvailability.amount
		) {
			lostBenefits.push(benefit.name);
		}

		return {
			...benefit,
			amount: correctAmount,
			disabled: correctAmount === 0 && withAddon === false,
			addon: withAddon,
		};
	});

	return (
		<PlanFeaturesList
			benefits={benefitsForPlanFeaturesList}
			compact={compact}
			lostBenefits={lostBenefits}
		/>
	);
};



export default React.memo(PlanFeatures);
