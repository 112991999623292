import classNames from 'classnames';
import React from 'react';

import Hint from '~/components/patterns/hints/hint/Hint';



type Props = {
	label: React.ReactNode,
	tooltip?: React.ReactNode,
};



const NoticeTag: React.FC<Props> = (props) => {
	const {
		label,
		tooltip,
	} = props;

	const tag = (
		<span
			className={classNames({
				'notice-tag': true,
				'notice-tag--is-hoverable': tooltip,
			})}
		>
			{label}
		</span>
	);

	return tooltip ? (
		<Hint
			popup={tooltip}
			popupOffsetSkidding={5}
		>
			{tag}
		</Hint>
	) : tag;
};



export default NoticeTag;
