import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import CK from '~/types/contentking';

import Colorizer, {
	ColorizerStyle,
} from '~/components/patterns/utils/Colorizer';
import AttachedNote from '~/components/patterns/structuredValues/AttachedNote';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import ColumnFormatter from '../../logic/formatters/ColumnFormatter';
import ColumnName from '../../names/ColumnName';
import DataLoadingFramework from '../../logic/datatables/DataLoadingFramework';
import DatatableOverlay from '~/components/patterns/tables/datatables/DatatableOverlay';
import DiffFormatter from '../../logic/formatters/DiffFormatter';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import ExpandableFilterOperatorHeaderLabel from '~/components/logic/filters/ExpandableFilterOperatorHeaderLabel';
import FieldFormatter from '../../logic/formatters/FieldFormatter';
import FilterFieldLayout from '~/components/patterns/filtering/FilterFieldLayout';
import HeaderLabel from './HeaderLabel';
import ImportanceFieldFilter from '../../logic/datatables/ImportanceFieldFilter';
import IssueChangeFieldFilter from '../../logic/issues/IssueChangeFieldFilter';
import IssueChangeName from '../../names/IssueChangeName';
import IssueChangesGroupName from '../../names/IssueChangesGroupName';
import MultiselectFieldFilter from '../../logic/datatables/MultiselectFieldFilter';
import NoSearchResults from '~/components/patterns/messages/embedded/NoSearchResults';
import PageTypeName from '../../names/PageTypeName';
import PlainTextFieldFilter from '../../logic/datatables/PlainTextFieldFilter';
import PowerStaticTextFieldFilter from '../../logic/datatables/PowerStaticTextFieldFilter';
import ResizableDatatable, {
	ResizableDatatableCellColorStyle,
	type ResizableDatatableColumnDefinition,
	ResizableDatatableRowHeight,
} from '../../logic/datatables/ResizableDatatable';
import ScreenMessage from '~/components/patterns/messages/embedded/ScreenMessage';
import SegmentFieldFilter from '../../logic/datatables/SegmentFieldFilter';
import SegmentsDiffFormatter from '../../logic/formatters/SegmentsDiffFormatter';
import UrlCellValue from '../../logic/datatables/cellValues/UrlCellValue';

import useWebsiteId from '~/hooks/useWebsiteId';

import {
	ISSUE_CHANGES_GROUPING,
	ISSUE_CHANGE_BROKEN_ENTERED,
	ISSUE_CHANGE_CLOSED,
	ISSUE_CHANGE_OPENED,
	ISSUE_CHANGE_OPENED_NEW_PAGE,
	getGroupOfIssueChange,
} from '~/model/affectedPagesComparison';

import {
	TYPE_MISSING,
	TYPE_PAGE,
	TYPE_REDIRECT,
	TYPE_SERVER_ERROR,
	TYPE_UNREACHABLE,
} from '~/model/pages';

import {
	isColumnHistorical,
} from '~/model/pagesColumns';

import goTo from '~/routing/goTo';

import {
	pagesSelector as comparisonPagesSelector,
	rangeSelector as comparisonRangeSelector,
	totalSelector as comparisonTotalSelector,
} from '~/state/affectedPagesComparison/currentWebsiteSelectors';

import {
	currentFilterSelector as comparisonFilterSelector,
	loadingSelector as comparisonLoadingSelector,
	sortBySelector as comparisonSortBySelector,
} from '~/state/affectedPagesComparison/selectors';



const messages = defineMessages({
	noData: {
		id: 'ui.general.noCrawledData',
	},
});

const pageTypeOptions = [
	{
		name: TYPE_PAGE,
		title: (
			<PageTypeName pageType={TYPE_PAGE} />
		),
	},
	{
		name: TYPE_MISSING,
		title: (
			<PageTypeName pageType={TYPE_MISSING} />
		),
	},
	{
		name: TYPE_REDIRECT,
		title: (
			<PageTypeName pageType={TYPE_REDIRECT} />
		),
	},
	{
		name: TYPE_SERVER_ERROR,
		title: (
			<PageTypeName pageType={TYPE_SERVER_ERROR} />
		),
	},
	{
		name: TYPE_UNREACHABLE,
		title: (
			<PageTypeName pageType={TYPE_UNREACHABLE} />
		),
	},
];



type Props = {
	columns: any,
	contextMenuEntries: any,
	filterCallback: any,
	isCrawled: any,
	pagesLoader: any,
	sortCallback: any,
};

const AffectedPagesComparisonTable: React.FC<Props> = (props) => {
	const {
		columns,
		contextMenuEntries,
		filterCallback,
		isCrawled,
		pagesLoader,
		sortCallback,
	} = props;

	const affectedPages = useSelector(comparisonPagesSelector);
	const dataLoading = useSelector(comparisonLoadingSelector);
	const filter = useSelector(comparisonFilterSelector);
	const pagesCount = useSelector(comparisonTotalSelector) || 0;
	const pagesIndexes = useSelector(comparisonRangeSelector);
	const sortBy = useSelector(comparisonSortBySelector);
	const websiteId = useWebsiteId();

	const handleOpenDetail = React.useCallback(
		({ row }, event) => {
			goTo(
				event,
				'website.pages.detail.history',
				{
					id: row.get('id'),
					websiteId,
				},
			);
		},
		[
			websiteId,
		],
	);

	const rowGetter = React.useCallback(
		({ rowIndex }) => {
			const pageId = pagesIndexes.get(rowIndex);

			if (pageId) {
				return affectedPages.get(pageId.toString()) ?? null;
			}

			return null;
		},
		[
			affectedPages,
			pagesIndexes,
		],
	);

	const rowStyleGetter = React.useCallback(
		({ row }) => {
			const issueChange = row.get('issues').first().get('change');

			if (issueChange === ISSUE_CHANGE_OPENED || issueChange === ISSUE_CHANGE_OPENED_NEW_PAGE) {
				return ResizableDatatableCellColorStyle.Removed;
			} else if (issueChange === ISSUE_CHANGE_CLOSED) {
				return ResizableDatatableCellColorStyle.Added;
			}

			return null;
		},
		[],
	);

	const columnDefinitions = React.useMemo(
		() => {
			const result: Array<ResizableDatatableColumnDefinition<any>> = [
				{
					name: CK.PagesCommonColumn.IssueChange,
					render: {
						cell({ row }) {
							const issueChangeGroup = getGroupOfIssueChange(row.get('issues').first().get('change'));

							const issueChangeName = ISSUE_CHANGES_GROUPING.get(issueChangeGroup).size > 1
								? (
									<Ellipsis>
										<IssueChangeName issueChange={row.get('issues').first().get('change')} />
									</Ellipsis>
								)
								: null;

							return (
								<AttachedNote note={issueChangeName}>
									<Ellipsis>
										<IssueChangesGroupName issueChangesGroup={issueChangeGroup as any} />
									</Ellipsis>
								</AttachedNote>
							);
						},
						filter: ({ filterName, filterWidth, ref }) => (
							<IssueChangeFieldFilter
								name={filterName}
								ref={ref}
								showSegmentChanges={filter.get('segment') !== 'all'}
								width={filterWidth}
							/>
						),
						label: () => (
							<Colorizer style={ColorizerStyle.History}>
								<ColumnName column={CK.PagesCommonColumn.ChangeType} />
							</Colorizer>
						),
					},
					sortOrder: true,
					width: 320,
				},
				{
					name: CK.PagesCommonColumn.Url,
					render: {
						cell({ innerWidth, row }) {
							return (
								<UrlCellValue
									value={row.get(CK.PagesCommonColumn.Url)}
									width={innerWidth}
								/>
							);
						},
						filter: ({ filterName, filterWidth, ref }) => (
							<PowerStaticTextFieldFilter
								name={filterName}
								ref={ref}
								width={filterWidth}
							/>
						),
						label: () => (
							<ColumnName column={CK.PagesCommonColumn.Url} />
						),
						labelFilterOperator: () => (
							<ExpandableFilterOperatorHeaderLabel
								value={filter.get(CK.PagesCommonColumn.Url)}
							/>
						),
					},
					sortOrder: true,
					width: 400,
				},
				{
					name: CK.PagesCommonColumn.Type,
					render: {
						cell({ row }) {
							const issueChange = row.get('issues').first().get('change');

							return (
								<DiffFormatter
									customElements={null}
									formatter={(value, pageType) => {
										return (
											<FieldFormatter
												column={CK.PagesCommonColumn.Type}
												pageType={pageType}
												value={value}
												zIndex={1200}
											/>
										);
									}}
									isColored={issueChange !== ISSUE_CHANGE_OPENED && issueChange !== ISSUE_CHANGE_CLOSED && issueChange !== ISSUE_CHANGE_BROKEN_ENTERED}
									pageType={row.get(CK.PagesCommonColumn.Type)}
									value={row.get(CK.PagesCommonColumn.Type)}
								/>
							);
						},
						filter: ({ filterName, filterWidth, ref }) => (
							<MultiselectFieldFilter
								isOnlyLinkVisible={true}
								name={filterName}
								options={pageTypeOptions}
								ref={ref}
								width={filterWidth}
							/>
						),
						label: () => (
							<ColumnName column={CK.PagesCommonColumn.Type} />
						),
					},
					sortOrder: true,
					width: 180,
				},
				{
					name: CK.PagesCommonColumn.Relevance,
					render: {
						cell({ row }) {
							const issueChange = row.get('issues').first().get('change');

							return (
								<DiffFormatter
									customElements={null}
									formatter={(value, pageType) => {
										return (
											<FieldFormatter
												column={CK.PagesCommonColumn.Relevance}
												pageType={pageType}
												value={value}
												zIndex={1200}
											/>
										);
									}}
									isColored={issueChange !== ISSUE_CHANGE_OPENED && issueChange !== ISSUE_CHANGE_CLOSED}
									pageType={row.get(CK.PagesCommonColumn.Type)}
									value={row.get(CK.PagesCommonColumn.Relevance)}
								/>
							);
						},
						filter: ({ filterName, filterWidth, ref }) => (
							<ImportanceFieldFilter
								name={filterName}
								ref={ref}
								width={filterWidth}
							/>
						),
						label: () => (
							<ColumnName column={CK.PagesCommonColumn.Relevance} />
						),
					},
					sortOrder: false,
					width: 150,
				},
				{
					filterName: 'segment',
					name: CK.PagesCommonColumn.Segments,
					render: {
						cell({ innerWidth, row }) {
							return (
								<SegmentsDiffFormatter
									value={row.get('segments')}
									width={innerWidth}
								/>
							);
						},
						header: ({ filterName, filterRef, filterWidth }) => {
							return (
								<FilterFieldLayout
									field={(
										<SegmentFieldFilter
											name={filterName}
											ref={filterRef}
											width={filterWidth}
										/>
									)}
									label={(
										<HeaderLabel
											icon={(
												<BasicIcon
													size={14}
													type={BasicIconType.Segment}
												/>
											)}
										>
											<ColumnName column={CK.PagesCommonColumn.Segments} />
										</HeaderLabel>
									)}
								/>
							);
						},
					},
					width: 280,
				},
			];

			columns.forEach((column) => {
				if (!isColumnHistorical(column.name)) {
					return;
				}

				result.push({
					name: column.name,
					render: {
						cell({ row }) {
							const issueChange = row.get('issues').first().get('change');

							return (
								<DiffFormatter
									customElements={null}
									formatter={(value, pageType) => {
										if (column.cellRenderer) {
											return (
												<ColumnFormatter
													column={column.name}
													pageType={pageType}
													value={column.cellRenderer({ value })}
													zIndex={1200}
												/>
											);
										}

										return (
											<FieldFormatter
												column={column.name}
												pageType={pageType}
												value={value}
												zIndex={1200}
											/>
										);
									}}
									isColored={issueChange !== ISSUE_CHANGE_OPENED && issueChange !== ISSUE_CHANGE_CLOSED}
									pageType={row.get(CK.PagesCommonColumn.Type)}
									value={
										column.name !== CK.PagesCommonColumn.CanonicalLinkElement
											? row.get(column.name)
											: row.get('canonical')
									}
								/>
							);
						},
						header: ({ filterWidth }) => {
							return (
								<FilterFieldLayout
									field={column.filterable && (
										column.filterRenderer
											? column.filterRenderer({
												name: column.name,
											})
											: (
												<PlainTextFieldFilter
													name={column.name}
													width={filterWidth}
												/>
											)
									)}
									label={(
										<HeaderLabel
											labelOperator={(column.filterable && !column.filterRenderer) ? (
												<ExpandableFilterOperatorHeaderLabel
													value={filter.get(column.name)}
												/>
											) : undefined}
											sorting={column.sortable ? {
												callback: sortCallback,
												flipped: column.defaultSortingDirection === undefined ? false : !column.defaultSortingDirection,
												name: column.name,
												status: sortBy,
											} : undefined}
										>
											<ColumnName column={column.name} />
										</HeaderLabel>
									)}
								/>
							);
						},
					},
					width: column.width,
				});
			});

			return result;
		},
		[
			columns,
			filter,
			sortBy,
			sortCallback,
		],
	);

	return (
		<DataLoadingFramework
			filter={filter}
			loadDataCallback={pagesLoader}
			sortBy={sortBy}
			syncInterval={10000}
		>
			<ResizableDatatable
				blankSlate={isCrawled && (
					<NoSearchResults />
				)}
				columns={columnDefinitions}
				contextMenuEntries={contextMenuEntries}
				filter={filter}
				isLoading={dataLoading}
				onFilterChange={filterCallback}
				onRowClick={handleOpenDetail}
				onSortChangeCallback={sortCallback}
				overlay={!dataLoading && pagesCount === 0 && isCrawled === false && (
					<DatatableOverlay>
						<ScreenMessage>
							<FormattedMessage {...messages.noData} />
						</ScreenMessage>
					</DatatableOverlay>
				)}
				rowGetter={rowGetter}
				rowHeightStyle={ResizableDatatableRowHeight.Medium}
				rowStyleGetter={rowStyleGetter}
				rowsCount={pagesCount}
				sortBy={sortBy}
			/>
		</DataLoadingFramework>
	);
};



export default AffectedPagesComparisonTable;
