import classNames from 'classnames';
import React from 'react';



export enum InlineIconSpinning {
	Disabled = 'disabled',
	Once = 'once',
	Infinite = 'infinite',
}

export enum InlineIconTransformation {
	Disabled = 'disabled',
	Enabled = 'enabled',
	FlipHorizontally = 'flip-horizontally',
	FlipVertically = 'flip-vertically',
}



type Props = {
	/** Special flag for all icons with gap around */
	gap?: boolean,
	/** Show icon in grayscale */
	grayscale?: boolean,
	/** SVG object of icon */
	icon: React.ReactNode,
	/** Square size of icon */
	size: number,
	/** Spinning animation */
	spinning?: InlineIconSpinning,
	/** Possibility to change speed of spinning animation */
	spinningAnimationSpeed?: number,
	/** Additional transformation applied to icon */
	transform?: InlineIconTransformation,
	/** Height of SVG viewBox container */
	viewBoxHeight: number,
	/** Width of SVG viewBox container */
	viewBoxWidth: number,
};



const InlineIcon: React.FC<Props> = (props) => {
	const {
		gap = true,
		grayscale = false,
		icon,
		size,
		spinning = InlineIconSpinning.Disabled,
		spinningAnimationSpeed = 1,
		transform = InlineIconTransformation.Disabled,
		viewBoxHeight,
		viewBoxWidth,
	} = props;

	const style = React.useMemo(
		() => ({
			animationDuration: (2 / spinningAnimationSpeed) + 's', // 2 = default speed is 2 seconds
			fill: 'currentcolor',
			verticalAlign: 'middle',
			width: size, // CSS instead of the width attr to support non-pixel units
			height: size, // Prevents scaling issue in IE
		}),
		[
			size,
			spinningAnimationSpeed,
		],
	);

	return (
		<svg
			className={classNames({
				'icon': true,
				'icon--without-gap': !gap,
				'icon--grayscale': grayscale,
				'icon--has-transform': transform !== InlineIconTransformation.Disabled,
				['icon--transform-' + transform]: transform !== InlineIconTransformation.Disabled,
				'animated': spinning !== InlineIconSpinning.Disabled,
				'animated--spinning': spinning !== InlineIconSpinning.Disabled,
				'animated--spinning--once': spinning === InlineIconSpinning.Once,
			})}
			preserveAspectRatio="xMidYMid meet"
			style={style}
			viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
		>
			{icon}
		</svg>
	);
};



export default React.memo(InlineIcon);
