import type CK from '~/types/contentking';

import {
	useAccountEnrichmentFieldsLimitQuery,
} from './useAccountEnrichmentFieldsLimit.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountEnrichmentFieldsLimit(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountEnrichmentFieldsLimitQuery,
		accountId,
	);

	return data?.account?.enrichmentFieldsLimit ?? null;
}



export default useAccountEnrichmentFieldsLimit;
