import type GraphQL from '~/types/graphql';

import {
	type Discount,
	type Purchase,
	type TariffDefinition,
	calculatePurchaseCostDetails,
	getAddonPrice,
	getPlanPrice,
} from './costs';

import {
	type Tariff,
	choosePlan,
	convertPagesToAddons,
	listAllPossiblePageCapacities,
	listAllPossiblePagesAmounts,
	normalizePageCapacity,
	normalizePagesAmount,
} from './tariffs';



export class TailoredTariff {

	currency: GraphQL.Currency;
	maximumPagesAmount: number;
	tariff: Tariff;
	tariffs: Record<Tariff, TariffDefinition>;

	constructor(
		currency: GraphQL.Currency,
		maximumSignupPageBundle: number,
		tariff: Tariff,
		tariffs: Record<Tariff, TariffDefinition>,
	) {
		this.currency = currency;
		this.maximumPagesAmount = maximumSignupPageBundle;
		this.tariff = tariff;
		this.tariffs = tariffs;
	}



	arePurchaseDetailsCompatible({ billingCycle, purchase }: {
		billingCycle: GraphQL.Term,
		purchase: Purchase<Record<string, any>>,
	}) {
		const plan = choosePlan({
			billingCycle,
			details: purchase.details,
			tariff: this.tariff,
		});

		return getPlanPrice({
			currency: this.currency,
			plan,
			tariff: this.tariff,
			tariffs: this.tariffs,
		}) !== undefined;
	}



	isPagesAmountCompatibleWithPurchase({ billingCycle, purchase }: {
		billingCycle: GraphQL.Term,
		purchase: Purchase<Record<string, any>>,
	}) {
		const plan = choosePlan({
			billingCycle,
			details: purchase.details,
			tariff: this.tariff,
		});

		const addonNames = Object.keys(convertPagesToAddons({
			numberOfPages: purchase.numberOfPages,
			tariff: this.tariff,
		}));

		if (addonNames[0] === undefined) {
			return false;
		}

		return getAddonPrice({
			addon: addonNames[0],
			currency: this.currency,
			plan,
			tariff: this.tariff,
			tariffs: this.tariffs,
		}) !== undefined;
	}



	calculatePurchaseCostDetails({ billingCycle, currency, discounts, purchases, taxRate }: {
		billingCycle: GraphQL.Term,
		currency?: GraphQL.Currency | null,
		discounts: Array<Discount>,
		purchases: Array<Purchase<Record<string, any>>>,
		taxRate: number,
	}) {
		return calculatePurchaseCostDetails({
			billingCycle,
			currency: currency ?? this.currency,
			discounts,
			purchases,
			tariff: this.tariff,
			tariffs: this.tariffs,
			taxRate,
			taxType: null,
		});
	}



	normalizePageCapacity(pageCapacity: number) {
		return normalizePageCapacity({
			pageCapacity,
			tariff: this.tariff,
		});
	}



	normalizePagesAmountOrNull(numberOfPages: number) {
		return normalizePagesAmount({
			numberOfPages,
			tariff: this.tariff,
		});
	}



	normalizePagesAmount(numberOfPages: number) {
		const result = this.normalizePagesAmountOrNull(numberOfPages);

		if (result === null) {
			throw new Error(`normalizePagesAmount can't return null`);
		}

		return result;
	}



	listAllPossiblePageCapacities(maximumPageCapacity: number | null = null) {
		return listAllPossiblePageCapacities(
			this.tariff,
			maximumPageCapacity,
		);
	}



	listAllPossiblePagesAmounts() {
		return listAllPossiblePagesAmounts(
			this.tariff,
			this.maximumPagesAmount,
		);
	}

}
