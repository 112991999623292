import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Hint, {
	HintAttachment,
	HintPopupVisibility,
} from '~/components/patterns/hints/hint/Hint';
import SegmentIdentifier, {
	SegmentIdentifierSize,
} from '~/components/patterns/segments/SegmentIdentifier';



const messages = defineMessages({
	description: {
		id: 'ui.scopes.website.description',
	},
	title: {
		id: 'ui.scopes.website.title',
	},
});



type Props = {
	hintDisplayOn?: HintPopupVisibility,
	onClickCallback?: () => void,
	onlyThumb?: boolean,
	showHint?: boolean,
	size?: SegmentIdentifierSize,
};

const WebsiteScopeLabel: React.FC<Props> = (props) => {
	const {
		hintDisplayOn = HintPopupVisibility.OnHover,
		onClickCallback,
		onlyThumb = false,
		showHint = true,
		size = SegmentIdentifierSize.Default,
	} = props;

	let result = (
		<SegmentIdentifier
			color="#f3f3f3"
			inversedColors={true}
			label={onlyThumb === false && (
				<FormattedMessage {...messages.title} />
			)}
			onClickCallback={onClickCallback}
			size={size}
			symbol={(
				<BasicIcon
					size={20}
					type={BasicIconType.Globe}
				/>
			)}
		/>
	);

	if (showHint) {
		result = (
			<Hint
				attachment={HintAttachment.Center}
				blurDelay={250}
				popup={(
					<FormattedMessage {...messages.description} />
				)}
				popupOffsetSkidding={5}
				popupVisibility={hintDisplayOn}
				targetCustomCursor={false}
			>
				{result}
			</Hint>
		);
	}

	return result;
};



export default WebsiteScopeLabel;

export {
	HintPopupVisibility as WebsiteScopeLabelHintDisplayOn,
	SegmentIdentifierSize as WebsiteScopeLabelSize,
};
