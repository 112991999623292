import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import EmbedImageIcon from '~/components/patterns/icons/EmbedImageIcon';
import FilterDefinitionFormatter, {
	FilterDefinitionFormatterStyle,
} from '~/components/logic/filters/FilterDefinitionFormatter';
import Hint, {
	HintAttachment,
	HintPopupVisibility,
} from '~/components/patterns/hints/hint/Hint';
import InternalLink from '~/components/patterns/links/InternalLink';
import LabeledValue, {
	LabeledValueContentAlignment,
	LabeledValueSize,
	LabeledValueStyle,
} from '~/components/patterns/structuredValues/labeledValues/LabeledValue';
import LinkTarget, {
	LinkTargetKeepParameter,
} from '~/components/logic/LinkTarget';
import SegmentIdentifier, {
	SegmentIdentifierSize,
	SegmentIdentifierType,
} from '~/components/patterns/segments/SegmentIdentifier';
import SegmentPopupLayout from '~/components/patterns/segments/SegmentPopupLayout';

import useIsAllowedWithWebsite from '~/hooks/useIsAllowedWithWebsite';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	type SegmentDefinition,
	isSegmentStatic,
} from '~/model/segments';



export enum SegmentDefinitionIdentifierChange {
	Entered = 'entered',
	Left = 'left',
	None = 'none',
}

const changeLabelMessages = defineMessages({
	[SegmentDefinitionIdentifierChange.Entered]: {
		id: 'ui.contentOverview.changeTracking.changeType.addedTo',
	},
	[SegmentDefinitionIdentifierChange.Left]: {
		id: 'ui.contentOverview.changeTracking.changeType.removedFrom',
	},
});

const messages = defineMessages({
	editLink: {
		id: 'ui.segments.popup.editLink',
	},
	filterCriteria: {
		id: 'ui.segments.popup.filterCriteria',
	},
	isBeingReevaluated: {
		id: 'ui.segments.popup.isBeingReevaluated',
	},
	sizeLimit: {
		id: 'ui.segments.popup.sizeLimit',
	},
});



export type SegmentDefinitionIdentifierProps = {
	change?: SegmentDefinitionIdentifierChange,
	disabled?: boolean,
	disabledExplanation?: React.ReactNode,
	hintDisplayOn?: HintPopupVisibility,
	onClickCallback?: (segmentName: string) => void,
	onlyThumb?: boolean,
	segmentDefinition: SegmentDefinition,
	showCriteria?: boolean,
	showEditLink?: boolean,
	size?: SegmentIdentifierSize,
	type?: SegmentIdentifierType,
};

const SegmentDefinitionIdentifier: React.FC<SegmentDefinitionIdentifierProps> = (props) => {
	const {
		change = SegmentDefinitionIdentifierChange.None,
		disabled = false,
		disabledExplanation,
		hintDisplayOn = HintPopupVisibility.OnHover,
		onClickCallback,
		onlyThumb = false,
		segmentDefinition,
		showCriteria = true,
		showEditLink = true,
		size = SegmentIdentifierSize.Default,
		type,
	} = props;

	const isBeingReevaluated = segmentDefinition.isBeingReevaluated;

	function handleClick() {
		if (onClickCallback) {
			onClickCallback(segmentDefinition.name);
		}
	}

	const additionalProps: Record<string, any> = {};

	if (onClickCallback && !disabled) {
		additionalProps.onClickCallback = handleClick;
	}

	let symbol;

	if (segmentDefinition.icon) {
		symbol = (
			<EmbedImageIcon
				files={segmentDefinition.icon.files}
				size={16}
			/>
		);
	} else if (segmentDefinition.shortcode !== null) {
		symbol = segmentDefinition.shortcode;
	}

	let identifier = (
		<SegmentIdentifier
			color={segmentDefinition.color}
			isLoading={isBeingReevaluated}
			label={!onlyThumb && segmentDefinition.label}
			size={size}
			strikethrough={change === SegmentDefinitionIdentifierChange.Left}
			symbol={symbol}
			type={type}
			{...additionalProps}
		/>
	);

	if (disabledExplanation) {
		identifier = (
			<Hint
				blurDelay={250}
				popup={disabledExplanation}
				popupOffsetSkidding={5}
				popupVisibility={HintPopupVisibility.OnHover}
			>
				{identifier}
			</Hint>
		);
	} else if (onlyThumb || showCriteria) {
		identifier = (
			<Hint
				attachment={HintAttachment.Center}
				blurDelay={250}
				popup={(
					<Popup
						change={change}
						segmentDefinition={segmentDefinition}
						showEditLink={showEditLink}
					/>
				)}
				popupLayout={(children) => children}
				popupOffsetSkidding={5}
				popupVisibility={hintDisplayOn}
				preventClickPropagation={true}
				targetCustomCursor={false}
			>
				{identifier}
			</Hint>
		);
	}

	return identifier;
};



type PopupProps = {
	change: SegmentDefinitionIdentifierChange,
	segmentDefinition: SegmentDefinition,
	showEditLink: boolean,
};

const Popup: React.FC<PopupProps> = (props) => {
	const {
		change,
		segmentDefinition,
		showEditLink,
	} = props;

	const websiteId = useWebsiteId();

	const isBeingReevaluated = segmentDefinition.isBeingReevaluated;

	const isAllowedToEditSegment = useIsAllowedWithWebsite(
		websiteId,
		GraphQL.ActionWithWebsite.ManageSegments,
	);

	const segmentIsManaged = segmentDefinition.isManaged;
	const showEditSegmentLink = showEditLink && isAllowedToEditSegment.yes && !segmentIsManaged;

	const filterCriteria = Object.values(segmentDefinition.filterDefinition).length > 0 && {
		caption: (
			<FormattedMessage {...messages.filterCriteria} />
		),
		data: (
			<FilterDefinitionFormatter
				filterDefinition={segmentDefinition.filterDefinition}
				style={FilterDefinitionFormatterStyle.CompactList}
			/>
		),
	};

	const sizeLimit = segmentDefinition.sizeLimit && {
		caption: (
			<FormattedMessage {...messages.sizeLimit} />
		),
		data: (
			<FilterDefinitionFormatter
				sizeLimitDefinition={segmentDefinition.sizeLimit}
				style={FilterDefinitionFormatterStyle.CompactList}
			/>
		),
	};

	const editLink = showEditSegmentLink && (
		<LinkTarget
			includeBacklink={true}
			routeName={isSegmentStatic(segmentDefinition) ? 'website.staticSegmentEditor.edit' : 'website.segmentEditor.edit'}
			routeParams={{
				segmentName: segmentDefinition.name,
				websiteId: LinkTargetKeepParameter,
			}}
		>
			{({ routeName, routeParams }) => (
				<InternalLink
					icon={(
						<BasicIcon
							color="#ffffff"
							type={BasicIconType.CogWheel}
						/>
					)}
					routeName={routeName}
					routeParams={routeParams}
				>
					<FormattedMessage {...messages.editLink} />
				</InternalLink>
			)}
		</LinkTarget>
	);

	const identifier = (
		<SegmentIdentifier
			color={segmentDefinition.color}
			label={segmentDefinition.label}
			symbol={
				segmentDefinition.icon ? (
					<EmbedImageIcon
						files={segmentDefinition.icon.files}
					/>
				) : (
					segmentDefinition.shortcode
				)
			}
		/>
	);

	let popupElement = (
		<SegmentPopupLayout
			filterCriteria={filterCriteria}
			link={editLink}
			message={isBeingReevaluated && (
				<FormattedMessage {...messages.isBeingReevaluated} />
			)}
			segmentIdentifier={identifier}
			sizeLimit={sizeLimit}
		/>
	);

	if (change !== SegmentDefinitionIdentifierChange.None) {
		popupElement = (
			<LabeledValue
				contentAlignment={LabeledValueContentAlignment.Left}
				label={(
					<FormattedMessage {...changeLabelMessages[change]} />
				)}
				size={LabeledValueSize.Small}
				style={LabeledValueStyle.Light}
			>
				{popupElement}
			</LabeledValue>
		);
	}

	return popupElement;
};



export default SegmentDefinitionIdentifier;

export {
	HintPopupVisibility as SegmentDefinitionIdentifierHintDisplayOn,
	SegmentIdentifierSize as SegmentDefinitionIdentifierSize,
	SegmentIdentifierType as SegmentDefinitionIdentifierType,
};
