import {
	isAfter,
} from 'date-fns';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import AppearAnimation, {
	AppearAnimationDirection,
} from '~/components/patterns/animations/AppearAnimation';
import CodeViewer from '~/components/patterns/tables/datatables/CodeViewer';
import DisabledContent from '~/components/patterns/content/DisabledContent';
import PageLastCheckedInfo, {
	PageLastCheckedMessageType,
} from './PageLastCheckedInfo';
import RobotsTxtStatusMessage from '~/components/app/RobotsTxtStatusMessage';
import RobotsTxtViewerHeader from '~/components/app/RobotsTxtViewerHeader';
import RobotsTxtViewerSidebar from '~/components/app/RobotsTxtViewerSidebar';
import ScreenBody from '~/components/patterns/screens/parts/body/ScreenBody';
import ScreenBodyLayout, {
	ScreenBodyLayoutPosition,
} from '~/components/patterns/screens/parts/body/ScreenBodyLayout';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';
import ScreenMessage from '~/components/patterns/messages/embedded/ScreenMessage';
import SnapshotHeaders from '~/components/app/SnapshotHeaders';
import Spinner from '~/components/patterns/loaders/Spinner';
import StickToScreenBottom, {
	StickToScreenBottomPreset,
} from '~/components/patterns/utils/StickToScreenBottom';
import TabNavigation from '~/components/patterns/navigations/tabNavigation/TabNavigation';
import TabNavigationItem from '~/components/patterns/navigations/tabNavigation/TabNavigationItem';
import TabNavigationStaticItem, {
	TabNavigationStaticItemFloat,
} from '~/components/patterns/navigations/tabNavigation/TabNavigationStaticItem';

import {
	useRobotsTxtViewerScreenQuery,
} from './RobotsTxtViewerScreen.gql';

import useBatchContextRobotsTxt from '~/hooks/useBatchContextRobotsTxt';
import useNavigation from '~/hooks/useNavigation';
import usePollInterval from '~/hooks/usePollInterval';
import useUrlState from '~/hooks/useUrlState';
import useViewportType from '~/hooks/useViewportType';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIsCrawlingPaused from '~/hooks/useWebsiteIsCrawlingPaused';

import matchAndReturn from '~/utilities/matchAndReturn';
import {
	assertString,
} from '~/utilities/typeCheck';



const HEADERS_AVAILABLE_SINCE = new Date('2022-01-20');



const messages = defineMessages({
	headers: {
		id: 'ui.robotsTxtViewerScreen.headers',
	},
	headersUnavailable: {
		id: 'ui.robotsTxtViewerScreen.headersUnavailable',
	},
	responseBody: {
		id: 'ui.robotsTxtViewerScreen.responseBody',
	},
});



type Props = {
	backlink?: string,
	closeCallback?: () => void,
};

const RobotsTxtViewerScreen: React.FC<Props> = (props) => {
	const {
		backlink,
		closeCallback,
	} = props;

	const navigation = useNavigation();
	const urlState = useUrlState();
	const viewportType = useViewportType();
	const websiteId = useWebsiteId();
	const isCrawlingPaused = useWebsiteIsCrawlingPaused(websiteId) ?? false;

	const [activeTab, setActiveTab] = React.useState<'body' | 'headers'>('body');
	const [showMobileFilter, setShowMobileFilter] = React.useState(false);

	const revisionId = urlState.params.revisionId as CK.ID;
	assertString(revisionId);

	const isLatestRevision = revisionId === 'latest';

	const { data } = useRobotsTxtViewerScreenQuery({
		context: useBatchContextRobotsTxt(websiteId),
		variables: {
			websiteId,
			revisionId,
			getRevisionById: !isLatestRevision,
		},
		returnPartialData: true,
		pollInterval: usePollInterval(10_000),
	});

	const selectedRevision = (
		isLatestRevision
			? data?.latest
			: data?.byId
	) ?? null;

	const selectedRevisionId = selectedRevision?.id ?? revisionId;
	const latestRevisionId = data?.latest?.id ?? null;

	const canonicalRobotsTxtLegacyUrlId = data?.platformData?.canonicalRobotsTxtLegacyUrlId ?? null;

	const showPageLastCheckedInfo = (
		selectedRevision?.id === latestRevisionId
		&& canonicalRobotsTxtLegacyUrlId !== null
	);

	const handleClickRevision = React.useCallback(
		(clickRevisionId: CK.ID) => {
			setShowMobileFilter(false);

			const nextRevisionId = (
				clickRevisionId === latestRevisionId
					? 'latest'
					: clickRevisionId
			);

			navigation.navigate({
				keepParameters: true,
				routeName: urlState.name,
				routeParams: {
					revisionId: nextRevisionId,
				},
			});
		},
		[
			latestRevisionId,
			navigation,
			urlState,
		],
	);

	function renderTabs() {
		return (
			<TabNavigation stretchItems={false}>
				<TabNavigationItem
					isActive={activeTab === 'body'}
					onClickCallback={() => setActiveTab('body')}
				>
					<FormattedMessage {...messages.responseBody} />
				</TabNavigationItem>
				<TabNavigationItem
					isActive={activeTab === 'headers'}
					onClickCallback={() => setActiveTab('headers')}
				>
					<FormattedMessage {...messages.headers} />
				</TabNavigationItem>

				{showPageLastCheckedInfo && (
					<TabNavigationStaticItem float={TabNavigationStaticItemFloat.Right}>
						<PageLastCheckedInfo
							legacyUrlId={canonicalRobotsTxtLegacyUrlId}
							messageType={PageLastCheckedMessageType.RobotsTxt}
							websiteId={websiteId}
						/>
					</TabNavigationStaticItem>
				)}
			</TabNavigation>
		);
	}

	function renderSelectedRevision() {
		if (selectedRevision === null) {
			return (
				<ScreenBody>
					<Spinner />
				</ScreenBody>
			);
		}

		return matchAndReturn(activeTab, {
			body: () => {
				const showStatusMessage = (
					selectedRevision.responseStatusCode === null
					|| selectedRevision.responseStatusCode === 0
					|| selectedRevision.responseStatusCode! >= 300
					|| selectedRevision.responseFailureReason !== null
					|| selectedRevision.isContentEmpty
				);

				if (showStatusMessage) {
					return (
						<ScreenBody>
							<RobotsTxtStatusMessage
								failureReason={selectedRevision.responseFailureReason}
								isContentEmpty={selectedRevision.isContentEmpty}
								isLatestRevision={selectedRevision.id === latestRevisionId}
								statusCode={selectedRevision.responseStatusCode}
							/>
						</ScreenBody>
					);
				}

				return (
					<StickToScreenBottom preset={StickToScreenBottomPreset.Fullscreen}>
						{({ height, width }) => (
							<DisabledContent
								disabledContent={!selectedRevision.content?.rows}
								disabledOverlay={(
									<Spinner />
								)}
							>
								<CodeViewer
									height={height}
									lines={(selectedRevision.content?.rows as any) ?? []}
									longestLine={selectedRevision.content?.longestRowCharacters ?? 0}
									width={width}
								/>
							</DisabledContent>
						)}
					</StickToScreenBottom>
				);
			},
			headers: () => {
				const headersAvailable = isAfter(new Date(selectedRevision.createdAt), HEADERS_AVAILABLE_SINCE);

				if (!headersAvailable) {
					return (
						<ScreenBody>
							<ScreenMessage>
								<FormattedMessage {...messages.headersUnavailable} />
							</ScreenMessage>
						</ScreenBody>
					);
				}

				return (
					<SnapshotHeaders
						requestHttpHeaders={selectedRevision.requestHttpHeaders}
						responseFailureReason={selectedRevision.responseFailureReason ?? null}
						responseHttpHeaders={selectedRevision.responseHttpHeaders ?? null}
						responseStatusCode={selectedRevision.responseStatusCode ?? null}
						timestamp={selectedRevision.createdAt}
						url={selectedRevision.url}
					/>
				);
			},
		})();
	}

	return (
		<ScreenLayout
			header={(
				<RobotsTxtViewerHeader
					backlink={backlink}
					closeCallback={closeCallback}
					isCrawlingPaused={isCrawlingPaused}
					isLatestRevision={isLatestRevision}
					onMobileFilterButtonClick={() => setShowMobileFilter((showMobileFilter) => !showMobileFilter)}
					revisionsList={data?.list}
					searchEngineActivity={selectedRevision?.searchEngineActivity ?? null}
					selectedRevisionCreatedAt={selectedRevision?.createdAt ?? null}
					selectedRevisionId={selectedRevisionId}
					selectedRevisionIsDownloadable={selectedRevision?.isDownloadAllowed ?? true}
					url={selectedRevision?.url ?? null}
				/>
			)}
		>
			{viewportType.isSmall ? (
				<ScreenBody
					gaps={false}
					header={showMobileFilter ? null : renderTabs()}
				>
					{showMobileFilter ? (
						<AppearAnimation direction={AppearAnimationDirection.Down}>
							<RobotsTxtViewerSidebar
								onClickRevision={handleClickRevision}
								selectedRevisionId={selectedRevisionId}
							/>
						</AppearAnimation>
					) : (
						renderSelectedRevision()
					)}
				</ScreenBody>
			) : (
				<ScreenBodyLayout
					hideContentScrollbar={true}
					hideSidebarScrollbar={true}
					sidebar={(
						<RobotsTxtViewerSidebar
							onClickRevision={handleClickRevision}
							selectedRevisionId={selectedRevisionId}
						/>
					)}
					sidebarPosition={ScreenBodyLayoutPosition.Left}
					sidebarWidth={viewportType.isMedium ? 240 : 260}
				>
					<ScreenBody
						gaps={false}
						header={renderTabs()}
					>
						{renderSelectedRevision()}
					</ScreenBody>
				</ScreenBodyLayout>
			)}
		</ScreenLayout>
	);
};



export default RobotsTxtViewerScreen;
