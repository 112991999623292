import type CK from '~/types/contentking';

import {
	useAccountStateQuery,
} from './useAccountState.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountState(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountStateQuery,
		accountId,
	);

	return data?.account?.state ?? null;
}



export default useAccountState;
