import type CK from '~/types/contentking';

import {
	useAccountIsTrialExtensionAllowedQuery,
} from './useAccountIsTrialExtensionAllowed.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountIsTrialExtensionAllowed(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountIsTrialExtensionAllowedQuery,
		accountId,
	);

	return data?.account?.isTrialExtensionAllowed ?? null;
}



export default useAccountIsTrialExtensionAllowed;
