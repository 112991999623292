import {
	type ApolloError,
} from '@apollo/client';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import AttachedFormControlElement from '~/components/patterns/forms/layouts/AttachedFormControlElement';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import ExternalLink from '~/components/patterns/links/ExternalLink';
import Form, {
	type FormRef,
} from '~/components/atoms/forms/basis/Form';
import InternalLink from '~/components/patterns/links/InternalLink';
import MarginsList from '~/components/atoms/lists/MarginsList';
import SubmitButton from '~/components/atoms/forms/components/SubmitButton';
import TextArea from '~/components/atoms/forms/components/TextArea';
import ValidationMessage, {
	ValidationMessageStatus,
} from '~/components/patterns/messages/embedded/ValidationMessage';

import {
	useExtractDomainsFromFreeInputMutation,
} from './AddMultipleWebsitesStep.gql';

import useAccountCanSelfServiceSignupRemoveWebsiteLimit from '~/hooks/useAccountCanSelfServiceSignupRemoveWebsiteLimit';

import FormError from '~/utilities/FormError';



const messages = defineMessages({
	addButton: {
		id: 'ui.websites.new.steps.multiple.sidebar.freeBulkInput.addButton',
	},
	maxWebsitesLimitReached: {
		id: 'ui.websites.new.steps.multiple.sidebar.maxWebsitesLimitReached',
	},
	serverError: {
		id: 'ui.general.unexpectedError',
	},
	skippedLines: {
		id: 'ui.websites.new.steps.multiple.sidebar.freeBulkInput.skippedLines',
	},
	skippedLinesTooltip: {
		id: 'ui.websites.new.steps.multiple.sidebar.freeBulkInput.skippedLines.tooltip',
	},
	skippedLinesTooltipLimit: {
		id: 'ui.websites.new.steps.multiple.sidebar.freeBulkInput.skippedLines.tooltip.limit',
	},
});

const validations = {
	freeInput: [
		{
			message: '',
			field: 'freeInput',
			rule: ({ values }) => {
				return values.freeInput.length > 0;
			},
		},
	],
};



type Props = {
	accountId: CK.AccountId | null,
	addDomainsToStagingArea: (domains: Array<string>) => void,
	isMaxWebsitesLimitReached: boolean,
	maxUnverifiedWebsites: number | null,
	maxWebsites?: number,
	sessionId?: string,
};

const SidebarFreeTextInput: React.FC<Props> = (props) => {
	const {
		accountId,
		addDomainsToStagingArea,
		isMaxWebsitesLimitReached,
		maxUnverifiedWebsites,
		maxWebsites,
		sessionId,
	} = props;

	const canSelfServiceSignupRemoveWebsiteLimit = useAccountCanSelfServiceSignupRemoveWebsiteLimit(accountId);

	const formRef = React.useRef<FormRef>(null);
	const textareaRef = React.useRef<HTMLTextAreaElement>(null);

	const [extractDomainsFromText] = useExtractDomainsFromFreeInputMutation();

	return (
		<Form
			defaultValues={{
				freeInput: '',
			}}
			onSuccess={(values) => {
				if (accountId === null) {
					return;
				}

				return extractDomainsFromText({
					variables: {
						accountId,
						sessionId: sessionId as string,
						freeInput: values.freeInput,
					},
				}).catch((error: ApolloError) => {
					throw FormError.fromApolloError(error);
				}).then((response) => {
					const output = response.data?.StageDomainsForValidationFromFreeInput;

					if (!output) {
						return;
					}

					const remainingText = output.remainingFreeInputText || '';

					formRef.current?.setValue('freeInput', remainingText);

					if (textareaRef.current) {
						textareaRef.current.value = remainingText;
					}

					const newDomains = [
						...output.stagingArea.finishedDomains.map((entry) => entry.domain),
						...output.stagingArea.pendingDomains.map((entry) => entry.domain),
						...output.stagingArea.failedDomains.map((entry) => entry.domain),
					];

					addDomainsToStagingArea(newDomains);

					if (remainingText.length > 0) {
						const linesRemaining = remainingText.split('\n').length;
						throw new FormError('lines-remaining', linesRemaining);
					}
				});
			}}
			ref={formRef}
			validations={validations}
		>
			{({ globalError, isSubmitting, isValid }) => (
				<AttachedFormControlElement
					button={(
						<SubmitButton
							disabled={isSubmitting || !isValid || isMaxWebsitesLimitReached}
							icon={(
								<BasicIcon
									size={15}
									type={BasicIconType.Plus}
								/>
							)}
							size={ButtonSize.XXSmall}
							style={ButtonStyle.Hollow}
							tooltip={isMaxWebsitesLimitReached && (
								<FormattedMessage
									{...messages.maxWebsitesLimitReached}
									values={{
										canSelfServiceSignupRemoveWebsiteLimit: canSelfServiceSignupRemoveWebsiteLimit ? 'yes' : 'no',
										maxWebsites,
										link_signup: (chunks) => (
											<InternalLink routeName="account.pricing">
												{chunks}
											</InternalLink>
										),
									}}
								/>
							)}
							uppercase={true}
						>
							<FormattedMessage {...messages.addButton} />
						</SubmitButton>
					)}
					validationMessage={
						isSubmitting ? (
							<ValidationMessage status={ValidationMessageStatus.ValidationInProgress} />
						) : globalError?.name === 'server-error' ? (
							<ValidationMessage status={ValidationMessageStatus.Invalid}>
								<FormattedMessage {...messages.serverError} />
							</ValidationMessage>
						) : globalError?.name === 'lines-remaining' ? (
							<ValidationMessage
								explanatoryTooltip={(
									<MarginsList>
										<FormattedMessage {...messages.skippedLinesTooltip} />

										{maxUnverifiedWebsites !== null ? (
											<FormattedMessage
												{...messages.skippedLinesTooltipLimit}
												values={{
													canSelfServiceSignupRemoveWebsiteLimit: canSelfServiceSignupRemoveWebsiteLimit ? 'yes' : 'no',
													maxUnverifiedWebsites,
													link_signup: (chunks) => (
														<InternalLink routeName="account.pricing">
															{chunks}
														</InternalLink>
													),
													link_support: (chunks) => (
														<ExternalLink href="https://www.contentkingapp.com/support/website-verification/">
															{chunks}
														</ExternalLink>
													),
												}}
											/>
										) : null}
									</MarginsList>
								)}
								status={ValidationMessageStatus.Invalid}
							>
								<FormattedMessage
									{...messages.skippedLines}
									values={{ lines: globalError.value }}
								/>
							</ValidationMessage>
						) : undefined
					}
				>
					<TextArea
						attributes={{
							style: {
								minHeight: 202,
							},
						}}
						isDisabled={isSubmitting}
						name="freeInput"
						placeholder={'www.example1.com\nwww.example2.com\nwww.example3.com'}
						ref={textareaRef}
						resizable={true}
						rows={8}
						width="100%"
					/>
				</AttachedFormControlElement>
			)}
		</Form>
	);
};



export default SidebarFreeTextInput;
