import type CK from '~/types/contentking';

import {
	useAccountOutOfBandPlansQuery,
} from './useAccountOutOfBandPlans.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountOutOfBandPlans(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountOutOfBandPlansQuery,
		accountId,
	);

	return data?.account?.outOfBandPlans ?? null;
}



export default useAccountOutOfBandPlans;
