import React from 'react';
import {
	FormattedMessage,
	useIntl,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import BillingCycleName from '~/components/names/BillingCycleName';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CheckboxField from '~/components/atoms/forms/components/CheckboxField';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import MultiselectField from '~/components/atoms/forms/components/MultiselectField';
import PlanName from '~/components/names/PlanName';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import SelectField from '~/components/atoms/forms/components/SelectField';
import StaticList from '~/components/atoms/forms/components/StaticList';
import StaticText from '~/components/atoms/forms/components/StaticText';
import TextField, {
	TextFieldType,
} from '~/components/atoms/forms/components/TextField';
import WhenAccountActionAllowed from '~/components/app/WhenAccountActionAllowed';

import {
	useAdministerAccountSubscriptionOptionsSettingsMutation,
	useSubscriptionOptionsSettingsFormQuery,
} from './SubscriptionOptionsSettingsForm.gql';

import useAccountBillingCycle from '~/hooks/useAccountBillingCycle';
import useAccountCanSignup from '~/hooks/useAccountCanSignup';
import useAccountDefaultCountry from '~/hooks/useAccountDefaultCountry';
import useAccountDetailedDiscounts from '~/hooks/useAccountDetailedDiscounts';
import useAccountOutOfBandPlans from '~/hooks/useAccountOutOfBandPlans';
import useAccountPaymentMethod from '~/hooks/useAccountPaymentMethod';
import useAccountPlan from '~/hooks/useAccountPlan';
import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';
import useAccountTariff from '~/hooks/useAccountTariff';
import useAllowedBillingCycles from '~/hooks/useAllowedBillingCycles';
import useAllowedPaymentMethods from '~/hooks/useAllowedPaymentMethods';
import useAllowedPlans from '~/hooks/useAllowedPlans';

import {
	listUsedBillingCycles,
} from '~/model/accounts';

import {
	METHOD_CARD,
	METHOD_INVOICE,
	METHOD_SEPA_DEBIT,
} from '~/model/payments';

import {
	BillingCycleByDuration,
} from '~/model/pricing/billingCycle';

import {
	Tariff,
} from '~/model/pricing/tariffs';

import {
	getTariffPlans,
	isPlanNativelyOutOfBand,
} from '~/model/universal';

import {
	countries,
} from '~/utilities/countries';

import getOptionsLabel from '~/utilities/getOptionsLabel';



const validations = {
	allowedBillingCycles: [
		{
			message: 'At least one billing cycle must be allowed',
			field: 'allowedBillingCycles',
			rule: ({ values, name }) => {
				return values.allowedPlans.indexOf(GraphQL.AccountPlan.Enterprise) === -1 || (values[name] && values[name].length > 0);
			},
		},
	],
	allowedPaymentMethods: [
		{
			message: 'At least one payment method must be allowed',
			field: 'allowedPaymentMethods',
			rule: ({ values, name }) => {
				return values[name] && values[name].length > 0;
			},
		},
	],
	allowedPlans: [
		{
			message: 'At least one plan must be allowed',
			field: 'allowedPlans',
			rule: ({ values, name }) => {
				return values[name] && values[name].length > 0;
			},
		},
	],
};



type Props = {
	accountId: CK.AccountId,
};

const SubscriptionOptionsSettingsForm: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const accountBillingCycle = useAccountBillingCycle(accountId);
	const accountCanSignup = useAccountCanSignup(accountId);
	const accountDefaultCountry = useAccountDefaultCountry(accountId);
	const accountDetailedDiscounts = useAccountDetailedDiscounts(accountId);
	const accountOutOfBandPlans = useAccountOutOfBandPlans(accountId);
	const accountPaymentMethod = useAccountPaymentMethod(accountId);
	const accountPlan = useAccountPlan(accountId);
	const accountTariff = useAccountTariff(accountId);
	const allowedBillingCycles = useAllowedBillingCycles(accountId);
	const allowedPaymentMethods = useAllowedPaymentMethods(accountId);
	const allowedPlans = useAllowedPlans(accountId);
	const intl = useIntl();

	const { data } = useAccountPropertiesQuery(
		useSubscriptionOptionsSettingsFormQuery,
		accountId,
	);

	const isLoaded = (data?.account ?? null) !== null;
	const manualPaymentNetTerms = data?.account?.manualPaymentNetTerms ?? null;
	const possibleBillingCycles = data?.account?.possibleBillingCycles ?? null;

	const [administerAccountSubscriptionOptionsSettings] = useAdministerAccountSubscriptionOptionsSettingsMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			if (accountTariff === null) {
				throw new Error(`accountTariff can't be null`);
			}

			if (values.allowedPlans.includes(GraphQL.AccountPlan.Enterprise)) {
				values.allowedBillingCycles = [];

				if (values.allowedQuarterly) {
					values.allowedBillingCycles.push(GraphQL.Term.Quarterly);
				}

				values.allowedBillingCycles.push(GraphQL.Term.Annually);

				delete values.allowedQuarterly;
			}

			let outOfBandPlans = values.outOfBandPlans;

			if (accountTariff !== values.tariff) {
				outOfBandPlans = getTariffPlans(values.tariff).filter(
					(plan) => isPlanNativelyOutOfBand(values.tariff, plan),
				);
			}

			await administerAccountSubscriptionOptionsSettings({
				variables: {
					accountId,
					allowedBillingCycles: values.allowedBillingCycles,
					allowedPaymentMethods: values.allowedPaymentMethods,
					allowedPlans: values.allowedPlans,
					customDiscount: values.customDiscount,
					defaultCountry: values.defaultCountry,
					manualPaymentNetTerms: values.manualPaymentNetTerms,
					outOfBandPlans,
					tariff: values.tariff ?? accountTariff,
				},
			});
		},
		[
			accountId,
			accountTariff,
			administerAccountSubscriptionOptionsSettings,
		],
	);

	const billingCycleOptions = React.useMemo(
		() => {
			const usedBillingCycles = listUsedBillingCycles(
				accountCanSignup,
				accountBillingCycle,
			);

			return BillingCycleByDuration
				.filter((billingCycle) => (possibleBillingCycles ?? []).includes(billingCycle))
				.map((billingCycle) => {
					return {
						disabled: usedBillingCycles.includes(billingCycle),
						title: (
							<BillingCycleName billingCycle={billingCycle} />
						),
						name: billingCycle,
					};
				});
		},
		[
			accountBillingCycle,
			accountCanSignup,
			possibleBillingCycles,
		],
	);

	const countryOptions = React.useMemo(
		() => {
			return countries.map((code) => {
				return {
					label: intl.formatMessage({ id: 'countries.' + code } as any),
					name: code,
				};
			}).sort(({ label: labelA }, { label: labelB }) => {
				return labelA.localeCompare(labelB);
			});
		},
		[
			intl,
		],
	);

	const outOfBandPlansOptions = React.useMemo(
		() => {
			return Object.fromEntries(
				Object.values(Tariff).map(
					(tariff) => [
						tariff,
						tariff === Tariff.V2 ? [] : getTariffPlans(tariff).map((plan) => ({
							disabled: (
								(accountCanSignup === false && accountTariff === tariff && accountPlan === plan)
								|| isPlanNativelyOutOfBand(tariff, plan)
							),
							title: (
								<PlanName
									plan={plan}
									tariff={tariff}
								/>
							),
							name: plan,
						})),
					],
				),
			);
		},
		[
			accountCanSignup,
			accountPlan,
			accountTariff,
		],
	);

	const paymentMethodOptions = React.useMemo(
		() => {
			return [
				{
					disabled: accountPaymentMethod?.type === METHOD_CARD,
					name: METHOD_CARD,
					title: 'Credit card',
				},
				{
					disabled: accountPaymentMethod?.type === METHOD_INVOICE,
					name: METHOD_INVOICE,
					title: 'By invoice',
				},
				{
					disabled: accountPaymentMethod?.type === METHOD_SEPA_DEBIT,
					name: METHOD_SEPA_DEBIT,
					title: 'SEPA (experimental)',
				},
			];
		},
		[
			accountPaymentMethod,
		],
	);

	const planOptions = React.useMemo(
		() => {
			return Object.fromEntries(
				Object.values(Tariff).map(
					(tariff) => [
						tariff,
						tariff === Tariff.V2 ? [] : getTariffPlans(tariff).map((plan) => ({
							disabled: accountCanSignup === false && accountTariff === tariff && accountPlan === plan,
							title: (
								<PlanName
									plan={plan}
									tariff={tariff}
								/>
							),
							name: plan,
						})),
					],
				),
			);
		},
		[
			accountCanSignup,
			accountPlan,
			accountTariff,
		],
	);

	const tariffOptions = React.useMemo(
		() => {
			return Object.values(Tariff).map((tariff) => ({
				disabled: tariff === Tariff.V2,
				label: tariff,
				name: tariff,
			}));
		},
		[],
	);

	if (
		accountCanSignup === null
		|| accountDefaultCountry === null
		|| accountDetailedDiscounts === null
		|| accountOutOfBandPlans === null
		|| accountTariff === null
		|| allowedBillingCycles === null
		|| allowedPaymentMethods === null
		|| allowedPlans === null
		|| isLoaded === false
	) {
		return null;
	}

	const defaultValues: Record<string, any> = {
		allowedBillingCycles: allowedPlans.includes(GraphQL.AccountPlan.Enterprise) === false
			? allowedBillingCycles
			: [
				GraphQL.Term.Annually,
				GraphQL.Term.Monthly,
				GraphQL.Term.Quarterly,
			],
		allowedQuarterly: allowedPlans.includes(GraphQL.AccountPlan.Enterprise)
			? allowedBillingCycles.includes(GraphQL.Term.Quarterly)
			: accountBillingCycle === GraphQL.Term.Quarterly,
		allowedPaymentMethods: allowedPaymentMethods.filter(
			(paymentMethod) => paymentMethod !== 'paypal',
		),
		allowedPlans,
		customDiscount: accountDetailedDiscounts.map((discount) => discount.coupon).join(', '),
		defaultCountry: accountDefaultCountry,
		manualPaymentNetTerms: manualPaymentNetTerms ?? 14,
		outOfBandPlans: accountOutOfBandPlans,
		tariff: accountTariff,
	};

	return (
		<WhenAccountActionAllowed
			accountId={accountId}
			action={GraphQL.ActionWithAccount.ManageInternals}
			showMessage={false}
		>
			{({ isAllowed }) => (
				<EditableFormWrapper
					isAllowed={isAllowed}
					isForAdmins={true}
					title="Transactions"
				>
					<DisplayPart>
						<FormRows>
							<FormRow label="Discount coupon">
								<StaticList
									focusTarget="customDiscount"
									items={
										accountDetailedDiscounts.length > 0
											? accountDetailedDiscounts.map((discount) => discount.coupon)
											: ['none']
									}
								/>
							</FormRow>

							<FormRow label="Default country">
								<StaticText focusTarget={accountCanSignup ? 'defaultCountry' : undefined}>
									<FormattedMessage id={'countries.' + accountDefaultCountry as any} />
								</StaticText>
							</FormRow>

							<FormRow label="Tariff">
								<StaticText>
									{accountTariff}
								</StaticText>
							</FormRow>

							<FormRow label="Allowed plans">
								<StaticList
									focusTarget="allowedPlans"
									items={getOptionsLabel(planOptions[accountTariff], allowedPlans)}
								/>
							</FormRow>

							<FormRow label="Out-of-band plans">
								<StaticList
									focusTarget="outOfBandPlans"
									items={getOptionsLabel(outOfBandPlansOptions[accountTariff], accountOutOfBandPlans)}
								/>
							</FormRow>

							<FormRow label="Allowed billing cycles">
								<StaticList
									focusTarget="allowedBillingCycles"
									items={getOptionsLabel(billingCycleOptions, allowedBillingCycles)}
								/>
							</FormRow>

							<FormRow label="Allowed payment methods">
								<StaticList
									focusTarget="allowedPaymentMethods"
									items={getOptionsLabel(paymentMethodOptions, allowedPaymentMethods)}
								/>
							</FormRow>

							{allowedPaymentMethods.includes(METHOD_INVOICE) && (
								<FormRow label="Net terms">
									<StaticText focusTarget="manualPaymentNetTerms">
										{manualPaymentNetTerms}
									</StaticText>
								</FormRow>
							)}
						</FormRows>
					</DisplayPart>

					<EditablePart>
						<Form
							defaultValues={defaultValues}
							onSuccess={handleSubmit}
							validations={validations}
						>
							{({ values }) => {
								return (
									<>
										<FormRows>
											<FormRow
												htmlFor="customDiscount"
												label="Discount coupon"
											>
												<FieldStatus name="customDiscount">
													<TextField
														name="customDiscount"
														placeholder="no special discount"
														trimValue={true}
													/>
												</FieldStatus>
											</FormRow>

											{accountCanSignup ? (
												<FormRow
													htmlFor="defaultCountry"
													label="Default country"
												>
													<FieldStatus name="defaultCountry">
														<SelectField
															name="defaultCountry"
															options={countryOptions}
															searchable={true}
														/>
													</FieldStatus>
												</FormRow>
											) : (
												<FormRow label="Country">
													<StaticText>
														<FormattedMessage id={'countries.' + accountDefaultCountry as any} />
													</StaticText>
												</FormRow>
											)}

											<FormRow
												description={accountTariff !== values.tariff && (
													'Out-of-band plans can be tweaked after tariff change is submitted.'
												)}
												htmlFor="tariff"
												label="Tariff"
											>
												{accountCanSignup ? (
													<FieldStatus name="tariff">
														<SelectField
															name="tariff"
															options={tariffOptions}
															searchable={true}
														/>
													</FieldStatus>
												) : (
													<StaticText>
														{accountTariff}
													</StaticText>
												)}
											</FormRow>

											<FormRow label="Allowed plans">
												<FieldStatus name="allowedPlans">
													<MultiselectField
														name="allowedPlans"
														options={planOptions[values.tariff] ?? []}
													/>
												</FieldStatus>
											</FormRow>

											{accountTariff === values.tariff && (
												<FormRow label="Out-of-band plans">
													<FieldStatus name="outOfBandPlans">
														<MultiselectField
															name="outOfBandPlans"
															options={outOfBandPlansOptions[values.tariff] ?? []}
														/>
													</FieldStatus>
												</FormRow>
											)}

											<FormRow label="Allowed billing cycles">
												<div hidden={values.allowedPlans.includes(GraphQL.AccountPlan.Enterprise)}>
													<FieldStatus name="allowedBillingCycles">
														<MultiselectField
															name="allowedBillingCycles"
															options={billingCycleOptions}
														/>
													</FieldStatus>
												</div>

												<div hidden={!values.allowedPlans.includes(GraphQL.AccountPlan.Enterprise)}>
													<CheckboxField
														disabled={accountBillingCycle === GraphQL.Term.Quarterly}
														label={
															values.allowedQuarterly
																? 'Quarterly allowed, Annually allowed'
																: 'Quarterly not allowed, Annually allowed'
														}
														name="allowedQuarterly"
														width={false}
													/>
												</div>
											</FormRow>

											<FormRow label="Allowed payment methods">
												<FieldStatus name="allowedPaymentMethods">
													<MultiselectField
														name="allowedPaymentMethods"
														options={paymentMethodOptions}
													/>
												</FieldStatus>
											</FormRow>

											{values.allowedPaymentMethods.includes(METHOD_INVOICE) && (
												<FormRow
													htmlFor="manualPaymentNetTerms"
													label="Net terms"
												>
													<FieldStatus name="manualPaymentNetTerms">
														<TextField
															attributes={{
																min: 1,
															}}
															name="manualPaymentNetTerms"
															placeholder="days"
															trimValue={true}
															type={TextFieldType.Number}
														/>
													</FieldStatus>
												</FormRow>
											)}
										</FormRows>

										<ButtonsLayout>
											<CancelButton />
											<SaveSubmitButton />
										</ButtonsLayout>
									</>
								);
							}}
						</Form>
					</EditablePart>
				</EditableFormWrapper>
			)
			}
		</WhenAccountActionAllowed>
	);
};



export default SubscriptionOptionsSettingsForm;
