import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';
import createKeyedStore from '~/createKeyedStore';

import useAccountFeaturesAvailability from '~/hooks/useAccountFeaturesAvailability';
import useAccountFeaturesUsage from '~/hooks/useAccountFeaturesUsage';
import useAccountPlan from '~/hooks/useAccountPlan';
import useAccountTariff from '~/hooks/useAccountTariff';
import useAllowedPlans from '~/hooks/useAllowedPlans';
import useCurrentUserId from '~/hooks/useCurrentUserId';
import useKeyedStoreDefaultValue from '~/hooks/useKeyedStoreDefaultValue';
import usePageBundle from '~/hooks/usePageBundle';
import useUserEmail from '~/hooks/useUserEmail';

import {
	useSignupDefaultsQuery,
} from './useAccountSignup.gql';

import {
	normalizePageCapacity,
	normalizePagesAmount,
} from '~/model/pricing/tariffs';

import {
	getPlanAvailability,
	getTariffPlans,
} from '~/model/universal';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



type AccountSignupState = {
	additionalCustomerDetails: AccountSignupAdditionalCustomerDetails,
	billingCycle: GraphQL.Term | null,
	currency: GraphQL.Currency | null,
	customerDetails: AccountSignupCustomerDetails,
	defaultCountry: string | null,
	email: string | null,
	pageBundle: number | null,
	plan: GraphQL.AccountPlan | null,
};

type AccountSignupAdditionalCustomerDetails = {
	attn: string | null,
	customerReference: string | null,
	purchaseOrderNumber: string | null,
	supplierNumber: string | null,
	// delivery address
	address: string | null,
	city: string | null,
	country: string | null,
	name: string | null,
	postalCode: string | null,
	state: string | null,
};

type AccountSignupCustomerDetails = {
	address: string | null,
	city: string | null,
	country: string | null,
	name: string | null,
	postalCode: string | null,
	state: string | null,
	vatNumber: string | null,
};



const useSignupStore = createKeyedStore<number, AccountSignupState>(
	() => ({
		additionalCustomerDetails: {
			attn: null,
			customerReference: null,
			purchaseOrderNumber: null,
			supplierNumber: null,
			// delivery address
			address: null,
			city: null,
			country: null,
			name: null,
			postalCode: null,
			state: null,
		},
		billingCycle: null,
		currency: null,
		customerDetails: {
			address: null,
			city: null,
			country: null,
			name: null,
			postalCode: null,
			state: null,
			vatNumber: null,
		},
		defaultCountry: null,
		email: null,
		pageBundle: null,
		plan: null,
	}),
);



function createOptimalPurchaseConfiguration(
	accountFeaturesAvailability: ReturnType<typeof useAccountFeaturesAvailability>,
	accountFeaturesUsage: ReturnType<typeof useAccountFeaturesUsage>,
	accountPlan: ReturnType<typeof useAccountPlan>,
	accountTariff: ReturnType<typeof useAccountTariff>,
	accountWebsites: any,
	allowedPlans: ReturnType<typeof useAllowedPlans>,
	isSignupOptimized: boolean | null,
	pageBundle: ReturnType<typeof usePageBundle>,
) {
	if (
		accountFeaturesAvailability === null
		|| accountFeaturesUsage === null
		|| accountPlan === null
		|| accountTariff === null
		|| accountWebsites === null
		|| allowedPlans === null
		|| isSignupOptimized === null
		|| pageBundle === null
	) {
		return null;
	}

	const optimalPageBundle = normalizePagesAmount({
		numberOfPages: isSignupOptimized ? accountWebsites.reduce((reduction, website) => {
			return reduction + normalizePageCapacity({
				pageCapacity: website.pagesCount,
				tariff: accountTariff,
			});
		}, 0) : pageBundle,
		tariff: accountTariff,
	});

	const availablePlans = getTariffPlans(accountTariff).filter((plan) => {
		const {
			isAvailable,
		} = getPlanAvailability(
			accountFeaturesAvailability,
			accountPlan,
			accountTariff,
			accountFeaturesUsage.featuresUsage,
			plan,
		);

		return isAvailable;
	}).filter((plan) => allowedPlans.includes(plan));

	if (availablePlans.length === 0) {
		return null;
	}

	const optimalPlan = getArrayItemAtSafeIndex(availablePlans, 0);

	return {
		plan: optimalPlan,
		pageBundle: optimalPageBundle as number,
	};
}



function useAccountSignup(accountId: CK.AccountId | null) {
	const currentUserId = useCurrentUserId();

	const accountFeaturesAvailability = useAccountFeaturesAvailability();
	const accountFeaturesUsage = useAccountFeaturesUsage(accountId);
	const accountPlan = useAccountPlan(accountId);
	const accountSignup = useSignupStore(accountId ?? -1);
	const accountTariff = useAccountTariff(accountId);
	const allowedPlans = useAllowedPlans(accountId);
	const currentUserEmail = useUserEmail(currentUserId);
	const pageBundle = usePageBundle(accountId);

	const signupDefaults = useSignupDefaultsQuery({
		variables: {
			accountId: accountId as number,
		},
		skip: !accountId,
	});

	useKeyedStoreDefaultValue(
		accountSignup,
		'billingCycle',
		signupDefaults.data?.account?.billingCycle ?? null,
	);

	useKeyedStoreDefaultValue(
		accountSignup,
		'currency',
		signupDefaults.data?.account?.currency ?? null,
	);

	useKeyedStoreDefaultValue(
		accountSignup,
		'email',
		currentUserEmail ?? null,
	);

	const accountWebsites = signupDefaults.data?.account?.websites ?? null;

	const optimalPurchase = createOptimalPurchaseConfiguration(
		accountFeaturesAvailability,
		accountFeaturesUsage,
		accountPlan,
		accountTariff,
		accountWebsites,
		allowedPlans,
		signupDefaults.data?.account?.isSignupOptimized ?? null,
		pageBundle,
	);

	const optimalPageBundle = optimalPurchase !== null ? optimalPurchase.pageBundle : null;
	const optimalPlan = (optimalPurchase !== null ? optimalPurchase.plan : null) as GraphQL.AccountPlan | null;

	const defaultCountry = signupDefaults.data?.account?.defaultCountry ?? null;

	return React.useMemo(
		() => {
			const isPageBundleTooLargeForPlan = (plan: GraphQL.AccountPlan) => (
				accountSignup.pageBundle !== null
				&& (
					(plan === GraphQL.AccountPlan.C2Starter && accountSignup.pageBundle > 100_000)
					|| (plan === GraphQL.AccountPlan.C2Professional && accountSignup.pageBundle > 500_000)
				)
			);

			return {
				...accountSignup,
				defaultCountry,
				isPageBundleTooLargeForPlan,
				optimalPageBundle,
				pageBundle: accountSignup.pageBundle ?? optimalPageBundle ?? pageBundle,
				plan: accountSignup.plan ?? optimalPlan,
			};
		},
		[
			accountSignup,
			defaultCountry,
			optimalPageBundle,
			optimalPlan,
			pageBundle,
		],
	);
}



export default useAccountSignup;
