import React from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import Ellipsis from '~/components/patterns/values/Ellipsis';
import List from '~/components/patterns/lists/List';
import LogSourceProviderIcon from '~/components/app/LogSourceProviderIcon';
import MultiselectFieldFilter from '~/components/logic/datatables/MultiselectFieldFilter';
import NoSearchResults from '~/components/patterns/messages/embedded/NoSearchResults';
import NumberFieldFilter from '~/components/logic/datatables/NumberFieldFilter';
import PlainTextFieldFilter from '~/components/logic/datatables/PlainTextFieldFilter';
import ResizableDatatable, {
	type ResizableDatatableRef,
} from '~/components/logic/datatables/ResizableDatatable';
import WebsitesOverviewTableIntegrationsList from '~/components/app/WebsitesOverviewTableIntegrationsList';
import WebsitesOverviewTablePlanUsage from '~/components/app/WebsitesOverviewTablePlanUsage';
import WebsiteName from '~/components/logic/websites/WebsiteName';
import WebsiteStatus from '~/components/app/WebsiteStatus';

import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';

import {
	COLUMN_DOMAIN,
	COLUMN_INTEGRATIONS,
	COLUMN_LOG_SOURCES,
	COLUMN_NAME,
	COLUMN_NUMBER_OF_CUSTOM_ELEMENTS,
	COLUMN_NUMBER_OF_SEGMENTS,
	COLUMN_PAGE_CAPACITY_USAGE,
	COLUMN_STATUS,
} from '~/model/websites';



const messages = defineMessages({
	[COLUMN_DOMAIN]: {
		id: 'ui.websites.overview.table.columns.domain',
	},
	[COLUMN_INTEGRATIONS]: {
		id: 'ui.websites.overview.table.columns.integrations',
	},
	[COLUMN_LOG_SOURCES]: {
		id: 'ui.websites.overview.table.columns.logSources',
	},
	[COLUMN_NAME]: {
		id: 'ui.websites.overview.table.columns.name',
	},
	[COLUMN_NUMBER_OF_CUSTOM_ELEMENTS]: {
		id: 'ui.websites.overview.table.columns.customElements',
	},
	[COLUMN_NUMBER_OF_SEGMENTS]: {
		id: 'ui.websites.overview.table.columns.segments',
	},
	[COLUMN_PAGE_CAPACITY_USAGE]: {
		id: 'ui.websites.overview.table.columns.planCapacity',
	},
	[GraphQL.WebsitePageCapacityUsageDescription.Near]: {
		id: 'ui.websites.overview.table.planCapacity.values.near',
	},
	[GraphQL.WebsitePageCapacityUsageDescription.Reached]: {
		id: 'ui.websites.overview.table.planCapacity.values.reached',
	},
	[GraphQL.WebsitePageCapacityUsageDescription.Within]: {
		id: 'ui.websites.overview.table.planCapacity.values.within',
	},
	[GraphQL.LogSourceStatus.Available]: {
		id: 'ui.websites.overview.table.logSourceStatus.available',
	},
	[GraphQL.LogSourceStatus.Enabled]: {
		id: 'ui.websites.overview.table.logSourceStatus.enabled',
	},
	[GraphQL.LogSourceStatus.NotAvailable]: {
		id: 'ui.websites.overview.table.logSourceStatus.notAvailable',
	},
});



const columnDefinitions = [
	{
		name: COLUMN_STATUS,
		render: {
			cell({ row: website }) {
				return (
					<WebsiteStatus
						websiteId={website.id}
					/>
				);
			},
			label: () => '',
		},
		sortOrder: true,
		width: 66,
	},
	{
		name: COLUMN_NAME,
		render: {
			cell({ row: website }) {
				return (
					<WebsiteName
						websiteId={website.id}
					/>
				);
			},
			filter: ({ filterName, filterWidth, ref }) => (
				<PlainTextFieldFilter
					name={filterName}
					ref={ref}
					width={filterWidth}
				/>
			),
			label: () => (
				<FormattedMessage {...messages[COLUMN_NAME]} />
			),
		},
		resizable: true,
		sortOrder: true,
		width: 400,
	},
	{
		name: COLUMN_DOMAIN,
		render: {
			cell({ row: website }) {
				return (
					<Ellipsis>
						{website.domain}
					</Ellipsis>
				);
			},
			filter: ({ filterName, filterWidth, ref }) => (
				<PlainTextFieldFilter
					name={filterName}
					ref={ref}
					width={filterWidth}
				/>
			),
			label: () => (
				<FormattedMessage {...messages[COLUMN_DOMAIN]} />
			),
		},
		resizable: true,
		sortOrder: true,
		width: 400,
	},
	{
		name: COLUMN_PAGE_CAPACITY_USAGE,
		render: {
			cell({ row: website }) {
				return (
					<WebsitesOverviewTablePlanUsage website={website} />
				);
			},
			filter: ({ filterName, filterWidth, ref }) => (
				<MultiselectFieldFilter
					isOnlyLinkVisible={true}
					name={filterName}
					options={pageCapacityUsageFilterOptions}
					ref={ref}
					width={filterWidth}
				/>
			),
			label: () => (
				<FormattedMessage {...messages[COLUMN_PAGE_CAPACITY_USAGE]} />
			),
		},
		resizable: true,
		width: 250,
	},
	{
		name: COLUMN_INTEGRATIONS,
		render: {
			cell({ row: website }) {
				return (
					<WebsitesOverviewTableIntegrationsList
						websiteId={website.id}
					/>
				);
			},
			label: () => (
				<FormattedMessage {...messages[COLUMN_INTEGRATIONS]} />
			),
		},
		resizable: true,
		width: 250,
		widthMinimum: 155,
	},
	{
		name: COLUMN_LOG_SOURCES,
		render: {
			cell({ row: website }) {
				const providers = [
					GraphQL.LogSourceProvider.Cloudflare,
					GraphQL.LogSourceProvider.CloudflareLogpush,
					GraphQL.LogSourceProvider.Akamai,
					GraphQL.LogSourceProvider.Cloudfront,
					GraphQL.LogSourceProvider.Fastly,
				];

				const providerStatus = providers.reduce((providerStatus, provider) => {
					providerStatus[provider] = website.logSources.find((logSource) => logSource.provider === provider)?.status ?? null;

					return providerStatus;
				}, {});

				const icons = providers
					.filter((provider) => {
						// Only show Cloodflare Log Push if it is enabled
						if (provider === GraphQL.LogSourceProvider.CloudflareLogpush) {
							return providerStatus[GraphQL.LogSourceProvider.CloudflareLogpush] === GraphQL.LogSourceStatus.Enabled;
						}

						// Hide plain Cloudflare status if Cloudflare Log push is enabled
						if (provider === GraphQL.LogSourceProvider.Cloudflare) {
							return providerStatus[GraphQL.LogSourceProvider.CloudflareLogpush] !== GraphQL.LogSourceStatus.Enabled;
						}

						return true;
					}).map((provider) => {
						const status = providerStatus[provider];

						if (status === null) {
							return null;
						}

						return (
							<LogSourceProviderIcon
								key={provider}
								provider={provider}
								status={status}
							/>
						);
					});

				return (
					<List inline={true}>
						{icons}
					</List>
				);
			},
			filter: ({ filterName, filterWidth, ref }) => (
				<MultiselectFieldFilter
					dropdownWidth={Math.max(filterWidth, 200)}
					isOnlyLinkVisible={true}
					name={filterName}
					options={logSourcesFilterOptions}
					ref={ref}
					width={filterWidth}
				/>
			),
			label: () => (
				<FormattedMessage {...messages[COLUMN_LOG_SOURCES]} />
			),
		},
		sortOrder: true,
		resizable: true,
		width: 170,
		widthMinimum: 170,
	},
	{
		name: COLUMN_NUMBER_OF_SEGMENTS,
		render: {
			cell({ row: website }) {
				return (
					<FormattedNumber value={website.pageSegments.length} />
				);
			},
			filter: ({ filterName, filterWidth, ref }) => (
				<NumberFieldFilter
					name={filterName}
					ref={ref}
					width={filterWidth}
				/>
			),
			label: (
				<FormattedMessage {...messages[COLUMN_NUMBER_OF_SEGMENTS]} />
			),
		},
		resizable: true,
		sortOrder: true,
		width: 200,
	},
	{
		name: COLUMN_NUMBER_OF_CUSTOM_ELEMENTS,
		render: {
			cell({ row: website }) {
				return (
					<FormattedNumber value={website.customElements.length} />
				);
			},
			filter: ({ filterName, filterWidth, ref }) => (
				<NumberFieldFilter
					name={filterName}
					ref={ref}
					width={filterWidth}
				/>
			),
			label: (
				<FormattedMessage {...messages[COLUMN_NUMBER_OF_CUSTOM_ELEMENTS]} />
			),
		},
		resizable: true,
		sortOrder: true,
		width: 225,
	},
];

const logSourcesFilterOptions = [
	{
		name: GraphQL.LogSourceStatus.Available,
		title: (
			<FormattedMessage {...messages[GraphQL.LogSourceStatus.Available]} />
		),
	},
	{
		name: GraphQL.LogSourceStatus.Enabled,
		title: (
			<FormattedMessage {...messages[GraphQL.LogSourceStatus.Enabled]} />
		),
	},
	{
		name: GraphQL.LogSourceStatus.NotAvailable,
		title: (
			<FormattedMessage {...messages[GraphQL.LogSourceStatus.NotAvailable]} />
		),
	},
];

const pageCapacityUsageFilterOptions = [
	{
		name: GraphQL.WebsitePageCapacityUsageDescription.Reached,
		title: (
			<FormattedMessage {...messages[GraphQL.WebsitePageCapacityUsageDescription.Reached]} />
		),
		suffix: '100%',
	},
	{
		name: GraphQL.WebsitePageCapacityUsageDescription.Near,
		title: (
			<FormattedMessage {...messages[GraphQL.WebsitePageCapacityUsageDescription.Near]} />
		),
		suffix: '> 95%',
	},
	{
		name: GraphQL.WebsitePageCapacityUsageDescription.Within,
		title: (
			<FormattedMessage {...messages[GraphQL.WebsitePageCapacityUsageDescription.Within]} />
		),
	},
];



type Props = {
	filter: any,
	isLoading: boolean,
	onFilterCallback: any,
	onSortCallback: any,
	sortBy: any,
	onWebsiteClickCallback: (website: Record<string, any>, event: React.MouseEvent) => void,
	websites: ReadonlyArray<any>,
};

const WebsitesOverviewTable = React.forwardRef<ResizableDatatableRef, Props>((props, ref) => {
	const {
		filter,
		isLoading,
		onFilterCallback,
		onSortCallback,
		onWebsiteClickCallback,
		sortBy,
		websites,
	} = props;

	const kingdomAdminFeatures = useKingdomAdminFeatures();

	const effectiveColumnDefinitions = React.useMemo(
		() => {
			if (kingdomAdminFeatures.areVisible) {
				return columnDefinitions;
			}

			return columnDefinitions.filter(
				(columnDefinition) => columnDefinition.name !== COLUMN_DOMAIN,
			);
		},
		[
			kingdomAdminFeatures,
		],
	);

	const rowGetter = React.useCallback(
		({ rowIndex }: { rowIndex: number }) => {
			return websites[rowIndex] ?? null;
		},
		[
			websites,
		],
	);

	const handleRowClick = React.useCallback(
		({ row }, event: React.MouseEvent) => {
			onWebsiteClickCallback(row.id, event);
		},
		[
			onWebsiteClickCallback,
		],
	);

	return (
		<ResizableDatatable
			blankSlate={!isLoading && websites.length === 0 && (
				<NoSearchResults />
			)}
			columns={effectiveColumnDefinitions}
			filter={filter}
			isLoading={isLoading}
			onFilterChange={onFilterCallback}
			onRowClick={handleRowClick}
			onSortChangeCallback={onSortCallback}
			ref={ref}
			rowGetter={rowGetter}
			rowsCount={websites.length}
			sortBy={sortBy}
		/>
	);
});



export default WebsitesOverviewTable;
