import React from 'react';

import type CK from '~/types/contentking';

import {
	FormContext,
} from '~/components/atoms/forms/basis/Form';

import PlanFeatures from '~/components/app/PlanFeatures';
import PlanName from '~/components/names/PlanName';
import SwitchButtonsField from '~/components/atoms/forms/components/SwitchButtonsField';
import SwitchButtonsDescription from '~/components/patterns/buttons/SwitchButtons/SwitchButtonsDescription';

import useAccountTariff from '~/hooks/useAccountTariff';
import useAllowedPlans from '~/hooks/useAllowedPlans';

import GraphQL from '~/types/graphql';

import {
	getTariffPlans,
} from '~/model/universal';



type Props = {
	accountId: CK.AccountId | null,
	name: string,
};

const PlanField: React.FC<Props> = (props) => {
	const {
		accountId,
		name,
	} = props;

	const {
		isDisabled: isGloballyDisabled,
		values,
	} = React.useContext(FormContext);

	const accountTariff = useAccountTariff(accountId);
	const allowedPlans = useAllowedPlans(accountId);

	const items = React.useMemo(
		() => {
			if (accountId === null || accountTariff === null) {
				return [];
			}

			return getTariffPlans(accountTariff)
				.filter((plan) => allowedPlans?.includes(plan))
				.filter((plan) => plan !== GraphQL.AccountPlan.Enterprise)
				.map((plan) => ({
					description: (
						<PlanFeatures
							accountId={accountId}
							compact={true}
							plan={plan}
						/>
					),
					disabled: isGloballyDisabled,
					label: (
						<PlanName plan={plan} />
					),
					value: plan,
				}));
		},
		[
			accountId,
			accountTariff,
			allowedPlans,
			isGloballyDisabled,
		],
	);

	const descriptionGetter = (value) => {
		const selectedItem = items.find((item) => item.value === value);

		if (selectedItem) {
			return selectedItem.description;
		}

		return null;
	};

	return (
		<SwitchButtonsDescription description={descriptionGetter(values[name])}>
			<SwitchButtonsField
				items={items}
				name={name}
			/>
		</SwitchButtonsDescription>
	);
};



export default React.memo(PlanField);
