import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import GoogleAnalyticsDependenciesWarning from '~/components/app/GoogleAnalyticsDependenciesWarning';
import GoogleAnalyticsIntegrationFields, {
	validateGoogleAnalyticsAccountId,
	validateGoogleAnalyticsPropertyId,
	validateGoogleAnalyticsViewId,
} from './GoogleAnalyticsIntegrationFields';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';
import SquareSkeleton from '~/components/patterns/loaders/SquareSkeleton';
import StaticList from '~/components/atoms/forms/components/StaticList';
import SubmitButton from '~/components/app/SubmitButton';

import {
	useConnectGoogleAnalyticsAccountToWebsiteMutation,
	useConnectGoogleAnalyticsV4AccountToWebsiteMutation,
	useDisableGoogleAnalyticsForWebsiteMutation,
} from './GoogleAnalyticsIntegrationForm.gql';

import useIsAllowedWithWebsite from '~/hooks/useIsAllowedWithWebsite';
import useWebsiteAccountId from '~/hooks/useWebsiteAccountId';
import useWebsiteGoogleAnalyticsIntegration from '~/hooks/useWebsiteGoogleAnalyticsIntegration';
import useWebsiteHasConflictingGoogleAnalyticsDependencies from '~/hooks/useWebsiteHasConflictingGoogleAnalyticsDependencies';
import useWebsiteIntegrations from '~/hooks/useWebsiteIntegrations';



const messages = defineMessages({
	available: {
		id: 'ui.websites.form.api.status.available',
	},
	disabled: {
		id: 'ui.websites.form.api.status.disabled',
	},
	enabled: {
		id: 'ui.websites.form.api.status.enabled',
	},
	saveButton: {
		id: 'ui.websites.form.api.googleAnalytics.saveButton',
	},
	title: {
		id: 'ui.websites.form.api.googleAnalytics.title',
	},
	viewNotFoundError: {
		id: 'ui.general.serverError',
	},
});

const validations = {
	validateGoogleAnalyticsAccountId,
	validateGoogleAnalyticsPropertyId,
	validateGoogleAnalyticsViewId,
};



type Props = {
	websiteId: CK.WebsiteId,
};

const GoogleAnalyticsIntegrationForm: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const websiteAccountId = useWebsiteAccountId(websiteId);
	const websiteIntegrations = useWebsiteIntegrations(websiteId);

	const isAllowedToEdit = useIsAllowedWithWebsite(
		websiteId,
		GraphQL.ActionWithWebsite.ManageSettings,
	);

	const integrationStatus = websiteIntegrations.getStatus(GraphQL.WebsiteIntegrationType.GoogleAnalytics);
	const isIntegrationOwned = websiteIntegrations.isNotOwned(GraphQL.WebsiteIntegrationType.GoogleAnalytics) === false;

	return (
		<EditableFormWrapper
			isAllowed={isAllowedToEdit}
			isReadOnly={integrationStatus !== GraphQL.WebsiteIntegrationStatus.Available && isIntegrationOwned === false}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<DisplayPart>
				<GoogleAnalyticsIntegrationDisplay
					integrationStatus={integrationStatus}
					isIntegrationOwned={isIntegrationOwned}
					websiteId={websiteId}
				/>
			</DisplayPart>

			<EditablePart>
				{websiteAccountId !== null && (
					<GoogleAnalyticsIntegrationEdit
						integrationStatus={integrationStatus}
						websiteId={websiteId}
					/>
				)}
			</EditablePart>
		</EditableFormWrapper>
	);
};



type DisplayProps = {
	integrationStatus: GraphQL.WebsiteIntegrationStatus | null,
	isIntegrationOwned: boolean,
	websiteId: CK.WebsiteId,
};

const GoogleAnalyticsIntegrationDisplay: React.FC<DisplayProps> = (props) => {
	const {
		integrationStatus,
		isIntegrationOwned,
		websiteId,
	} = props;

	const {
		integration,
		property,
		view,
		isLoading,
	} = useWebsiteGoogleAnalyticsIntegration(websiteId);

	const hasConflictingGoogleAnalyticsDependencies = useWebsiteHasConflictingGoogleAnalyticsDependencies(websiteId);

	return (
		<FormRows>
			<FormRow
				label={(
					<FormattedMessage {...messages.title} />
				)}
			>
				<StaticList>
					{(integrationStatus === GraphQL.WebsiteIntegrationStatus.Available || integrationStatus === GraphQL.WebsiteIntegrationStatus.FeatureNotEnabled) ? (
						<FormattedMessage {...messages.available} />
					) : integrationStatus === GraphQL.WebsiteIntegrationStatus.Disabled ? (
						<FormattedMessage {...messages.disabled} />
					) : null}

					{integrationStatus === GraphQL.WebsiteIntegrationStatus.Enabled && !isIntegrationOwned && (
						<FormattedMessage {...messages.enabled} />
					)}

					{integrationStatus === GraphQL.WebsiteIntegrationStatus.Enabled && isIntegrationOwned && (
						isLoading ? (
							<SquareSkeleton
								height={15}
								width={80}
							/>
						) : (
							integration?.googleAnalyticsAccount.name
						)
					)}

					{integrationStatus === GraphQL.WebsiteIntegrationStatus.Enabled && isIntegrationOwned && (
						isLoading ? (
							<SquareSkeleton
								height={15}
								width={80}
							/>
						) : (
							property?.name
						)
					)}

					{integrationStatus === GraphQL.WebsiteIntegrationStatus.Enabled && isIntegrationOwned && (
						view?.name
					)}
				</StaticList>
			</FormRow>

			{integrationStatus === GraphQL.WebsiteIntegrationStatus.Enabled && hasConflictingGoogleAnalyticsDependencies && (
				<FormRow>
					<GoogleAnalyticsDependenciesWarning
						googleAnalyticsVersion={(
							integration?.version === GraphQL.GoogleAnalyticsVersion.V4
								? GraphQL.GoogleAnalyticsVersion.V3
								: GraphQL.GoogleAnalyticsVersion.V4
						)}
					/>
				</FormRow>
			)}
		</FormRows>
	);
};



type EditProps = {
	integrationStatus: GraphQL.WebsiteIntegrationStatus | null,
	websiteId: CK.WebsiteId,
};

const GoogleAnalyticsIntegrationEdit: React.FC<EditProps> = (props) => {
	const {
		integrationStatus,
		websiteId,
	} = props;

	const googleAnalyticsIntegration = useWebsiteGoogleAnalyticsIntegration(websiteId);
	const hasConflictingGoogleAnalyticsDependencies = useWebsiteHasConflictingGoogleAnalyticsDependencies(websiteId);

	const [connectGoogleAnalyticsAccountToWebsite] = useConnectGoogleAnalyticsAccountToWebsiteMutation();
	const [connectGoogleAnalyticsV4AccountToWebsite] = useConnectGoogleAnalyticsV4AccountToWebsiteMutation();
	const [disableGoogleAnalyticsForWebsite] = useDisableGoogleAnalyticsForWebsiteMutation();

	const isLoading = googleAnalyticsIntegration.isLoading;

	async function handleFormSubmit(values): Promise<void> {
		if (values.googleAnalyticsEnabled) {
			if (values.googleAnalyticsVersion === GraphQL.GoogleAnalyticsVersion.V4) {
				await connectGoogleAnalyticsV4AccountToWebsite({
					variables: {
						propertyId: values.googleAnalyticsPropertyId,
						websiteId,
					},
				});
			} else {
				await connectGoogleAnalyticsAccountToWebsite({
					variables: {
						viewId: values.googleAnalyticsViewId,
						websiteId,
					},
				});
			}
		} else {
			await disableGoogleAnalyticsForWebsite({
				variables: {
					websiteId,
				},
			});
		}
	}

	return (
		<PremiumFeatureSituation
			featureName={GraphQL.AccountFeature.GoogleAnalyticsIntegration}
			hideIfUnattainable={false}
			style={PremiumFeatureSituationStyle.Ribbon}
		>
			{({ isFeatureEnabled }) => (
				<Form
					defaultValues={{
						googleAnalyticsEnabled: integrationStatus === GraphQL.WebsiteIntegrationStatus.Enabled,
						googleAnalyticsAccountId: googleAnalyticsIntegration.integration?.googleAnalyticsAccount.id,
						googleAnalyticsPropertyId: googleAnalyticsIntegration.integration?.propertyId,
						googleAnalyticsVersion: googleAnalyticsIntegration.version,
						googleAnalyticsViewId: googleAnalyticsIntegration.integration?.viewId,
					}}
					ignoreFieldUnmounts={true}
					isDisabled={isFeatureEnabled === false}
					key={isLoading ? 'loading' : 'ready'}
					onSuccess={handleFormSubmit}
					validations={validations}
				>
					{({ values }) => (
						<>
							<FormRows>
								<GoogleAnalyticsIntegrationFields
									websiteId={websiteId}
								/>

								{(hasConflictingGoogleAnalyticsDependencies || values.googleAnalyticsVersion !== googleAnalyticsIntegration.version) && (
									<FormRow>
										<GoogleAnalyticsDependenciesWarning
											googleAnalyticsVersion={(
												values.googleAnalyticsVersion === GraphQL.GoogleAnalyticsVersion.V4
													? GraphQL.GoogleAnalyticsVersion.V3
													: GraphQL.GoogleAnalyticsVersion.V4
											)}
										/>
									</FormRow>
								)}
							</FormRows>

							<FormErrorMessages
								errors={{
									viewNotFound: (
										<FormattedMessage {...messages.viewNotFoundError} />
									),
								}}
							/>

							<ButtonsLayout>
								<CancelButton />

								<SubmitButton>
									<FormattedMessage {...messages.saveButton} />
								</SubmitButton>
							</ButtonsLayout>
						</>
					)}
				</Form>
			)}
		</PremiumFeatureSituation>
	);
};



export default GoogleAnalyticsIntegrationForm;
