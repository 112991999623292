import type GraphQL from '~/types/graphql';

import {
	useUserSegmentsDisplayOrderQuery,
} from './useUserSegmentsDisplayOrder.gql';

import useUserPropertiesQuery from '~/hooks/useUserPropertiesQuery';



function useUserSegmentsDisplayOrder(legacyUserId: string | null): ReadonlyArray<GraphQL.SegmentIdentifier> | null {
	const { data } = useUserPropertiesQuery(
		useUserSegmentsDisplayOrderQuery,
		legacyUserId,
		{
			poll: true,
		},
	);

	return data?.user?.segmentsDisplayOrder ?? null;
}



export default useUserSegmentsDisplayOrder;
