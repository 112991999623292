import React from 'react';

import AdminSchemaOrgValidator from '~/components/app/AdminSchemaOrgValidator';
import KingdomDiagnosticsDiagnoseExternalDomainSection from '~/components/app/KingdomDiagnosticsDiagnoseExternalDomainSection';
import Sections from '~/components/logic/lego/Sections';

import matchAndReturn from '~/utilities/matchAndReturn';



enum Section {
	DiagnoseExternalDomain = 'DiagnoseExternalDomain',
	SchemaOrgValidator = 'SchemaOrgValidator',
}

const sectionSorting = [
	Section.DiagnoseExternalDomain,
	Section.SchemaOrgValidator,
];

const sectionRoutes = {
	[Section.DiagnoseExternalDomain]: 'generalAdmin.diagnostics.diagnoseExternalDomain',
	[Section.SchemaOrgValidator]: 'generalAdmin.diagnostics.schemaOrgValidator',
};

const sectionTitles = {
	[Section.DiagnoseExternalDomain]: 'External domain',
	[Section.SchemaOrgValidator]: 'Schema.org validator',
};

function renderSectionContent(section: Section) {
	return matchAndReturn(section, {
		[Section.DiagnoseExternalDomain]: (
			<KingdomDiagnosticsDiagnoseExternalDomainSection />
		),
		[Section.SchemaOrgValidator]: (
			<AdminSchemaOrgValidator />
		),
	});
}

function renderSectionTitle(section: Section) {
	return sectionTitles[section];
}



const AdminDiagnostics: React.FC = () => {
	return (
		<Sections
			getSectionRoute={(section) => sectionRoutes[section]}
			renderSectionContent={renderSectionContent}
			renderSectionTitle={renderSectionTitle}
			sections={sectionSorting}
		/>
	);
};



export default AdminDiagnostics;
