import type CK from '~/types/contentking';

import {
	useAccountEndUserPlanQuery,
} from './useAccountEndUserPlan.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountEndUserPlan(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountEndUserPlanQuery,
		accountId,
	);

	return data?.account?.endUserPlan ?? null;
}



export default useAccountEndUserPlan;
