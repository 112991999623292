import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import AvailabilityList, {
	AvailabilityLabelStatus,
	AvailabilityListSize,
} from '~/components/patterns/statuses/AvailabilityList';
import BenefitNameInPlan from './plans/BenefitNameInPlan';
import ExternalLink from '~/components/patterns/links/ExternalLink';

import {
	Benefit,
} from '~/model/benefits';



const hints = {
	[Benefit.IssuesConfiguration]: defineMessages({
		content: {
			id: 'ui.pricing.agency.benefitTooltips.issuesConfiguration.content',
		},
		href: {
			id: 'ui.pricing.agency.benefitTooltips.issuesConfiguration.href',
		},
		link: {
			id: 'ui.pricing.agency.benefitTooltips.issuesConfiguration.link',
		},
	}),
	[Benefit.ReportingApi]: defineMessages({
		content: {
			id: 'ui.pricing.agency.benefitTooltips.reportingAPI.content',
		},
		href: {
			id: 'ui.pricing.agency.benefitTooltips.reportingAPI.href',
		},
		link: {
			id: 'ui.pricing.agency.benefitTooltips.reportingAPI.link',
		},
	}),
};



type Props = {
	benefits: Array<{
		addon?: boolean,
		amount?: number,
		detailed?: boolean,
		disabled?: boolean,
		name: Benefit,
	}>,
	compact?: boolean,
	lostBenefits: Array<Benefit>,
};

const PlanFeaturesList: React.FC<Props> = (props) => {
	const {
		benefits,
		compact = false,
		lostBenefits,
	} = props;

	const intl = useIntl();

	const items = benefits.filter((benefit) => {
		if (compact && !benefit.detailed) {
			return true;
		}

		if (!compact && benefit.detailed) {
			return true;
		}

		if (benefit.detailed === undefined) {
			return true;
		}

		return false;
	}).map((benefit) => {
		const helpHintProperties = hints[benefit.name] && hints[benefit.name].link ? {
			text__link: (
				<ExternalLink href={intl.formatMessage(hints[benefit.name].href)}>
					<FormattedMessage {...hints[benefit.name].link} />
				</ExternalLink>
			),
		} : {};

		return {
			hint: hints[benefit.name] && (
				<FormattedMessage
					{...hints[benefit.name].content}
					values={helpHintProperties}
				/>
			),
			label: (
				<>
					<BenefitNameInPlan
						amount={benefit.amount ?? 1}
						disabled={!!benefit.disabled}
						name={benefit.name}
					/>
					{benefit.addon === true && ' (add-on)'}
				</>
			),
			status: benefit.disabled
				? (lostBenefits.includes(benefit.name) ? AvailabilityLabelStatus.Warning : AvailabilityLabelStatus.Unavailable)
				: AvailabilityLabelStatus.Available,
		};
	});

	return (
		<AvailabilityList
			items={items}
			size={AvailabilityListSize.XSmall}
		/>
	);
};



export default PlanFeaturesList;
