import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import ChangeSummary, {
	PAGES_DIFF_BUNDLE,
} from '../pricing/ChangeSummary';
import Copy from '../Copy';
import SubscriptionChangeSummary from '~/components/app/SubscriptionChangeSummary';

import useAccountBillingCycle from '~/hooks/useAccountBillingCycle';
import useAccountBillingEntity from '~/hooks/useAccountBillingEntity';
import useAccountCurrency from '~/hooks/useAccountCurrency';
import useAccountPhase from '~/hooks/useAccountPhase';
import useAccountPlan from '~/hooks/useAccountPlan';
import useAccountTariffCalculator from '~/hooks/useAccountTariffCalculator';
import useAccountWebsiteSizes from '~/hooks/useAccountWebsiteSizes';
import useAllowedBillingCycles from '~/hooks/useAllowedBillingCycles';
import useLegacyBillingDetails from '~/hooks/useLegacyBillingDetails';
import usePageBundle from '~/hooks/usePageBundle';

import {
	DETAIL_PLAN,
} from '~/model/pricing/universal';



const messages = defineMessages({
	overMaximumMessage: {
		id: 'ui.websites.new.changeSummary.overMaximumPages.message',
	},
	overMaximumTitle: {
		id: 'ui.general.caution',
	},
});



type Props = {
	accountId: CK.AccountId | null,
	pageCapacity: number,
	websites?: number,
};

const PageBundleChangeSummary: React.FC<Props> = (props) => {
	const {
		accountId,
		pageCapacity,
		websites = 0,
	} = props;

	const accountBillingEntity = useAccountBillingEntity(accountId);
	const accountPageBundle = usePageBundle(accountId);
	const accountPhase = useAccountPhase(accountId);
	const accountTariffCalculator = useAccountTariffCalculator(accountId);
	const accountWebsiteSizes = useAccountWebsiteSizes(accountId);

	if (
		accountBillingEntity === null
		|| accountId === null
		|| accountPageBundle === null
		|| accountPhase === null
		|| accountTariffCalculator.isReady === false
		|| accountWebsiteSizes === null
		|| pageCapacity === 0
	) {
		return null;
	}

	if (accountPhase === GraphQL.AccountPhase.Trial) {
		return null;
	}

	const normalizedPageCapacity = accountTariffCalculator.tariff.normalizePageCapacity(pageCapacity);

	const pageBundleUsage = accountWebsiteSizes.totalPageCapacity;

	const numberOfPages = pageBundleUsage + normalizedPageCapacity;

	if (numberOfPages > accountTariffCalculator.tariff.maximumPagesAmount && websites > 0) {
		return (
			<CalloutMessage
				borders={true}
				highlightedBorders={true}
				message={<FormattedMessage {...messages.overMaximumTitle} />}
				status={CalloutMessageStatus.Critical}
			>
				<Copy
					{...messages.overMaximumMessage}
					values={{
						countWebsites: websites,
						maximumNumberOfPages: accountTariffCalculator.tariff.maximumPagesAmount,
						numberOfPages,
					}}
				/>
			</CalloutMessage>
		);
	}

	const websitesOld = accountWebsiteSizes.websites.length;
	const websitesNew = websitesOld + websites;

	const pagesOld = accountPageBundle;

	const pagesNew = Math.max(pagesOld, accountTariffCalculator.tariff.normalizePagesAmount(pageBundleUsage + normalizedPageCapacity));

	if (accountBillingEntity !== 'old') {
		return (
			<SubscriptionChangeSummary
				accountId={accountId}
				additionalWebsitesPageCapacity={pageCapacity}
				extraWebsites={websites}
				hidePrice={false}
				pageBundle={pagesNew}
			/>
		);
	}

	if (websites === 0 && pagesOld >= pagesNew) {
		return null;
	}

	return (
		<LegacyPageBundleChangeSummary
			accountId={accountId}
			pageCapacity={pageCapacity}
			pagesNew={pagesNew}
			pagesOld={pagesOld}
			websitesNew={websitesNew}
			websitesOld={websitesOld}
		/>
	);
};



type LegacyPageBundleChangeSummaryProps = {
	accountId: CK.AccountId,
	pageCapacity: number,
	pagesNew: number,
	pagesOld: number,
	websitesNew: number,
	websitesOld: number,
};

const LegacyPageBundleChangeSummary: React.FC<LegacyPageBundleChangeSummaryProps> = (props) => {
	const {
		accountId,
		pageCapacity,
		pagesNew,
		pagesOld,
		websitesNew,
		websitesOld,
	} = props;

	const accountBillingCycle = useAccountBillingCycle(accountId);
	const accountCurrency = useAccountCurrency(accountId);
	const accountPlan = useAccountPlan(accountId);
	const accountTariffCalculator = useAccountTariffCalculator(accountId);
	const allowedBillingCycles = useAllowedBillingCycles(accountId);
	const teamBilling = useLegacyBillingDetails(accountId);

	if (
		accountBillingCycle === null
		|| accountCurrency === null
		|| accountPlan === null
		|| accountTariffCalculator.isReady === false
		|| allowedBillingCycles === null
		|| teamBilling === null
	) {
		return null;
	}

	const discounts = teamBilling.get('discounts')
		?.filter((discount) => discount.get('type') !== 'dollars') ?? [];
	let taxRate = teamBilling.get('tax');

	if (typeof taxRate === 'object') {
		taxRate = taxRate.get('rate');
	}

	const priceOld = accountTariffCalculator.tariff.calculatePurchaseCostDetails({
		billingCycle: accountBillingCycle,
		discounts,
		purchases: [
			{
				details: {
					[DETAIL_PLAN]: accountPlan,
				},
				numberOfPages: pagesOld,
			},
		],
		taxRate,
	});

	const priceNew = accountTariffCalculator.tariff.calculatePurchaseCostDetails({
		billingCycle: accountBillingCycle,
		discounts,
		purchases: [
			{
				details: {
					[DETAIL_PLAN]: accountPlan,
				},
				numberOfPages: pagesNew,
			},
		],
		taxRate,
	});

	return (
		<ChangeSummary
			accountId={accountId}
			additionalWebsitesPageCapacity={pageCapacity}
			allowedBillingCycles={allowedBillingCycles}
			billingCycleNew={accountBillingCycle}
			billingCycleOld={accountBillingCycle}
			currency={accountCurrency}
			hidePrice={false}
			pagesDiff={PAGES_DIFF_BUNDLE}
			pagesNew={pagesNew}
			pagesOld={pagesOld}
			planNew={accountPlan}
			planOld={accountPlan}
			priceNew={priceNew.subtotal}
			priceOld={priceOld.subtotal}
			taxType={priceNew.taxType}
			websitesNew={websitesNew}
			websitesOld={websitesOld}
		/>
	);
};



export default PageBundleChangeSummary;
