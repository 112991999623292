import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Button, {
	ButtonSize,
	ButtonStyle,
	ButtonWidth,
} from '~/components/patterns/buttons/Button';

import useIntercom from '~/hooks/useIntercom';



const messages = defineMessages({
	buttonLabel: {
		id: 'ui.plan.contactUsButton.label',
	},
});



type Props = {
	dominant?: boolean,
};

const ContactUsButton: React.FC<Props> = (props) => {
	const {
		dominant = false,
	} = props;

	const {
		showIntercom,
	} = useIntercom();

	return (
		<Button
			onClick={showIntercom}
			size={dominant ? ButtonSize.Large : ButtonSize.Medium}
			style={ButtonStyle.Action}
			width={dominant ? ButtonWidth.Fullwidth : ButtonWidth.Default}
		>
			<FormattedMessage {...messages.buttonLabel} />
		</Button>
	);
};



export default ContactUsButton;
