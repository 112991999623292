import React from 'react';

import type CK from '~/types/contentking';

import {
	useAllowedCurrenciesQuery,
} from './useAllowedCurrencies.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';
import useBillingEntities from '~/hooks/useBillingEntities';
import useSignupBillingEntity from '~/hooks/useSignupBillingEntity';



function useAllowedCurrencies(accountId: CK.AccountId | null) {
	const billingEntities = useBillingEntities();
	const signupBillingEntity = useSignupBillingEntity(accountId);

	const { data } = useAccountPropertiesQuery(
		useAllowedCurrenciesQuery,
		accountId,
	);

	const accountAllowedCurrencies = data?.account?.allowedCurrencies ?? null;

	return React.useMemo(
		() => {
			if (
				accountAllowedCurrencies === null
				|| billingEntities === null
				|| signupBillingEntity === null
			) {
				return null;
			}

			return signupBillingEntity !== 'old'
				? billingEntities.getByCode(signupBillingEntity).allowedCurrencies
				: accountAllowedCurrencies;
		},
		[
			accountAllowedCurrencies,
			billingEntities,
			signupBillingEntity,
		],
	);
}



export default useAllowedCurrencies;
