import type CK from '~/types/contentking';

import {
	useAccountCustomElementsLimitQuery,
} from './useAccountCustomElementsLimit.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountCustomElementsLimit(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountCustomElementsLimitQuery,
		accountId,
	);

	return data?.account?.customElementsLimit ?? null;
}



export default useAccountCustomElementsLimit;
