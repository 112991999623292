import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';



class ModalContentSection extends Component {

	render() {
		const {
			centered,
			enforceReadableTextLength,
			children,
		} = this.props;

		const componentClasses = classNames({
			'modal-section': true,
			'modal-section--is-centered': centered,
			'modal-section--enforce-readable-text-length': enforceReadableTextLength,
		});

		return (
			<div
				className={componentClasses}
			>
				{children}
			</div>
		);
	}

}

ModalContentSection.defaultProps = {
	centered: false,
	enforceReadableTextLength: false,
};

ModalContentSection.propTypes = {
	/** Align content to the centre */
	centered: PropTypes.bool.isRequired,
	/** Define whether content will be textual. If yes, there will be applied max. width limitation for better readability. */
	enforceReadableTextLength: PropTypes.bool,
};



export default ModalContentSection;
