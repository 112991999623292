import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';



const messages = defineMessages({
	[GraphQL.AccountFeature.AdminRole]: {
		id: 'ui.planBenefits.benefit.organizationAdminRole',
	},
	[GraphQL.AccountFeature.CustomAlertTrigger]: {
		id: 'ui.downgradeBox.features.alertsForCustomElements',
	},
	[GraphQL.AccountFeature.CustomElements]: {
		id: 'ui.downgradeBox.features.customElements',
	},
	[GraphQL.AccountFeature.EnrichmentFields]: {
		id: 'ui.downgradeBox.features.enrichmentFields',
	},
	[GraphQL.AccountFeature.IssuesConfiguration]: {
		id: 'ui.downgradeBox.features.issuesConfiguration',
	},
	[GraphQL.AccountFeature.LighthouseMonitoring]: {
		id: 'ui.downgradeBox.features.lighthouseMonitoring',
	},
	[GraphQL.AccountFeature.LogFileAnalysis]: {
		id: 'ui.downgradeBox.features.logFileAnalysis',
	},
	[GraphQL.AccountFeature.MicrosoftTeams]: {
		id: 'ui.downgradeBox.features.microsoftTeams',
	},
	[GraphQL.AccountFeature.IndexNow]: {
		id: 'ui.downgradeBox.features.indexNow',
	},
	[GraphQL.AccountFeature.Rendering]: {
		id: 'ui.downgradeBox.features.rendering',
	},
	[GraphQL.AccountFeature.Slack]: {
		id: 'ui.downgradeBox.features.slack',
	},
	[GraphQL.AccountFeature.WebVitalsOriginSummary]: {
		id: 'ui.downgradeBox.features.webVitalsOriginSummary',
	},
	[GraphQL.AccountFeature.Websites]: {
		id: 'ui.downgradeBox.features.websites',
	},
});



type Props = {
	lostFeatures: ReadonlyArray<{
		featureAmount: number,
		featureName: GraphQL.AccountFeature,
	}>,
};

const ListOfFeaturesInUse: React.FC<Props> = (props) => {
	const {
		lostFeatures,
	} = props;

	return (
		<ul>
			{lostFeatures.map(({ featureAmount, featureName }) => (
				<li key={featureName}>
					<FormattedMessage
						{...messages[featureName]}
						values={{
							featureAmount: featureAmount - 1,
						}}
					/>
				</li>
			))}
		</ul>
	);
};



export default ListOfFeaturesInUse;
