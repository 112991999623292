import GraphQL from '~/types/graphql';



export function doesAccountTypeHavePageBundle(accountType) {
	return accountType === GraphQL.AccountType.Universal;
}



export function listUsedBillingCycles(
	accountCanSignup: boolean | null,
	accountBillingCycle: GraphQL.Term | null,
) {
	if (accountCanSignup === null || accountBillingCycle === null) {
		return [];
	}

	if (accountCanSignup) {
		return [];
	}

	return [
		accountBillingCycle,
	];
}
