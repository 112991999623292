import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	AbstractCheckboxFieldCheckedState,
} from '~/components/patterns/forms/fields/AbstractCheckboxField';
import AbstractSelectField, {
	AbstractSelectFieldDropdownAttachment,
	type AbstractSelectFieldRef,
	AbstractSelectFieldSize,
} from '~/components/patterns/forms/fields/AbstractSelectField';
import FieldDropdownMultiselectableOptions, {
	type FieldDropdownMultiselectableOptionsOptionChangeCallbackInput,
	type FieldDropdownMultiselectableOptionsOptionClickCallbackInput,
	type FieldDropdownMultiselectableOptionsOptions,
} from '~/components/patterns/forms/fieldParts/dropdowns/FieldDropdownMultiselectableOptions';

import useFormContext from '~/hooks/useFormContext';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';

import {
	gridMultiselection,
} from '~/utilities/gridMultiselection';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';

import {
	notEmpty,
} from '~/utilities/typeCheck';



type ClickCoordinates = {
	columnIndex: number,
	rowIndex: number,
};

type MultiselectFieldRef = {
	changeValue: (value: any) => void,
	close: () => void,
	open: () => void,
};

type Options = FieldDropdownMultiselectableOptionsOptions;

type SelectedOptions = Record<string, { selected: boolean | typeof INDETERMINATE }>;

const messages = defineMessages({
	all: {
		id: 'ui.multiselect.all',
		defaultMessage: 'All',
	},
	multiple: {
		id: 'ui.multiselect.multiple',
		defaultMessage: 'Multiple',
	},
	onlyLabel: {
		id: 'ui.forms.options.only',
		defaultMessage: 'only',
	},
	showMoreOptionsLabel: {
		id: 'ui.forms.options.showMore',
		defaultMessage: 'More options',
	},
});

function getOptionTitle(key: string, options: Options, namePrefix: string): ReadonlyArray<React.ReactNode> {
	return options.map((option) => {
		if ('divider' in option) {
			return null;
		}

		const name = namePrefix + option.name;

		if (key === name) {
			let output = option.title;

			if (React.isValidElement(output)) {
				output = React.cloneElement(output as any, {
					ellipsis: true,
				});
			}

			return output;
		} else if ('children' in option && option.children !== undefined && option.children.length > 0) {
			return getOptionTitle(key, option.children, name + '_');
		}

		return null;
	}).filter(notEmpty);
}

function getOptionsData(selectedOptions: SelectedOptions, options: Options, namePrefix: string) {
	return options.map((option) => {
		if ('divider' in option) {
			return option;
		}

		option = { ...option };

		const optionName = namePrefix + option.name;

		option.selected = selectedOptions[optionName]?.selected ?? false;

		if (option.children) {
			option.children = getOptionsData(selectedOptions, option.children, optionName + '_');
		}

		return option;
	});
}

function listAllSelectableOptions(options: Options) {
	const result: Array<string> = [];

	const extractSuboptions = (prefix: string, options: Options) => {
		options.forEach((option) => {
			if (!('name' in option)) {
				return;
			}

			if ('children' in option && option.children !== undefined) {
				extractSuboptions(prefix + option.name + '_', option.children);
			} else {
				result.push(prefix + option.name);
			}
		});
	};

	extractSuboptions('', options);

	return result;
}

function getSelectedOptionsArray(options: Options, selectedOptions: SelectedOptions) {
	const outputArray: Array<string> = [];

	const selectableOptions = listAllSelectableOptions(options);

	Object.entries(selectedOptions).forEach(([key, value]) => {
		if (selectableOptions.includes(key) === false) {
			return;
		}

		if (value.selected === true || value.selected == INDETERMINATE) {
			outputArray.push(key);
		}
	});

	return outputArray;
}

function normalizeFormValue(options: ReadonlyArray<string> | SelectedOptions) {
	let outputData: SelectedOptions = {};

	if (options instanceof Array) {
		options.forEach((option) => {
			outputData[option] = {
				selected: true,
			};
		});
	} else {
		outputData = options;
	}

	return outputData;
}

function selectableOptionsCount(options: Options) {
	let count = 0;

	options.map((option) => {
		if ('divider' in option) {
			return;
		}

		if (option.children && option.children.length > 0 && !option.onlyOneChildSelectable) {
			count += selectableOptionsCount(option.children);
		} else {
			count += 1;
		}
	});

	return count;
}

function selectedOptionsCount(
	selectedOptions: SelectedOptions,
	options: Options,
	namePrefix: string,
) {
	if (options.length === 0) {
		return 0;
	}

	return options.filter(
		(option) => (
			'name' in option
			&& (selectedOptions[namePrefix + option.name]?.selected ?? false)
		),
	).length;
}

function setStateToOptions(
	selectedOptions: SelectedOptions,
	state: boolean,
	options: Options,
	namePrefix: string,
	toggleMode: boolean,
	defaultSelectedChild: string | null,
) {
	let newSelectedOptions = { ...selectedOptions };

	options.forEach((option) => {
		if ('divider' in option) {
			return;
		}

		const optionName = namePrefix + option.name;

		if (toggleMode) {
			if (state) {
				if (defaultSelectedChild === null || defaultSelectedChild === option.name) {
					if (newSelectedOptions[optionName]?.selected !== true) {
						newSelectedOptions[optionName] = {
							selected: true,
						};
					}
				}
			} else {
				if (newSelectedOptions[optionName]?.selected === true) {
					newSelectedOptions[optionName] = {
						selected: false,
					};
				}
			}
		} else {
			if (newSelectedOptions[optionName]?.selected === true) {
				newSelectedOptions[optionName] = {
					selected: false,
				};
			}

			if (defaultSelectedChild === option.name) {
				newSelectedOptions[optionName] = {
					selected: true,
				};
			}
		}
	});

	options.forEach((option) => {
		if (
			'children' in option
			&& option.children !== undefined
			&& option.children.length > 0
		) {
			newSelectedOptions = setStateToOptions(
				newSelectedOptions,
				state,
				option.children,
				namePrefix + option.name + '_',
				toggleMode,
				defaultSelectedChild,
			);
		}
	});

	return newSelectedOptions;
}

const emptySelectedOptions = {};



export const INDETERMINATE = AbstractCheckboxFieldCheckedState.Indeterminate;



type Props = {
	dropdownAttachment?: AbstractSelectFieldDropdownAttachment,
	/** Possibility to set width of inner dropdown panels visible when multiselect contains deeper structure of options */
	dropdownInnerPanelWidth?: number,
	dropdownWidth?: number,
	/** Allow to hide inner options for multiselect with deeper structure of options */
	hideInnerOptions?: boolean,
	isDisabled?: boolean,
	isOnlyLinkVisible?: boolean,
	/** Possibility to set custom label renderer */
	labelRenderer?: React.ComponentProps<typeof AbstractSelectField>['labelRenderer'],
	name: string,
	onChangeCallback?: (name: string, value: any) => void,
	onDropdownCloseCallback?: () => void,
	onDropdownOpenCallback?: () => void,
	options: Options,
	popperEnabled?: boolean,
	/** Set minimum height for dropdown and make it scrollable. Note: this will break overflowing variants present in dropdown. */
	scrollableDropdown?: boolean,
	selectedLabelRenderer?: RenderProp<{
		defaultSelectedLabelRenderer: () => React.ReactNode,
		selectedOptions: ReadonlyArray<string>,
	}>,
	size?: AbstractSelectFieldSize,
	/** Possibility to limit amount of visible options */
	visibleOptionsCount?: number,
	width?: number,
};

const MultiselectField = React.forwardRef<MultiselectFieldRef, Props>((props, ref) => {
	const {
		dropdownAttachment,
		dropdownInnerPanelWidth,
		dropdownWidth,
		hideInnerOptions = false,
		isDisabled = false,
		isOnlyLinkVisible = false,
		labelRenderer,
		name,
		onChangeCallback = null,
		onDropdownCloseCallback = null,
		onDropdownOpenCallback = null,
		options,
		popperEnabled = true,
		scrollableDropdown = false,
		selectedLabelRenderer = null,
		size,
		visibleOptionsCount,
		width = 200,
	} = props;

	const formContext = useFormContext();

	const {
		defaultValues,
		onBlurHandler,
		onChangeHandler,
		onFocusHandler,
		onUnmountHandler,
	} = formContext;

	const defaultValue = defaultValues[name];

	const defaultSelectedOptions = React.useMemo(
		(): SelectedOptions => {
			if (!defaultValue) {
				return emptySelectedOptions;
			}

			const result = defaultValue.toJS
				? defaultValue.toJS()
				: defaultValue;

			return normalizeFormValue(result);
		},
		[
			defaultValue,
		],
	);

	const [doingMultiselection, setDoingMultiselection] = React.useState(false);
	const [lastClickCoordinates, setLastClickCoordinates] = React.useState<ClickCoordinates | null>(null);
	const [selectedOptions, setSelectedOptions] = React.useState(defaultSelectedOptions);

	const fieldRef = React.useRef<AbstractSelectFieldRef>();
	const isDataFormatArrayRef = React.useRef<boolean>();

	if (
		isDataFormatArrayRef.current === undefined
		&& defaultValue
	) {
		const rawDefaultValue = defaultValue.toJS
			? defaultValue.toJS()
			: defaultValue;

		isDataFormatArrayRef.current = rawDefaultValue instanceof Array;
	}

	React.useImperativeHandle(ref, () => ({
		changeValue: (value) => {
			if (value && value.toJS) {
				value = value.toJS();
			}

			setSelectedOptions(value ? normalizeFormValue(value) : emptySelectedOptions);
		},
		close: () => fieldRef.current?.close(),
		open: () => fieldRef.current?.open(),
	}));

	function preprocessSelectedOptionsOutput(options: Options, selectedOptions: SelectedOptions) {
		if (isDataFormatArrayRef.current !== true) {
			return selectedOptions;
		}

		return getSelectedOptionsArray(options, selectedOptions);
	}

	React.useEffect(
		() => {
			const output = preprocessSelectedOptionsOutput(options, selectedOptions);

			onChangeHandler(name, output).then(() => {
				if (onChangeCallback !== null) {
					onChangeCallback(name, output);
				}
			});
		},
		[
			name,
			onChangeCallback,
			onChangeHandler,
			options,
			selectedOptions,
		],
	);

	React.useEffect(
		() => {
			return () => {
				onUnmountHandler(name);
			};
		},
		[
			name,
			onUnmountHandler,
		],
	);

	const dropdownCloseCallback = React.useCallback(
		() => {
			onBlurHandler(name);

			// reset last click coordinates for multiselection
			setLastClickCoordinates(null);

			if (onDropdownCloseCallback !== null) {
				onDropdownCloseCallback();
			}
		},
		[
			name,
			onBlurHandler,
			onDropdownCloseCallback,
		],
	);

	const dropdownOpenCallback = React.useCallback(
		() => {
			onFocusHandler(name);

			if (onDropdownOpenCallback !== null) {
				onDropdownOpenCallback();
			}
		},
		[
			name,
			onDropdownOpenCallback,
			onFocusHandler,
		],
	);

	const handleOptionChange = React.useCallback(
		({ optionName, children, toggleMode, defaultSelectedChild }: FieldDropdownMultiselectableOptionsOptionChangeCallbackInput) => {
			let newSelectedOptions = { ...selectedOptions };

			// when in multiselection mode values will be changed in handleMultiselect
			if (doingMultiselection) {
				// we will just disable multiselection mode
				setDoingMultiselection(false);
			} else {
				if (children === undefined) {
					newSelectedOptions[optionName] = {
						selected: (newSelectedOptions[optionName]?.selected ?? false) === false,
					};
				} else {
					const selectedChildrenCount = selectedOptionsCount(
						newSelectedOptions,
						children,
						optionName + '_',
					);

					newSelectedOptions = setStateToOptions(
						newSelectedOptions,
						selectedChildrenCount === 0,
						children,
						optionName + '_',
						toggleMode,
						defaultSelectedChild,
					);
				}

				setSelectedOptions(newSelectedOptions);
			}

			const output = preprocessSelectedOptionsOutput(options, newSelectedOptions);

			onChangeHandler(name, output).then(() => {
				if (onChangeCallback !== null) {
					onChangeCallback(name, output);
				}
			});
		},
		[
			doingMultiselection,
			name,
			onChangeCallback,
			onChangeHandler,
			options,
			selectedOptions,
		],
	);

	const handleMultiselect = React.useCallback(
		(input: {
			firstClickedCoordinates: ClickCoordinates,
			newCheckedStatus: boolean,
			secondClickedCoordinates: ClickCoordinates,
		}) => {
			// prepare grid
			let grid: any = new Array(options.length).fill(false).map(() => new Array(1).fill(false));
			const optionNames = options.map((option) => 'name' in option ? option.name : null).filter(notEmpty);

			optionNames.forEach((optionName, rowIndex) => {
				if (selectedOptions[optionName]?.selected === true) {
					grid[rowIndex][0] = true;
				}

				const rowOption = getArrayItemAtSafeIndex(options, rowIndex);

				if ('children' in rowOption) {
					const selectedChildrenCount = selectedOptionsCount(
						selectedOptions,
						rowOption.children ?? [],
						optionName + '_',
					);

					if (selectedChildrenCount > 0) {
						grid[rowIndex][0] = true;
					}
				}
			});

			grid = gridMultiselection(
				grid,
				input.firstClickedCoordinates,
				input.secondClickedCoordinates,
				input.newCheckedStatus,
			);

			let newSelectedOptions = { ...selectedOptions };

			grid.forEach((row, rowIndex) => {
				const rowOption = getArrayItemAtSafeIndex(options, rowIndex);

				if ('divider' in rowOption) {
					return;
				}

				const optionName = rowOption.name;
				const optionChildren = rowOption.children;

				if (optionChildren && optionChildren.length > 0) {
					const selectedChildrenCount = selectedOptionsCount(
						selectedOptions,
						optionChildren,
						optionName + '_',
					);

					if ((selectedChildrenCount < optionChildren.length && row[0]) || (selectedChildrenCount > 0 && !row[0])) {
						let defaultSelectedChild: string | null = null;

						if (rowOption.onlyOneChildSelectable) {
							defaultSelectedChild = optionChildren
								.map((child) => 'name' in child ? child.name : null)
								.filter(notEmpty)[0] ?? null;
						}

						newSelectedOptions = setStateToOptions(
							newSelectedOptions,
							input.newCheckedStatus,
							optionChildren,
							optionName + '_',
							rowOption.onlyOneChildSelectable ?? true,
							defaultSelectedChild,
						);
					}
				} else {
					// 0 because grid has only one column
					if (row[0]) {
						newSelectedOptions[optionName] = {
							selected: input.newCheckedStatus,
						};
					} else {
						newSelectedOptions[optionName] = {
							selected: false,
						};
					}
				}
			});

			setDoingMultiselection(true);
			setSelectedOptions(newSelectedOptions);
		},
		[
			options,
			selectedOptions,
		],
	);

	const handleOptionClick = React.useCallback(
		({ optionName, children, event }: FieldDropdownMultiselectableOptionsOptionClickCallbackInput) => {
			const isChecked = selectedOptions[optionName]?.selected ?? false;

			// We will ignore clicking in children when having multi-level options.
			// Only first level is accepted for multiselection.
			const optionNames = options.map((option) => 'name' in option ? option.name : null).filter(notEmpty);
			const nameIndex = optionNames.indexOf(optionName);

			if (nameIndex !== -1) {
				const newLastClickCoordinates = {
					columnIndex: 0,
					rowIndex: nameIndex,
				};

				setLastClickCoordinates(newLastClickCoordinates);

				if (
					event.shiftKey
					&& lastClickCoordinates !== null
				) {
					const selectedChildrenCount = selectedOptionsCount(
						selectedOptions,
						children ?? [],
						optionName + '_',
					);

					const checkedStatus = isChecked || (children !== undefined && selectedChildrenCount > 0);

					handleMultiselect({
						firstClickedCoordinates: lastClickCoordinates,
						newCheckedStatus: checkedStatus === false,
						secondClickedCoordinates: newLastClickCoordinates,
					});
				}
			} else {
				// Multiselection isn't allowed in children
				setLastClickCoordinates(null);
			}
		},
		[
			handleMultiselect,
			lastClickCoordinates,
			options,
			selectedOptions,
		],
	);

	const handleOnlyLinkClick = React.useCallback(
		(optionName: string) => {
			let nextSelectedOptions = {};

			options.forEach((option) => {
				if ('divider' in option) {
					return;
				}

				if ('children' in option && option.children !== undefined) {
					const childSelectedOptions = {};

					option.children.forEach((child) => {
						if ('divider' in child) {
							return;
						}

						const childName = `${option.name}_${child.name}`;

						childSelectedOptions[childName] = {
							selected: name === option.name || optionName === childName,
						};
					});

					// When only a single child can be selected we need to keep the current
					// selected child option the same if it has already been selected. If
					// the parent option is not selected (and thus also no child option) we
					// will default to select only the first child option.
					if (option.name === optionName && option.onlyOneChildSelectable) {
						const childNames = Object.keys(childSelectedOptions);
						let singleSelectedChildName = getArrayItemAtSafeIndex(childNames, 0);

						childNames.forEach((childName) => {
							if (selectedOptions[childName]?.selected === true) {
								singleSelectedChildName = childName;
							}
						});

						nextSelectedOptions = {
							...nextSelectedOptions,
							[singleSelectedChildName]: childSelectedOptions[singleSelectedChildName],
						};
					} else {
						nextSelectedOptions = {
							...nextSelectedOptions,
							...childSelectedOptions,
						};
					}
				} else {
					nextSelectedOptions[option.name] = {
						selected: optionName === option.name,
					};
				}
			});

			setSelectedOptions(nextSelectedOptions);

			const output = preprocessSelectedOptionsOutput(options, nextSelectedOptions);

			onChangeHandler(name, output).then(() => {
				if (onChangeCallback !== null) {
					onChangeCallback(name, output);
				}
			});
		},
		[
			name,
			onChangeCallback,
			onChangeHandler,
			options,
			selectedOptions,
		],
	);

	const renderLabelText = () => {
		const selectedOptionsArray = getSelectedOptionsArray(options, selectedOptions).filter(
			(name) => options.find(
				(option) => {
					if ('divider' in option) {
						return false;
					}

					if (option.name === name && option.onlyOneChildSelectable !== true) {
						return true;
					}

					if (
						option.children !== undefined
						&& option.onlyOneChildSelectable === true
						&& option.children.some((child) => ('name' in child && option.name + '_' + child.name) === name)
					) {
						return true;
					}
				},
			),
		).filter((value, index, array) => array.indexOf(value) === index);

		const defaultSelectedLabelRenderer = () => {
			if (selectedOptionsArray.length === 0) {
				return '-';
			} else if (selectedOptionsArray.length === 1) {
				return getOptionTitle(
					getArrayItemAtSafeIndex(selectedOptionsArray, 0),
					options,
					'',
				).map((element, index) => (
					<React.Fragment key={index}>
						{element}
					</React.Fragment>
				));
			} else if (selectableOptionsCount(options) === selectedOptionsArray.length) {
				return (
					<FormattedMessage {...messages.all} />
				);
			}

			return (
				<FormattedMessage {...messages.multiple} />
			);
		};

		if (selectedLabelRenderer !== null) {
			return renderProp(selectedLabelRenderer, {
				defaultSelectedLabelRenderer,
				selectedOptions: selectedOptionsArray,
			});
		}

		return defaultSelectedLabelRenderer();
	};

	return (
		<AbstractSelectField
			dropdownAttachment={dropdownAttachment}
			dropdownWidth={dropdownWidth}
			isDisabled={isDisabled || formContext.isDisabled}
			label={renderLabelText()}
			labelRenderer={labelRenderer}
			onDropdownCloseCallback={dropdownCloseCallback}
			onDropdownOpenCallback={dropdownOpenCallback}
			popperEnabled={popperEnabled}
			ref={fieldRef}
			scrollableDropdown={scrollableDropdown}
			size={size}
			width={width}
		>
			<FieldDropdownMultiselectableOptions
				hideInnerOptions={hideInnerOptions}
				innerPanelWidth={dropdownInnerPanelWidth}
				isOnlyLinkVisible={isOnlyLinkVisible}
				name={name}
				onOnlyLinkClickCallback={handleOnlyLinkClick}
				onOptionChangeCallback={handleOptionChange}
				onOptionClickCallback={handleOptionClick}
				onlyLinkLabel={(
					<FormattedMessage {...messages.onlyLabel} />
				)}
				options={getOptionsData(selectedOptions, options, '')}
				showMoreLinkLabel={(
					<FormattedMessage {...messages.showMoreOptionsLabel} />
				)}
				visibleOptionsCount={visibleOptionsCount}
			/>
		</AbstractSelectField>
	);
});



export default MultiselectField;

export {
	AbstractSelectFieldDropdownAttachment as MultiselectFieldDropdownAttachment,
	MultiselectFieldRef,
	AbstractSelectFieldSize as MultiselectFieldSize,
};
