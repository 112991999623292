import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import useAccountFeaturesAvailability from '~/hooks/useAccountFeaturesAvailability';
import useAccountPlan from '~/hooks/useAccountPlan';
import useAccountTariff from '~/hooks/useAccountTariff';
import useAccountType from '~/hooks/useAccountType';
import useAllowedPlans from '~/hooks/useAllowedPlans';

import {
	getTariffPlans,
	isFeatureAvailable,
} from '~/model/universal';



function useFindRequiredPlan(accountId: CK.AccountId | null) {
	const accountFeaturesAvailability = useAccountFeaturesAvailability();
	const accountPlan = useAccountPlan(accountId);
	const accountTariff = useAccountTariff(accountId);
	const accountType = useAccountType(accountId);
	const allowedPlans = useAllowedPlans(accountId);

	return React.useCallback(
		(featureName: GraphQL.AccountFeature, featureAmount: number = 1) => {
			if (
				accountFeaturesAvailability === null
				|| allowedPlans === null
				|| accountTariff === null
			) {
				return null;
			}

			if (accountType !== GraphQL.AccountType.Universal) {
				return null;
			}

			const allowedTariffPlans: ReadonlyArray<GraphQL.AccountPlan> = getTariffPlans(accountTariff).filter(
				(plan: GraphQL.AccountPlan) => allowedPlans.includes(plan),
			);

			for (const plan of allowedTariffPlans) {
				if (isFeatureAvailable(accountFeaturesAvailability, accountTariff, plan, featureName, featureAmount)) {
					if (plan !== accountPlan) {
						return plan;
					}
				}
			}

			return null;
		},
		[
			accountFeaturesAvailability,
			accountPlan,
			accountTariff,
			accountType,
			allowedPlans,
		],
	);
}



export default useFindRequiredPlan;
