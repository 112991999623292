/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteIsCrawledForConductorIntelligenceQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteIsCrawledForConductorIntelligenceQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly isCrawledForConductorIntelligence: boolean } | null };


export const WebsiteIsCrawledForConductorIntelligenceDocument = gql`
    query WebsiteIsCrawledForConductorIntelligence($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    isCrawledForConductorIntelligence
  }
}
    `;

/**
 * __useWebsiteIsCrawledForConductorIntelligenceQuery__
 *
 * To run a query within a React component, call `useWebsiteIsCrawledForConductorIntelligenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteIsCrawledForConductorIntelligenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteIsCrawledForConductorIntelligenceQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteIsCrawledForConductorIntelligenceQuery(baseOptions: Apollo.QueryHookOptions<WebsiteIsCrawledForConductorIntelligenceQuery, WebsiteIsCrawledForConductorIntelligenceQueryVariables> & ({ variables: WebsiteIsCrawledForConductorIntelligenceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteIsCrawledForConductorIntelligenceQuery, WebsiteIsCrawledForConductorIntelligenceQueryVariables>(WebsiteIsCrawledForConductorIntelligenceDocument, options);
      }
export function useWebsiteIsCrawledForConductorIntelligenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteIsCrawledForConductorIntelligenceQuery, WebsiteIsCrawledForConductorIntelligenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteIsCrawledForConductorIntelligenceQuery, WebsiteIsCrawledForConductorIntelligenceQueryVariables>(WebsiteIsCrawledForConductorIntelligenceDocument, options);
        }
export function useWebsiteIsCrawledForConductorIntelligenceSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WebsiteIsCrawledForConductorIntelligenceQuery, WebsiteIsCrawledForConductorIntelligenceQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteIsCrawledForConductorIntelligenceQuery, WebsiteIsCrawledForConductorIntelligenceQueryVariables>(WebsiteIsCrawledForConductorIntelligenceDocument, options);
        }
export type WebsiteIsCrawledForConductorIntelligenceQueryHookResult = ReturnType<typeof useWebsiteIsCrawledForConductorIntelligenceQuery>;
export type WebsiteIsCrawledForConductorIntelligenceLazyQueryHookResult = ReturnType<typeof useWebsiteIsCrawledForConductorIntelligenceLazyQuery>;
export type WebsiteIsCrawledForConductorIntelligenceSuspenseQueryHookResult = ReturnType<typeof useWebsiteIsCrawledForConductorIntelligenceSuspenseQuery>;
export type WebsiteIsCrawledForConductorIntelligenceQueryResult = Apollo.QueryResult<WebsiteIsCrawledForConductorIntelligenceQuery, WebsiteIsCrawledForConductorIntelligenceQueryVariables>;