import React from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import CodeValue from '~/components/patterns/values/CodeValue';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import SmallTable from '~/components/atoms/issues/components/detailsPlaceholders/SmallTable';

import {
	useInvalidRobotsTxtSyntaxTableQuery,
} from './InvalidRobotsTxtSyntaxTable.gql';



const messages = defineMessages({
	columnsDirective: {
		id: 'ui.platformIssueDetail.robotsTxt.columns.directive',
	},
	columnsError: {
		id: 'ui.platformIssueDetail.robotsTxt.columns.error',
	},
	columnsLine: {
		id: 'ui.platformIssueDetail.robotsTxt.columns.line',
	},
	errorsInvalidFormat: {
		id: 'ui.platformIssueDetail.robotsTxt.lineErrors.invalidFormat',
	},
});



type Props = {
	tableWidth: number,
	websiteId: CK.WebsiteId,
};

const InvalidRobotsTxtSyntaxTable: React.FC<Props> = (props) => {
	const {
		tableWidth,
		websiteId,
	} = props;

	const { data } = useInvalidRobotsTxtSyntaxTableQuery({
		variables: {
			websiteId,
		},
	});

	const firstColumnWidth = 50;
	const secondColumnWidth = Math.ceil((tableWidth - firstColumnWidth) / 2);

	return (
		<SmallTable
			columns={[
				{
					render: {
						cell: ({ row }) => {
							return (
								<FormattedNumber value={row.lineNumber} />
							);
						},
						header: () => (
							<FormattedMessage {...messages.columnsLine} />
						),
					},
					width: firstColumnWidth,
				},
				{
					render: {
						cell: ({ row }) => {
							return (
								<Ellipsis>
									<CodeValue>
										{row.lineContent}
									</CodeValue>
								</Ellipsis>
							);
						},
						header: () => (
							<FormattedMessage {...messages.columnsDirective} />
						),
					},
					width: secondColumnWidth - 2,
				},
				{
					render: {
						cell: () => {
							return (
								<FormattedMessage {...messages.errorsInvalidFormat} />
							);
						},
						header: () => (
							<Ellipsis>
								<FormattedMessage {...messages.columnsError} />
							</Ellipsis>
						),
					},
					width: tableWidth - firstColumnWidth - secondColumnWidth - 2,
				},
			]}
			rows={data?.robotsTxtRevisionLatest?.linesWithInvalidSyntax ?? null}
			tableWidth={tableWidth}
		/>
	);
};



export default React.memo(InvalidRobotsTxtSyntaxTable);
