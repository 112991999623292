import React from 'react';

import type CK from '~/types/contentking';

import {
	usePrioritizePageMutation,
} from './usePrioritizePage.gql';



function usePrioritizePage(
	legacyUrlId: number | null,
	websiteId: CK.WebsiteId,
): () => void {
	const [prioritizePage] = usePrioritizePageMutation();

	const prioritize = React.useCallback(
		() => {
			if (legacyUrlId === null) {
				return;
			}

			prioritizePage({
				variables: {
					legacyUrlId,
					websiteId,
				},
			});
		},
		[
			legacyUrlId,
			prioritizePage,
			websiteId,
		],
	);

	return prioritize;
}



export default usePrioritizePage;
