/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountFeaturesAvailabilityQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type AccountFeaturesAvailabilityQuery = { readonly __typename?: 'Query', readonly accountFeaturesAvailability: ReadonlyArray<{ readonly __typename?: 'AccountFeatureAvailability', readonly accountFeature: GraphQL.AccountFeature, readonly amount: number, readonly plan: GraphQL.AccountPlan, readonly status: GraphQL.AccountFeatureAvailabilityStatus, readonly tariff: string }> };


export const AccountFeaturesAvailabilityDocument = gql`
    query AccountFeaturesAvailability {
  accountFeaturesAvailability {
    accountFeature
    amount
    plan
    status
    tariff
  }
}
    `;

/**
 * __useAccountFeaturesAvailabilityQuery__
 *
 * To run a query within a React component, call `useAccountFeaturesAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountFeaturesAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountFeaturesAvailabilityQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountFeaturesAvailabilityQuery(baseOptions?: Apollo.QueryHookOptions<AccountFeaturesAvailabilityQuery, AccountFeaturesAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountFeaturesAvailabilityQuery, AccountFeaturesAvailabilityQueryVariables>(AccountFeaturesAvailabilityDocument, options);
      }
export function useAccountFeaturesAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountFeaturesAvailabilityQuery, AccountFeaturesAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountFeaturesAvailabilityQuery, AccountFeaturesAvailabilityQueryVariables>(AccountFeaturesAvailabilityDocument, options);
        }
export function useAccountFeaturesAvailabilitySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccountFeaturesAvailabilityQuery, AccountFeaturesAvailabilityQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountFeaturesAvailabilityQuery, AccountFeaturesAvailabilityQueryVariables>(AccountFeaturesAvailabilityDocument, options);
        }
export type AccountFeaturesAvailabilityQueryHookResult = ReturnType<typeof useAccountFeaturesAvailabilityQuery>;
export type AccountFeaturesAvailabilityLazyQueryHookResult = ReturnType<typeof useAccountFeaturesAvailabilityLazyQuery>;
export type AccountFeaturesAvailabilitySuspenseQueryHookResult = ReturnType<typeof useAccountFeaturesAvailabilitySuspenseQuery>;
export type AccountFeaturesAvailabilityQueryResult = Apollo.QueryResult<AccountFeaturesAvailabilityQuery, AccountFeaturesAvailabilityQueryVariables>;