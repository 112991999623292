import Immutable from 'immutable';

import {
	ACTION_TARIFFS_START_LOADING_TARIFFS,
	ACTION_TARIFFS_STORE_TARIFFS,
} from '~/actions/tariffs';



function createDefaultState() {
	return Immutable.Map({
		loading: false,
		tariffs: null,
	});
}



export function tariffs(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case ACTION_TARIFFS_START_LOADING_TARIFFS: {
			state = state.set('loading', true);

			break;
		}



		case ACTION_TARIFFS_STORE_TARIFFS: {
			const {
				tariffs,
			} = action;

			state = state.set('tariffs', tariffs);

			break;
		}

	}

	return state;
}
