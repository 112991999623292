import React from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import CurrencyConfigurator from '~/components/logic/payments/CurrencyConfigurator';
import PlanName from '~/components/names/PlanName';
import PriceItem from '~/components/patterns/pricing/priceItems/PriceItem';
import PriceItems from '~/components/patterns/pricing/priceItems/PriceItems';
import PriceItemsDivider from '~/components/patterns/pricing/priceItems/PriceItemsDivider';
import SimpleBox from '~/components/patterns/boxes/SimpleBox';

import useAccountSignup from '~/hooks/useAccountSignup';
import useSignupPrice from '~/hooks/useSignupPrice';



const messages = defineMessages({
	discountsDollars: {
		id: 'ui.billing.priceSummary.discounts.customExact',
	},
	discountsPercent: {
		id: 'ui.billing.priceSummary.discounts.customPercentage',
	},
	priceSummaryEndUserItem: {
		id: 'ui.billing.priceSummary.website',
	},
	subtotal: {
		id: 'ui.teamDetail.websites.summary.label.subtotal',
	},
	taxLabel: {
		id: 'ui.taxType',
	},
});

const totalMessages = defineMessages({
	[GraphQL.Term.Annually]: {
		id: 'ui.teamDetail.websites.summary.label.total.annually',
	},
	[GraphQL.Term.Monthly]: {
		id: 'ui.teamDetail.websites.summary.label.total.monthly',
	},
	[GraphQL.Term.Quarterly]: {
		id: 'ui.teamDetail.websites.summary.label.total.quarterly',
	},
});



type Props = {
	accountId: CK.AccountId,
	assumeVatNumberWillBeGiven: boolean,
	customerDetailsProvided: boolean,
	renderCurrencySelect?: boolean,
};

const SignupPriceSummary: React.FC<Props> = (props) => {
	const {
		accountId,
		assumeVatNumberWillBeGiven = false,
		customerDetailsProvided,
		renderCurrencySelect = true,
	} = props;

	const accountSignup = useAccountSignup(accountId);
	const signupPrice = useSignupPrice(
		accountId,
		customerDetailsProvided,
		assumeVatNumberWillBeGiven,
	);

	if (
		accountSignup.plan === null
		|| !signupPrice.isReady
	) {
		return null;
	}

	const calculatedPrice = signupPrice.calculatePrice({
		plan: accountSignup.plan,
	});

	const billingCycle = calculatedPrice.billingCycle;
	const currency = signupPrice.currency;
	const plan = accountSignup.plan;

	const hasOtherDiscounts = calculatedPrice.discounts.some(
		(discount) => discount.reason !== 'commitment',
	);

	const hasTax = calculatedPrice.tax > 0 && calculatedPrice.taxType !== null;

	let commitmentDiscount = 0.0;

	calculatedPrice.discounts.forEach((discount) => {
		if (discount.reason === 'commitment') {
			commitmentDiscount = discount.value;
		}
	});

	return (
		<SimpleBox>
			<PriceItems
				ctaElements={renderCurrencySelect && (
					<CurrencyConfigurator
						accountId={accountId}
					/>
				)}
			>
				<PriceItem
					currency={currency}
					key="purchase"
					label={(
						<PlanName plan={plan} />
					)}
					priceValue={calculatedPrice.priceWithoutDiscounts - commitmentDiscount}
				/>

				{calculatedPrice.discounts.map((discount) => {
					let discountLabel;

					if (discount.reason === 'commitment') {
						return null;
					} else if (discount.type === 'percent') {
						discountLabel = (
							<FormattedMessage
								{...messages.discountsPercent}
								values={{
									count__discountAmount: discount.rate,
								}}
							/>
						);
					} else if (discount.type === 'dollars') {
						discountLabel = (
							<FormattedMessage {...messages.discountsDollars} />
						);
					}

					return (
						<PriceItem
							currency={currency}
							key={discount.code}
							label={discountLabel ?? null}
							priceValue={-discount.value}
						/>
					);
				})}

				{hasTax && hasOtherDiscounts && (
					<PriceItem
						currency={currency}
						label={(
							<FormattedMessage {...messages.subtotal} />
						)}
						priceValue={calculatedPrice.priceWithDiscounts}
					/>
				)}

				{hasTax && hasOtherDiscounts && (
					<PriceItemsDivider />
				)}

				{hasTax && (
					<PriceItem
						currency={currency}
						label={(
							<>
								<FormattedMessage
									{...messages.taxLabel}
									values={{
										isEstimate: calculatedPrice.taxIsEstimate ? 'yes' : 'no',
										taxType: calculatedPrice.taxType,
									}}
								/>{' '}
								<FormattedNumber
									maximumFractionDigits={3}
									style="percent"
									value={calculatedPrice.taxRate}
								/>
							</>
						)}
						priceValue={calculatedPrice.tax}
					/>
				)}

				<PriceItemsDivider />

				<PriceItem
					currency={currency}
					highlighted={true}
					label={
						<FormattedMessage {...totalMessages[billingCycle]} />
					}
					priceValue={calculatedPrice.totalPrice}
				/>
			</PriceItems>
		</SimpleBox>
	);
};



export default SignupPriceSummary;
