import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import useAccountDefaultCountry from '~/hooks/useAccountDefaultCountry';
import useAccountSignup from '~/hooks/useAccountSignup';
import useAccountTariff from '~/hooks/useAccountTariff';
import useAllowedCurrencies from '~/hooks/useAllowedCurrencies';
import useBillingEntities from '~/hooks/useBillingEntities';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



function useSignupCurrency(accountId: CK.AccountId | null): GraphQL.Currency | null {
	const accountDefaultCountry = useAccountDefaultCountry(accountId);
	const accountSignup = useAccountSignup(accountId);
	const accountTariff = useAccountTariff(accountId);
	const allowedCurrencies = useAllowedCurrencies(accountId);
	const billingEntities = useBillingEntities();

	if (
		accountSignup.customerDetails.country !== null
		&& accountTariff !== null
		&& billingEntities !== null
	) {
		return getArrayItemAtSafeIndex(
			billingEntities.getByCode(
				billingEntities.getByTariffAndCountry(accountTariff, accountSignup.customerDetails.country),
			).allowedCurrencies,
			0,
		);
	}

	if (
		accountDefaultCountry !== null
		&& accountTariff !== null
		&& billingEntities !== null
	) {
		return getArrayItemAtSafeIndex(
			billingEntities.getByCode(
				billingEntities.getByTariffAndCountry(accountTariff, accountDefaultCountry),
			).allowedCurrencies,
			0,
		);
	}

	if (
		accountSignup.currency !== null
		&& allowedCurrencies !== null
		&& !allowedCurrencies.includes(accountSignup.currency)
	) {
		return getArrayItemAtSafeIndex(allowedCurrencies, 0);
	}

	return accountSignup.currency;
}



export default useSignupCurrency;
