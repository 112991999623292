import type CK from '~/types/contentking';



const REVISIONS_PARAM_SEPARATOR = '...';



export function packRobotsTxtRevisionsParameter(revisionA: CK.ID, revisionB: CK.ID) {
	return revisionA + REVISIONS_PARAM_SEPARATOR + revisionB;
}



export function unpackRobotsTxtRevisionsParameter(revisionsParameter: string): {
	revisionA: CK.ID,
	revisionB: CK.ID,
} | {
	revisionA: null,
	revisionB: null,
} {
	const result = revisionsParameter.split(REVISIONS_PARAM_SEPARATOR);

	if (result.length !== 2) {
		return {
			revisionA: null,
			revisionB: null,
		};
	}

	return {
		revisionA: result[0] as CK.ID,
		revisionB: result[1] as CK.ID,
	};
}
