import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AttachedIcon from '~/components/patterns/structuredValues/AttachedIcon';
import BasicIcon from '~/components/patterns/icons/BasicIcon';
import MultiselectFieldFilter, {
	type MultiselectFieldFilterRef,
} from '~/components/logic/datatables/MultiselectFieldFilter';

import useMessagingAppChannels from '~/hooks/useMessagingAppChannels';
import useMessagingAppDefinitions from '~/hooks/useMessagingAppDefinitions';



const messages = defineMessages({
	none: {
		id: 'ui.alertsConfiguration.messagingApp.none',
	},
});



type Props = {
	name: string,
	width: number,
};

const MessagingAppChannelsFieldFilter = React.forwardRef<MultiselectFieldFilterRef, Props>((props, ref) => {
	const {
		name,
		width,
	} = props;

	const messagingAppChannels = useMessagingAppChannels();
	const messagingAppDefinitions = useMessagingAppDefinitions();

	const options = React.useMemo(
		() => {
			if (messagingAppChannels.isLoaded === false) {
				return [];
			}

			const result: Array<{
				name: string,
				title: React.ReactNode,
			} | {
				divider: boolean,
			}> = [];

			result.push({
				name: 'none',
				title: (
					<FormattedMessage {...messages.none} />
				),
			});

			messagingAppDefinitions.listAll().forEach((messagingAppType) => {
				const messagingAppChannelsWithType = messagingAppChannels.listByType(messagingAppType);

				if (messagingAppChannelsWithType.length > 0) {
					result.push({
						divider: true,
					});
				}

				messagingAppChannelsWithType.forEach((messagingAppChannel) => {
					result.push({
						name: messagingAppChannel.id,
						title: (
							<AttachedIcon
								ellipsis={false}
								icon={(
									<BasicIcon type={messagingAppDefinitions.getIcon(messagingAppType)} />
								)}
							>
								{messagingAppChannel.label}
							</AttachedIcon>
						),
					});
				});
			});

			return result;
		},
		[
			messagingAppChannels,
			messagingAppDefinitions,
		],
	);

	if (messagingAppChannels.isLoaded === false) {
		return null;
	}

	return (
		<MultiselectFieldFilter
			allValue="all"
			dropdownWidth={250}
			isOnlyLinkVisible={true}
			name={name}
			options={options}
			ref={ref}
			scrollableDropdown={true}
			width={width}
		/>
	);
});



export default React.memo(MessagingAppChannelsFieldFilter);
