import {
	useCalculateTaxQuery,
} from './useCalculateTax.gql';

import {
	type Tariff,
} from '~/model/pricing/tariffs';



function useCalculateTax(
	country: string | null,
	state: string | null,
	tariff: Tariff | null,
	vatNumber: string | null,
) {
	const { data } = useCalculateTaxQuery({
		skip: country === null || tariff === null,
		variables: {
			country: country ?? '',
			state,
			tariff: tariff ?? '',
			vatNumber: vatNumber !== null ? 'foo' : null,
		},
	});

	return data?.tax ?? null;
}



export default useCalculateTax;
