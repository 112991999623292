import type Immutable from 'immutable';
import React from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import BillingCycleName from '~/components/names/BillingCycleName';
import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import Copy from '~/components/logic/Copy';
import InsufficientPermissionMessage from '~/components/app/InsufficientPermissionMessage';
import PlanName from '~/components/names/PlanName';

import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';

import {
	PLAN_HIGHER,
	PLAN_LOWER,
	PLAN_SAME,
} from '~/model/plans';

import {
	BillingCycleComparison,
	compareBillingCycles,
	getBillingCycleDurationInMonths,
} from '~/model/pricing/billingCycle';

import {
	comparePlans,
} from '~/model/universal';



export const PAGES_DIFF_BUNDLE = 'bundle';
export const PAGES_DIFF_CAPACITY = 'capacity';
export const PAGES_DIFF_NONE = 'none';

const PAGES_LESS = 'PAGES_LESS';
const PAGES_MORE = 'PAGES_MORE';
const PAGES_SAME = 'PAGES_SAME';

const PRICE_CHEAPER = 'PRICE_CHEAPER';
const PRICE_MORE_EXPENSIVE = 'PRICE_MORE_EXPENSIVE';
const PRICE_SAME = 'PRICE_SAME';

const WEBSITES_LESS = 'WEBSITES_LESS';
const WEBSITES_MORE = 'WEBSITES_MORE';
const WEBSITES_SAME = 'WEBSITES_SAME';



const messages = defineMessages({
	[BillingCycleComparison.Longer]: {
		id: 'ui.billing.changeSummary.list.billingCycleChanged.longer',
	},
	[BillingCycleComparison.Shorter]: {
		id: 'ui.billing.changeSummary.list.billingCycleChanged.shorter',
	},
	[WEBSITES_MORE]: {
		id: 'ui.billing.changeSummary.list.websitesChanged.more',
	},
	additionalCost: {
		id: 'ui.billing.changeSummary.list.priceChanged.additionalCost',
	},
	cannotUpgradeSubscriptionBecauseInsufficientPermissionsForKingdomAdmin: {
		id: 'ui.billing.changeSummary.cannotUpgradeSubscriptionBecauseInsufficientPermissionsForKingdomAdmin',
	},
	cannotUpgradeSubscriptionBecauseManaged: {
		id: 'ui.billing.changeSummary.cannotUpgradeSubscriptionBecauseManaged',
	},
	caution: {
		id: 'ui.general.caution',
	},
	newPrice: {
		id: 'ui.billing.changeSummary.list.priceChanged.newPrice',
	},
	pagesChanged: {
		id: 'ui.billing.changeSummary.list.pagesChanged',
	},
	planChanged: {
		id: 'ui.billing.changeSummary.list.planChanged',
	},
	pricePerBillingCycle: {
		id: 'ui.billing.changeSummary.pricePerBillingCycle',
	},
	proratedCharge: {
		id: 'ui.billing.changeSummary.proratedCharge',
	},
	proratedCredit: {
		id: 'ui.billing.changeSummary.proratedCredit',
	},
	title: {
		id: 'ui.billing.changeSummary.header',
	},
});

const messagesSingleChange = defineMessages({
	[BillingCycleComparison.Longer]: {
		id: 'ui.billing.changeSummary.single.billingCycleChanged.longer',
	},
	[BillingCycleComparison.Shorter]: {
		id: 'ui.billing.changeSummary.single.billingCycleChanged.shorter',
	},
	[PAGES_LESS]: {
		id: 'ui.billing.changeSummary.single.pagesChanged.less',
	},
	[PAGES_MORE]: {
		id: 'ui.billing.changeSummary.single.pagesChanged.more',
	},
	[PLAN_HIGHER]: {
		id: 'ui.billing.changeSummary.single.planChanged.higher',
	},
	[PLAN_LOWER]: {
		id: 'ui.billing.changeSummary.single.planChanged.lower',
	},
});

function comparePages(oldPages, newPages) {
	if (oldPages < newPages) {
		return PAGES_MORE;
	} else if (oldPages > newPages) {
		return PAGES_LESS;
	}

	return PAGES_SAME;
}

function comparePrices(oldPrice, newPrice) {
	if (oldPrice < newPrice) {
		return PRICE_MORE_EXPENSIVE;
	} else if (oldPrice > newPrice) {
		return PRICE_CHEAPER;
	}

	return PRICE_SAME;
}

function compareWebsites(oldWebsites, newWebsites) {
	if (oldWebsites < newWebsites) {
		return WEBSITES_MORE;
	} else if (oldWebsites > newWebsites) {
		return WEBSITES_LESS;
	}

	return WEBSITES_SAME;
}



type Props = {
	accountId: CK.AccountId | null,
	additionalWebsitesPageCapacity?: number,
	allowedBillingCycles: Readonly<Array<GraphQL.Term>> | Immutable.List<string>,
	billingCycleNew: GraphQL.Term,
	billingCycleOld: GraphQL.Term,
	currency: GraphQL.Currency | string,
	hidePrice: boolean,
	nextChargeAt?: number,
	pagesDiff: (
		| typeof PAGES_DIFF_BUNDLE
		| typeof PAGES_DIFF_CAPACITY
		| typeof PAGES_DIFF_NONE
	),
	pagesNew: number,
	pagesOld: number,
	planNew?: (
		| typeof GraphQL.AccountPlan.Basic
		| typeof GraphQL.AccountPlan.C2Enterprise
		| typeof GraphQL.AccountPlan.C2Professional
		| typeof GraphQL.AccountPlan.C2Starter
		| typeof GraphQL.AccountPlan.Enterprise
		| typeof GraphQL.AccountPlan.EnterpriseLite
		| typeof GraphQL.AccountPlan.Standard
		| typeof GraphQL.AccountPlan.Pro
		| typeof GraphQL.AccountPlan.Prov4
	),
	planOld?: (
		| typeof GraphQL.AccountPlan.Basic
		| typeof GraphQL.AccountPlan.C2Enterprise
		| typeof GraphQL.AccountPlan.C2Professional
		| typeof GraphQL.AccountPlan.C2Starter
		| typeof GraphQL.AccountPlan.Enterprise
		| typeof GraphQL.AccountPlan.EnterpriseLite
		| typeof GraphQL.AccountPlan.Standard
		| typeof GraphQL.AccountPlan.Pro
		| typeof GraphQL.AccountPlan.Prov4
	),
	priceNew: number,
	priceOld: number,
	taxType: GraphQL.TaxType | null,
	websitesNew?: number,
	websitesOld?: number,
};

const ChangeSummary: React.FC<Props> = (props) => {
	const {
		accountId,
		additionalWebsitesPageCapacity,
		allowedBillingCycles,
		billingCycleNew,
		billingCycleOld,
		currency,
		hidePrice,
		pagesDiff,
		pagesNew,
		pagesOld,
		planNew,
		planOld,
		priceNew,
		priceOld,
		taxType,
		websitesNew,
		websitesOld,
	} = props;

	const canSpendMoney = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.SpendMoney,
	);

	function listItems(): Array<React.ReactNode> | null {
		const billingCycleComparison = compareBillingCycles(billingCycleOld, billingCycleNew);
		const pagesComparison = comparePages(pagesOld, pagesNew);
		const planComparison = comparePlans(planOld, planNew);
		const priceComparison = comparePrices(priceOld, priceNew);
		const websitesComparison = compareWebsites(websitesOld, websitesNew);

		const billingCycleUseWordNotation = !!allowedBillingCycles.find((billingCycle) => getBillingCycleDurationInMonths(billingCycle) > 12);

		const pricePerBillingCycle = (
			<FormattedMessage
				{...messages.pricePerBillingCycle}
				values={{
					billingCycle: billingCycleNew,
					price: (
						<FormattedNumber
							currency={currency}
							style="currency"
							value={priceNew}
						/>
					),
					tax: taxType ?? 'none',
					months: getBillingCycleDurationInMonths(billingCycleNew),
				}}
			/>
		);

		const additionalCostPerBillingCycle = (
			<FormattedMessage
				{...messages.pricePerBillingCycle}
				values={{
					billingCycle: billingCycleNew,
					price: (
						<FormattedNumber
							currency={currency}
							style="currency"
							value={priceNew - priceOld}
						/>
					),
					tax: taxType ?? 'none',
					months: getBillingCycleDurationInMonths(billingCycleNew),
				}}
			/>
		);

		if (
			pagesComparison !== PAGES_SAME
			&& billingCycleComparison === BillingCycleComparison.Same
			&& planComparison === PLAN_SAME
			&& websitesComparison === WEBSITES_SAME
		) {
			if (pagesDiff === PAGES_DIFF_NONE) {
				return null;
			}

			return [
				(
					<Copy
						{...messagesSingleChange[pagesComparison]}
						key="pagesChanged"
						values={{
							pages: pagesNew,
							pagesDiff,
							pricePerBillingCycle,
							additionalCostPerBillingCycle,
						}}
					/>
				),
			];
		}

		if (
			billingCycleComparison !== BillingCycleComparison.Same
			&& pagesComparison === PAGES_SAME
			&& planComparison === PLAN_SAME
			&& websitesComparison === WEBSITES_SAME
		) {
			return [
				(
					<Copy
						{...messagesSingleChange[billingCycleComparison]}
						key="billingCycleChanged"
						values={{
							billingCycleName: (
								<BillingCycleName
									billingCycle={billingCycleNew}
									useMonthNotation={billingCycleUseWordNotation}
								/>
							),
							pricePerBillingCycle,
							additionalCostPerBillingCycle,
						}}
					/>
				),
			];
		}

		if (
			(planComparison !== PLAN_SAME)
			&& pagesComparison === PAGES_SAME
			&& billingCycleComparison === BillingCycleComparison.Same
			&& websitesComparison === WEBSITES_SAME
			&& planNew !== undefined
		) {
			return [
				(
					<Copy
						{...hidePrice ? messages.planChanged : messagesSingleChange[planComparison]}
						key="planChanged"
						values={{
							planName: (
								<PlanName plan={planNew} />
							),
							pricePerBillingCycle,
							additionalCostPerBillingCycle,
						}}
					/>
				),
			];
		}

		const items: Array<React.ReactNode> = [];

		if (websitesComparison === WEBSITES_MORE) {
			const items: Array<React.ReactElement> = [];
			items.push(
				<Copy
					{...messages[WEBSITES_MORE]}
					key="websitesChanged"
					values={{
						websites: websitesNew! - websitesOld!,
						additionalWebsitesPageCapacity,
					}}
				/>,
			);

			if (pagesComparison !== PAGES_SAME) {
				items.push(
					<Copy
						{...messagesSingleChange[pagesComparison]}
						key="pagesChanged"
						values={{
							pages: pagesNew,
							pagesDiff,
							pricePerBillingCycle,
							additionalCostPerBillingCycle,
						}}
					/>,
				);
			}

			return items;
		}

		if (pagesDiff !== PAGES_DIFF_NONE && pagesComparison !== PAGES_SAME) {
			items.push(
				<Copy
					{...messages.pagesChanged}
					values={{
						pages: pagesNew,
						pagesDiff,
					}}
				/>,
			);
		}

		if (
			billingCycleComparison !== BillingCycleComparison.Same
		) {
			items.push(
				<Copy
					{...messages[billingCycleComparison]}
					values={{
						billingCycleName: (
							<BillingCycleName
								billingCycle={billingCycleNew}
								useMonthNotation={billingCycleUseWordNotation}
							/>
						),
					}}
				/>,
			);
		}

		if (planComparison !== PLAN_SAME && planNew !== undefined) {
			items.push(
				<Copy
					{...messages.planChanged}
					values={{
						planName: (
							<PlanName plan={planNew} />
						),
					}}
				/>,
			);
		}

		if (!hidePrice) {
			let priceChange;
			let prorate;

			if (billingCycleComparison === BillingCycleComparison.Shorter) {
				priceChange = (
					<Copy
						{...messages.newPrice}
						values={{
							pricePerBillingCycle,
						}}
					/>
				);
			} else if (billingCycleComparison === BillingCycleComparison.Longer) {
				priceChange = (
					<Copy
						{...messages.newPrice}
						values={{
							pricePerBillingCycle,
						}}
					/>
				);

				prorate = (
					<Copy {...messages.proratedCredit} />
				);
			} else if (priceComparison === PRICE_CHEAPER) {
				prorate = (
					<Copy {...messages.proratedCredit} />
				);
			} else if (priceComparison === PRICE_MORE_EXPENSIVE) {
				priceChange = (
					<Copy
						{...messages.additionalCost}
						values={{
							additionalCostPerBillingCycle,
						}}
					/>
				);

				prorate = (
					<Copy {...messages.proratedCharge} />
				);
			}

			if (priceChange && prorate) {
				items.push(<>{priceChange} {prorate}</>);
			} else if (priceChange && !prorate) {
				items.push(priceChange);
			} else if (prorate) {
				items.push(prorate);
			}
		}

		return items;
	}

	if (!hidePrice && priceNew > priceOld && canSpendMoney.yes === false) {
		return (
			<CalloutMessage
				borders={true}
				highlightedBorders={true}
				message={(
					<FormattedMessage {...messages.caution} />
				)}
				status={CalloutMessageStatus.Critical}
			>
				{canSpendMoney.noBecauseInsufficientPermissions ? (
					<InsufficientPermissionMessage />
				) : canSpendMoney.noBecauseInsufficientPermissionsForKingdomAdmin ? (
					<Copy {...messages.cannotUpgradeSubscriptionBecauseInsufficientPermissionsForKingdomAdmin} />
				) : canSpendMoney.noBecauseManaged ? (
					<Copy {...messages.cannotUpgradeSubscriptionBecauseManaged} />
				) : null}
			</CalloutMessage>
		);
	}

	const items = listItems();
	let list: React.ReactNode;

	if (items === null || items.length === 0) {
		return null;
	}

	if (items.length === 1) {
		list = items[0];
	} else {
		list = (
			<ul>
				{items.map((item, index) => (
					<li key={index}>
						{item}
					</li>
				))}
			</ul>
		);
	}

	return (
		<CalloutMessage
			borders={true}
			highlightedBorders={true}
			message={(
				<FormattedMessage {...messages.title} />
			)}
			status={CalloutMessageStatus.Summary}
		>
			{list}
		</CalloutMessage>
	);
};



export default ChangeSummary;
