/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdministerAccountSubscriptionOptionsSettingsMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  allowedBillingCycles: ReadonlyArray<GraphQL.Term> | GraphQL.Term;
  allowedPaymentMethods: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  allowedPlans: ReadonlyArray<GraphQL.AccountPlan> | GraphQL.AccountPlan;
  customDiscount: GraphQL.Scalars['String']['input'];
  defaultCountry: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
  manualPaymentNetTerms: GraphQL.InputMaybe<GraphQL.Scalars['Int']['input']>;
  outOfBandPlans: ReadonlyArray<GraphQL.AccountPlan> | GraphQL.AccountPlan;
  tariff: GraphQL.Scalars['String']['input'];
}>;


export type AdministerAccountSubscriptionOptionsSettingsMutation = { readonly __typename?: 'Mutation', readonly AdministerAccountSubscriptionOptionsSettings: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly allowedBillingCycles: ReadonlyArray<GraphQL.Term>, readonly allowedCurrencies: ReadonlyArray<GraphQL.Currency>, readonly allowedPaymentMethods: ReadonlyArray<string>, readonly allowedPlans: ReadonlyArray<GraphQL.AccountPlan>, readonly billingCycle: GraphQL.Term, readonly billingEntity: GraphQL.SubscriptionsSellerCode, readonly currency: GraphQL.Currency | null, readonly defaultCountry: string, readonly id: CK.AccountId, readonly isSubscriptionRenewalEnabled: boolean, readonly manualPaymentNetTerms: number | null, readonly outOfBandPlans: ReadonlyArray<GraphQL.AccountPlan>, readonly plan: GraphQL.AccountPlan | null, readonly possibleBillingCycles: ReadonlyArray<GraphQL.Term>, readonly tariff: string, readonly features: ReadonlyArray<{ readonly __typename?: 'AccountFeatureState', readonly amount: number, readonly feature: GraphQL.AccountFeature, readonly id: CK.ID, readonly inUse: boolean }>, readonly websiteRestrictions: { readonly __typename?: 'AccountWebsiteRestrictions', readonly domainsWhitelist: ReadonlyArray<string> | null, readonly maximumPageBundle: number | null, readonly maximumPageCapacity: number, readonly maximumUnverifiedWebsites: number | null, readonly maximumWebsites: number | null } } | null } } };

export type SubscriptionOptionsSettingsFormQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type SubscriptionOptionsSettingsFormQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly manualPaymentNetTerms: number | null, readonly possibleBillingCycles: ReadonlyArray<GraphQL.Term> } | null };


export const AdministerAccountSubscriptionOptionsSettingsDocument = gql`
    mutation AdministerAccountSubscriptionOptionsSettings($accountId: AccountId!, $allowedBillingCycles: [Term!]!, $allowedPaymentMethods: [String!]!, $allowedPlans: [AccountPlan!]!, $customDiscount: String!, $defaultCountry: String, $manualPaymentNetTerms: Int, $outOfBandPlans: [AccountPlan!]!, $tariff: String!) {
  AdministerAccountSubscriptionOptionsSettings(
    accountId: $accountId
    allowedBillingCycles: $allowedBillingCycles
    allowedPaymentMethods: $allowedPaymentMethods
    allowedPlans: $allowedPlans
    customDiscount: $customDiscount
    defaultCountry: $defaultCountry
    manualPaymentNetTerms: $manualPaymentNetTerms
    outOfBandPlans: $outOfBandPlans
    tariff: $tariff
  ) {
    query {
      account(id: $accountId) {
        allowedBillingCycles
        allowedCurrencies
        allowedPaymentMethods
        allowedPlans
        billingCycle
        billingEntity
        currency
        defaultCountry
        features {
          amount
          feature
          id
          inUse
        }
        id
        isSubscriptionRenewalEnabled
        manualPaymentNetTerms
        outOfBandPlans
        plan
        possibleBillingCycles
        tariff
        websiteRestrictions {
          domainsWhitelist
          maximumPageBundle
          maximumPageCapacity
          maximumUnverifiedWebsites
          maximumWebsites
        }
      }
    }
  }
}
    `;
export type AdministerAccountSubscriptionOptionsSettingsMutationFn = Apollo.MutationFunction<AdministerAccountSubscriptionOptionsSettingsMutation, AdministerAccountSubscriptionOptionsSettingsMutationVariables>;

/**
 * __useAdministerAccountSubscriptionOptionsSettingsMutation__
 *
 * To run a mutation, you first call `useAdministerAccountSubscriptionOptionsSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdministerAccountSubscriptionOptionsSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [administerAccountSubscriptionOptionsSettingsMutation, { data, loading, error }] = useAdministerAccountSubscriptionOptionsSettingsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      allowedBillingCycles: // value for 'allowedBillingCycles'
 *      allowedPaymentMethods: // value for 'allowedPaymentMethods'
 *      allowedPlans: // value for 'allowedPlans'
 *      customDiscount: // value for 'customDiscount'
 *      defaultCountry: // value for 'defaultCountry'
 *      manualPaymentNetTerms: // value for 'manualPaymentNetTerms'
 *      outOfBandPlans: // value for 'outOfBandPlans'
 *      tariff: // value for 'tariff'
 *   },
 * });
 */
export function useAdministerAccountSubscriptionOptionsSettingsMutation(baseOptions?: Apollo.MutationHookOptions<AdministerAccountSubscriptionOptionsSettingsMutation, AdministerAccountSubscriptionOptionsSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdministerAccountSubscriptionOptionsSettingsMutation, AdministerAccountSubscriptionOptionsSettingsMutationVariables>(AdministerAccountSubscriptionOptionsSettingsDocument, options);
      }
export type AdministerAccountSubscriptionOptionsSettingsMutationHookResult = ReturnType<typeof useAdministerAccountSubscriptionOptionsSettingsMutation>;
export type AdministerAccountSubscriptionOptionsSettingsMutationResult = Apollo.MutationResult<AdministerAccountSubscriptionOptionsSettingsMutation>;
export type AdministerAccountSubscriptionOptionsSettingsMutationOptions = Apollo.BaseMutationOptions<AdministerAccountSubscriptionOptionsSettingsMutation, AdministerAccountSubscriptionOptionsSettingsMutationVariables>;
export const SubscriptionOptionsSettingsFormDocument = gql`
    query SubscriptionOptionsSettingsForm($accountId: AccountId!) {
  account(id: $accountId) {
    id
    manualPaymentNetTerms
    possibleBillingCycles
  }
}
    `;

/**
 * __useSubscriptionOptionsSettingsFormQuery__
 *
 * To run a query within a React component, call `useSubscriptionOptionsSettingsFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionOptionsSettingsFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionOptionsSettingsFormQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useSubscriptionOptionsSettingsFormQuery(baseOptions: Apollo.QueryHookOptions<SubscriptionOptionsSettingsFormQuery, SubscriptionOptionsSettingsFormQueryVariables> & ({ variables: SubscriptionOptionsSettingsFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionOptionsSettingsFormQuery, SubscriptionOptionsSettingsFormQueryVariables>(SubscriptionOptionsSettingsFormDocument, options);
      }
export function useSubscriptionOptionsSettingsFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionOptionsSettingsFormQuery, SubscriptionOptionsSettingsFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionOptionsSettingsFormQuery, SubscriptionOptionsSettingsFormQueryVariables>(SubscriptionOptionsSettingsFormDocument, options);
        }
export function useSubscriptionOptionsSettingsFormSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SubscriptionOptionsSettingsFormQuery, SubscriptionOptionsSettingsFormQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubscriptionOptionsSettingsFormQuery, SubscriptionOptionsSettingsFormQueryVariables>(SubscriptionOptionsSettingsFormDocument, options);
        }
export type SubscriptionOptionsSettingsFormQueryHookResult = ReturnType<typeof useSubscriptionOptionsSettingsFormQuery>;
export type SubscriptionOptionsSettingsFormLazyQueryHookResult = ReturnType<typeof useSubscriptionOptionsSettingsFormLazyQuery>;
export type SubscriptionOptionsSettingsFormSuspenseQueryHookResult = ReturnType<typeof useSubscriptionOptionsSettingsFormSuspenseQuery>;
export type SubscriptionOptionsSettingsFormQueryResult = Apollo.QueryResult<SubscriptionOptionsSettingsFormQuery, SubscriptionOptionsSettingsFormQueryVariables>;