import React from 'react';

import type GraphQL from '~/types/graphql';

import {
	useBillingEntitiesQuery,
} from './useBillingEntities.gql';

import {
	type Tariff,
} from '~/model/pricing/tariffs';



type BillingEntities = {
	getByCode: (code: string) => Readonly<{
		id: GraphQL.BillingEntity['id'],
		allowedCurrencies: GraphQL.BillingEntity['allowedCurrencies'],
	}>,
	getByTariffAndCountry: (tariff: Tariff, country: string) => string,
};



function useBillingEntities(): BillingEntities | null {
	const { data } = useBillingEntitiesQuery();

	const billingEntities = data?.billingEntities ?? null;

	return React.useMemo(
		() => {
			if (billingEntities === null) {
				return null;
			}

			return {
				getByCode: (code) => {
					const billingEntity = billingEntities.find(
						(billingEntity) => billingEntity.id === code,
					);

					if (billingEntity === undefined) {
						throw new Error(
							`Billing entity with code '${code}' doesn't exist`,
						);
					}

					return billingEntity;
				},
				getByTariffAndCountry: (tariff, country) => {
					const billingEntity = billingEntities.find(
						(billingEntity) => billingEntity.mappingToCountries.some(
							(tariffMapping) => tariffMapping.tariff === tariff && tariffMapping.countries.includes(country),
						),
					);

					if (billingEntity !== undefined) {
						return billingEntity.id;
					}

					const fallbackBillingEntity = billingEntities.find(
						(billingEntity) => billingEntity.mappingToCountries.some(
							(tariffMapping) => tariffMapping.tariff === tariff && (tariffMapping.countries.includes('*') || tariffMapping.countries.includes('all')),
						),
					);

					if (fallbackBillingEntity !== undefined) {
						return fallbackBillingEntity.id;
					}

					throw new Error(
						`Fallback billing entity (needed for country '${country}' under tariff '${tariff}') doesn't exist`,
					);
				},
			};
		},
		[
			billingEntities,
		],
	);
}



export default useBillingEntities;
