import Immutable from 'immutable';
import React from 'react';

import GraphQL from '~/types/graphql';

import AdminAddTrialAbuserEmailsModal from '~/components/app/AdminAddTrialAbuserEmailsModal';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import ButtonsGroup from '~/components/patterns/buttons/ButtonsGroup';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import NewDatatable, {
	type NewDatatableColumnDefinitions,
} from '~/components/app/NewDatatable';
import PlainTextFieldFilter from '~/components/logic/datatables/PlainTextFieldFilter';
import WithPermission from '~/components/logic/access/WithPermission';

import {
	useKingdomSettingsTrialAbuserEmailsQuery,
} from './KingdomSettingsTrialAbuserEmailsSection.gql';

import useOpenModal from '~/hooks/useOpenModal';

import {
	ObjectType,
} from '~/model/permissions';



const KingdomSettingsTrialAbuserEmailsSection: React.FC = () => {
	const [filter, setFilter] = React.useState(Immutable.Map({
		trialAbuserEmail: '',
	}));

	const { data } = useKingdomSettingsTrialAbuserEmailsQuery();

	const trialAbuserEmails = data?.kingdomSettings?.trialAbuserEmails ?? null;

	const handleOpenAddEmailsModal = useOpenModal(
		() => (
			<AdminAddTrialAbuserEmailsModal />
		),
		[],
	);

	const updateFilter = React.useCallback(
		(filterUpdate) => {
			setFilter((filter) => filter.merge(filterUpdate));
		},
		[],
	);

	const rows = React.useMemo(
		() => {
			return (trialAbuserEmails ?? []).filter(
				(row) => {
					return row.includes(filter.get('trialAbuserEmail'));
				},
			);
		},
		[
			filter,
			trialAbuserEmails,
		],
	);

	const columns: NewDatatableColumnDefinitions<typeof rows> = React.useMemo(
		() => {
			return [
				{
					name: 'trialAbuserEmail',
					render: {
						cell: ({ row }) => (
							<Ellipsis>
								{row}
							</Ellipsis>
						),
						filter: ({ filterName, filterWidth, ref }) => (
							<PlainTextFieldFilter
								name={filterName}
								ref={ref}
								width={filterWidth}
							/>
						),
						label: () => 'Email',
					},
					width: 'fill',
				},
			];
		},
		[],
	);

	return (
		<NewDatatable
			buttons={(
				<ButtonsGroup>
					<WithPermission
						action={GraphQL.ActionWithPlatform.ManageInternals}
						objectId="platform"
						objectType={ObjectType.Platform}
						showMessage={false}
					>
						{({ isAllowed, message: permissionMessage }) => (
							<Button
								disabled={isAllowed.yes === false}
								icon={(
									<BasicIcon type={BasicIconType.Plus} />
								)}
								onClick={handleOpenAddEmailsModal}
								size={ButtonSize.Small}
								style={ButtonStyle.Hollow}
								tooltip={permissionMessage}
								uppercase={true}
							>
								Add trial abuser emails
							</Button>
						)}
					</WithPermission>
				</ButtonsGroup>
			)}
			columns={columns}
			filter={filter}
			isLoading={trialAbuserEmails === null}
			onFilterChange={updateFilter}
			rows={rows}
			title="Trial abuser emails"
		/>
	);
};



export default KingdomSettingsTrialAbuserEmailsSection;
