import React from 'react';

import {
	useDispatch,
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';

import useAccountCurrency from '~/hooks/useAccountCurrency';
import useAccountId from '~/hooks/useAccountId';
import useAccountMaximumSignupPageBundle from '~/hooks/useAccountMaximumSignupPageBundle';
import useAccountTariff from '~/hooks/useAccountTariff';

import {
	loadTariffs,
} from '~/actions/tariffs';

import {
	type TariffDefinition,
} from '~/model/pricing/costs';

import {
	TailoredTariff,
} from '~/model/pricing/tailoredTariff';

import {
	type Tariff,
} from '~/model/pricing/tariffs';

import {
	tariffsSelector,
} from '~/state/tariffs/selectors';



export type LoadingTariffs = {
	isReady: false,
	tariff: null,
	tariffs: null,
};

export type LoadedTariffs = {
	isReady: true,
	tariff: TailoredTariff,
	tariffs: Record<Tariff, TariffDefinition>,
};



function useTariffs(specifiedAccountId: CK.AccountId | null = null): LoadingTariffs | LoadedTariffs {
	const ambientAccountId = useAccountId();
	const accountId = specifiedAccountId ?? ambientAccountId;

	const accountCurrency = useAccountCurrency(accountId);
	const accountMaximumSignupPageBundle = useAccountMaximumSignupPageBundle(accountId);
	const accountTariff = useAccountTariff(accountId);
	const dispatch = useDispatch();
	const tariffs = useSelector(tariffsSelector) as Record<Tariff, TariffDefinition> | null;

	React.useEffect(
		() => {
			dispatch(loadTariffs());
		},
		[
			dispatch,
		],
	);

	return React.useMemo(
		() => {
			if (
				accountCurrency === null
				|| accountMaximumSignupPageBundle === null
				|| accountTariff === null
				|| tariffs === null
			) {
				return {
					isReady: false,
					tariff: null,
					tariffs: null,
				};
			}

			return {
				isReady: true,
				tariff: new TailoredTariff(
					accountCurrency,
					accountMaximumSignupPageBundle,
					accountTariff,
					tariffs,
				),
				tariffs,
			};
		},
		[
			accountCurrency,
			accountMaximumSignupPageBundle,
			accountTariff,
			tariffs,
		],
	);
}



export default useTariffs;
