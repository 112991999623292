import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import {
	linkInternal,
} from '~/components/logic/Copy';
import DisabledContent from '~/components/patterns/content/DisabledContent';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import InternalLink from '~/components/patterns/links/InternalLink';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';
import StaticText from '~/components/atoms/forms/components/StaticText';
import WithPermission from '~/components/logic/access/WithPermission';

import useMessagingAppChannels from '~/hooks/useMessagingAppChannels';

import {
	ObjectType,
} from '~/model/permissions';



const messages = defineMessages({
	connect: {
		id: 'ui.slack.connect',
	},
	enabledAndChange: {
		id: 'ui.websites.form.api.slack.enabled',
	},
	title: {
		id: 'ui.websites.form.messagingAppsTitle',
	},
});

const labelMessages = defineMessages({
	[GraphQL.MessagingAppType.MicrosoftTeams]: {
		id: 'ui.websites.form.api.microsoftTeams.title',
	},
	[GraphQL.MessagingAppType.Slack]: {
		id: 'ui.websites.form.api.slack.title',
	},
});

const pluginStatusMessages = defineMessages({
	available: {
		id: 'ui.websites.form.api.status.available',
	},
	enabled: {
		id: 'ui.websites.form.api.status.enabled',
	},
});



type Props = {
	websiteId: CK.WebsiteId,
};

const WebsiteIntegrationsMessagingAppsBlock: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const messagingAppChannels = useMessagingAppChannels();

	const messagingApps = [
		{
			accountFeature: GraphQL.AccountFeature.Slack,
			messagingAppType: GraphQL.MessagingAppType.Slack,
		},
		{
			accountFeature: GraphQL.AccountFeature.MicrosoftTeams,
			messagingAppType: GraphQL.MessagingAppType.MicrosoftTeams,
		},
	];

	return (
		<WithPermission
			action={GraphQL.ActionWithWebsite.ManageSettings}
			objectId={websiteId}
			objectType={ObjectType.Website}
			showMessage={false}
		>
			{({ isAllowed }) => (
				<EditableFormWrapper
					isAllowed={isAllowed}
					key={websiteId}
					title={(
						<FormattedMessage {...messages.title} />
					)}
				>
					<DisplayPart>
						<FormRows>
							{messagingApps.map(({ messagingAppType }) => {
								const isConnected = messagingAppChannels.listByType(messagingAppType).length > 0;

								return (
									<FormRow
										key={messagingAppType}
										label={(
											<FormattedMessage {...labelMessages[messagingAppType]} />
										)}
									>
										<StaticText>
											{isConnected ? (
												<FormattedMessage {...pluginStatusMessages.enabled} />
											) : (
												<FormattedMessage {...pluginStatusMessages.available} />
											)}
										</StaticText>
									</FormRow>
								);
							})}
						</FormRows>
					</DisplayPart>

					<EditablePart>
						<Form>
							<FormRows>
								{messagingApps.map(({ accountFeature, messagingAppType }) => {
									const isConnected = messagingAppChannels.listByType(messagingAppType).length > 0;

									return (
										<PremiumFeatureSituation
											featureName={accountFeature}
											hideIfUnattainable={true}
											key={messagingAppType}
											style={PremiumFeatureSituationStyle.Ribbon}
										>
											{({ isFeatureEnabled, premiumAnnotation }) => (
												<DisabledContent
													disabledContent={!isFeatureEnabled}
													disabledOverlay={!isFeatureEnabled && premiumAnnotation}
												>
													<FormRow
														label={(
															<FormattedMessage {...labelMessages[messagingAppType]} />
														)}
													>
														<StaticText>
															{!isFeatureEnabled ? (
																<FormattedMessage {...messages.connect} />
															) : isConnected ? (
																<FormattedMessage
																	{...messages.enabledAndChange}
																	values={{
																		link_alert_definitions: linkInternal(
																			'website.alerts.configuration',
																			{
																				websiteId,
																			},
																		),
																	}}
																/>
															) : (
																<InternalLink
																	routeName="website.alerts.configuration"
																	routeParams={{
																		websiteId,
																	}}
																>
																	<FormattedMessage {...messages.connect} />
																</InternalLink>
															)}
														</StaticText>
													</FormRow>
												</DisabledContent>
											)}
										</PremiumFeatureSituation>
									);
								})}
							</FormRows>

							<ButtonsLayout>
								<CancelButton />
							</ButtonsLayout>
						</Form>
					</EditablePart>
				</EditableFormWrapper>
			)}
		</WithPermission>
	);
};



export default WebsiteIntegrationsMessagingAppsBlock;
