/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConnectAdobeAnalyticsAccountMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  adobeAnalyticsClientId: GraphQL.Scalars['String']['input'];
  adobeAnalyticsClientSecret: GraphQL.Scalars['String']['input'];
  adobeAnalyticsOrganizationId: GraphQL.Scalars['String']['input'];
  ownerAccountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type ConnectAdobeAnalyticsAccountMutation = { readonly __typename?: 'Mutation', readonly ConnectAdobeAnalyticsAccountUsingClientSecret: { readonly __typename?: 'ConnectAdobeAnalyticsAccountResult', readonly adobeAnalyticsAccount: { readonly __typename?: 'AdobeAnalyticsAccount', readonly id: number }, readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly connectedAdobeAnalyticsAccounts: ReadonlyArray<{ readonly __typename?: 'AdobeAnalyticsAccount', readonly id: number, readonly name: string, readonly reportSuites: ReadonlyArray<{ readonly __typename?: 'AdobeAnalyticsReportSuite', readonly name: string, readonly dimensions: ReadonlyArray<{ readonly __typename?: 'AdobeAnalyticsDimension', readonly id: string, readonly name: string, readonly title: string }> }> }> | null } | null } } };


export const ConnectAdobeAnalyticsAccountDocument = gql`
    mutation ConnectAdobeAnalyticsAccount($accountId: AccountId!, $adobeAnalyticsClientId: String!, $adobeAnalyticsClientSecret: String!, $adobeAnalyticsOrganizationId: String!, $ownerAccountId: AccountId!) {
  ConnectAdobeAnalyticsAccountUsingClientSecret(
    accountId: $accountId
    adobeAnalyticsClientId: $adobeAnalyticsClientId
    adobeAnalyticsClientSecret: $adobeAnalyticsClientSecret
    adobeAnalyticsOrganizationId: $adobeAnalyticsOrganizationId
    ownerAccountId: $ownerAccountId
  ) {
    adobeAnalyticsAccount {
      id
    }
    query {
      account(id: $accountId) {
        id
        connectedAdobeAnalyticsAccounts {
          id
          name
          reportSuites {
            name
            dimensions {
              id
              name
              title
            }
          }
        }
      }
    }
  }
}
    `;
export type ConnectAdobeAnalyticsAccountMutationFn = Apollo.MutationFunction<ConnectAdobeAnalyticsAccountMutation, ConnectAdobeAnalyticsAccountMutationVariables>;

/**
 * __useConnectAdobeAnalyticsAccountMutation__
 *
 * To run a mutation, you first call `useConnectAdobeAnalyticsAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectAdobeAnalyticsAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectAdobeAnalyticsAccountMutation, { data, loading, error }] = useConnectAdobeAnalyticsAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      adobeAnalyticsClientId: // value for 'adobeAnalyticsClientId'
 *      adobeAnalyticsClientSecret: // value for 'adobeAnalyticsClientSecret'
 *      adobeAnalyticsOrganizationId: // value for 'adobeAnalyticsOrganizationId'
 *      ownerAccountId: // value for 'ownerAccountId'
 *   },
 * });
 */
export function useConnectAdobeAnalyticsAccountMutation(baseOptions?: Apollo.MutationHookOptions<ConnectAdobeAnalyticsAccountMutation, ConnectAdobeAnalyticsAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectAdobeAnalyticsAccountMutation, ConnectAdobeAnalyticsAccountMutationVariables>(ConnectAdobeAnalyticsAccountDocument, options);
      }
export type ConnectAdobeAnalyticsAccountMutationHookResult = ReturnType<typeof useConnectAdobeAnalyticsAccountMutation>;
export type ConnectAdobeAnalyticsAccountMutationResult = Apollo.MutationResult<ConnectAdobeAnalyticsAccountMutation>;
export type ConnectAdobeAnalyticsAccountMutationOptions = Apollo.BaseMutationOptions<ConnectAdobeAnalyticsAccountMutation, ConnectAdobeAnalyticsAccountMutationVariables>;