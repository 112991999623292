import React from 'react';

import BasicIcon, {
	AdobeAnalyticsIconVariants,
	BasicIconType,
	ChromeWebStoreIconVariants,
	GoogleAnalyticsIconVariants,
	GoogleSearchConsoleIconVariants,
	LookerStudioIconVariants,
	MicrosoftTeamsIconVariants,
	SlackIconVariants,
	WordPressIconVariants,
} from '~/components/patterns/icons/BasicIcon';

import Hint from '~/components/patterns/hints/hint/Hint';



export enum APIIntegrationStatusService {
	AdobeAnalytics = 'AdobeAnalytics',
	GoogleAnalytics = 'GoogleAnalytics',
	GoogleLookerStudio = 'GoogleLookerStudio',
	GoogleSearchConsole = 'GoogleSearchConsole',
	ChromeWebStore = 'ChromeWebStore',
	MicrosoftTeams = 'MicrosoftTeams',
	Slack = 'Slack',
	WordPress = 'WordPress',
}

export enum APIIntegrationStatusSize {
	Default = 'default',
	Small = 'small',
}

export enum APIIntegrationStatusStatus {
	Available = 'available',
	Disabled = 'disabled',
	Enabled = 'enabled',
	Installed = 'installed',
	NotInstalled = 'notInstalled',
}

type Props = {
	service: APIIntegrationStatusService,
	/* Possibility to hide status label */
	compact?: boolean,
	/** Label describing current status */
	label: React.ReactNode,
	size?: APIIntegrationStatusSize,
	status: APIIntegrationStatusStatus,
	/* Optional tooltip to show on hover */
	tooltip?: React.ReactNode,
};



const basicIconsMapping = {
	[APIIntegrationStatusService.AdobeAnalytics]: {
		icon: BasicIconType.AdobeAnalytics,
		variants: AdobeAnalyticsIconVariants,
	},
	[APIIntegrationStatusService.GoogleAnalytics]: {
		icon: BasicIconType.GoogleAnalytics,
		variants: GoogleAnalyticsIconVariants,
	},
	[APIIntegrationStatusService.GoogleLookerStudio]: {
		icon: BasicIconType.LookerStudio,
		variants: LookerStudioIconVariants,
	},
	[APIIntegrationStatusService.GoogleSearchConsole]: {
		icon: BasicIconType.GoogleSearchConsole,
		variants: GoogleSearchConsoleIconVariants,
	},
	[APIIntegrationStatusService.ChromeWebStore]: {
		icon: BasicIconType.ChromeWebStore,
		variants: ChromeWebStoreIconVariants,
	},
	[APIIntegrationStatusService.MicrosoftTeams]: {
		icon: BasicIconType.MicrosoftTeams,
		variants: MicrosoftTeamsIconVariants,
	},
	[APIIntegrationStatusService.Slack]: {
		icon: BasicIconType.Slack,
		variants: SlackIconVariants,
	},
	[APIIntegrationStatusService.WordPress]: {
		icon: BasicIconType.WordPress,
		variants: WordPressIconVariants,
	},
};



const APIIntegrationStatus: React.FC<Props> = (props) => {
	const {
		service,
		compact = false,
		label,
		size,
		status,
		tooltip,
	} = props;

	const isInactive = status === APIIntegrationStatusStatus.Available
		|| status === APIIntegrationStatusStatus.Disabled
		|| status === APIIntegrationStatusStatus.NotInstalled;

	const additionalProps = {
		variant: basicIconsMapping[service].variants[isInactive ? 'Inactive' : 'Active'],
	};

	let content = (
		<div className="api-integration-status">
			{!compact && (
				<div className="api-integration-status__label">
					{label}
				</div>
			)}
			<div className="api-integration-status__icon">
				<BasicIcon
					size={size === APIIntegrationStatusSize.Small ? 20 : 24}
					type={basicIconsMapping[service].icon}
					{...additionalProps}
				/>
			</div>
		</div>
	);

	if (tooltip || compact) {
		content = (
			<Hint
				blurDelay={250}
				popup={tooltip || label}
				popupOffsetSkidding={5}
			>
				{content}
			</Hint>
		);
	}

	return content;
};



export default APIIntegrationStatus;
