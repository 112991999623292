import React from 'react';

import type CK from '~/types/contentking';

import FormsList from '~/components/atoms/lists/FormsList';
import MarketingEmailSettingsForm from '~/components/atoms/forms/forms/MarketingEmailSettingsForm';
import WebsitesReportsAlertsForm from '~/components/atoms/forms/forms/WebsitesReportsAlertsForm';

import useUserMemberships from '~/hooks/useUserMemberships';
import useUserUniqueId from '~/hooks/useUserUniqueId';



type Props = {
	legacyUserId: string,
} & ({
	accountId: CK.AccountId,
	isForCurrentUser: false,
} | {
	accountId?: never,
	isForCurrentUser: true,
});

const EmailSettingsGroup: React.FC<Props> = (props) => {
	const {
		accountId,
		isForCurrentUser,
		legacyUserId,
	} = props;

	const userId = useUserUniqueId(legacyUserId);

	let userMemberships = useUserMemberships(legacyUserId);

	if (isForCurrentUser === false && userMemberships !== null) {
		userMemberships = userMemberships.filter(
			(membership) => membership.account.id === accountId,
		);
	}

	return (
		<FormsList>
			{userId !== null && (
				<WebsitesReportsAlertsForm
					userAccountMemberships={userMemberships}
					userId={userId}
				/>
			)}

			<MarketingEmailSettingsForm
				isForCurrentUser={isForCurrentUser}
				legacyUserId={legacyUserId}
			/>
		</FormsList>
	);
};



export default EmailSettingsGroup;
