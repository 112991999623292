import memoizee from 'memoizee';

import type GraphQL from '~/types/graphql';

import {
	get,
} from '~/model/api/API';

import {
	calculateAllPointsInRange,
} from '~/model/rangeChoices';

import tariffC1 from './c1';
import tariffC2 from './c2';
import tariffV2 from './v2';
import tariffV3 from './v3';
import tariffV4 from './v4';



export enum Tariff {
	C1 = 'c1',
	C2 = 'c2',
	V2 = 'v2',
	V3 = 'v3',
	V4 = 'v4',
}



const TARIFFS = {
	[Tariff.C1]: tariffC1,
	[Tariff.C2]: tariffC2,
	[Tariff.V2]: tariffV2,
	[Tariff.V3]: tariffV3,
	[Tariff.V4]: tariffV4,
};



function getTariff(tariff: Tariff) {
	return TARIFFS[tariff];
}



export function loadTariffsViaApi() {
	return get('/pricing');
}



export function choosePlan({ billingCycle, details, tariff }: {
	billingCycle: GraphQL.Term,
	details: Record<string, any>,
	tariff: Tariff,
}) {
	return getTariff(tariff).choosePlan(billingCycle, details);
}



export function convertDetailsToAddons({ details, tariff }: {
	details: Record<string, number>,
	tariff: Tariff,
}) {
	return getTariff(tariff).convertDetailsToAddons(details);
}



export function convertPagesToAddons({ numberOfPages, tariff }: {
	numberOfPages: number,
	tariff: Tariff,
}) {
	return getTariff(tariff).convertPagesToAddons(numberOfPages);
}



export function normalizePageCapacity({ pageCapacity, tariff }: {
	pageCapacity: number,
	tariff: Tariff,
}) {
	return getTariff(tariff).normalizePageCapacity(pageCapacity);
}



export function normalizePagesAmount({ numberOfPages, tariff }: {
	numberOfPages: number,
	tariff: Tariff,
}) {
	return getTariff(tariff).normalizePagesAmount(numberOfPages);
}



export const listAllPossiblePagesAmounts = memoizee((tariff: Tariff, maximumPageCapacity: number | null = Infinity) => {
	return calculateAllPointsInRange(
		0,
		maximumPageCapacity,
		(numberOfPages) => getTariff(tariff).normalizePagesAmount(numberOfPages),
	);
}, {
	length: 2,
});



export const listAllPossiblePageCapacities = memoizee((tariff: Tariff, maximumPageCapacity: number | null = null) => {
	const allPossiblePagesAmounts = listAllPossiblePagesAmounts(tariff, maximumPageCapacity);

	return calculateAllPointsInRange(
		0,
		maximumPageCapacity || allPossiblePagesAmounts[allPossiblePagesAmounts.length - 1],
		(pageCapacity) => getTariff(tariff).normalizePageCapacity(pageCapacity),
	);
}, {
	length: 2,
});
