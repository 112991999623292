import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import BlankSlate from '~/components/patterns/messages/embedded/BlankSlate';
import {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import Copy from '~/components/logic/Copy';
import DatatableBodyCell, {
	DatatableBodyCellAlignment,
	DatatableBodyCellSize,
} from '~/components/patterns/tables/datatables/cells/DatatableBodyCell';
import DatatableContainer from '~/components/patterns/tables/datatables/DatatableContainer';
import DatatableLayout from '~/components/patterns/tables/datatables/DatatableLayout';
import DatatableOverlay from '~/components/patterns/tables/datatables/DatatableOverlay';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import LoadingDots from '~/components/patterns/loaders/LoadingDots';
import SimpleGrid from '~/components/patterns/tables/datatables/SimpleGrid';
import Small from '~/components/patterns/typography/Small';
import SubmitButton from '~/components/atoms/forms/components/SubmitButton';

import {
	useStageDomainsMutation,
} from './AddMultipleWebsitesStep.gql';

import useAccountCanSelfServiceSignupRemoveWebsiteLimit from '~/hooks/useAccountCanSelfServiceSignupRemoveWebsiteLimit';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



const messages = defineMessages({
	add: {
		id: 'ui.websites.new.steps.multiple.sidebar.suggestedDomains.add',
	},
	addAll: {
		id: 'ui.websites.new.steps.multiple.sidebar.suggestedDomains.addAll',
	},
	exhaustedSuggestions: {
		id: 'ui.websites.new.steps.multiple.sidebar.suggestedDomains.exhaustedSuggestions',
	},
	maxWebsitesLimitReached: {
		id: 'ui.websites.new.steps.multiple.sidebar.maxWebsitesLimitReached',
	},
	noSuggestions: {
		id: 'ui.websites.new.steps.multiple.sidebar.suggestedDomains.noSuggestions',
	},
});



type Props = {
	accountId: CK.AccountId | null,
	addDomainsToStagingArea: (domains: Array<string>) => void,
	isLoading: boolean,
	isMaxWebsitesLimitReached: boolean,
	maxWebsites?: number,
	onFreeTextInputClick: () => void,
	sessionId?: string,
	stagedDomains?: ReadonlyArray<string>,
	suggestedDomains?: ReadonlyArray<string>,
	width: number,
};

const SidebarSuggestedDomains: React.FC<Props> = (props) => {
	const {
		accountId,
		addDomainsToStagingArea,
		isLoading,
		isMaxWebsitesLimitReached,
		maxWebsites,
		onFreeTextInputClick,
		sessionId,
		stagedDomains,
		suggestedDomains,
		width,
	} = props;

	const availableSuggestions = suggestedDomains?.filter((domain) => !stagedDomains?.includes(domain)) || [];
	const canSelfServiceSignupRemoveWebsiteLimit = useAccountCanSelfServiceSignupRemoveWebsiteLimit(accountId);

	const noSuggestions = Array.isArray(suggestedDomains) && suggestedDomains.length === 0;
	const exhaustedSuggestions = availableSuggestions.length === 0;

	const rowCount = availableSuggestions.length;

	const [stageDomains] = useStageDomainsMutation();

	function selectDomains(domains: Array<string>) {
		if (accountId === null) {
			return;
		}

		addDomainsToStagingArea(domains);

		stageDomains({
			variables: {
				accountId,
				sessionId: sessionId as string,
				domains,
			},
		});
	}

	return (
		<DatatableLayout
			borders={false}
			footerButton={(
				<SubmitButton
					disabled={noSuggestions || exhaustedSuggestions || isMaxWebsitesLimitReached}
					icon={(
						<BasicIcon
							size={15}
							type={BasicIconType.Plus}
						/>
					)}
					onClickCallback={() => {
						if (suggestedDomains) {
							selectDomains(availableSuggestions);
						}
					}}
					size={ButtonSize.XXSmall}
					style={ButtonStyle.Hollow}
					tooltip={isMaxWebsitesLimitReached && availableSuggestions.length > 0 && (
						<FormattedMessage
							{...messages.maxWebsitesLimitReached}
							values={{
								canSelfServiceSignupRemoveWebsiteLimit: canSelfServiceSignupRemoveWebsiteLimit ? 'yes' : 'no',
								maxWebsites,
								link_signup: (chunks) => (
									<InternalLink routeName="account.pricing">
										{chunks}
									</InternalLink>
								),
							}}
						/>
					)}
					uppercase={true}
				>
					<FormattedMessage {...messages.addAll} />
				</SubmitButton>
			)}
		>
			<DatatableContainer
				overlay={(
					isLoading ? (
						<DatatableOverlay>
							<LoadingDots />
						</DatatableOverlay>
					) : noSuggestions ? (
						<DatatableOverlay>
							<BlankSlate>
								<Copy
									{...messages.noSuggestions}
									values={{
										linkTab: (chunks) => (
											<InternalLink onClickCallback={onFreeTextInputClick}>
												{chunks}
											</InternalLink>
										),
									}}
								/>
							</BlankSlate>
						</DatatableOverlay>
					) : exhaustedSuggestions ? (
						<DatatableOverlay>
							<BlankSlate>
								<FormattedMessage {...messages.exhaustedSuggestions} />
							</BlankSlate>
						</DatatableOverlay>
					) : null
				)}
			>
				<SimpleGrid
					cellRenderer={({ columnIndex, key, rowIndex, style }) => {
						if (columnIndex === 0) {
							return (
								<DatatableBodyCell
									alignment={DatatableBodyCellAlignment.Left}
									cssStyle={style}
									key={key}
									rowIndex={rowIndex}
									separator={false}
									size={DatatableBodyCellSize.Small}
								>
									{availableSuggestions[rowIndex]}
								</DatatableBodyCell>
							);
						}

						if (columnIndex === 1) {
							return (
								<DatatableBodyCell
									alignment={DatatableBodyCellAlignment.Right}
									cssStyle={style}
									key={key}
									rowIndex={rowIndex}
									separator={false}
									size={DatatableBodyCellSize.Small}
								>
									<InternalLink
										disabled={isMaxWebsitesLimitReached}
										onClickCallback={() => {
											selectDomains([
												getArrayItemAtSafeIndex(availableSuggestions, rowIndex),
											]);
										}}
										style={InternalLinkStyle.Decent}
									>
										<Small>
											<FormattedMessage {...messages.add} />
										</Small>
									</InternalLink>
								</DatatableBodyCell>
							);
						}

						return (
							<DatatableBodyCell
								cssStyle={style}
								key={key}
								rowIndex={rowIndex}
								separator={false}
								size={DatatableBodyCellSize.Small}
							/>
						);
					}}
					columnCount={2}
					columnWidth={({ index }) => {
						if (index == 0) {
							return width - 86;
						}

						return 70;
					}}
					height={210}
					overscanColumnCount={2}
					rowCount={rowCount}
					rowHeight={32}
					width={width}
				/>
			</DatatableContainer>
		</DatatableLayout>
	);
};



export default SidebarSuggestedDomains;
