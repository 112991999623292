import React from 'react';

import BorderedBox from '~/components/patterns/boxes/BorderedBox';
import DefinitionTerm from '~/components/patterns/structuredValues/definitionTerms/DefinitionTerm';
import DefinitionTermsList from '~/components/patterns/structuredValues/definitionTerms/DefinitionTermsList';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import FormSentence from '~/components/atoms/forms/basis/FormSentence';
import FormsList from '~/components/atoms/lists/FormsList';
import MarginBox from '~/components/atoms/boxes/MarginBox';
import SubmitButton from '~/components/app/SubmitButton';
import TextField from '~/components/atoms/forms/components/TextField';

import {
	useDiagnoseExternalDomainLazyQuery,
} from './KingdomDiagnosticsDiagnoseExternalDomainSection.gql';

import {
	validateField,
} from '~/components/app/validations';



const validations = {
	validateDomain: validateField(
		'domain',
		(f) => [
			f.validateNonEmpty(),
			f.validateDomain(),
		],
	),
};



const KingdomDiagnosticsDiagnoseExternalDomainSection: React.FC = () => {
	const [diagnoseExternalDomain, { data }] = useDiagnoseExternalDomainLazyQuery();

	const handleSubmit = React.useCallback(
		async (values) => {
			await diagnoseExternalDomain({
				variables: {
					domain: values.domain,
				},
			});
		},
		[
			diagnoseExternalDomain,
		],
	);

	const result = data?.diagnoseExternalDomain ?? null;

	return (
		<>
			<FormsList>
				<Form
					defaultFocus="domain"
					onSuccess={handleSubmit}
					validations={validations}
				>
					<FormRows>
						<FormRow
							fullwidth={true}
							htmlFor="domain"
							label="Domain"
						>
							<FieldStatus
								name="validateDomain"
								showIcon={false}
							>
								<FormSentence>
									<TextField
										name="domain"
										trimValue={true}
									/>

									<SubmitButton>
										Diagnose
									</SubmitButton>
								</FormSentence>
							</FieldStatus>
						</FormRow>
					</FormRows>
				</Form>
			</FormsList>

			{result?.externalDomainInfo.map((externalDomainInfo) => (
				<MarginBox key={externalDomainInfo.websiteId}>
					<BorderedBox
						headerLabel={externalDomainInfo.websiteId}
						paddingSize={3}
					>
						<DefinitionTermsList>
							<DefinitionTerm
								description={externalDomainInfo.createdAt}
								term="First crawled at"
							/>
							<DefinitionTerm
								description={externalDomainInfo.countAll + ' ' + (externalDomainInfo.countAll === 1 ? 'URL' : 'URLs')}
								term="Size"
							/>
							<DefinitionTerm
								description={externalDomainInfo.isCrawlingStopped ? 'yes' : 'no'}
								term="Is crawling stopped"
							/>
							<DefinitionTerm
								description={externalDomainInfo.averageFetchingRatePerCycle + ' per minute'}
								term="Fetching rate"
							/>
							<DefinitionTerm
								description={externalDomainInfo.fetchingUserAgent}
								term="User-agent"
							/>
						</DefinitionTermsList>
					</BorderedBox>
				</MarginBox>
			))}
		</>
	);
};



export default KingdomDiagnosticsDiagnoseExternalDomainSection;
