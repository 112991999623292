/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdobeAnalyticsIntegrationQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type AdobeAnalyticsIntegrationQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly connectedAdobeAnalyticsAccounts: ReadonlyArray<{ readonly __typename?: 'AdobeAnalyticsAccount', readonly id: number, readonly isJwt: boolean, readonly name: string, readonly status: GraphQL.AdobeAnalyticsAccountStatus, readonly reportSuites: ReadonlyArray<{ readonly __typename?: 'AdobeAnalyticsReportSuite', readonly rsid: string, readonly name: string, readonly dimensions: ReadonlyArray<{ readonly __typename?: 'AdobeAnalyticsDimension', readonly id: string, readonly name: string, readonly title: string }> }> }> | null } | null, readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly adobeAnalyticsIntegration: { readonly __typename?: 'WebsiteAdobeAnalyticsIntegration', readonly dataMatchingMethod: GraphQL.AdobeAnalyticsDataMatchingMethod, readonly dimensionId: string, readonly reportSuiteId: string, readonly adobeAnalyticsAccount: { readonly __typename?: 'AdobeAnalyticsAccount', readonly id: number, readonly status: GraphQL.AdobeAnalyticsAccountStatus } } | null } | null };

export type ConnectAdobeAnalyticsAccountToWebsiteMutationVariables = GraphQL.Exact<{
  adobeAnalyticsAccountId: GraphQL.Scalars['Int']['input'];
  dataMatchingMethod: GraphQL.AdobeAnalyticsDataMatchingMethod;
  dimensionId: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
  reportSuiteId: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type ConnectAdobeAnalyticsAccountToWebsiteMutation = { readonly __typename?: 'Mutation', readonly ConnectAdobeAnalyticsAccountToWebsite: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly adobeAnalyticsIntegration: { readonly __typename?: 'WebsiteAdobeAnalyticsIntegration', readonly dataMatchingMethod: GraphQL.AdobeAnalyticsDataMatchingMethod, readonly dimensionId: string, readonly reportSuiteId: string, readonly adobeAnalyticsAccount: { readonly __typename?: 'AdobeAnalyticsAccount', readonly id: number } } | null, readonly integrations: ReadonlyArray<{ readonly __typename?: 'WebsiteIntegration', readonly isNotOwned: boolean, readonly status: GraphQL.WebsiteIntegrationStatus, readonly type: GraphQL.WebsiteIntegrationType }> } | null } } };

export type DisableAdobeAnalyticsForWebsiteMutationVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type DisableAdobeAnalyticsForWebsiteMutation = { readonly __typename?: 'Mutation', readonly DisableAdobeAnalyticsForWebsite: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly adobeAnalyticsIntegration: { readonly __typename?: 'WebsiteAdobeAnalyticsIntegration', readonly dataMatchingMethod: GraphQL.AdobeAnalyticsDataMatchingMethod, readonly dimensionId: string, readonly reportSuiteId: string, readonly adobeAnalyticsAccount: { readonly __typename?: 'AdobeAnalyticsAccount', readonly id: number } } | null, readonly integrations: ReadonlyArray<{ readonly __typename?: 'WebsiteIntegration', readonly isNotOwned: boolean, readonly status: GraphQL.WebsiteIntegrationStatus, readonly type: GraphQL.WebsiteIntegrationType }> } | null } } };


export const AdobeAnalyticsIntegrationDocument = gql`
    query AdobeAnalyticsIntegration($accountId: AccountId!, $websiteId: WebsiteId!) {
  account(id: $accountId) {
    id
    connectedAdobeAnalyticsAccounts {
      id
      isJwt
      name
      reportSuites {
        rsid
        name
        dimensions {
          id
          name
          title
        }
      }
      status
    }
  }
  website(id: $websiteId) {
    id
    adobeAnalyticsIntegration {
      adobeAnalyticsAccount {
        id
        status
      }
      dataMatchingMethod
      dimensionId
      reportSuiteId
    }
  }
}
    `;

/**
 * __useAdobeAnalyticsIntegrationQuery__
 *
 * To run a query within a React component, call `useAdobeAnalyticsIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdobeAnalyticsIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdobeAnalyticsIntegrationQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useAdobeAnalyticsIntegrationQuery(baseOptions: Apollo.QueryHookOptions<AdobeAnalyticsIntegrationQuery, AdobeAnalyticsIntegrationQueryVariables> & ({ variables: AdobeAnalyticsIntegrationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdobeAnalyticsIntegrationQuery, AdobeAnalyticsIntegrationQueryVariables>(AdobeAnalyticsIntegrationDocument, options);
      }
export function useAdobeAnalyticsIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdobeAnalyticsIntegrationQuery, AdobeAnalyticsIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdobeAnalyticsIntegrationQuery, AdobeAnalyticsIntegrationQueryVariables>(AdobeAnalyticsIntegrationDocument, options);
        }
export function useAdobeAnalyticsIntegrationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AdobeAnalyticsIntegrationQuery, AdobeAnalyticsIntegrationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdobeAnalyticsIntegrationQuery, AdobeAnalyticsIntegrationQueryVariables>(AdobeAnalyticsIntegrationDocument, options);
        }
export type AdobeAnalyticsIntegrationQueryHookResult = ReturnType<typeof useAdobeAnalyticsIntegrationQuery>;
export type AdobeAnalyticsIntegrationLazyQueryHookResult = ReturnType<typeof useAdobeAnalyticsIntegrationLazyQuery>;
export type AdobeAnalyticsIntegrationSuspenseQueryHookResult = ReturnType<typeof useAdobeAnalyticsIntegrationSuspenseQuery>;
export type AdobeAnalyticsIntegrationQueryResult = Apollo.QueryResult<AdobeAnalyticsIntegrationQuery, AdobeAnalyticsIntegrationQueryVariables>;
export const ConnectAdobeAnalyticsAccountToWebsiteDocument = gql`
    mutation ConnectAdobeAnalyticsAccountToWebsite($adobeAnalyticsAccountId: Int!, $dataMatchingMethod: AdobeAnalyticsDataMatchingMethod!, $dimensionId: String, $reportSuiteId: String!, $websiteId: WebsiteId!) {
  ConnectAdobeAnalyticsAccountToWebsite(
    adobeAnalyticsAccountId: $adobeAnalyticsAccountId
    dataMatchingMethod: $dataMatchingMethod
    dimensionId: $dimensionId
    reportSuiteId: $reportSuiteId
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        id
        adobeAnalyticsIntegration {
          adobeAnalyticsAccount {
            id
          }
          dataMatchingMethod
          dimensionId
          reportSuiteId
        }
        integrations {
          isNotOwned
          status
          type
        }
      }
    }
  }
}
    `;
export type ConnectAdobeAnalyticsAccountToWebsiteMutationFn = Apollo.MutationFunction<ConnectAdobeAnalyticsAccountToWebsiteMutation, ConnectAdobeAnalyticsAccountToWebsiteMutationVariables>;

/**
 * __useConnectAdobeAnalyticsAccountToWebsiteMutation__
 *
 * To run a mutation, you first call `useConnectAdobeAnalyticsAccountToWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectAdobeAnalyticsAccountToWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectAdobeAnalyticsAccountToWebsiteMutation, { data, loading, error }] = useConnectAdobeAnalyticsAccountToWebsiteMutation({
 *   variables: {
 *      adobeAnalyticsAccountId: // value for 'adobeAnalyticsAccountId'
 *      dataMatchingMethod: // value for 'dataMatchingMethod'
 *      dimensionId: // value for 'dimensionId'
 *      reportSuiteId: // value for 'reportSuiteId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useConnectAdobeAnalyticsAccountToWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<ConnectAdobeAnalyticsAccountToWebsiteMutation, ConnectAdobeAnalyticsAccountToWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectAdobeAnalyticsAccountToWebsiteMutation, ConnectAdobeAnalyticsAccountToWebsiteMutationVariables>(ConnectAdobeAnalyticsAccountToWebsiteDocument, options);
      }
export type ConnectAdobeAnalyticsAccountToWebsiteMutationHookResult = ReturnType<typeof useConnectAdobeAnalyticsAccountToWebsiteMutation>;
export type ConnectAdobeAnalyticsAccountToWebsiteMutationResult = Apollo.MutationResult<ConnectAdobeAnalyticsAccountToWebsiteMutation>;
export type ConnectAdobeAnalyticsAccountToWebsiteMutationOptions = Apollo.BaseMutationOptions<ConnectAdobeAnalyticsAccountToWebsiteMutation, ConnectAdobeAnalyticsAccountToWebsiteMutationVariables>;
export const DisableAdobeAnalyticsForWebsiteDocument = gql`
    mutation DisableAdobeAnalyticsForWebsite($websiteId: WebsiteId!) {
  DisableAdobeAnalyticsForWebsite(websiteId: $websiteId) {
    query {
      website(id: $websiteId) {
        id
        adobeAnalyticsIntegration {
          adobeAnalyticsAccount {
            id
          }
          dataMatchingMethod
          dimensionId
          reportSuiteId
        }
        integrations {
          isNotOwned
          status
          type
        }
      }
    }
  }
}
    `;
export type DisableAdobeAnalyticsForWebsiteMutationFn = Apollo.MutationFunction<DisableAdobeAnalyticsForWebsiteMutation, DisableAdobeAnalyticsForWebsiteMutationVariables>;

/**
 * __useDisableAdobeAnalyticsForWebsiteMutation__
 *
 * To run a mutation, you first call `useDisableAdobeAnalyticsForWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableAdobeAnalyticsForWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableAdobeAnalyticsForWebsiteMutation, { data, loading, error }] = useDisableAdobeAnalyticsForWebsiteMutation({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useDisableAdobeAnalyticsForWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<DisableAdobeAnalyticsForWebsiteMutation, DisableAdobeAnalyticsForWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableAdobeAnalyticsForWebsiteMutation, DisableAdobeAnalyticsForWebsiteMutationVariables>(DisableAdobeAnalyticsForWebsiteDocument, options);
      }
export type DisableAdobeAnalyticsForWebsiteMutationHookResult = ReturnType<typeof useDisableAdobeAnalyticsForWebsiteMutation>;
export type DisableAdobeAnalyticsForWebsiteMutationResult = Apollo.MutationResult<DisableAdobeAnalyticsForWebsiteMutation>;
export type DisableAdobeAnalyticsForWebsiteMutationOptions = Apollo.BaseMutationOptions<DisableAdobeAnalyticsForWebsiteMutation, DisableAdobeAnalyticsForWebsiteMutationVariables>;