import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import AttachedIcon from '~/components/patterns/structuredValues/AttachedIcon';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import {
	BenefitIconType,
} from '~/components/patterns/icons/BenefitIcon';
import Copy from '~/components/logic/Copy';
import HighlightedValue from '~/components/patterns/values/HighlightedValue';

import {
	Benefit,
	BenefitSection,
} from '~/model/benefits';

import {
	comparePlans as _comparePlans,
	isEnterprisePlan,
} from '~/model/plans';

import {
	Tariff,
} from '~/model/pricing/tariffs';

import {
	isArray,
	notEmpty,
} from '~/utilities/typeCheck';

import matchAndReturn from '~/utilities/matchAndReturn';



type PlanBenefit = {
	amount?: number,
	detailed?: boolean,
	feature?: GraphQL.AccountFeature,
	featureAmount?: number,
	name: Benefit,
};

type PlanBenefits = {
	[key in 'c2' | 'v3' | 'v4']: ReadonlyArray<PlanBenefit> | {
		[key in GraphQL.AccountPlan]?: ReadonlyArray<PlanBenefit>
	}
};

type NewPlanBenefit = {
	featureAmount?: number,
	featureForAll?: boolean,
	featureName?: GraphQL.AccountFeature,
	hint?: React.ReactNode,
	iconType?: BasicIconType,
	name: Benefit,
	perPlan?: {
		[key in GraphQL.AccountPlan]?: {
			benefitAmount?: number,
			benefitLabel?: 'monthsCount' | 'weeksCount',
			featureName: GraphQL.AccountFeature,
			upsell?: true | {
				maximumAmount: number,
				unit: 'months' | 'perWebsite',
			},
		}
	},
	showTick?: boolean,
	upsell?: true | {
		maximumAmount: number,
		unit: 'months' | 'perWebsite',
	},
	values?: {
		[key in GraphQL.AccountPlan]?: React.ReactNode
	},
};

type NewPlanBenefitWithValues = (
	NewPlanBenefit & {
		values: Exclude<NewPlanBenefit['values'], undefined>,
	}
);

type NewPlanBenefits = {
	[key in BenefitSection]: Array<NewPlanBenefit>
};



const benefitValueMessages = defineMessages({
	amount: {
		id: 'ui.planBenefits.values.amount',
	},
	addon: {
		id: 'ui.planBenefits.values.addon',
	},
	monthsCount: {
		id: 'ui.planBenefits.values.monthsCount',
	},
	nonStandard: {
		id: 'ui.planBenefits.values.nonStandard',
	},
	pageBundles: {
		id: 'ui.planBenefits.values.pageBundles',
	},
	perWebsite: {
		id: 'ui.planBenefits.values.perWebsite',
	},
	priority: {
		id: 'ui.planBenefits.values.priority',
	},
	standard: {
		id: 'ui.planBenefits.values.standard',
	},
	unlimited: {
		id: 'ui.planBenefits.values.unlimited',
	},
	upToMonthsCount: {
		id: 'ui.planBenefits.values.upToMonthsCount',
	},
	upToPerWebsite: {
		id: 'ui.planBenefits.values.upToPerWebsite',
	},
	weeksCount: {
		id: 'ui.planBenefits.values.weeksCount',
	},
});

const messages = defineMessages({
	comingSoon: {
		id: 'ui.general.comingSoon',
	},
});

const hintMessages = defineMessages({
	lightspeedMonitoring: {
		id: 'ui.planBenefits.section.monitoringSettings.benefitHints.lightspeedMonitoring',
	},
	rechecks: {
		id: 'ui.planBenefits.section.MainFeatures.benefitHints.rechecks',
	},
});

const upsellMessages = defineMessages({
	basic: {
		id: 'ui.planBenefits.values.upsellAmount',
	},
	months: {
		id: 'ui.planBenefits.values.upsellMonthsCount',
	},
	perWebsite: {
		id: 'ui.planBenefits.values.upsellPerWebsite',
	},
});



const LIST_OF_ALL_PLANS = {
	[Tariff.C1]: [
		GraphQL.AccountPlan.EnterpriseLite,
		GraphQL.AccountPlan.Enterprise,
	],
	[Tariff.C2]: [
		GraphQL.AccountPlan.C2Starter,
		GraphQL.AccountPlan.C2Professional,
		GraphQL.AccountPlan.C2Enterprise,
	],
	[Tariff.V3]: [
		GraphQL.AccountPlan.Basic,
		GraphQL.AccountPlan.Standard,
		GraphQL.AccountPlan.Pro,
		GraphQL.AccountPlan.Prov4,
		GraphQL.AccountPlan.Enterprise,
	],
	[Tariff.V4]: [
		GraphQL.AccountPlan.Basic,
		GraphQL.AccountPlan.Standard,
		GraphQL.AccountPlan.Pro,
		GraphQL.AccountPlan.Enterprise,
	],
};

export function isPlanNativelyOutOfBand(tariff: Tariff, plan: GraphQL.AccountPlan): boolean {
	return (
		(tariff === Tariff.C1 && plan === GraphQL.AccountPlan.Enterprise)
		|| (tariff === Tariff.C1 && plan === GraphQL.AccountPlan.EnterpriseLite)
		|| plan === GraphQL.AccountPlan.C2Enterprise
	);
}

export function getTariffPlans(tariff: Tariff): ReadonlyArray<GraphQL.AccountPlan> {
	return LIST_OF_ALL_PLANS[tariff];
}


export function getPlanBenefits(tariff: Tariff, plan: GraphQL.AccountPlan): ReadonlyArray<PlanBenefit> {
	return isArray(PLAN_BENEFITS[tariff]) ? PLAN_BENEFITS[tariff] : PLAN_BENEFITS[tariff][plan];
}

const PLAN_BENEFITS: PlanBenefits = {
	v3: [
		{
			detailed: true,
			name: Benefit.UnlimitedWebsites,
		},
		{
			detailed: true,
			name: Benefit.UnlimitedUsers,
		},
		{
			feature: GraphQL.AccountFeature.DataRetentionMonths,
			name: Benefit.DataRetentionMonths,
		},
		{
			name: Benefit.Cobranding,
		},
		{
			feature: GraphQL.AccountFeature.IssuesConfiguration,
			name: Benefit.IssuesConfiguration,
		},
		{
			feature: GraphQL.AccountFeature.CustomElements,
			name: Benefit.CustomElements,
		},
		{
			feature: GraphQL.AccountFeature.WebVitalsOriginSummary,
			name: Benefit.CoreWebVitalsOriginSummary,
		},
		{
			feature: GraphQL.AccountFeature.LighthouseMonitoring,
			name: Benefit.LighthouseMonitoring,
		},
		{
			feature: GraphQL.AccountFeature.CustomAlertTrigger,
			name: Benefit.CustomAlertTrigger,
		},
		{
			feature: GraphQL.AccountFeature.Rendering,
			name: Benefit.Rendering,
		},
		{
			feature: GraphQL.AccountFeature.ReportingApi,
			name: Benefit.GoogleLookerStudio,
		},
		{
			feature: GraphQL.AccountFeature.ReportingApi,
			name: Benefit.ReportingApi,
		},
		{
			feature: GraphQL.AccountFeature.LogFileAnalysis,
			name: Benefit.LogFileAnalysis,
		},
		{
			feature: GraphQL.AccountFeature.IndexNow,
			name: Benefit.RealtimeIndexNow,
		},
		{
			feature: GraphQL.AccountFeature.MicrosoftTeams,
			name: Benefit.MicrosoftTeams,
		},
	],
	v4: [
		{
			detailed: true,
			name: Benefit.UnlimitedWebsites,
		},
		{
			detailed: true,
			name: Benefit.UnlimitedUsers,
		},
		{
			feature: GraphQL.AccountFeature.DataRetentionMonths,
			name: Benefit.DataRetentionMonths,
		},
		{
			name: Benefit.Cobranding,
		},
		{
			feature: GraphQL.AccountFeature.CustomElements,
			name: Benefit.CustomElements,
		},
		{
			feature: GraphQL.AccountFeature.WebVitalsOriginSummary,
			name: Benefit.CoreWebVitalsOriginSummary,
		},
		{
			feature: GraphQL.AccountFeature.LighthouseMonitoring,
			name: Benefit.LighthouseMonitoring,
		},
		{
			feature: GraphQL.AccountFeature.CustomAlertTrigger,
			name: Benefit.CustomAlertTrigger,
		},
		{
			feature: GraphQL.AccountFeature.Rendering,
			name: Benefit.Rendering,
		},
		{
			feature: GraphQL.AccountFeature.ReportingApi,
			name: Benefit.GoogleLookerStudio,
		},
		{
			feature: GraphQL.AccountFeature.ReportingApi,
			name: Benefit.ReportingApi,
		},
		{
			feature: GraphQL.AccountFeature.LogFileAnalysis,
			name: Benefit.LogFileAnalysis,
		},
		{
			feature: GraphQL.AccountFeature.IndexNow,
			name: Benefit.RealtimeIndexNow,
		},
		{
			feature: GraphQL.AccountFeature.MicrosoftTeams,
			name: Benefit.MicrosoftTeams,
		},
	],
	c2: {
		[GraphQL.AccountPlan.C2Starter]: [
			{
				detailed: true,
				feature: GraphQL.AccountFeature.Websites,
				name: Benefit.Websites,
			},
			{
				detailed: true,
				name: Benefit.UnlimitedUsers,
			},
			{
				feature: GraphQL.AccountFeature.DataRetentionDays,
				name: Benefit.DataRetentionDays,
			},
			{
				name: Benefit.Cobranding,
			},
			{
				feature: GraphQL.AccountFeature.WebVitalsOriginSummary,
				name: Benefit.CoreWebVitalsOriginSummary,
			},
			{
				feature: GraphQL.AccountFeature.CustomElements,
				name: Benefit.CustomElements,
			},
			{
				feature: GraphQL.AccountFeature.LighthouseMonitoring,
				name: Benefit.LighthouseMonitoring,
			},
			{
				feature: GraphQL.AccountFeature.CustomAlertTrigger,
				name: Benefit.CustomAlertTrigger,
			},
			{
				feature: GraphQL.AccountFeature.Rendering,
				name: Benefit.Rendering,
			},
			{
				feature: GraphQL.AccountFeature.ReportingApi,
				name: Benefit.GoogleLookerStudio,
			},
			{
				feature: GraphQL.AccountFeature.ReportingApi,
				name: Benefit.ReportingApi,
			},
			{
				feature: GraphQL.AccountFeature.LogFileAnalysis,
				name: Benefit.LogFileAnalysis,
			},
			{
				feature: GraphQL.AccountFeature.IndexNow,
				name: Benefit.RealtimeIndexNow,
			},
			{
				feature: GraphQL.AccountFeature.MicrosoftTeams,
				name: Benefit.MicrosoftTeams,
			},
		],
		[GraphQL.AccountPlan.C2Professional]: [
			{
				detailed: true,
				feature: GraphQL.AccountFeature.Websites,
				name: Benefit.Websites,
			},
			{
				detailed: true,
				name: Benefit.UnlimitedUsers,
			},
			{
				feature: GraphQL.AccountFeature.DataRetentionMonths,
				name: Benefit.DataRetentionMonths,
			},
			{
				name: Benefit.Cobranding,
			},
			{
				feature: GraphQL.AccountFeature.WebVitalsOriginSummary,
				name: Benefit.CoreWebVitalsOriginSummary,
			},
			{
				feature: GraphQL.AccountFeature.CustomElements,
				name: Benefit.CustomElements,
			},
			{
				feature: GraphQL.AccountFeature.LighthouseMonitoring,
				name: Benefit.LighthouseMonitoring,
			},
			{
				feature: GraphQL.AccountFeature.CustomAlertTrigger,
				name: Benefit.CustomAlertTrigger,
			},
			{
				feature: GraphQL.AccountFeature.Rendering,
				name: Benefit.Rendering,
			},
			{
				feature: GraphQL.AccountFeature.ReportingApi,
				name: Benefit.GoogleLookerStudio,
			},
			{
				feature: GraphQL.AccountFeature.ReportingApi,
				name: Benefit.ReportingApi,
			},
			{
				feature: GraphQL.AccountFeature.LogFileAnalysis,
				name: Benefit.LogFileAnalysis,
			},
			{
				feature: GraphQL.AccountFeature.IndexNow,
				name: Benefit.RealtimeIndexNow,
			},
			{
				feature: GraphQL.AccountFeature.MicrosoftTeams,
				name: Benefit.MicrosoftTeams,
			},
		],
		[GraphQL.AccountPlan.C2Enterprise]: [
			{
				detailed: true,
				feature: GraphQL.AccountFeature.Websites,
				name: Benefit.UnlimitedWebsites,
			},
			{
				detailed: true,
				name: Benefit.UnlimitedUsers,
			},
			{
				feature: GraphQL.AccountFeature.DataRetentionMonths,
				name: Benefit.DataRetentionMonths,
			},
			{
				name: Benefit.Cobranding,
			},
			{
				feature: GraphQL.AccountFeature.WebVitalsOriginSummary,
				name: Benefit.CoreWebVitalsOriginSummary,
			},
			{
				feature: GraphQL.AccountFeature.CustomElements,
				name: Benefit.CustomElements,
			},
			{
				feature: GraphQL.AccountFeature.LighthouseMonitoring,
				name: Benefit.LighthouseMonitoring,
			},
			{
				feature: GraphQL.AccountFeature.CustomAlertTrigger,
				name: Benefit.CustomAlertTrigger,
			},
			{
				feature: GraphQL.AccountFeature.Rendering,
				name: Benefit.Rendering,
			},
			{
				feature: GraphQL.AccountFeature.ReportingApi,
				name: Benefit.GoogleLookerStudio,
			},
			{
				feature: GraphQL.AccountFeature.ReportingApi,
				name: Benefit.ReportingApi,
			},
			{
				feature: GraphQL.AccountFeature.LogFileAnalysis,
				name: Benefit.LogFileAnalysis,
			},
			{
				feature: GraphQL.AccountFeature.IndexNow,
				name: Benefit.RealtimeIndexNow,
			},
			{
				feature: GraphQL.AccountFeature.MicrosoftTeams,
				name: Benefit.MicrosoftTeams,
			},
		],
	},
};

export function getNewPlanBenefits(
	tariff: Tariff,
	accountFeaturesAvailability: ReadonlyArray<{
		accountFeature: GraphQL.AccountFeature,
		amount: number,
		plan: GraphQL.AccountPlan,
		status: GraphQL.AccountFeatureAvailabilityStatus,
		tariff: string,
	}>,
	section: BenefitSection,
): Array<NewPlanBenefitWithValues> {
	const newPlanBenefits = matchAndReturn(tariff, {
		[Tariff.C1]: null,
		[Tariff.C2]: C2PlanBenefits,
		[Tariff.V2]: null,
		[Tariff.V3]: null,
		[Tariff.V4]: V4PlanBenefits,
	});

	if (newPlanBenefits === null) {
		return [];
	}

	return newPlanBenefits[section].map((benefit) => {
		if (benefit.values !== undefined) {
			return benefit as NewPlanBenefitWithValues;
		}

		const values: Exclude<NewPlanBenefit['values'], undefined> = {};

		getTariffPlans(tariff).forEach((plan) => {
			const color = isEnterprisePlan(plan)
				? `#f4a21e`
				: undefined;

			let availableAmount = 0;
			let benefitLabel = 'amount';
			let upsell = benefit.upsell ?? null;

			if (benefit.featureForAll) {
				availableAmount = 1;
			} else if (benefit.featureName) {
				availableAmount = getAvailableFeatureAmount(
					accountFeaturesAvailability,
					tariff,
					plan,
					benefit.featureName,
				);
			} else if (benefit.perPlan) {
				const perPlanSetup = benefit.perPlan[plan];

				if (perPlanSetup !== undefined) {
					availableAmount = perPlanSetup.benefitAmount ?? getAvailableFeatureAmount(
						accountFeaturesAvailability,
						tariff,
						plan,
						perPlanSetup.featureName,
					);

					if (perPlanSetup.benefitLabel !== undefined) {
						benefitLabel = perPlanSetup.benefitLabel;
					}

					upsell = perPlanSetup.upsell ?? null;
				}
			}

			let upsellLabel: React.ReactNode = null;

			if (upsell === true) {
				if (availableAmount === 0) {
					upsellLabel = (
						<strong>$</strong>
					);
				} else {
					upsellLabel = (
						<Copy {...upsellMessages.basic} />
					);
				}
			} else if (upsell !== null) {
				upsellLabel = (
					<Copy
						{...upsellMessages[upsell.unit]}
						values={{
							upsell: upsell.maximumAmount,
						}}
					/>
				);
			}

			if (availableAmount === 0) {
				if (upsellLabel !== null) {
					values[plan] = upsellLabel;
				} else {
					values[plan] = (
						<BasicIcon
							color={color}
							size={18}
							type={BasicIconType.Unavailable}
						/>
					);
				}
			} else {
				if (availableAmount > 1) {
					values[plan] = (
						<Copy
							{...benefitValueMessages[benefitLabel]}
							values={{
								count: availableAmount,
							}}
						/>
					);
				} else {
					values[plan] = (
						<BasicIcon
							color={color}
							size={18}
							type={BasicIconType.Available}
						/>
					);
				}

				if (upsellLabel !== null) {
					values[plan] = (
						<>
							{values[plan]} ({upsellLabel})
						</>
					);
				}

				if (availableAmount > 1 && (benefit.showTick ?? false)) {
					values[plan] = (
						<AttachedIcon
							columnFlow={true}
							icon={(
								<BasicIcon
									color={color}
									size={18}
									type={BasicIconType.Available}
								/>
							)}
						>
							{values[plan]}
						</AttachedIcon>
					);
				}
			}
		});

		return {
			...benefit,
			values,
		} as NewPlanBenefitWithValues;
	});
}



const V4PlanBenefits: NewPlanBenefits = {
	[BenefitSection.AccessControl]: [
		{
			featureName: GraphQL.AccountFeature.AdminRole,
			name: Benefit.OrganizationAdminRole,
		},
		{
			featureName: GraphQL.AccountFeature.EmailDomainsWhitelist,
			name: Benefit.RestrictEmailDomains,
		},
		{
			featureName: GraphQL.AccountFeature.EnforceTwoFactorAuthentication,
			name: Benefit.Enforce2FA,
		},
		{
			name: Benefit.AuditLog,
			values: {
				[GraphQL.AccountPlan.Basic]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Standard]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Pro]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Enterprise]: (
					<HighlightedValue tag={true}>
						<FormattedMessage {...messages.comingSoon} />
					</HighlightedValue>
				),
			},
		},
	],
	[BenefitSection.AdvancedAnalysis]: [
		{
			featureName: GraphQL.AccountFeature.RequestHeadersSnapshots,
			name: Benefit.HttpHeaderSnapshot,
		},
		{
			featureName: GraphQL.AccountFeature.StoreResponseSnapshots,
			name: Benefit.HttpBodySnapshot,
		},
		{
			featureName: GraphQL.AccountFeature.LogFileAnalysis,
			name: Benefit.LogFileAnalysis,
		},
		{
			name: Benefit.LogFileExplorer,
			values: {
				[GraphQL.AccountPlan.Basic]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Standard]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Pro]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Enterprise]: (
					<HighlightedValue tag={true}>
						<FormattedMessage {...messages.comingSoon} />
					</HighlightedValue>
				),
			},
		},
	],
	[BenefitSection.AdvancedFeatures]: [
		{
			featureName: GraphQL.AccountFeature.DataRetentionMonths,
			name: Benefit.DataRetention,
			values: {
				[GraphQL.AccountPlan.Basic]: (
					<Copy
						{...benefitValueMessages.monthsCount}
						values={{
							count: 6,
						}}
					/>
				),
				[GraphQL.AccountPlan.Standard]: (
					<Copy
						{...benefitValueMessages.monthsCount}
						values={{
							count: 9,
						}}
					/>
				),
				[GraphQL.AccountPlan.Pro]: (
					<Copy
						{...benefitValueMessages.monthsCount}
						values={{
							count: 12,
						}}
					/>
				),
				[GraphQL.AccountPlan.Enterprise]: (
					<Copy
						{...benefitValueMessages.upToMonthsCount}
						values={{
							count: 60,
						}}
					/>
				),
			},
		},
		{
			name: Benefit.CustomElementExtraction,
			values: {
				[GraphQL.AccountPlan.Basic]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Standard]: (
					<AttachedIcon
						columnFlow={true}
						icon={(
							<BasicIcon
								size={18}
								type={BasicIconType.Available}
							/>
						)}
					>
						<Copy
							{...benefitValueMessages.perWebsite}
							values={{
								count: 5,
							}}
						/>
					</AttachedIcon>
				),
				[GraphQL.AccountPlan.Pro]: (
					<AttachedIcon
						columnFlow={true}
						icon={(
							<BasicIcon
								size={18}
								type={BasicIconType.Available}
							/>
						)}
					>
						<Copy
							{...benefitValueMessages.perWebsite}
							values={{
								count: 10,
							}}
						/>
					</AttachedIcon>
				),
				[GraphQL.AccountPlan.Enterprise]: (
					<AttachedIcon
						columnFlow={true}
						ellipsis={false}
						icon={(
							<BasicIcon
								color="#f4a21e"
								size={18}
								type={BasicIconType.Available}
							/>
						)}
					>
						<Copy
							{...benefitValueMessages.upToPerWebsite}
							values={{
								count: 20,
							}}
						/>
					</AttachedIcon>
				),
			},
		},
		{
			featureName: GraphQL.AccountFeature.CustomAlertTrigger,
			name: Benefit.AlertsForCustomElements,
		},
		{
			featureName: GraphQL.AccountFeature.Rendering,
			iconType: BasicIconType.JavaScript,
			name: Benefit.JsRendering,
		},
		{
			featureName: GraphQL.AccountFeature.WebVitalsOriginSummary,
			iconType: BasicIconType.CoreWebVitalsMonitoring,
			name: Benefit.CoreWebVitalsMonitoring,
		},
		{
			featureName: GraphQL.AccountFeature.IndexNow,
			iconType: BasicIconType.RealtimeIndexNow,
			name: Benefit.RealtimeIndexNow,
		},
	],
	[BenefitSection.Collaboration]: [
		{
			featureForAll: true,
			name: Benefit.Cobranding,
		},
		{
			featureForAll: true,
			name: Benefit.ClientAccess,
		},
	],
	[BenefitSection.MainFeatures]: [
		{
			name: Benefit.Users,
			values: {
				[GraphQL.AccountPlan.Basic]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
				[GraphQL.AccountPlan.Standard]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
				[GraphQL.AccountPlan.Pro]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
				[GraphQL.AccountPlan.Enterprise]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
			},
		},
		{
			hint: (
				<FormattedMessage {...hintMessages.rechecks} />
			),
			name: Benefit.Rechecks,
			values: {
				[GraphQL.AccountPlan.Basic]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
				[GraphQL.AccountPlan.Standard]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
				[GraphQL.AccountPlan.Pro]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
				[GraphQL.AccountPlan.Enterprise]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
			},
		},
		{
			featureForAll: true,
			name: Benefit.WebsiteAuditing,
		},
		{
			featureForAll: true,
			name: Benefit.NonStopMonitoring,
		},
		{
			featureForAll: true,
			name: Benefit.ChangeTracking,
		},
		{
			featureForAll: true,
			name: Benefit.Alerting,
		},
	],
	[BenefitSection.Integrations]: [
		{
			featureName: GraphQL.AccountFeature.Slack,
			iconType: BasicIconType.Slack,
			name: Benefit.Slack,
		},
		{
			featureName: GraphQL.AccountFeature.GoogleAnalyticsIntegration,
			iconType: BasicIconType.GoogleAnalytics,
			name: Benefit.GoogleAnalytics,
		},
		{
			featureName: GraphQL.AccountFeature.GoogleSearchConsoleIntegration,
			iconType: BasicIconType.GoogleSearchConsole,
			name: Benefit.GoogleSearchConsole,
		},
		{
			featureForAll: true,
			iconType: BasicIconType.Chrome,
			name: Benefit.GoogleChromeExtension,
		},
		{
			featureName: GraphQL.AccountFeature.ReportingApi,
			iconType: BasicIconType.LookerStudio,
			name: Benefit.GoogleLookerStudio,
		},
		{
			featureName: GraphQL.AccountFeature.ReportingApi,
			iconType: BasicIconType.Api,
			name: Benefit.ReportingApi,
		},
		{
			featureName: GraphQL.AccountFeature.MicrosoftTeams,
			iconType: BasicIconType.MicrosoftTeams,
			name: Benefit.MicrosoftTeams,
		},
		{
			featureAmount: 5,
			featureName: GraphQL.AccountFeature.EnrichmentFields,
			iconType: BasicIconType.Api,
			name: Benefit.DataEnrichmentAPI,
		},
		{
			featureName: GraphQL.AccountFeature.AdobeAnalyticsIntegration,
			iconType: BasicIconType.AdobeAnalytics,
			name: Benefit.AdobeAnalytics,
		},
	],
	[BenefitSection.MonitoringSettings]: [
		{
			featureForAll: true,
			name: Benefit.MonitoringFromUSUKEU,
		},
		{
			name: Benefit.CustomMonitoringLocation,
			values: {
				[GraphQL.AccountPlan.Basic]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Standard]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Pro]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Enterprise]: (
					<AttachedIcon
						columnFlow={true}
						icon={(
							<BasicIcon
								color="#f4a21e"
								size={18}
								type={BasicIconType.Available}
							/>
						)}
					>
						<FormattedMessage {...benefitValueMessages.addon} />
					</AttachedIcon>
				),
			},
		},
		{
			featureName: GraphQL.AccountFeature.HighCrawlingSpeed,
			hint: (
				<FormattedMessage {...hintMessages.lightspeedMonitoring} />
			),
			name: Benefit.LightspeedMonitoring,
		},
	],
	[BenefitSection.Services]: [
		{
			name: Benefit.ChatSupport,
			values: {
				[GraphQL.AccountPlan.Basic]: (
					<FormattedMessage {...benefitValueMessages.standard} />
				),
				[GraphQL.AccountPlan.Standard]: (
					<FormattedMessage {...benefitValueMessages.standard} />
				),
				[GraphQL.AccountPlan.Pro]: (
					<FormattedMessage {...benefitValueMessages.standard} />
				),
				[GraphQL.AccountPlan.Enterprise]: (
					<AttachedIcon
						ellipsis={false}
						icon={(
							<BasicIcon
								color="#f4a21e"
								size={24}
								type={BasicIconType.Priority}
							/>
						)}
					>
						<FormattedMessage {...benefitValueMessages.priority} />
					</AttachedIcon>
				),
			},
		},
		{
			name: Benefit.EmailSupport,
			values: {
				[GraphQL.AccountPlan.Basic]: (
					<FormattedMessage {...benefitValueMessages.standard} />
				),
				[GraphQL.AccountPlan.Standard]: (
					<FormattedMessage {...benefitValueMessages.standard} />
				),
				[GraphQL.AccountPlan.Pro]: (
					<FormattedMessage {...benefitValueMessages.standard} />
				),
				[GraphQL.AccountPlan.Enterprise]: (
					<AttachedIcon
						ellipsis={false}
						icon={(
							<BasicIcon
								color="#f4a21e"
								size={24}
								type={BasicIconType.Priority}
							/>
						)}
					>
						<FormattedMessage {...benefitValueMessages.priority} />
					</AttachedIcon>
				),
			},
		},
		{
			name: Benefit.GuidesAndVideos,
			values: {
				[GraphQL.AccountPlan.Basic]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Available}
					/>
				),
				[GraphQL.AccountPlan.Standard]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Available}
					/>
				),
				[GraphQL.AccountPlan.Pro]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Available}
					/>
				),
				[GraphQL.AccountPlan.Enterprise]: (
					<BasicIcon
						color="#f4a21e"
						size={18}
						type={BasicIconType.Available}
					/>
				),
			},
		},
		{
			name: Benefit.CustomTraining,
			values: {
				[GraphQL.AccountPlan.Basic]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Standard]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Pro]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Enterprise]: (
					<AttachedIcon
						columnFlow={true}
						icon={(
							<BasicIcon
								color="#f4a21e"
								size={18}
								type={BasicIconType.Available}
							/>
						)}
					>
						<FormattedMessage {...benefitValueMessages.addon} />
					</AttachedIcon>
				),
			},
		},
		{
			name: Benefit.PhoneSupport,
			values: {
				[GraphQL.AccountPlan.Basic]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Standard]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Pro]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Enterprise]: (
					<BasicIcon
						color="#f4a21e"
						size={18}
						type={BasicIconType.Available}
					/>
				),
			},
		},
		{
			name: Benefit.DedicatedSuccessManager,
			values: {
				[GraphQL.AccountPlan.Basic]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Standard]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Pro]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Enterprise]: (
					<BasicIcon
						color="#f4a21e"
						size={18}
						type={BasicIconType.Available}
					/>
				),
			},
		},
	],
};

const C2PlanBenefits: NewPlanBenefits = {
	[BenefitSection.AccessControl]: [
		{
			featureName: GraphQL.AccountFeature.AdminRole,
			name: Benefit.OrganizationAdminRole,
		},
		{
			featureName: GraphQL.AccountFeature.EmailDomainsWhitelist,
			name: Benefit.RestrictEmailDomains,
		},
		{
			featureName: GraphQL.AccountFeature.EnforceTwoFactorAuthentication,
			name: Benefit.Enforce2FA,
		},
	],
	[BenefitSection.AdvancedAnalysis]: [
		{
			featureName: GraphQL.AccountFeature.RequestHeadersSnapshots,
			name: Benefit.HttpHeaderSnapshot,
		},
		{
			featureName: GraphQL.AccountFeature.StoreResponseSnapshots,
			name: Benefit.HttpBodySnapshot,
		},
		{
			featureName: GraphQL.AccountFeature.LogFileAnalysis,
			name: Benefit.LogFileAnalysis,
		},
	],
	[BenefitSection.AdvancedFeatures]: [
		{
			name: Benefit.DataRetention,
			perPlan: {
				[GraphQL.AccountPlan.C2Starter]: {
					benefitAmount: 2,
					benefitLabel: 'weeksCount',
					featureName: GraphQL.AccountFeature.DataRetentionDays,
				},
				[GraphQL.AccountPlan.C2Professional]: {
					benefitLabel: 'monthsCount',
					featureName: GraphQL.AccountFeature.DataRetentionMonths,
				},
				[GraphQL.AccountPlan.C2Enterprise]: {
					benefitLabel: 'monthsCount',
					featureName: GraphQL.AccountFeature.DataRetentionMonths,
					upsell: {
						maximumAmount: 60,
						unit: 'months',
					},
				},
			},
		},
		{
			name: Benefit.CustomElementExtraction,
			perPlan: {
				[GraphQL.AccountPlan.C2Starter]: {
					featureName: GraphQL.AccountFeature.CustomElements,
				},
				[GraphQL.AccountPlan.C2Professional]: {
					featureName: GraphQL.AccountFeature.CustomElements,
				},
				[GraphQL.AccountPlan.C2Enterprise]: {
					featureName: GraphQL.AccountFeature.CustomElements,
					upsell: {
						maximumAmount: 20,
						unit: 'perWebsite',
					},
				},
			},
			showTick: true,
		},
		{
			featureName: GraphQL.AccountFeature.CustomAlertTrigger,
			name: Benefit.AlertsForCustomElements,
		},
		{
			name: Benefit.CWVMonitoringAndJSRendering,
			perPlan: {
				[GraphQL.AccountPlan.C2Starter]: {
					featureName: GraphQL.AccountFeature.Rendering,
				},
				[GraphQL.AccountPlan.C2Professional]: {
					featureName: GraphQL.AccountFeature.Rendering,
					upsell: true,
				},
				[GraphQL.AccountPlan.C2Enterprise]: {
					featureName: GraphQL.AccountFeature.Rendering,
					upsell: true,
				},
			},
		},
		{
			featureName: GraphQL.AccountFeature.WebVitalsOriginSummary,
			name: Benefit.CoreWebVitalsMonitoring,
		},
		{
			featureName: GraphQL.AccountFeature.IndexNow,
			name: Benefit.RealtimeIndexNow,
		},
	],
	[BenefitSection.Collaboration]: [],
	[BenefitSection.MainFeatures]: [
		{
			name: Benefit.PageBundles,
			values: {
				[GraphQL.AccountPlan.C2Starter]: (
					<Copy
						{...benefitValueMessages.pageBundles}
						values={{
							from: 1_000,
							to: 100_000,
						}}
					/>
				),
				[GraphQL.AccountPlan.C2Professional]: (
					<Copy
						{...benefitValueMessages.pageBundles}
						values={{
							from: 1_000,
							to: 500_000,
						}}
					/>
				),
				[GraphQL.AccountPlan.C2Enterprise]: (
					<Copy
						{...benefitValueMessages.pageBundles}
						values={{
							from: 1_000,
							to: 10_000_000,
						}}
					/>
				),
			},
		},
		{
			name: Benefit.Websites,
			perPlan: {
				[GraphQL.AccountPlan.C2Starter]: {
					featureName: GraphQL.AccountFeature.Websites,
				},
				[GraphQL.AccountPlan.C2Professional]: {
					featureName: GraphQL.AccountFeature.Websites,
				},
				[GraphQL.AccountPlan.C2Enterprise]: {
					featureName: GraphQL.AccountFeature.Websites,
					upsell: true,
				},
			},
		},
		{
			hint: (
				<FormattedMessage {...hintMessages.rechecks} />
			),
			name: Benefit.Rechecks,
			values: {
				[GraphQL.AccountPlan.C2Starter]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
				[GraphQL.AccountPlan.C2Professional]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
				[GraphQL.AccountPlan.C2Enterprise]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
			},
		},
		{
			name: Benefit.Users,
			values: {
				[GraphQL.AccountPlan.C2Starter]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
				[GraphQL.AccountPlan.C2Professional]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
				[GraphQL.AccountPlan.C2Enterprise]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
			},
		},
		{
			featureForAll: true,
			name: Benefit.WebsiteAuditing,
		},
		{
			featureForAll: true,
			name: Benefit.NonStopMonitoring,
		},
		{
			featureForAll: true,
			name: Benefit.ChangeTracking,
		},
		{
			featureForAll: true,
			name: Benefit.Alerting,
		},
	],
	[BenefitSection.Integrations]: [
		{
			featureForAll: true,
			iconType: BasicIconType.Chrome,
			name: Benefit.GoogleChromeExtension,
		},
		{
			featureName: GraphQL.AccountFeature.GoogleAnalyticsIntegration,
			iconType: BasicIconType.GoogleAnalytics,
			name: Benefit.GoogleAnalytics,
		},
		{
			featureName: GraphQL.AccountFeature.GoogleSearchConsoleIntegration,
			iconType: BasicIconType.GoogleSearchConsole,
			name: Benefit.GoogleSearchConsole,
		},
		{
			featureName: GraphQL.AccountFeature.Slack,
			iconType: BasicIconType.Slack,
			name: Benefit.Slack,
		},
		{
			featureName: GraphQL.AccountFeature.ReportingApi,
			iconType: BasicIconType.LookerStudio,
			name: Benefit.GoogleLookerStudio,
		},
		{
			featureName: GraphQL.AccountFeature.MicrosoftTeams,
			iconType: BasicIconType.MicrosoftTeams,
			name: Benefit.MicrosoftTeams,
		},
		{
			featureName: GraphQL.AccountFeature.ReportingApi,
			iconType: BasicIconType.Api,
			name: Benefit.ReportingApi,
		},
		{
			iconType: BasicIconType.Api,
			name: Benefit.DataEnrichmentAPI,
			perPlan: {
				[GraphQL.AccountPlan.C2Starter]: {
					featureName: GraphQL.AccountFeature.EnrichmentFields,
				},
				[GraphQL.AccountPlan.C2Professional]: {
					featureName: GraphQL.AccountFeature.EnrichmentFields,
				},
				[GraphQL.AccountPlan.C2Enterprise]: {
					featureName: GraphQL.AccountFeature.EnrichmentFields,
					upsell: {
						maximumAmount: 20,
						unit: 'perWebsite',
					},
				},
			},
			showTick: true,
		},
		{
			featureName: GraphQL.AccountFeature.AdobeAnalyticsIntegration,
			iconType: BasicIconType.AdobeAnalytics,
			name: Benefit.AdobeAnalytics,
		},
	],
	[BenefitSection.MonitoringSettings]: [
		{
			featureForAll: true,
			name: Benefit.MonitoringFromUSUKEU,
		},
		{
			featureName: GraphQL.AccountFeature.HighCrawlingSpeed,
			hint: (
				<FormattedMessage {...hintMessages.lightspeedMonitoring} />
			),
			name: Benefit.LightspeedMonitoring,
		},
		{
			name: Benefit.CustomMonitoringLocation,
			values: {
				[GraphQL.AccountPlan.C2Starter]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.C2Professional]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.C2Enterprise]: (
					<strong>$</strong>
				),
			},
		},
	],
	[BenefitSection.Services]: [
		{
			name: Benefit.ChatSupport,
			values: {
				[GraphQL.AccountPlan.C2Starter]: (
					<FormattedMessage {...benefitValueMessages.standard} />
				),
				[GraphQL.AccountPlan.C2Professional]: (
					<FormattedMessage {...benefitValueMessages.standard} />
				),
				[GraphQL.AccountPlan.C2Enterprise]: (
					<AttachedIcon
						ellipsis={false}
						icon={(
							<BasicIcon
								color="#f4a21e"
								size={24}
								type={BasicIconType.Priority}
							/>
						)}
					>
						<FormattedMessage {...benefitValueMessages.priority} />
					</AttachedIcon>
				),
			},
		},
		{
			name: Benefit.EmailSupport,
			values: {
				[GraphQL.AccountPlan.C2Starter]: (
					<FormattedMessage {...benefitValueMessages.standard} />
				),
				[GraphQL.AccountPlan.C2Professional]: (
					<FormattedMessage {...benefitValueMessages.standard} />
				),
				[GraphQL.AccountPlan.C2Enterprise]: (
					<AttachedIcon
						ellipsis={false}
						icon={(
							<BasicIcon
								color="#f4a21e"
								size={24}
								type={BasicIconType.Priority}
							/>
						)}
					>
						<FormattedMessage {...benefitValueMessages.priority} />
					</AttachedIcon>
				),
			},
		},
		{
			name: Benefit.GuidesAndVideos,
			values: {
				[GraphQL.AccountPlan.C2Starter]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Available}
					/>
				),
				[GraphQL.AccountPlan.C2Professional]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Available}
					/>
				),
				[GraphQL.AccountPlan.C2Enterprise]: (
					<BasicIcon
						color="#f4a21e"
						size={18}
						type={BasicIconType.Available}
					/>
				),
			},
		},
		{
			name: Benefit.CustomTraining,
			values: {
				[GraphQL.AccountPlan.C2Starter]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.C2Professional]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.C2Enterprise]: (
					<BasicIcon
						color="#f4a21e"
						size={18}
						type={BasicIconType.Available}
					/>
				),
			},
		},
		{
			name: Benefit.PhoneSupport,
			values: {
				[GraphQL.AccountPlan.C2Starter]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.C2Professional]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.C2Enterprise]: (
					<BasicIcon
						color="#f4a21e"
						size={18}
						type={BasicIconType.Available}
					/>
				),
			},
		},
	],
};



export function getEnterprisePlanBenefits({ accountIsOutOfBand = false }) {
	return [
		!accountIsOutOfBand ? {
			name: Benefit.UnlimitedUsersWebsitesRechecks,
			iconType: BenefitIconType.UnlimitedUsersWebsitesRechecks,
		} : null,
		accountIsOutOfBand ? {
			name: Benefit.UnlimitedUsersRechecks,
			iconType: BenefitIconType.UnlimitedUsersWebsitesRechecks,
		} : null,
		{
			name: Benefit.RealtimeAuditing,
			iconType: BenefitIconType.RealtimeAuditing,
		},
		{
			name: Benefit.ChangeTrackingAndAlerting,
			iconType: BenefitIconType.ChangeTrackingAndAlerting,
		},
		{
			name: Benefit.BodySnapshots,
			iconType: BenefitIconType.BodySnapshots,
		},
		{
			name: Benefit.LogFileAnalysis,
			iconType: BenefitIconType.LogFileAnalysis,
		},
		{
			name: Benefit.CWVMonitoringAndJSRendering,
			iconType: BenefitIconType.CWVMonitoringAndJSRendering,
		},
		{
			name: Benefit.DedicatedSuccessManager,
			iconType: BenefitIconType.DedicatedSuccessManager,
		},
		{
			name: Benefit.OnboardingAndCustomTraining,
			iconType: BenefitIconType.OnboardingAndCustomTraining,
		},
		{
			name: Benefit.PrioritySupport,
			iconType: BenefitIconType.PrioritySupport,
		},
		{
			name: Benefit.CustomElements,
			iconType: BenefitIconType.CustomElements,
		},
		{
			name: Benefit.DataRetention,
			iconType: BenefitIconType.DataRetention,
		},
		{
			name: Benefit.AnalyticsAndGSCIntegrations,
			iconType: BenefitIconType.AnalyticsAndGSCIntegrations,
		},
		{
			name: Benefit.DataStudioAndAPIs,
			iconType: BenefitIconType.DataStudioAndAPIs,
		},
		{
			name: Benefit.ChatPlatformIntegrations,
			iconType: BenefitIconType.ChatPlatformIntegrations,
		},
		{
			name: Benefit.CustomMonitoringLocation,
			iconType: BenefitIconType.CustomMonitoringLocation,
		},
		{
			name: Benefit.InvoiceBilling,
			iconType: BenefitIconType.InvoiceBilling,
		},
		{
			name: Benefit.AdminAndAccessControlFeatures,
			iconType: BenefitIconType.AdminAndAccessControlFeatures,
		},
	].filter(notEmpty);
}



export function comparePlans(previous, next) {
	return _comparePlans([
		GraphQL.AccountPlan.Basic,
		GraphQL.AccountPlan.Standard,
		GraphQL.AccountPlan.Pro,
		GraphQL.AccountPlan.Prov4,
		GraphQL.AccountPlan.EnterpriseLite,
		GraphQL.AccountPlan.Enterprise,
		GraphQL.AccountPlan.C2Starter,
		GraphQL.AccountPlan.C2Professional,
		GraphQL.AccountPlan.C2Enterprise,
	], previous, next);
}



export function getAvailableFeatureAmount(
	accountFeaturesAvailability: ReadonlyArray<{
		accountFeature: GraphQL.AccountFeature,
		amount: number,
		plan: GraphQL.AccountPlan,
		status: GraphQL.AccountFeatureAvailabilityStatus,
		tariff: string,
	}>,
	tariff: string,
	plan: GraphQL.AccountPlan,
	featureName: GraphQL.AccountFeature,
) {
	const featureAvailability = accountFeaturesAvailability.find(
		(item) => (
			item.accountFeature === featureName
			&& item.plan === plan
			&& item.tariff === tariff
		),
	);

	if (featureAvailability === undefined) {
		return 0;
	}

	return featureAvailability.amount;
}



export function isFeatureAvailable(
	accountFeaturesAvailability: ReadonlyArray<{
		accountFeature: GraphQL.AccountFeature,
		amount: number,
		plan: GraphQL.AccountPlan,
		status: GraphQL.AccountFeatureAvailabilityStatus,
		tariff: string,
	}>,
	tariff: string,
	plan: GraphQL.AccountPlan,
	featureName: GraphQL.AccountFeature,
	featureAmount: number,
) {
	return getAvailableFeatureAmount(
		accountFeaturesAvailability,
		tariff,
		plan,
		featureName,
	) >= featureAmount;
}



export function getPlanAvailability(
	accountFeaturesAvailability: ReadonlyArray<{
		accountFeature: GraphQL.AccountFeature,
		amount: number,
		plan: GraphQL.AccountPlan,
		status: GraphQL.AccountFeatureAvailabilityStatus,
		tariff: string,
	}>,
	accountPlan: GraphQL.AccountPlan,
	accountTariff: string,
	featuresUsage: ReadonlyArray<{
		featureAmount: number,
		featureName: GraphQL.AccountFeature,
	}>,
	plan: GraphQL.AccountPlan,
) {
	if (accountPlan === plan) {
		return {
			conflictingFeatures: [],
			isAttainable: true,
			isAvailable: true,
		};
	}

	const conflictingFeatures = featuresUsage.filter(
		({ featureAmount, featureName }) => isFeatureAvailable(
			accountFeaturesAvailability,
			accountTariff,
			plan,
			featureName,
			featureAmount,
		) === false,
	);

	return {
		conflictingFeatures,
		isAttainable: conflictingFeatures
			.filter(({ featureName }) => featureName === GraphQL.AccountFeature.Websites)
			.length === 0,
		isAvailable: conflictingFeatures.length === 0,
	};
}



export function findHighestPlan(plans) {
	return [
		GraphQL.AccountPlan.Enterprise,
		GraphQL.AccountPlan.Prov4,
		GraphQL.AccountPlan.Pro,
		GraphQL.AccountPlan.Standard,
		GraphQL.AccountPlan.Basic,
	].find((plan) => plans.includes(plan)) ?? null;
}
