import React from 'react';

import type GraphQL from '~/types/graphql';

import ContactUsButton from '~/components/logic/account/components/ContactUsButton';
import SignUpButton from '~/components/logic/account/components/SignUpButton';

import useAccountId from '~/hooks/useAccountId';
import useAccountOutOfBandPlans from '~/hooks/useAccountOutOfBandPlans';
import useAllowedPlans from '~/hooks/useAllowedPlans';



type Props = {
	dominant?: boolean,
	plan: GraphQL.AccountPlan,
};

const SignupPlanComparisonButton: React.FC<Props> = (props) => {
	const {
		dominant = false,
		plan,
	} = props;

	const accountId = useAccountId();
	const accountOutOfBandPlans = useAccountOutOfBandPlans(accountId);
	const allowedPlans = useAllowedPlans(accountId);

	if (
		accountId === null
		|| accountOutOfBandPlans === null
		|| allowedPlans === null
	) {
		return null;
	}

	let showContactUsButton = false;

	if (accountOutOfBandPlans.includes(plan)) {
		showContactUsButton = true;
	}

	if (!allowedPlans.includes(plan)) {
		showContactUsButton = true;
	}

	if (showContactUsButton) {
		return (
			<ContactUsButton dominant={dominant} />
		);
	}

	return (
		<SignUpButton
			accountId={accountId}
			dominant={dominant}
			plan={plan}
		/>
	);
};



export default SignupPlanComparisonButton;
