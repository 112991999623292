import type CK from '~/types/contentking';

import {
	usePageIsPrioritizedQuery,
} from './usePageIsPrioritized.gql';

import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';



function usePageIsPrioritized(
	legacyUrlId: number | null,
	websiteId: CK.WebsiteId,
) {
	const { data } = usePageDetailPropertiesQuery(
		usePageIsPrioritizedQuery,
		legacyUrlId,
		websiteId,
	);

	return data?.lookupPageByLegacyId?.isPrioritized ?? null;
}



export default usePageIsPrioritized;
