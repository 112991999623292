import React from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AttachedElement from '~/components/patterns/structuredValues/AttachedElement';
import AttachedIcon, {
	AttachedIconPosition,
} from '~/components/patterns/structuredValues/AttachedIcon';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CalloutMessage, {
	CalloutMessageSize,
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import CancelButton from '~/components/app/CancelButton';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import CrawlingSpeedField from '~/components/app/CrawlingSpeedField';
import DeviceTypeName from '~/components/names/DeviceTypeName';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import FieldSeparator from '~/components/atoms/forms/components/FieldSeparator';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import HelpHint from '~/components/patterns/hints/HelpHint';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import SelectField from '~/components/atoms/forms/components/SelectField';
import SquareSkeleton from '~/components/patterns/loaders/SquareSkeleton';
import StaticText, {
	StaticTextAlignment,
} from '~/components/atoms/forms/components/StaticText';
import TimeField from '~/components/atoms/forms/components/TimeField';
import UserAgentFields, {
	UserAgentFieldsScope,
	validateUserAgent,
	validateUserAgentCustomValue,
} from '~/components/app/UserAgentFields';
import WebsiteStatus from '~/components/app/WebsiteStatus';
import WithPermission from '~/components/logic/access/WithPermission';

import {
	useUpdateWebsiteMonitoringSettingsMutation,
	useWebsiteMonitoringFormQuery,
} from './WebsiteMonitoringForm.gql';

import useWebsiteIsVerified from '~/hooks/useWebsiteIsVerified';
import useWebsitePageCapacity from '~/hooks/useWebsitePageCapacity';

import {
	ObjectType,
} from '~/model/permissions';

import {
	TIME_OFFSETS,
	TIME_OFFSET_UTC,
} from '~/model/time';

import {
	DeviceType,
} from '~/model/websites/deviceTypes';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';
import matchAndReturn from '~/utilities/matchAndReturn';



const messages = defineMessages({
	caution: {
		id: 'ui.general.caution',
	},
	crawlingDisabledByCustomerTeam: {
		id: 'ui.websites.form.monitoringSpeed.crawlingDisabledByCustomerTeam',
	},
	deviceTypeHint: {
		id: 'ui.websites.form.deviceType.hint',
	},
	deviceTypeLabel: {
		id: 'ui.websites.form.deviceType.label',
	},
	disablePeakModeLink: {
		id: 'ui.websites.form.peakMode.disableLink',
	},
	enablePeakModeLink: {
		id: 'ui.websites.form.peakMode.enableLink',
	},
	monitoringSpeed: {
		id: 'ui.websites.form.monitoringSpeed.label',
	},
	monitoringSpeedHigherThanRecommended: {
		id: 'ui.websites.form.monitoringSpeed.higherThanRecommended',
	},
	monitoringSpeedLowerThanRecommended: {
		id: 'ui.websites.form.monitoringSpeed.lowerThanRecommended',
	},
	monitoringSpeedPaused: {
		id: 'ui.forms.websiteSpeed.paused.label',
	},
	monitoringSpeedZero: {
		id: 'ui.websites.form.monitoringSpeed.zero',
	},
	offPeakSpeed: {
		id: 'ui.websites.form.offPeakSpeed.label',
	},
	peakSpeed: {
		id: 'ui.websites.form.peakSpeed.label',
	},
	peakTime: {
		id: 'ui.websites.form.peakTime.label',
	},
	throttledWarning: {
		id: 'ui.websites.form.monitoringSpeed.throttledWarning',
	},
	title: {
		id: 'ui.websites.form.monitoring.titleSettings',
	},
});

const deviceTypeOptions = [
	{
		name: DeviceType.Desktop,
		label: (
			<DeviceTypeName deviceType={DeviceType.Desktop} />
		),
	},
	{
		name: DeviceType.Mobile,
		label: (
			<DeviceTypeName deviceType={DeviceType.Mobile} />
		),
	},
];

const timeZoneOptions = TIME_OFFSETS
	.map(({ timeOffset, utcTimeOffset }) => ({
		name: timeOffset,
		label: utcTimeOffset,
	}))
	.sort((offsetA: any, offsetB: any) => {
		offsetA = parseInt(offsetA.name.replace(':', ''));
		offsetB = parseInt(offsetB.name.replace(':', ''));

		return offsetA < offsetB ? -1 : 1;
	});

type CrawlingSpeed = {
	intervals: ReadonlyArray<{
		isSetDueToThrottling: boolean,
		scaleFactor: number,
		since: string,
		tags: ReadonlyArray<string>,
		until: string,
	}>,
	timezoneOffset: string | null,
};



type Props = {
	websiteId: CK.WebsiteId,
};

const WebsiteMonitoringForm: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const [peakModeEnabled, setPeakModeEnabled] = React.useState<boolean | null>(null);

	const { data } = useWebsiteMonitoringFormQuery({
		variables: {
			websiteId,
		},
	});

	const crawlingSpeed = data?.website?.crawlingSpeed ?? null;
	const isCrawlingDisabledByCustomerTeam = data?.website?.isCrawlingDisabledByCustomerTeam ?? false;
	const renderingDeviceType = data?.website?.renderingDeviceType ?? null;
	const userAgentType = data?.website?.userAgentType ?? null;
	const userAgentValue = data?.website?.userAgentValue ?? null;

	const handleFormModeSwitch = React.useCallback(
		(inEditMode) => {
			if (!inEditMode) {
				setPeakModeEnabled(null);
			}
		},
		[
			setPeakModeEnabled,
		],
	);

	return (
		<WithPermission
			action={GraphQL.ActionWithWebsite.ManageMonitoringSettings}
			objectId={websiteId}
			objectType={ObjectType.Website}
			showMessage={false}
		>
			{({ isAllowed }) => (
				<EditableFormWrapper
					isAllowed={isAllowed}
					isReadOnly={isCrawlingDisabledByCustomerTeam}
					onEnterEditModeCallback={handleFormModeSwitch}
					title={(
						<FormattedMessage {...messages.title} />
					)}
				>
					<DisplayPart>
						<WebsiteMonitoringFormDisplayPart
							crawlingSpeed={crawlingSpeed}
							renderingDeviceType={renderingDeviceType}
							userAgentType={userAgentType}
							userAgentValue={userAgentValue}
							websiteId={websiteId}
						/>

						{isCrawlingDisabledByCustomerTeam && (
							<FormRow>
								<CalloutMessage
									borders={true}
									message={
										<FormattedMessage {...messages.caution} />
									}
									size={CalloutMessageSize.Small}
									status={CalloutMessageStatus.Warning}
								>
									<Copy {...messages.crawlingDisabledByCustomerTeam} />
								</CalloutMessage>
							</FormRow>
						)}
					</DisplayPart>

					<EditablePart>
						{crawlingSpeed !== null && renderingDeviceType !== null && userAgentType !== null && (
							<WebsiteMonitoringFormEditablePart
								crawlingSpeed={crawlingSpeed}
								peakModeEnabled={peakModeEnabled}
								renderingDeviceType={renderingDeviceType}
								setPeakModeEnabled={setPeakModeEnabled}
								userAgentType={userAgentType}
								userAgentValue={userAgentValue}
								websiteId={websiteId}
							/>
						)}
					</EditablePart>
				</EditableFormWrapper>
			)}
		</WithPermission>
	);
};



type WebsiteMonitoringFormDisplayPartProps = {
	crawlingSpeed: CrawlingSpeed | null,
	renderingDeviceType: GraphQL.RenderingDeviceType | null,
	userAgentType: string | null,
	userAgentValue: string | null,
	websiteId: CK.WebsiteId,
};

const WebsiteMonitoringFormDisplayPart: React.FC<WebsiteMonitoringFormDisplayPartProps> = (props) => {
	const {
		crawlingSpeed,
		renderingDeviceType,
		userAgentType,
		userAgentValue,
		websiteId,
	} = props;

	return (
		<FormRows>
			<FormRow
				label={(
					<AttachedElement
						element={(
							<HelpHint
								message={(
									<FormattedMessage
										{...messages.deviceTypeHint}
										values={{
											linkSupport: linkExternal('https://www.contentkingapp.com/support/monitoring-settings/'),
										}}
									/>
								)}
							/>
						)}
					>
						<FormattedMessage {...messages.deviceTypeLabel} />
					</AttachedElement>
				)}
			>
				<StaticText focusTarget="deviceType">
					{renderingDeviceType !== null ? (
						<DeviceTypeName
							deviceType={matchAndReturn(renderingDeviceType, {
								[GraphQL.RenderingDeviceType.Desktop]: DeviceType.Desktop,
								[GraphQL.RenderingDeviceType.Mobile]: DeviceType.Mobile,
							})}
						/>
					) : (
						<SquareSkeleton maxWidth={80} />
					)}
				</StaticText>
			</FormRow>

			<UserAgentFields
				customHeaderValue={userAgentValue}
				isEditable={false}
				scope={UserAgentFieldsScope.Website}
				userAgent={userAgentType as any}
			/>

			{(() => {
				if (crawlingSpeed === null) {
					return (
						<FormRow
							label={(
								<FormattedMessage {...messages.monitoringSpeed} />
							)}
						>
							<SquareSkeleton maxWidth={80} />
						</FormRow>
					);
				}

				const peakIntervals = crawlingSpeed.intervals.filter((interval) => interval.tags.includes('peak'));
				const offPeakIntervals = crawlingSpeed.intervals.filter((interval) => interval.tags.includes('peak') === false);

				const monitoringSpeed = peakIntervals.length > 0
					? getArrayItemAtSafeIndex(offPeakIntervals, 0).scaleFactor
					: getArrayItemAtSafeIndex(crawlingSpeed.intervals, 0).scaleFactor;

				const monitoringSpeedThrottled = peakIntervals.length > 0
					? getArrayItemAtSafeIndex(offPeakIntervals, 0).isSetDueToThrottling
					: getArrayItemAtSafeIndex(crawlingSpeed.intervals, 0).isSetDueToThrottling;

				const peakSpeed = peakIntervals.length > 0
					? getArrayItemAtSafeIndex(peakIntervals, 0).scaleFactor
					: null;

				const peakSpeedThrottled = peakIntervals.length > 0
					? getArrayItemAtSafeIndex(peakIntervals, 0).isSetDueToThrottling
					: null;

				let peakTimeSince: string | null = null;
				let peakTimeUntil: string | null = null;

				if (peakIntervals.length > 0) {
					if (peakIntervals.length > 1) {
						peakTimeSince = getArrayItemAtSafeIndex(peakIntervals, 1).since;
						peakTimeUntil = getArrayItemAtSafeIndex(peakIntervals, 0).until;
					} else {
						peakTimeSince = getArrayItemAtSafeIndex(peakIntervals, 0).since;
						peakTimeUntil = getArrayItemAtSafeIndex(peakIntervals, 0).until;
					}
				}

				return (
					<>
						<FormRow
							description={[
								monitoringSpeedThrottled && (
									<FormattedMessage {...messages.throttledWarning} />
								),
								(monitoringSpeed == 0.0) && (
									<FormattedMessage {...messages.monitoringSpeedZero} />
								),
							]}
							label={peakSpeed !== null
								? (
									<FormattedMessage {...messages.offPeakSpeed} />
								)
								: (
									<FormattedMessage {...messages.monitoringSpeed} />
								)
							}
						>
							<StaticText focusTarget="monitoring_speed">
								{monitoringSpeed > 0.0 && (
									<FormattedNumber
										style="percent"
										value={monitoringSpeed}
									/>
								)}
								{monitoringSpeed == 0.0 && (
									<AttachedIcon
										ellipsis={false}
										icon={(
											<WebsiteStatus
												showStatus={[GraphQL.WebsiteProblem.Paused]}
												websiteId={websiteId}
											/>
										)}
										iconPosition={AttachedIconPosition.Suffix}
									>
										<FormattedMessage {...messages.monitoringSpeedPaused} />
									</AttachedIcon>
								)}
							</StaticText>
						</FormRow>
						{peakSpeed !== null && (
							<FormRow
								description={[
									peakSpeedThrottled && (
										<FormattedMessage {...messages.throttledWarning} />
									),
									(peakSpeed == 0.0) && (
										<FormattedMessage {...messages.monitoringSpeedZero} />
									),
								]}
								label={(
									<FormattedMessage {...messages.peakSpeed} />
								)}
							>
								<StaticText focusTarget="peak_speed">
									{peakSpeed > 0.0 && (
										<FormattedNumber
											style="percent"
											value={peakSpeed}
										/>
									)}
									{peakSpeed == 0.0 && (
										<FormattedMessage {...messages.monitoringSpeedPaused} />
									)}
								</StaticText>
							</FormRow>
						)}
						{peakSpeed !== null && (
							<FormRow
								label={(
									<FormattedMessage {...messages.peakTime} />
								)}
							>
								<StaticText focusTarget="peak_speed_time_from">
									{peakTimeSince} - {peakTimeUntil} ({TIME_OFFSETS.find(
										({ timeOffset }) => timeOffset === crawlingSpeed.timezoneOffset,
									)?.utcTimeOffset})
								</StaticText>
							</FormRow>
						)}
					</>
				);
			})()}
		</FormRows>
	);
};



type WebsiteMonitoringFormEditablePartProps = {
	crawlingSpeed: CrawlingSpeed,
	peakModeEnabled: boolean | null,
	renderingDeviceType: GraphQL.RenderingDeviceType,
	setPeakModeEnabled: React.Dispatch<React.SetStateAction<boolean | null>>,
	userAgentType: string,
	userAgentValue: string | null,
	websiteId: CK.WebsiteId,
};

const WebsiteMonitoringFormEditablePart: React.FC<WebsiteMonitoringFormEditablePartProps> = (props) => {
	const {
		crawlingSpeed,
		peakModeEnabled,
		renderingDeviceType,
		setPeakModeEnabled,
		userAgentType,
		userAgentValue,
		websiteId,
	} = props;

	const websiteIsVerified = useWebsiteIsVerified(websiteId) ?? false;
	const websitePageCapacity = useWebsitePageCapacity(websiteId);

	const [updateWebsiteMonitoringSettings] = useUpdateWebsiteMonitoringSettingsMutation();

	const disablePeakMode = React.useCallback(
		(e) => {
			e.preventDefault();

			setPeakModeEnabled(false);
		},
		[
			setPeakModeEnabled,
		],
	);

	const enablePeakMode = React.useCallback(
		(e) => {
			e.preventDefault();

			setPeakModeEnabled(true);
		},
		[
			setPeakModeEnabled,
		],
	);

	const handleSubmitForm = React.useCallback(
		async (values) => {
			let crawlingSpeedPayload: {
				intervals: Array<{
					scaleFactor: number,
					since: string,
					tags: Array<string>,
					until: string,
				}>,
				timezoneOffset: string | null,
			};

			const inPeakMode = peakModeEnabled !== null
				? peakModeEnabled
				: crawlingSpeed.intervals.filter(
					(interval) => interval.tags.includes('peak'),
				).length > 0;

			if (inPeakMode) {
				const offPeakSpeed = values.monitoring_speed * 1.0;
				const peakSpeed = values.peak_speed * 1.0;

				const intervals: typeof crawlingSpeedPayload['intervals'] = [];

				const peakTimeSince = values.peak_speed_time_from.match(/(\d+):(\d+)/);
				const peakTimeSinceInSeconds = parseInt(peakTimeSince[1]) * 3660 + parseInt(peakTimeSince[2]);

				const peakTimeUntil = values.peak_speed_time_to.match(/(\d+):(\d+)/);
				const peakTimeUntilInSeconds = parseInt(peakTimeUntil[1]) * 3600 + parseInt(peakTimeUntil[2]);
				const peakTimeUntilInHours = Math.round(peakTimeUntilInSeconds / 3600);

				if (peakTimeSinceInSeconds === 0) {
					if (peakTimeUntilInHours === 24) {
						intervals.push({
							since: '00:00',
							until: '24:00',
							scaleFactor: parseFloat(peakSpeed.toFixed(1)),
							tags: [
								'peak',
							],
						});
					} else {
						intervals.push({
							since: '00:00',
							until: values.peak_speed_time_to,
							scaleFactor: parseFloat(peakSpeed.toFixed(1)),
							tags: [
								'peak',
							],
						});
						intervals.push({
							since: values.peak_speed_time_to,
							until: '24:00',
							scaleFactor: parseFloat(offPeakSpeed.toFixed(1)),
							tags: [
								'off_peak',
							],
						});
					}
				} else if (peakTimeSinceInSeconds < peakTimeUntilInSeconds) {
					if (peakTimeUntilInHours === 24) {
						intervals.push({
							since: '00:00',
							until: values.peak_speed_time_from,
							scaleFactor: parseFloat(offPeakSpeed.toFixed(1)),
							tags: [
								'off_peak',
							],
						});
						intervals.push({
							since: values.peak_speed_time_from,
							until: '24:00',
							scaleFactor: parseFloat(peakSpeed.toFixed(1)),
							tags: [
								'peak',
							],
						});
					} else {
						intervals.push({
							since: '00:00',
							until: values.peak_speed_time_from,
							scaleFactor: parseFloat(offPeakSpeed.toFixed(1)),
							tags: [
								'off_peak',
							],
						});
						intervals.push({
							since: values.peak_speed_time_from,
							until: values.peak_speed_time_to,
							scaleFactor: parseFloat(peakSpeed.toFixed(1)),
							tags: [
								'peak',
							],
						});
						intervals.push({
							since: values.peak_speed_time_to,
							until: '24:00',
							scaleFactor: parseFloat(offPeakSpeed.toFixed(1)),
							tags: [
								'off_peak',
							],
						});
					}
				} else {
					intervals.push({
						since: '00:00',
						until: values.peak_speed_time_to,
						scaleFactor: parseFloat(peakSpeed.toFixed(1)),
						tags: [
							'peak',
						],
					});
					intervals.push({
						since: values.peak_speed_time_to,
						until: values.peak_speed_time_from,
						scaleFactor: parseFloat(offPeakSpeed.toFixed(1)),
						tags: [
							'off_peak',
						],
					});
					intervals.push({
						since: values.peak_speed_time_from,
						until: '24:00',
						scaleFactor: parseFloat(peakSpeed.toFixed(1)),
						tags: [
							'peak',
						],
					});
				}

				crawlingSpeedPayload = {
					intervals,
					timezoneOffset: values.peak_speed_time_zone,
				};
			} else {
				const monitoringSpeed = values.monitoring_speed * 1.0;

				crawlingSpeedPayload = {
					intervals: [
						{
							since: '00:00',
							until: '24:00',
							scaleFactor: parseFloat(monitoringSpeed.toFixed(1)),
							tags: [],
						},
					],
					timezoneOffset: crawlingSpeed.timezoneOffset,
				};
			}

			await updateWebsiteMonitoringSettings({
				variables: {
					crawlingSpeed: crawlingSpeedPayload,
					renderingDeviceType: values.deviceType,
					userAgentType: values.userAgent,
					userAgentValue: values.userAgentCustomValue,
					websiteId,
				},
			});
		},
		[
			crawlingSpeed,
			peakModeEnabled,
			updateWebsiteMonitoringSettings,
			websiteId,
		],
	);

	const timeZone = crawlingSpeed.timezoneOffset || TIME_OFFSET_UTC;

	let peakTimeSince = '09:00';
	let peakTimeUntil = '17:00';

	let offPeakInterval: {
		isSetDueToThrottling: boolean,
		scaleFactor: number,
		since: string,
		until: string,
	} | null = null;

	const peakIntervals = crawlingSpeed.intervals.filter(
		(interval) => interval.tags.includes('peak'),
	);

	if (peakIntervals.length > 0) {
		if (peakIntervals.length > 1) {
			peakTimeSince = getArrayItemAtSafeIndex(peakIntervals, 1).since;
			peakTimeUntil = getArrayItemAtSafeIndex(peakIntervals, 0).until;
		} else {
			peakTimeSince = getArrayItemAtSafeIndex(peakIntervals, 0).since;
			peakTimeUntil = getArrayItemAtSafeIndex(peakIntervals, 0).until;
		}

		offPeakInterval = getArrayItemAtSafeIndex(
			crawlingSpeed.intervals.filter((interval) => interval.tags.includes('peak') === false),
			0,
		);
	}

	const monitoringSpeed: number = offPeakInterval !== null
		? offPeakInterval.scaleFactor
		: getArrayItemAtSafeIndex(crawlingSpeed.intervals, 0).scaleFactor;

	const monitoringSpeedThrottled: boolean = offPeakInterval !== null
		? offPeakInterval.isSetDueToThrottling
		: getArrayItemAtSafeIndex(crawlingSpeed.intervals, 0).isSetDueToThrottling;

	const peakSpeed = peakIntervals.length > 0
		? getArrayItemAtSafeIndex(peakIntervals, 0).scaleFactor
		: monitoringSpeed;

	const peakSpeedThrottled = peakIntervals.length > 0
		? getArrayItemAtSafeIndex(peakIntervals, 0).isSetDueToThrottling
		: false;

	const inPeakMode = peakModeEnabled !== null
		? peakModeEnabled
		: peakIntervals.length > 0;

	return (
		<PremiumFeatureSituation
			featureName={GraphQL.AccountFeature.HighCrawlingSpeed}
			hideIfUnattainable={false}
			showUnavailablePlan={true}
			style={PremiumFeatureSituationStyle.Box}
		>
			{({ isFeatureEnabled }) => (
				<Form
					defaultValues={{
						deviceType: renderingDeviceType,
						monitoring_speed: monitoringSpeed * 1.0,
						peak_speed: peakSpeed * 1.0,
						peak_speed_time_from: peakTimeSince,
						peak_speed_time_to: peakTimeUntil,
						peak_speed_time_zone: timeZone,
						userAgent: userAgentType,
						userAgentCustomValue: userAgentValue,
					}}
					ignoreFieldUnmounts={true}
					onSuccess={handleSubmitForm}
					overrideDataHasChanged={peakModeEnabled !== null ? (peakModeEnabled !== (offPeakInterval !== null)) : null}
					validations={{
						monitoring_speed: [
							{
								message: '',
								field: 'monitoring_speed',
								rule: ({ values, name }) => {
									if (!websiteIsVerified) {
										return values[name] <= 1.0;
									}

									return true;
								},
							},
							{
								message: '',
								field: 'monitoring_speed',
								rule: ({ values, name }) => {
									if (!isFeatureEnabled) {
										return values[name] !== 10;
									}

									return true;
								},
							},
						],
						peak_speed: [
							{
								message: '',
								field: 'peak_speed',
								rule: ({ values, name }) => {
									if (!inPeakMode) {
										return true;
									}

									if (!websiteIsVerified) {
										return values[name] <= 1.0;
									}

									return true;
								},
							},
							{
								message: '',
								field: 'peak_speed',
								rule: ({ values, name }) => {
									if (!inPeakMode) {
										return true;
									}

									if (!isFeatureEnabled) {
										return values[name] !== 10;
									}

									return true;
								},
							},
						],
						peak_speed_time: [
							{
								message: '',
								field: 'peak_speed_time_from',
								rule: ({ values }) => {
									if (!inPeakMode) {
										return true;
									}

									const value = values['peak_speed_time_from']?.trim() ?? '';

									return /^((0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]|24:00)$/.test(value);
								},
							},
							{
								message: '',
								field: 'peak_speed_time_to',
								rule: ({ values }) => {
									if (!inPeakMode) {
										return true;
									}

									const value = values['peak_speed_time_to']?.trim() ?? '';

									return /^((0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]|24:00)$/.test(value);
								},
							},
						],
						validateUserAgent: validateUserAgent({ isWebsiteVerified: websiteIsVerified }),
						validateUserAgentCustomValue: validateUserAgentCustomValue({ isWebsiteVerified: websiteIsVerified }),
					}}
				>
					{({ values }) => (
						<>
							<FormRows>
								<FormRow
									htmlFor="deviceType"
									label={(
										<AttachedElement
											element={(
												<HelpHint
													message={(
														<FormattedMessage
															{...messages.deviceTypeHint}
															values={{
																linkSupport: linkExternal('https://www.contentkingapp.com/support/monitoring-settings/'),
															}}
														/>
													)}
												/>
											)}
										>
											<FormattedMessage {...messages.deviceTypeLabel} />
										</AttachedElement>
									)}
								>
									<FieldStatus name="deviceType">
										<SelectField
											name="deviceType"
											options={deviceTypeOptions}
										/>
									</FieldStatus>
								</FormRow>

								<UserAgentFields
									customHeaderValue={userAgentValue}
									isEditable={true}
									isWebsiteVerified={websiteIsVerified}
									scope={UserAgentFieldsScope.Website}
									userAgent={userAgentType as any}
								/>

								<FormRow
									htmlFor="monitoring_speed"
									key="monitoringSpeed"
									label={inPeakMode
										? (
											<FormattedMessage {...messages.offPeakSpeed} />
										)
										: (
											<FormattedMessage {...messages.monitoringSpeed} />
										)
									}
								>
									<FieldStatus name="monitoring_speed">
										{websitePageCapacity !== null && (
											<CrawlingSpeedField
												isVerified={websiteIsVerified}
												name="monitoring_speed"
												pageTotal={websitePageCapacity}
											/>
										)}
									</FieldStatus>
								</FormRow>

								<CrawlingSpeedCaution
									key="monitoringSpeedCaution"
									monitoringSpeed={values.monitoring_speed}
									monitoringSpeedThrottled={monitoringSpeedThrottled}
								/>

								{!inPeakMode && (
									<StaticText
										alignment={StaticTextAlignment.Center}
										key="enablePeakModeLink"
										width="100%"
									>
										<InternalLink
											onClickCallback={enablePeakMode}
											style={InternalLinkStyle.Decent}
										>
											<FormattedMessage {...messages.enablePeakModeLink} />
										</InternalLink>
									</StaticText>
								)}

								{inPeakMode && (
									<FormRow
										htmlFor="peak_speed"
										key="peakSpeed"
										label={(
											<FormattedMessage {...messages.peakSpeed} />
										)}
									>
										<FieldStatus name="peak_speed">
											{websitePageCapacity !== null && (
												<CrawlingSpeedField
													isVerified={websiteIsVerified}
													name="peak_speed"
													pageTotal={websitePageCapacity}
												/>
											)}
										</FieldStatus>
									</FormRow>
								)}

								{inPeakMode && (
									<CrawlingSpeedCaution
										key="peakSpeedCaution"
										monitoringSpeed={values.peak_speed}
										monitoringSpeedThrottled={peakSpeedThrottled}
									/>
								)}

								{inPeakMode && (
									<FormRow
										htmlFor="peak_speed_time_from"
										key="peakTime"
										label={(
											<FormattedMessage {...messages.peakTime} />
										)}
									>
										<FieldStatus name="peak_speed_time">
											<TimeField
												name="peak_speed_time_from"
											/>
											<FieldSeparator
												separator="—"
											/>
											<TimeField
												name="peak_speed_time_to"
											/>
											<SelectField
												dropdownWidth={125}
												name="peak_speed_time_zone"
												options={timeZoneOptions}
												width={125}
											/>
										</FieldStatus>
									</FormRow>
								)}

								{inPeakMode && (
									<StaticText
										alignment={StaticTextAlignment.Center}
										key="disablePeakModeLink"
										width="100%"
									>
										<InternalLink
											onClickCallback={disablePeakMode}
											style={InternalLinkStyle.Decent}
										>
											<FormattedMessage {...messages.disablePeakModeLink} />
										</InternalLink>
									</StaticText>
								)}
							</FormRows>

							<ButtonsLayout>
								<CancelButton />

								<SaveSubmitButton />
							</ButtonsLayout>
						</>
					)}
				</Form>
			)}
		</PremiumFeatureSituation>
	);
};



type CrawlingSpeedCautionProps = {
	monitoringSpeed: number,
	monitoringSpeedThrottled: boolean,
};

const CrawlingSpeedCaution: React.FC<CrawlingSpeedCautionProps> = (props) => {
	const {
		monitoringSpeed,
		monitoringSpeedThrottled,
	} = props;

	if (monitoringSpeed > 0.6 && monitoringSpeed < 3) {
		return null;
	}

	return (
		<PremiumFeatureSituation
			featureName={GraphQL.AccountFeature.HighCrawlingSpeed}
			hideIfUnattainable={false}
			showUnavailablePlan={true}
			style={PremiumFeatureSituationStyle.Box}
		>
			{({ isFeatureEnabled, premiumAnnotation }) => {
				if (!isFeatureEnabled && monitoringSpeed === 10) {
					return (
						<FormRow>
							{premiumAnnotation}
						</FormRow>
					);
				}

				return (
					<FormRow>
						<CalloutMessage
							borders={true}
							message={
								<FormattedMessage {...messages.caution} />
							}
							size={CalloutMessageSize.Small}
							status={CalloutMessageStatus.Warning}
						>
							{monitoringSpeed === 0 && (
								<FormattedMessage {...messages.monitoringSpeedZero} />
							)}

							{monitoringSpeed > 0 && monitoringSpeed <= 0.6 && (
								<FormattedMessage {...messages.monitoringSpeedLowerThanRecommended} />
							)}

							{monitoringSpeed >= 3 && (
								<FormattedMessage {...messages.monitoringSpeedHigherThanRecommended} />
							)}

							{monitoringSpeed >= 3 && monitoringSpeedThrottled && (
								<FormattedMessage {...messages.throttledWarning} />
							)}
						</CalloutMessage>
					</FormRow>
				);
			}}
		</PremiumFeatureSituation>
	);
};



export default WebsiteMonitoringForm;
