import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import ActiveFilter from '~/components/logic/filters/ActiveFilter';
import AddWebsiteHeaderButton from '~/components/logic/buttons/AddWebsiteHeaderButton';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button from '~/components/patterns/buttons/Button';
import HeaderButtonsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderButtonsLayout';
import HeaderFilterLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderFilterLayout';
import HeaderOptionsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderOptionsLayout';
import HeaderTitleLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderTitleLayout';
import HeaderTitleWithNumericValue from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitleWithNumericValue';
import ImportWebsiteModal from '~/components/app/ImportWebsiteModal';
import NoWebsites from '~/components/app/NoWebsites';
import OnlyForAdmin from '~/components/app/OnlyForAdmin';
import ScreenBody from '~/components/patterns/screens/parts/body/ScreenBody';
import ScreenHeader from '~/components/patterns/screens/basicScreen/header/ScreenHeader';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';
import ViewSwitch from '~/components/logic/ViewSwitch';
import WebsitesOverviewGrid from '~/components/app/WebsitesOverviewGrid';
import WebsitesOverviewTable from '~/components/app/WebsitesOverviewTable';
import WhenAccountActionAllowed from '~/components/app/WhenAccountActionAllowed';

import {
	useWebsitesOverviewQuery,
} from './WebsitesOverviewSection.gql';

import useAccountId from '~/hooks/useAccountId';
import useDataSource from '~/hooks/useDataSource';
import useFilteredView from '~/hooks/useFilteredView';
import useHomeAccountIds from '~/hooks/useHomeAccountIds';
import useNavigation from '~/hooks/useNavigation';
import useOpenModal from '~/hooks/useOpenModal';
import usePollInterval from '~/hooks/usePollInterval';
import useViewportType from '~/hooks/useViewportType';

import {
	COLUMN_DOMAIN,
	COLUMN_INTEGRATIONS,
	COLUMN_LOG_SOURCES,
	COLUMN_NAME,
	COLUMN_NUMBER_OF_CUSTOM_ELEMENTS,
	COLUMN_NUMBER_OF_SEGMENTS,
	COLUMN_PAGE_CAPACITY_USAGE,
	COLUMN_STATUS,
	filterWebsitesByDomain,
	filterWebsitesByName,
} from '~/model/websites';

import {
	createNumberFilter,
} from '~/utilities/createNumberFilter';

import goTo from '~/routing/goTo';
import sortArrayByProperty from '~/utilities/sortArrayByProperty';

import {
	isImmutableList,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	[COLUMN_INTEGRATIONS]: {
		id: 'ui.websites.overview.table.columns.integrations',
	},
	[COLUMN_LOG_SOURCES]: {
		id: 'ui.websites.overview.table.columns.logSources',
	},
	[COLUMN_NAME]: {
		id: 'ui.websites.overview.table.columns.name',
	},
	[COLUMN_NUMBER_OF_CUSTOM_ELEMENTS]: {
		id: 'ui.websites.overview.table.columns.customElements',
	},
	[COLUMN_NUMBER_OF_SEGMENTS]: {
		id: 'ui.websites.overview.table.columns.segments',
	},
	[COLUMN_PAGE_CAPACITY_USAGE]: {
		id: 'ui.websites.overview.table.columns.planCapacity',
	},
	title: {
		id: 'ui.teamDetail.websites.menuLabel',
	},
});



type Props = {
	panel: any,
};

const WebsitesOverviewSection: React.FC<Props> = (props) => {
	const {
		panel,
	} = props;

	const accountId = useAccountId();
	const homeAccountIds = useHomeAccountIds();

	const navigation = useNavigation();
	const viewportType = useViewportType();

	const { data } = useWebsitesOverviewQuery({
		pollInterval: usePollInterval(30_000),
		skip: accountId === null,
		variables: {
			accountId: accountId ?? 0,
		},
	});

	const isHomeAccount = accountId !== null && homeAccountIds !== null && homeAccountIds.includes(accountId);
	const isLoading = (data?.account?.websites ?? null) === null;
	const websites = data?.account?.websites ?? null;
	const hasWebsites = isLoading || (websites !== null && websites.length > 0);

	const tableRef = React.useRef<any | null>(null);

	const {
		activeFilter,
		filter,
		listAll,
		listFiltered,
		removeFilter,
		sortBy,
		updateFilter,
		updateSortBy,
	} = useDataSource(
		(filterAndSort) => {
			filterAndSort.addField(COLUMN_NAME, {
				filtering: (websites, name) => {
					return filterWebsitesByName({
						name,
						websites,
					});
				},
				sorting: (websites) => {
					return sortArrayByProperty(websites, (website) => website.displayName);
				},
			});

			filterAndSort.addField(COLUMN_DOMAIN, {
				filtering: (websites, domain) => {
					return filterWebsitesByDomain({
						domain,
						websites,
					});
				},
				sorting: (websites) => {
					return sortArrayByProperty(websites, (website) => website.domain);
				},
			});

			filterAndSort.addField(COLUMN_PAGE_CAPACITY_USAGE, {
				filtering: (websites, pageCapacityUsages) => {
					return websites.filter((website) => {
						return pageCapacityUsages.includes(
							website.pageCapacityUsageDescription,
						);
					});
				},
			});

			filterAndSort.addField(COLUMN_LOG_SOURCES, {
				filtering: (websites, logSourceStatuses) => {
					if (isImmutableList(logSourceStatuses)) {
						logSourceStatuses = logSourceStatuses.toArray();
					}

					if (
						logSourceStatuses.length === 1
						&& logSourceStatuses[0] === GraphQL.LogSourceStatus.NotAvailable
					) {
						return websites.filter((website) => {
							return website.logSources.every((logSource) => {
								return logSource.status === GraphQL.LogSourceStatus.NotAvailable;
							});
						});
					}

					return websites.filter((website) => {
						return website.logSources.some((logSource) => {
							return logSourceStatuses.includes(logSource.status);
						});
					});
				},
				sorting: (websites) => {
					const sorting = [
						GraphQL.LogSourceStatus.Enabled,
						GraphQL.LogSourceStatus.Available,
						GraphQL.LogSourceStatus.NotAvailable,
					];

					return sortArrayByProperty(
						websites,
						(website) => {
							return website.logSources.reduce((result, logSource) => {
								return Math.max(result, sorting.indexOf(logSource.status));
							}, -1);
						},
					);
				},
			});

			filterAndSort.addField(COLUMN_NUMBER_OF_CUSTOM_ELEMENTS, {
				filtering: createNumberFilter(
					(website) => website.customElements.length,
				),
				sorting: (websites) => {
					return sortArrayByProperty(websites, (website) => website.customElements.length);
				},
			});

			filterAndSort.addField(COLUMN_NUMBER_OF_SEGMENTS, {
				filtering: createNumberFilter(
					(website) => website.pageSegments.length,
				),
				sorting: (websites) => {
					return sortArrayByProperty(websites, (website) => website.pageSegments.length);
				},
			});

			filterAndSort.addField(COLUMN_STATUS, {
				sorting: (websites) => {
					const groups = [
						(website) => website.problem === GraphQL.WebsiteProblem.CapacityReached,
						(website) => website.problem === GraphQL.WebsiteProblem.NearCapacity,
						(website) => website.problem === null,
						(website) => website.problem === GraphQL.WebsiteProblem.Paused,
					];

					const result: Array<typeof websites[0]> = [];

					groups.forEach((groupFilter) => {
						const groupWebsites = sortArrayByProperty(
							websites.filter(groupFilter),
							(website) => website.displayName,
						);

						groupWebsites.forEach((website) => {
							result.push(website);
						});
					});

					return result;
				},
			});

			return {
				defaultFilter: {
					[COLUMN_LOG_SOURCES]: [
						GraphQL.LogSourceStatus.Available,
						GraphQL.LogSourceStatus.Enabled,
						GraphQL.LogSourceStatus.NotAvailable,
					],
					[COLUMN_PAGE_CAPACITY_USAGE]: [
						GraphQL.WebsitePageCapacityUsageDescription.Near,
						GraphQL.WebsitePageCapacityUsageDescription.Reached,
						GraphQL.WebsitePageCapacityUsageDescription.Within,
					],
				},
				defaultSortBy: {
					key: COLUMN_STATUS,
					direction: true,
				},
			};
		},
		[
		],
		websites,
	);

	const filteredView = useFilteredView({
		ready: !isLoading,
		simple: () => (websites ?? []).length < 7,
	});

	const handleScrollToColumn = React.useCallback(
		(field) => {
			tableRef.current?.scrollToColumn(field);
		},
		[],
	);

	const handleWebsiteClick = React.useCallback(
		(websiteId, event) => {
			const {
				name,
				params,
			} = navigation.createRoute({
				keepParameters: true,
				routeName: 'website.detail',
				routeParams: {
					websiteId,
				},
			});

			goTo(event, name, params);
		},
		[
			navigation,
		],
	);

	const openImportWebsiteModal = useOpenModal(
		() => (
			<ImportWebsiteModal />
		),
		[],
	);

	const listedWebsites = filteredView.isActive
		? listFiltered()
		: listAll();

	let content;

	if (panel) {
		content = (
			<ScreenBody>
				{panel()}
			</ScreenBody>
		);
	} else if (filteredView.isActive) {
		content = (
			<WebsitesOverviewTable
				filter={filter}
				isLoading={isLoading}
				onFilterCallback={updateFilter}
				onSortCallback={updateSortBy}
				onWebsiteClickCallback={handleWebsiteClick}
				ref={tableRef}
				sortBy={sortBy}
				websites={listedWebsites}
			/>
		);
	} else {
		content = (
			<ScreenBody>
				<WebsitesOverviewGrid
					onWebsiteClickCallback={handleWebsiteClick}
					websites={listedWebsites}
				/>
			</ScreenBody>
		);
	}

	return (
		<ScreenLayout
			header={(
				<ScreenHeader>
					<HeaderTitleLayout>
						<HeaderTitleWithNumericValue
							title={(
								<FormattedMessage {...messages.title} />
							)}
							value={listedWebsites.length}
						/>
					</HeaderTitleLayout>

					{filteredView.isActive && (
						<HeaderFilterLayout compactView={viewportType.isSmall || viewportType.isMedium}>
							<ActiveFilter
								activeFilters={activeFilter}
								filterNames={{
									[COLUMN_INTEGRATIONS]: (
										<FormattedMessage {...messages[COLUMN_INTEGRATIONS]} />
									),
									[COLUMN_LOG_SOURCES]: (
										<FormattedMessage {...messages[COLUMN_LOG_SOURCES]} />
									),
									[COLUMN_NAME]: (
										<FormattedMessage {...messages[COLUMN_NAME]} />
									),
									[COLUMN_NUMBER_OF_CUSTOM_ELEMENTS]: (
										<FormattedMessage {...messages[COLUMN_NUMBER_OF_CUSTOM_ELEMENTS]} />
									),
									[COLUMN_NUMBER_OF_SEGMENTS]: (
										<FormattedMessage {...messages[COLUMN_NUMBER_OF_SEGMENTS]} />
									),
									[COLUMN_PAGE_CAPACITY_USAGE]: (
										<FormattedMessage {...messages[COLUMN_PAGE_CAPACITY_USAGE]} />
									),
								}}
								onFilterClickCallback={handleScrollToColumn}
								removeFilterCallback={removeFilter}
								segmentsManagement={false}
							/>
						</HeaderFilterLayout>
					)}

					<HeaderOptionsLayout>
						<HeaderButtonsLayout>
							{isHomeAccount && (
								<OnlyForAdmin canBeHidden={true}>
									<WhenAccountActionAllowed
										accountId={accountId}
										action={GraphQL.ActionWithAccount.ManageInternals}
									>
										<Button
											icon={(
												<BasicIcon type={BasicIconType.Import} />
											)}
											onClick={openImportWebsiteModal}
										>
											Import website
										</Button>
									</WhenAccountActionAllowed>
								</OnlyForAdmin>
							)}

							<AddWebsiteHeaderButton />
						</HeaderButtonsLayout>

						{hasWebsites && (
							<ViewSwitch
								isListActive={filteredView.isActive}
								switchToGridRoute={filteredView.deactivateRoute}
								switchToListRoute={filteredView.activateRoute}
							/>
						)}
					</HeaderOptionsLayout>
				</ScreenHeader>
			)}
		>
			{hasWebsites && content}

			{!hasWebsites && (
				<ScreenBody>
					<NoWebsites />
				</ScreenBody>
			)}
		</ScreenLayout>
	);
};



export default WebsitesOverviewSection;
