import React from 'react';

import type CK from '~/types/contentking';

import AdobeAnalyticsIntegrationForm from '~/components/app/AdobeAnalyticsIntegrationForm';
import FormsList from '~/components/atoms/lists/FormsList';
import GoogleAnalyticsIntegrationForm from '~/components/app/GoogleAnalyticsIntegrationForm';
import WebsiteIntegrationsMessagingAppsBlock from '~/components/app/WebsiteIntegrationsMessagingAppsBlock';
import WebsiteSettingsIntegrationsForm from '~/components/app/WebsiteSettingsIntegrationsForm';



type Props = {
	websiteId: CK.WebsiteId,
};

const WebsiteIntegrationsGroup: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	return (
		<FormsList>
			<GoogleAnalyticsIntegrationForm
				websiteId={websiteId}
			/>

			<WebsiteSettingsIntegrationsForm
				websiteId={websiteId}
			/>

			<WebsiteIntegrationsMessagingAppsBlock
				websiteId={websiteId}
			/>

			<AdobeAnalyticsIntegrationForm
				websiteId={websiteId}
			/>
		</FormsList>
	);
};



export default WebsiteIntegrationsGroup;
