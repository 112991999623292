import {
	addMilliseconds,
	differenceInMilliseconds,
} from 'date-fns';
import React from 'react';

import type CK from '~/types/contentking';

import usePageIsPrioritized from '~/hooks/usePageIsPrioritized';
import usePageLastCheckedAt from '~/hooks/usePageLastCheckedAt';
import usePrioritizePage from '~/hooks/usePrioritizePage';
import useWindowHasFocus from '~/hooks/useWindowHasFocus';



function usePrioritizePageContinuously(
	legacyUrlId: number | null,
	websiteId: CK.WebsiteId,
): void {
	const now = React.useMemo(
		() => new Date(),
		[],
	);

	const prioritizePage = usePrioritizePage(legacyUrlId, websiteId);
	const lastCheckedAt = usePageLastCheckedAt(legacyUrlId, websiteId) ?? now;
	const isPrioritized = usePageIsPrioritized(legacyUrlId, websiteId);
	const windowHasFocus = useWindowHasFocus();

	const timeoutRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);

	React.useEffect(
		() => {
			if (isPrioritized !== false || windowHasFocus === false) {
				return;
			}

			const shouldPrioritizeAt = addMilliseconds(lastCheckedAt, 10000);
			const waitForPrioritization = Math.max(differenceInMilliseconds(shouldPrioritizeAt, new Date()), 0);

			timeoutRef.current = setTimeout(
				prioritizePage,
				waitForPrioritization,
			);

			return () => {
				if (timeoutRef.current) {
					clearTimeout(timeoutRef.current);
				}
			};
		},
		[
			isPrioritized,
			lastCheckedAt,
			prioritizePage,
			windowHasFocus,
		],
	);
}



export default usePrioritizePageContinuously;
