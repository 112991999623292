import type CK from '~/types/contentking';

import {
	useWebsiteIsCrawledForConductorIntelligenceQuery,
} from './useWebsiteIsCrawledForConductorIntelligence.gql';



function useWebsiteIsCrawledForConductorIntelligence(websiteId: CK.WebsiteId | null): boolean | null {
	const { data } = useWebsiteIsCrawledForConductorIntelligenceQuery({
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	return (data?.website?.isCrawledForConductorIntelligence) ?? null;
}



export default useWebsiteIsCrawledForConductorIntelligence;
