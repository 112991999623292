import React from 'react';

import SegmentMultiselectFieldFilter, {
	type SegmentMultiselectFieldFilterRef,
} from './SegmentMultiselectFieldFilter';
import SegmentDefinitionIdentifier from '../segments/SegmentDefinitionIdentifier';
import WebsiteScopeLabel from '~/components/app/WebsiteScopeLabel';

import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';



type Props = {
	name: string,
	width: number,
};

const ScopesFieldFilter = React.forwardRef<SegmentMultiselectFieldFilterRef, Props>((props, ref) => {
	const {
		name,
		width,
	} = props;

	const websiteId = useWebsiteId();
	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);

	const options = React.useMemo(
		() => {
			const result = [
				{
					name: 'website',
					title: (
						<WebsiteScopeLabel
							showHint={false}
						/>
					),
				},
			];

			segmentDefinitions.listAll().forEach((segmentDefinition) => {
				result.push({
					name: 'segment:' + segmentDefinition.name,
					title: (
						<SegmentDefinitionIdentifier
							segmentDefinition={segmentDefinition}
							showCriteria={false}
						/>
					),
				});
			});

			return result;
		},
		[
			segmentDefinitions,
		],
	);

	if (segmentDefinitions.isLoaded === false) {
		return null;
	}

	return (
		<SegmentMultiselectFieldFilter
			allValue="all"
			dropdownWidth={250}
			name={name}
			options={options}
			ref={ref}
			scrollableDropdown={true}
			segmentDefinitions={segmentDefinitions.listAll()}
			width={width}
		/>
	);
});



export default React.memo(ScopesFieldFilter);
