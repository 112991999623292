import React from 'react';

import AccountAdminContextActions from './navigations/AccountAdminContextActions';
import AccountAdminDefaultWebsiteSettingsForm from '~/components/app/AccountAdminDefaultWebsiteSettingsForm';
import AccountAdminPhaseForm from '~/components/app/AccountAdminPhaseForm';
import AccountAdminPreverifiedDomainsForm from '~/components/app/AccountAdminPreverifiedDomainsForm';
import AccountCustomerTeamContextDetailsOverview from '~/components/app/AccountCustomerTeamContextDetailsOverview';
import AccountCustomerTeamMessagingAppChannelOverview from '~/components/app/AccountCustomerTeamMessagingAppChannelOverview';
import AddonsForm from './forms/AddonsForm';
import AdminFeaturesSettingsForm from '~/components/app/AdminFeaturesSettingsForm';
import CertifiedPartnerProgramForm from './forms/CertifiedPartnerProgramForm';
import EmailSettingsForm from './forms/EmailSettingsForm';
import FormsList from '~/components/atoms/lists/FormsList';
import HeaderTitle from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitle';
import HeaderTitleLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderTitleLayout';
import InvoicesSettingsForm from './forms/InvoicesSettingsForm';
import MainSettingsForm from './forms/MainSettingsForm';
import RenderingAndLighthouseWebsitesForm from './forms/RenderingAndLighthouseWebsitesForm';
import ReviewsForm from './forms/ReviewsForm';
import SalesSettingsForm from './forms/SalesSettingsForm';
import ScreenHeader from '~/components/patterns/screens/basicScreen/header/ScreenHeader';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';
import Sections from '~/components/logic/lego/Sections';
import SubscriptionOptionsSettingsForm from './forms/SubscriptionOptionsSettingsForm';

import useEffectiveHomeAccountId from '~/hooks/useEffectiveHomeAccountId';



enum Section {
	Account = 'account',
	CustomerTeamContext = 'customerTeamContext',
	FeatureUsage = 'featureUsage',
	Integrations = 'integrations',
	Marketing = 'marketing',
	Phase = 'phase',
	Sales = 'sales',
	WebsiteSettings = 'websiteSettings',
}

const sectionSorting = [
	Section.Account,
	Section.Phase,
	Section.Sales,
	Section.CustomerTeamContext,
	Section.Marketing,
	Section.WebsiteSettings,
	Section.Integrations,
	Section.FeatureUsage,
];

const sectionRoutes = {
	[Section.Account]: 'account.admin',
	[Section.CustomerTeamContext]: 'account.admin.customerTeamContext',
	[Section.FeatureUsage]: 'account.admin.featureUsage',
	[Section.Integrations]: 'account.admin.integrations',
	[Section.Marketing]: 'account.admin.marketing',
	[Section.Phase]: 'account.admin.phase',
	[Section.Sales]: 'account.admin.sales',
	[Section.WebsiteSettings]: 'account.admin.websiteSettings',
};

const sectionTitles = {
	[Section.Account]: 'Account',
	[Section.CustomerTeamContext]: 'Customer team context',
	[Section.FeatureUsage]: 'Feature usage',
	[Section.Integrations]: 'Integrations',
	[Section.Marketing]: 'Marketing',
	[Section.Phase]: 'Account phase',
	[Section.Sales]: 'Sales',
	[Section.WebsiteSettings]: 'Website settings',
};



const AccountAdmin: React.FC = () => {
	const homeAccountId = useEffectiveHomeAccountId();

	const renderSectionContent = React.useCallback(
		(section) => {
			if (homeAccountId === null) {
				return;
			}

			switch (section) {

				case Section.Account:
					return (
						<FormsList>
							<MainSettingsForm
								accountId={homeAccountId}
							/>
							<AdminFeaturesSettingsForm
								accountId={homeAccountId}
							/>
							<AddonsForm />
						</FormsList>
					);

				case Section.CustomerTeamContext:
					return (
						<FormsList>
							<AccountCustomerTeamContextDetailsOverview
								accountId={homeAccountId}
							/>
						</FormsList>
					);

				case Section.FeatureUsage:
					return (
						<FormsList>
							<RenderingAndLighthouseWebsitesForm
								accountId={homeAccountId}
							/>
						</FormsList>
					);

				case Section.Integrations:
					return (
						<FormsList>
							<AccountCustomerTeamMessagingAppChannelOverview
								accountId={homeAccountId}
							/>
						</FormsList>
					);

				case Section.Marketing:
					return (
						<FormsList>
							<EmailSettingsForm
								accountId={homeAccountId}
							/>
							<ReviewsForm
								accountId={homeAccountId}
							/>
						</FormsList>
					);

				case Section.Phase:
					return (
						<FormsList>
							<AccountAdminPhaseForm
								accountId={homeAccountId}
							/>
						</FormsList>
					);

				case Section.Sales:
					return (
						<FormsList>
							<SalesSettingsForm
								accountId={homeAccountId}
							/>
							<SubscriptionOptionsSettingsForm
								accountId={homeAccountId}
							/>
							<InvoicesSettingsForm
								accountId={homeAccountId}
							/>
							<CertifiedPartnerProgramForm
								accountId={homeAccountId}
							/>
						</FormsList>
					);

				case Section.WebsiteSettings:
					return (
						<FormsList>
							<AccountAdminDefaultWebsiteSettingsForm
								accountId={homeAccountId}
							/>

							<AccountAdminPreverifiedDomainsForm
								accountId={homeAccountId}
							/>
						</FormsList>
					);

			}
		},
		[
			homeAccountId,
		],
	);

	const renderSectionTitle = React.useCallback(
		(section) => {
			return sectionTitles[section];
		},
		[],
	);

	return (
		<ScreenLayout
			header={(
				<ScreenHeader>
					<HeaderTitleLayout>
						<HeaderTitle baselineAlignment={false}>
							Admin
						</HeaderTitle>
					</HeaderTitleLayout>
				</ScreenHeader>
			)}
		>
			<Sections
				actions={(
					<AccountAdminContextActions />
				)}
				getSectionRoute={(section) => sectionRoutes[section]}
				renderSectionContent={renderSectionContent}
				renderSectionTitle={renderSectionTitle}
				sections={sectionSorting}
			/>
		</ScreenLayout>
	);
};



export default AccountAdmin;
