/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountTaxRateQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountTaxRateQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly billingDetails: { readonly __typename?: 'AccountBillingDetails', readonly tax: { readonly __typename?: 'Tax', readonly country: string | null, readonly id: CK.ID, readonly isEstimate: boolean, readonly rate: CK.Percentage, readonly type: GraphQL.TaxType | null } } | null } | null };


export const AccountTaxRateDocument = gql`
    query AccountTaxRate($accountId: AccountId!) {
  account(id: $accountId) {
    id
    billingDetails {
      tax {
        country
        id
        isEstimate
        rate
        type
      }
    }
  }
}
    `;

/**
 * __useAccountTaxRateQuery__
 *
 * To run a query within a React component, call `useAccountTaxRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountTaxRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountTaxRateQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountTaxRateQuery(baseOptions: Apollo.QueryHookOptions<AccountTaxRateQuery, AccountTaxRateQueryVariables> & ({ variables: AccountTaxRateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountTaxRateQuery, AccountTaxRateQueryVariables>(AccountTaxRateDocument, options);
      }
export function useAccountTaxRateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountTaxRateQuery, AccountTaxRateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountTaxRateQuery, AccountTaxRateQueryVariables>(AccountTaxRateDocument, options);
        }
export function useAccountTaxRateSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccountTaxRateQuery, AccountTaxRateQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountTaxRateQuery, AccountTaxRateQueryVariables>(AccountTaxRateDocument, options);
        }
export type AccountTaxRateQueryHookResult = ReturnType<typeof useAccountTaxRateQuery>;
export type AccountTaxRateLazyQueryHookResult = ReturnType<typeof useAccountTaxRateLazyQuery>;
export type AccountTaxRateSuspenseQueryHookResult = ReturnType<typeof useAccountTaxRateSuspenseQuery>;
export type AccountTaxRateQueryResult = Apollo.QueryResult<AccountTaxRateQuery, AccountTaxRateQueryVariables>;