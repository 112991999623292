/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteQuotasQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteQuotasQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly quotas: ReadonlyArray<{ readonly __typename?: 'WebsiteQuota', readonly count: number, readonly limit: number, readonly type: string }> | null } | null };


export const WebsiteQuotasDocument = gql`
    query WebsiteQuotas($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    quotas {
      count
      limit
      type
    }
  }
}
    `;

/**
 * __useWebsiteQuotasQuery__
 *
 * To run a query within a React component, call `useWebsiteQuotasQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteQuotasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteQuotasQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteQuotasQuery(baseOptions: Apollo.QueryHookOptions<WebsiteQuotasQuery, WebsiteQuotasQueryVariables> & ({ variables: WebsiteQuotasQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteQuotasQuery, WebsiteQuotasQueryVariables>(WebsiteQuotasDocument, options);
      }
export function useWebsiteQuotasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteQuotasQuery, WebsiteQuotasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteQuotasQuery, WebsiteQuotasQueryVariables>(WebsiteQuotasDocument, options);
        }
export function useWebsiteQuotasSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WebsiteQuotasQuery, WebsiteQuotasQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteQuotasQuery, WebsiteQuotasQueryVariables>(WebsiteQuotasDocument, options);
        }
export type WebsiteQuotasQueryHookResult = ReturnType<typeof useWebsiteQuotasQuery>;
export type WebsiteQuotasLazyQueryHookResult = ReturnType<typeof useWebsiteQuotasLazyQuery>;
export type WebsiteQuotasSuspenseQueryHookResult = ReturnType<typeof useWebsiteQuotasSuspenseQuery>;
export type WebsiteQuotasQueryResult = Apollo.QueryResult<WebsiteQuotasQuery, WebsiteQuotasQueryVariables>;