/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCustomerDetailsInBillingTabMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  customerDetails: GraphQL.Scalars['Array']['input'];
}>;


export type UpdateCustomerDetailsInBillingTabMutation = { readonly __typename?: 'Mutation', readonly StoreCustomerDetails: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly allowedCurrencies: ReadonlyArray<GraphQL.Currency>, readonly billingEntity: GraphQL.SubscriptionsSellerCode, readonly billingDetails: { readonly __typename?: 'AccountBillingDetails', readonly emails: ReadonlyArray<string>, readonly vatNumber: string | null, readonly customerAddress: { readonly __typename?: 'BillingDetailsAddress', readonly address: string, readonly city: string, readonly country: string, readonly name: string, readonly postalCode: string, readonly state: string | null }, readonly tax: { readonly __typename?: 'Tax', readonly country: string | null, readonly id: CK.ID, readonly rate: CK.Percentage, readonly type: GraphQL.TaxType | null } } | null } | null } } };


export const UpdateCustomerDetailsInBillingTabDocument = gql`
    mutation UpdateCustomerDetailsInBillingTab($accountId: AccountId!, $customerDetails: Array!) {
  StoreCustomerDetails(accountId: $accountId, customerDetails: $customerDetails) {
    query {
      account(id: $accountId) {
        id
        allowedCurrencies
        billingDetails {
          customerAddress {
            address
            city
            country
            name
            postalCode
            state
          }
          emails
          tax {
            country
            id
            rate
            type
          }
          vatNumber
        }
        billingEntity
      }
    }
  }
}
    `;
export type UpdateCustomerDetailsInBillingTabMutationFn = Apollo.MutationFunction<UpdateCustomerDetailsInBillingTabMutation, UpdateCustomerDetailsInBillingTabMutationVariables>;

/**
 * __useUpdateCustomerDetailsInBillingTabMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerDetailsInBillingTabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerDetailsInBillingTabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerDetailsInBillingTabMutation, { data, loading, error }] = useUpdateCustomerDetailsInBillingTabMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      customerDetails: // value for 'customerDetails'
 *   },
 * });
 */
export function useUpdateCustomerDetailsInBillingTabMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerDetailsInBillingTabMutation, UpdateCustomerDetailsInBillingTabMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerDetailsInBillingTabMutation, UpdateCustomerDetailsInBillingTabMutationVariables>(UpdateCustomerDetailsInBillingTabDocument, options);
      }
export type UpdateCustomerDetailsInBillingTabMutationHookResult = ReturnType<typeof useUpdateCustomerDetailsInBillingTabMutation>;
export type UpdateCustomerDetailsInBillingTabMutationResult = Apollo.MutationResult<UpdateCustomerDetailsInBillingTabMutation>;
export type UpdateCustomerDetailsInBillingTabMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerDetailsInBillingTabMutation, UpdateCustomerDetailsInBillingTabMutationVariables>;