import classNames from 'classnames';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import CodeValue, {
	CodeValueColorStyle,
	CodeValueWrapping,
} from './CodeValue';
import Hint, {
	HOVER_DELAY as HINT_HOVER_DELAY,
	HintAttachment,
	HintPopupVisibility,
} from '~/components/patterns/hints/hint/Hint';

import styles from './Ellipsis.module.scss';



type Props = {
	/** Alternate text shown in popup */
	altPopupText?: React.ReactNode,
	/** Custom timeout before we display or hide popup bubble */
	hoverTimeout?: number,
	children: React.ReactNode,
	popupZIndex?: number,
};



const Ellipsis: React.FC<Props> = (props) => {
	const {
		altPopupText,
		hoverTimeout = HINT_HOVER_DELAY,
		children,
		popupZIndex,
	} = props;

	const isMounted = React.useRef<boolean>(false);
	const clone = React.useRef<HTMLDivElement>();

	const closePopupTimeout = React.useRef<any | null>(null);
	const openPopupTimeout = React.useRef<any | null>(null);

	const valueRef = React.useRef<HTMLDivElement | null>(null);

	const [isTruncated, setTruncated] = React.useState(false);
	const [forcedWidth, setForcedWidth] = React.useState<number | false>(false);

	React.useEffect(
		() => {
			isMounted.current = true;

			return () => {
				isMounted.current = false;

				removeCloneElement();
			};
		},
		[],
	);

	const removeCloneElement = () => {
		clearTimeout(openPopupTimeout.current);

		if (isMounted.current) {
			setForcedWidth(false);
			setTruncated(false);
		}

		clone.current?.remove();
	};

	const stopPopupClosing = React.useCallback(
		() => {
			clearTimeout(closePopupTimeout.current);
		},
		[],
	);

	const closePopupWithTimeout = React.useCallback(
		() => {
			if (openPopupTimeout.current) {
				clearTimeout(openPopupTimeout.current);
			} else {
				closePopupTimeout.current = setTimeout(() => {
					removeCloneElement();
				}, hoverTimeout);
			}
		},
		[hoverTimeout],
	);

	const openPopupWithTimeout = () => {
		openPopupTimeout.current = setTimeout(() => {
			openPopupTimeout.current = null;

			if (!valueRef.current) {
				return false;
			}

			const domElement = valueRef.current;

			const containerWidth = domElement.offsetWidth;
			const computedStyles = window.getComputedStyle(domElement);
			const fontSize = computedStyles.fontSize;
			const textTransform = computedStyles.textTransform;

			clone.current = domElement.cloneNode(true) as HTMLDivElement;

			clone.current.style.cssText = 'font-size: ' + fontSize + '; text-transform: ' + textTransform + ';';
			clone.current.classList.add(styles.isVirtual);

			document.getElementsByTagName('body')[0]?.appendChild(clone.current);
			const fullWidth = clone.current.offsetWidth;

			if (isMounted.current) {
				setForcedWidth(containerWidth < fullWidth ? containerWidth : false);
				setTruncated(containerWidth < fullWidth);
			}
		}, hoverTimeout);
	};

	const handleMouseEnter = () => {
		if (!forcedWidth) {
			openPopupWithTimeout();
		}
	};

	const handleMouseLeave = () => {
		closePopupWithTimeout();
	};

	let content = children;
	let popupText = altPopupText ?? content;
	if (React.isValidElement(children)) {
		type CodeValueChildren = React.ReactElement<React.ComponentProps<typeof CodeValue>>;

		content = React.cloneElement(children as CodeValueChildren, {
			wrapping: CodeValueWrapping.None,
		});

		popupText = altPopupText ?? React.cloneElement(children as CodeValueChildren, {
			colorStyle: CodeValueColorStyle.Light,
			highlightType: undefined,
			wrapping: CodeValueWrapping.Word,
		});
	}


	return (
		<div
			className={classNames({
				[styles.component]: true,
			})}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			ref={valueRef}
		>
			{isTruncated ? (
				<Hint
					attachment={HintAttachment.Center}
					breakWords={true}
					className={styles.hint}
					hoverDelay={0}
					onPopupMouseEnter={stopPopupClosing}
					onPopupMouseLeave={closePopupWithTimeout}
					popup={(
						<div className={styles.popup}>
							{popupText}
						</div>
					)}
					popupVisibility={HintPopupVisibility.Always}
					popupZIndex={popupZIndex}
				>
					<div
						className={styles.hintValue}
						style={{
							width: forcedWidth || undefined,
						}}
					>
						<div className={styles.hintEllipsis}>
							{content}
						</div>
					</div>
				</Hint>
			) : (
				content
			)}
		</div>
	);
};



export default Ellipsis;
