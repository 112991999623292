import type CK from '~/types/contentking';

import {
	useAccountTypeQuery,
} from './useAccountType.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountType(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountTypeQuery,
		accountId,
	);

	return data?.account?.type ?? null;
}



export default useAccountType;
