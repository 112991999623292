import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import AccountAccessLevelName from '~/components/names/AccountAccessLevelName';
import AttachedIcon from '~/components/patterns/structuredValues/AttachedIcon';
import CancelConnectedAccountAccessRequestConfirmationModal from '~/components/app/CancelConnectedAccountAccessRequestConfirmationModal';
import InternalLink from '~/components/patterns/links/InternalLink';
import MultiselectFieldFilter from '../datatables/MultiselectFieldFilter';
import PlainTextFieldFilter from '../datatables/PlainTextFieldFilter';
import RemoveAccessToConnectedAccountConfirmationModal from '~/components/app/RemoveAccessToConnectedAccountConfirmationModal';
import ResizableDatatable, {
	type ResizableDatatableColumnDefinition,
	type ResizableDatatableRowGetterInput,
} from '../datatables/ResizableDatatable';
import StatusFlag, {
	StatusFlagStatus,
} from '~/components/patterns/statuses/StatusFlag';
import Ellipsis from '~/components/patterns/values/Ellipsis';

import useModals from '~/hooks/useModals';

import {
	ConnectedClientsStatus,
	determineConnectionStatus,
} from '~/model/accountAccess';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';

import {
	notEmpty,
} from '~/utilities/typeCheck';



export enum ConnectedAccountsOverviewTableColumn {
	AccessRights = 'accessRights',
	Action = 'action',
	Name = 'name',
	Status = 'status',
}



const messages = defineMessages({
	actionCancelRequest: {
		id: 'ui.connectedAccounts.overview.action.cancelRequest',
	},
	actionRemoveAccess: {
		id: 'ui.connectedAccounts.overview.action.removeAccess',
	},
	actionViewRequest: {
		id: 'ui.connectedAccounts.overview.action.viewRequest',
	},
});

const messagesColumns = defineMessages({
	[ConnectedAccountsOverviewTableColumn.AccessRights]: {
		id: 'ui.connectedAccounts.overview.table.column.accessRights',
	},
	[ConnectedAccountsOverviewTableColumn.Action]: {
		id: 'ui.connectedAccounts.overview.table.column.action',
	},
	[ConnectedAccountsOverviewTableColumn.Name]: {
		id: 'ui.connectedAccounts.overview.table.column.name',
	},
	[ConnectedAccountsOverviewTableColumn.Status]: {
		id: 'ui.connectedAccounts.overview.table.column.status',
	},
});

const messagesStatus = defineMessages({
	[ConnectedClientsStatus.AccessOffered]: {
		id: 'ui.connectedAccounts.overview.status.accessOffered',
	},
	[ConnectedClientsStatus.AccessRequested]: {
		id: 'ui.connectedAccounts.overview.status.accessRequested',
	},
	[ConnectedClientsStatus.Connected]: {
		id: 'ui.connectedAccounts.overview.status.connected',
	},
});

const statusFilterOptions = [
	{
		name: ConnectedClientsStatus.Connected,
		title: (
			<FormattedMessage {...messagesStatus[ConnectedClientsStatus.Connected]} />
		),
	},
	{
		name: ConnectedClientsStatus.AccessOffered,
		title: (
			<FormattedMessage {...messagesStatus[ConnectedClientsStatus.AccessOffered]} />
		),
	},
	{
		name: ConnectedClientsStatus.AccessRequested,
		title: (
			<FormattedMessage {...messagesStatus[ConnectedClientsStatus.AccessRequested]} />
		),
	},
];



type ConnectedAccount = {
	clientAccountId: CK.AccountId,
	clientAccountName: string,
	isInitiatedByAgency: boolean,
	isPending: boolean,
	level: GraphQL.AccountAccessConnectionLevel,
};

type Props = {
	accountId: CK.AccountId | null,
	connectedAccounts: ReadonlyArray<ConnectedAccount>,
	filter: any,
	isLoading: boolean,
	onFilterCallback: (filterUpdate: any) => void,
	onRowClick: (input: {
		row: ConnectedAccount,
		rowIndex: number,
	}) => void,
	onSortCallback: (updatedSortBy: any) => void,
	showAccessRightsColumn: boolean,
	sortBy: any,
};

const ConnectedAccountsOverviewTable: React.FC<Props> = (props) => {
	const {
		accountId,
		connectedAccounts,
		filter,
		isLoading,
		onFilterCallback,
		onRowClick,
		onSortCallback,
		showAccessRightsColumn,
		sortBy,
	} = props;

	const modals = useModals();

	const openRemoveAccessModal = React.useCallback(
		(connectedAccount: ConnectedAccount) => {
			if (accountId === null) {
				return;
			}

			modals.openModal(
				() => (
					<RemoveAccessToConnectedAccountConfirmationModal
						agencyAccountId={accountId}
						connectedAccountId={connectedAccount.clientAccountId}
						connectedAccountName={connectedAccount.clientAccountName}
					/>
				),
			);
		},
		[
			accountId,
			modals,
		],
	);

	const openCancelRequestModal = React.useCallback(
		(connectedAccount: ConnectedAccount) => {
			if (accountId === null) {
				return;
			}

			modals.openModal(
				() => (
					<CancelConnectedAccountAccessRequestConfirmationModal
						agencyAccountId={accountId}
						connectedAccountId={connectedAccount.clientAccountId}
						connectedAccountName={connectedAccount.clientAccountName}
					/>
				),
			);
		},
		[
			accountId,
			modals,
		],
	);

	const columnDefinitions: ReadonlyArray<ResizableDatatableColumnDefinition<ConnectedAccount>> = React.useMemo(
		() => [
			{
				name: ConnectedAccountsOverviewTableColumn.Name,
				render: {
					cell: ({ row }: { row: ConnectedAccount }) => (
						<Ellipsis>
							{row.clientAccountName}
						</Ellipsis>
					),
					filter: ({ filterName, filterWidth, ref }) => (
						<PlainTextFieldFilter
							// @ts-ignore
							name={filterName}
							ref={ref}
							width={filterWidth}
						/>
					),
					label: (
						<FormattedMessage {...messagesColumns[ConnectedAccountsOverviewTableColumn.Name]} />
					),
				},
				resizable: true,
				sortOrder: false,
				width: 400,
			},
			{
				name: ConnectedAccountsOverviewTableColumn.Status,
				render: {
					cell: ({ row }: { row: ConnectedAccount }) => {
						const status = determineConnectionStatus(row);

						switch (status) {

							case ConnectedClientsStatus.Connected:
								return (
									<AttachedIcon
										icon={<StatusFlag status={StatusFlagStatus.Normal} />}
									>
										<FormattedMessage {...messagesStatus[status]} />
									</AttachedIcon>
								);

							case ConnectedClientsStatus.AccessOffered:
							case ConnectedClientsStatus.AccessRequested:
								return (
									<AttachedIcon
										icon={<StatusFlag status={StatusFlagStatus.Waiting} />}
									>
										<FormattedMessage {...messagesStatus[status]} />
									</AttachedIcon>
								);

							default:
								return null;

						}
					},
					filter: ({ filterName, filterWidth, ref }) => (
						<MultiselectFieldFilter
							isOnlyLinkVisible={true}
							name={filterName}
							options={statusFilterOptions}
							ref={ref}
							width={filterWidth}
						/>
					),
					label: (
						<FormattedMessage {...messagesColumns[ConnectedAccountsOverviewTableColumn.Status]} />
					),
				},
				sortOrder: true,
				resizable: true,
				width: 240,
			},
			showAccessRightsColumn ? {
				name: ConnectedAccountsOverviewTableColumn.AccessRights,
				render: {
					cell: ({ row }: { row: ConnectedAccount }) => (
						<Ellipsis>
							<AccountAccessLevelName level={row.level} />
						</Ellipsis>
					),
					label: (
						<FormattedMessage {...messagesColumns[ConnectedAccountsOverviewTableColumn.AccessRights]} />
					),
				},
				resizable: true,
				width: 200,
			} : null,
			{
				name: ConnectedAccountsOverviewTableColumn.Action,
				render: {
					cell: ({ row }: { row: ConnectedAccount }) => {
						const status = determineConnectionStatus(row);

						switch (status) {

							case ConnectedClientsStatus.Connected:
								return (
									<InternalLink
										onClickCallback={(event) => {
											event.stopPropagation();
											openRemoveAccessModal(row);
										}}
									>
										<FormattedMessage {...messages.actionRemoveAccess} />
									</InternalLink>
								);

							case ConnectedClientsStatus.AccessOffered:
								return (
									<InternalLink
										routeName="account.connectedAccounts.acceptAccessToClient"
										routeParams={{
											clientId: row.clientAccountId,
										}}
									>
										<FormattedMessage {...messages.actionViewRequest} />
									</InternalLink>
								);

							case ConnectedClientsStatus.AccessRequested:
								return (
									<InternalLink
										onClickCallback={(event) => {
											event.stopPropagation();
											openCancelRequestModal(row);
										}}
									>
										<FormattedMessage {...messages.actionCancelRequest} />
									</InternalLink>
								);

							default:
								return null;

						}
					},
					label: (
						<FormattedMessage {...messagesColumns[ConnectedAccountsOverviewTableColumn.Action]} />
					),
				},
				resizable: false,
				width: 200,
			},
		].filter(notEmpty),
		[
			openCancelRequestModal,
			openRemoveAccessModal,
			showAccessRightsColumn,
		],
	);

	const rowGetter = React.useCallback(
		({ rowIndex }: ResizableDatatableRowGetterInput) => getArrayItemAtSafeIndex(connectedAccounts, rowIndex),
		[
			connectedAccounts,
		],
	);

	return (
		<ResizableDatatable
			columns={columnDefinitions}
			filter={filter}
			isLoading={isLoading}
			onFilterChange={onFilterCallback}
			onRowClick={onRowClick}
			onSortChangeCallback={onSortCallback}
			rowGetter={rowGetter}
			rowsCount={connectedAccounts.length || 0}
			sortBy={sortBy}
		/>
	);
};



export default ConnectedAccountsOverviewTable;
