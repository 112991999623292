import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import FullUserName from '~/components/app/FullUserName';
import Gravatar from '~/components/patterns/users/Gravatar';
import GravatarsList from '~/components/patterns/users/GravatarsList';
import Hint from '~/components/patterns/hints/hint/Hint';
import SmallImageLabel from '~/components/patterns/images/SmallImageLabel';
import TextualList from '~/components/patterns/lists/TextualList';

import useAllUsers from '~/hooks/useAllUsers';
import useCurrentUserId from '~/hooks/useCurrentUserId';
import useUserEmail from '~/hooks/useUserEmail';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	extractInitials,
	sortUsers,
} from '~/model/users';



const messages = defineMessages({
	everybody: {
		id: 'ui.general.everybody',
	},
	nobody: {
		id: 'ui.general.nobody',
	},
});



type Props = {
	containerWidth: number,
	recipients: ReadonlyArray<string>,
};

const AlertDefinitionRecipientsList: React.FC<Props> = (props) => {
	const {
		containerWidth,
		recipients,
	} = props;

	const currentUserId = useCurrentUserId();
	const websiteId = useWebsiteId();

	const allUsers = useAllUsers();
	const currentUserEmail = useUserEmail(currentUserId);

	if (
		allUsers.isLoaded === false
		|| currentUserEmail === null
	) {
		return null;
	}

	const users = allUsers.listByWebsite(websiteId);

	const recipientUsers = users.filter((user) => recipients.includes(user.email));

	if (users.length > 1 && recipients.length === users.length) {
		return (
			<Hint
				popup={(
					<TextualList>
						{recipientUsers.map((recipient) => (
							<FullUserName
								email={recipient.email}
								firstName={recipient.firstName}
								key={recipient.email}
								lastName={recipient.lastName}
							/>
						))}
					</TextualList>
				)}
				popupOffsetSkidding={3}
				popupZIndex={2200}
			>
				<SmallImageLabel
					image={(
						<Gravatar
							email={null}
							placeholder={(
								<BasicIcon
									size={22}
									type={BasicIconType.Team}
								/>
							)}
						/>
					)}
					label={(
						<FormattedMessage {...messages.everybody} />
					)}
				/>
			</Hint>
		);
	}

	const sortedRecipientUsers = sortUsers(currentUserEmail, recipientUsers);

	return (
		<GravatarsList
			people={sortedRecipientUsers.map((recipient) => {
				return {
					gravatar: (
						<Gravatar
							email={recipient.email}
							placeholder={extractInitials(recipient.email)}
						/>
					),
					key: recipient.email,
					name: (
						<FullUserName
							email={recipient.email}
							firstName={recipient.firstName}
							lastName={recipient.lastName}
						/>
					),
				};
			})}
			placeholder={(
				<FormattedMessage {...messages.nobody} />
			)}
			width={containerWidth}
		/>
	);
};



export default AlertDefinitionRecipientsList;
