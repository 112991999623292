import type CK from '~/types/contentking';

import {
	useAccountCurrencyQuery,
} from './useAccountCurrency.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountCurrency(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountCurrencyQuery,
		accountId,
	);

	return data?.account?.currency ?? null;
}



export default useAccountCurrency;
