import React from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import Form from '~/components/atoms/forms/basis/Form';
import Hint, {
	HintAttachment,
	HintPopupSkin,
	HintPopupVisibility,
} from '~/components/patterns/hints/hint/Hint';
import HintPopupLayout from '~/components/patterns/hints/hint/HintPopupLayout';
import IntercomActivator from '~/components/logic/IntercomActivator';
import List from '~/components/patterns/lists/List';
import PlanName from '~/components/names/PlanName';
import PremiumAnnouncement from '~/components/patterns/messages/embedded/PremiumAnnouncement';
import Ribbon, {
	RibbonStyle,
	RibbonTooltipVisibility,
} from '~/components/patterns/tags/Ribbon';
import RichText from '~/components/patterns/typography/RichText';
import SubmitButton, {
	SIZE_SMALL as SUBMIT_BUTTON_SIZE_SMALL,
	STYLE_ACTION as SUBMIT_BUTTON_STYLE_ACTION,
} from '~/components/atoms/forms/components/SubmitButton';
import WhenAccountActionAllowed from '~/components/app/WhenAccountActionAllowed';

import useAccountId from '~/hooks/useAccountId';
import useAccountOutOfBandPlans from '~/hooks/useAccountOutOfBandPlans';
import useAccountPremiumTrialOffer from '~/hooks/useAccountPremiumTrialOffer';
import useAccountType from '~/hooks/useAccountType';
import useActivatePremiumTrial from '~/hooks/useActivatePremiumTrial';
import useCalculatePrice from '~/hooks/useCalculatePrice';
import usePremiumFeatureSituation from '~/hooks/usePremiumFeatureSituation';

import {
	applyBillingCycleToCost,
} from '~/model/pricing/billingCycle';

import {
	type Tariff,
} from '~/model/pricing/tariffs';

import matchAndReturn from '~/utilities/matchAndReturn';



const messages = defineMessages({
	featureRequiresPlan: {
		id: 'ui.upsell.tooltip.firstParagraph',
	},
	legacyPlanButton: {
		id: 'ui.upsell.legacy.button',
	},
	legacyPlanDescription: {
		id: 'ui.upsell.legacy.description',
	},
	outOfBandPlanUpgradeButton: {
		id: 'ui.upsell.unavailablePlan.button',
	},
	outOfBandPlanUpgradeProposition: {
		id: 'ui.upsell.unavailablePlan.enterpriseDescription',
	},
	premiumTrialAsManager: {
		id: 'ui.upsell.trial.tooltip.secondParagraph.manager',
	},
	premiumTrialAsMember: {
		id: 'ui.upsell.trial.tooltip.secondParagraph.member',
	},
	premiumTrialButton: {
		id: 'ui.upsell.trial.button',
	},
	premiumTrialEntry: {
		id: 'ui.upsell.trial.ribbon',
	},
	unavailablePlanButton: {
		id: 'ui.upsell.unavailablePlan.button',
	},
	unavailablePlanDescription: {
		id: 'ui.upsell.unavailablePlan.description',
	},
	unavailablePlanEnterpiseDescription: {
		id: 'ui.upsell.unavailablePlan.enterpriseDescription',
	},
	upgradeAsManager: {
		id: 'ui.upsell.upgrade.tooltip.secondParagraph.manager',
	},
	upgradeAsMember: {
		id: 'ui.upsell.upgrade.tooltip.secondParagraph.member',
	},
	upgradeButton: {
		id: 'ui.upsell.upgrade.button',
	},
	upgradeEntry: {
		id: 'ui.upsell.upgrade.ribbon',
	},
});



export enum PremiumFeatureSituationStyle {
	Box = 'STYLE_BOX',
	Ribbon = 'STYLE_RIBBON',
	Tooltip = 'STYLE_TOOLTIP',
}

type Props = {
	children: (childProps: {
		isFeatureAttainable: boolean,
		isFeatureEnabled: boolean,
		premiumAnnotation: React.ReactElement | null,
	}) => React.ReactElement | null,
	extraAnnotation?: React.ReactNode,
	featureAmount?: number,
	featureName: GraphQL.AccountFeature,
	hideIfUnattainable?: boolean,
	showUnavailablePlan?: boolean,
	style: PremiumFeatureSituationStyle,
};

const PremiumFeatureSituation: React.FC<Props> = (props) => {
	const {
		children,
		extraAnnotation,
		featureAmount = 1,
		featureName,
		hideIfUnattainable,
		showUnavailablePlan = false,
		style,
	} = props;

	const accountId = useAccountId();
	const accountType = useAccountType(accountId);
	const activatePremiumTrial = useActivatePremiumTrial(accountId);

	const {
		isFeatureAttainable,
		isFeatureEnabled,
		requiredPlan,
		requiredUniversalPlan,
		shouldBeOfferedIfAttainable,
	} = usePremiumFeatureSituation(featureName, featureAmount);

	const [isSubmitting, setIsSubmitting] = React.useState(false);

	const handleActivatePremiumTrial = React.useCallback(
		async () => {
			setIsSubmitting(true);

			await activatePremiumTrial();

			setIsSubmitting(false);
		},
		[
			activatePremiumTrial,
			setIsSubmitting,
		],
	);

	if (isFeatureEnabled) {
		return children({
			isFeatureAttainable,
			isFeatureEnabled,
			premiumAnnotation: null,
		});
	}

	if (!shouldBeOfferedIfAttainable) {
		return null;
	}

	if (requiredPlan === null) {
		if (accountType !== GraphQL.AccountType.Universal) {
			if (requiredUniversalPlan === null) {
				return null;
			}

			return children({
				isFeatureAttainable,
				isFeatureEnabled,
				premiumAnnotation: (
					<RequiresUniversalPlan
						accountType={accountType}
						extraAnnotation={extraAnnotation}
						isSubmitting={isSubmitting}
						requiredPlan={requiredUniversalPlan.plan}
						requiredTariff={requiredUniversalPlan.tariff}
						style={style}
					/>
				),
			});
		} else if (hideIfUnattainable) {
			return null;
		} else if (showUnavailablePlan) {
			if (requiredUniversalPlan === null) {
				return null;
			}

			return children({
				isFeatureAttainable,
				isFeatureEnabled,
				premiumAnnotation: (
					<RequiresUniversalPlan
						accountType={accountType}
						extraAnnotation={extraAnnotation}
						isSubmitting={isSubmitting}
						requiredPlan={requiredUniversalPlan.plan}
						requiredTariff={requiredUniversalPlan.tariff}
						style={style}
					/>
				),
			});
		}

		return children({
			isFeatureAttainable,
			isFeatureEnabled,
			premiumAnnotation: null,
		});
	}

	return children({
		isFeatureAttainable,
		isFeatureEnabled,
		premiumAnnotation: (
			<PremiumFeatureSituationUI
				accountId={accountId}
				extraAnnotation={extraAnnotation}
				handleActivation={handleActivatePremiumTrial}
				isSubmitting={isSubmitting}
				requiredPlan={requiredPlan.plan}
				requiredTariff={requiredPlan.tariff}
				style={style}
			>
				{''}
			</PremiumFeatureSituationUI>
		),
	});
};



type PremiumFeatureSituationUIProps = {
	accountId: CK.AccountId | null,
	children: React.ReactNode,
	extraAnnotation: React.ReactNode,
	handleActivation: () => void,
	isSubmitting: boolean,
	requiredPlan: GraphQL.AccountPlan,
	requiredTariff: Tariff,
	style: PremiumFeatureSituationStyle,
};

const PremiumFeatureSituationUI: React.FC<PremiumFeatureSituationUIProps> = (props) => {
	const {
		accountId,
		children,
		extraAnnotation,
		handleActivation,
		isSubmitting,
		requiredPlan,
		requiredTariff,
		style,
	} = props;

	const accountOutOfBandPlans = useAccountOutOfBandPlans(accountId);
	const accountPremiumTrialOffer = useAccountPremiumTrialOffer(accountId);

	const calculatePrice = useCalculatePrice(accountId);

	return (
		<WhenAccountActionAllowed
			accountId={accountId}
			action={GraphQL.ActionWithAccount.ManageDetails}
		>
			{({ isAllowed }) => {
				let ctaElement: React.ReactNode;
				let label: React.ReactNode;
				let proposition: React.ReactNode;

				if (accountPremiumTrialOffer !== null) {
					ctaElement = isAllowed.yes && (
						<Form
							onSuccess={handleActivation}
						>
							{({ isSubmitting }) => (
								<SubmitButton
									progress={isSubmitting}
									size={SUBMIT_BUTTON_SIZE_SMALL}
									style={SUBMIT_BUTTON_STYLE_ACTION}
									uppercase={true}
								>
									<FormattedMessage {...messages.premiumTrialButton} />
								</SubmitButton>
							)}
						</Form>
					);

					label = (
						<FormattedMessage {...messages.premiumTrialEntry} />
					);

					proposition = isAllowed.yes
						? (
							<FormattedMessage
								{...messages.premiumTrialAsManager}
								values={{
									days: accountPremiumTrialOffer.durationInDays,
								}}
							/>
						)
						: (
							<FormattedMessage
								{...messages.premiumTrialAsMember}
								values={{
									days: accountPremiumTrialOffer.durationInDays,
								}}
							/>
						);
				} else if (accountOutOfBandPlans !== null) {
					if (isAllowed.yes) {
						if (accountOutOfBandPlans.includes(requiredPlan)) {
							ctaElement = (
								<IntercomActivator>
									<Button
										size={ButtonSize.Small}
										style={ButtonStyle.Action}
										uppercase={true}
									>
										<FormattedMessage {...messages.outOfBandPlanUpgradeButton} />
									</Button>
								</IntercomActivator>
							);
						} else {
							ctaElement = (
								<Button
									linkRouteName="account.settings.subscription"
									linkRouteParams={{
										accountId: accountId + '',
										plan: requiredPlan,
									}}
									size={ButtonSize.Small}
									style={ButtonStyle.Action}
									uppercase={true}
								>
									<FormattedMessage {...messages.upgradeButton} />
								</Button>
							);
						}
					}

					label = (
						<FormattedMessage {...messages.upgradeEntry} />
					);

					if (isAllowed.yes) {
						if (accountOutOfBandPlans.includes(requiredPlan)) {
							proposition = (
								<FormattedMessage {...messages.outOfBandPlanUpgradeProposition} />
							);
						} else {
							const currentPrice = calculatePrice();
							const upgradePrice = calculatePrice({
								plan: requiredPlan,
							});

							if (currentPrice && upgradePrice) {
								const extraCost = applyBillingCycleToCost({
									baseBillingCycle: upgradePrice.billingCycle,
									cost: upgradePrice.total - currentPrice.total,
									newBillingCycle: GraphQL.Term.Monthly,
								});

								const isExtraCostWithDecimals = (extraCost % 1) > 0;

								proposition = (
									<FormattedMessage
										{...messages.upgradeAsManager}
										values={{
											text__price: (
												<FormattedNumber
													currency={upgradePrice.currency}
													maximumFractionDigits={isExtraCostWithDecimals ? 2 : 0}
													minimumFractionDigits={isExtraCostWithDecimals ? 2 : 0}
													style="currency"
													value={extraCost}
												/>
											),
											text__pricingPlan: (
												<PlanName
													plan={requiredPlan}
													tariff={requiredTariff}
												/>
											),
										}}
									/>
								);
							} else {
								proposition = null;
							}
						}
					} else {
						proposition = (
							<FormattedMessage {...messages.upgradeAsMember} />
						);
					}
				}

				const requiresPlanMessage = (
					<FormattedMessage
						{...messages.featureRequiresPlan}
						values={{
							text__pricingPlan: (
								<PlanName
									plan={requiredPlan}
									tariff={requiredTariff}
								/>
							),
						}}
					/>
				);

				return matchAndReturn(style, {
					[PremiumFeatureSituationStyle.Box]: () => (
						<PremiumAnnouncement
							ctaElement={ctaElement}
							title={requiresPlanMessage}
						>
							<List>
								{extraAnnotation}
								{proposition}
							</List>
						</PremiumAnnouncement>
					),
					[PremiumFeatureSituationStyle.Ribbon]: () => (
						<Ribbon
							style={RibbonStyle.Premium}
							tooltipCtaElement={ctaElement}
							tooltipMessage={(
								<List>
									{extraAnnotation}
									{requiresPlanMessage}
									{proposition}
								</List>
							)}
							tooltipVisibility={isSubmitting ? RibbonTooltipVisibility.Always : RibbonTooltipVisibility.Auto}
						>
							{label}
						</Ribbon>
					),
					[PremiumFeatureSituationStyle.Tooltip]: () => (
						<Hint
							attachment={HintAttachment.Right}
							blurDelay={200}
							popup={(
								<List>
									{extraAnnotation}
									{requiresPlanMessage}
									{proposition}
								</List>
							)}
							popupLayout={(children) => (
								<HintPopupLayout
									ctaElement={ctaElement}
								>
									{children}
								</HintPopupLayout>
							)}
							popupMaxWidth={280}
							popupSkin={HintPopupSkin.Premium}
							popupVisibility={isSubmitting ? HintPopupVisibility.Always : HintPopupVisibility.OnHover}
						>
							{children}
						</Hint>
					),
				})();
			}}
		</WhenAccountActionAllowed>
	);
};



type RequiresUniversalPlanProps = {
	accountType: GraphQL.AccountType | null,
	extraAnnotation: React.ReactNode,
	isSubmitting: boolean,
	requiredPlan: GraphQL.AccountPlan,
	requiredTariff: Tariff,
	style: PremiumFeatureSituationStyle,
};

const RequiresUniversalPlan: React.FC<RequiresUniversalPlanProps> = (props) => {
	const {
		accountType,
		extraAnnotation,
		isSubmitting,
		requiredPlan,
		requiredTariff,
		style,
	} = props;

	const accountIsLegacy = accountType !== GraphQL.AccountType.Universal;
	const requiredPlanIsEnterprise = requiredPlan === GraphQL.AccountPlan.Enterprise;

	const requiresUniversalPlanMessage = (
		<FormattedMessage
			{...messages.featureRequiresPlan}
			values={{
				text__pricingPlan: (
					<PlanName
						plan={requiredPlan}
						tariff={requiredTariff}
					/>
				),
			}}
		/>
	);

	const requiresUniversalPlanButton = (
		<IntercomActivator>
			<Button
				size={ButtonSize.Small}
				style={ButtonStyle.Action}
				uppercase={true}
			>
				{accountIsLegacy ? (
					<FormattedMessage {...messages.legacyPlanButton} />
				) : (
					<FormattedMessage {...messages.unavailablePlanButton} />
				)}
			</Button>
		</IntercomActivator>
	);

	const requiresUniversalPlanDescription = accountIsLegacy ? (
		<FormattedMessage {...messages.legacyPlanDescription} />
	) : requiredPlanIsEnterprise ? (
		<FormattedMessage {...messages.unavailablePlanEnterpiseDescription} />
	) : (
		<FormattedMessage {...messages.unavailablePlanDescription} />
	);

	return matchAndReturn(style, {
		[PremiumFeatureSituationStyle.Box]: () => (
			<PremiumAnnouncement
				ctaElement={requiresUniversalPlanButton}
				title={requiresUniversalPlanMessage}
			>
				<p>{extraAnnotation}</p>
				<p>{requiresUniversalPlanDescription}</p>
			</PremiumAnnouncement>
		),
		[PremiumFeatureSituationStyle.Ribbon]: () => (
			<Ribbon
				style={RibbonStyle.Premium}
				tooltipCtaElement={requiresUniversalPlanButton}
				tooltipMessage={(
					<>
						<p>{extraAnnotation}</p>
						<p>{requiresUniversalPlanMessage}</p>
						<p>{requiresUniversalPlanDescription}</p>
					</>
				)}
				tooltipVisibility={isSubmitting ? RibbonTooltipVisibility.Always : RibbonTooltipVisibility.Auto}
			>
				{accountIsLegacy ? (
					<FormattedMessage {...messages.legacyPlanButton} />
				) : (
					<FormattedMessage {...messages.unavailablePlanButton} />
				)}
			</Ribbon>
		),
		[PremiumFeatureSituationStyle.Tooltip]: () => (
			<Hint
				attachment={HintAttachment.Right}
				blurDelay={200}
				popup={(
					<RichText>
						<p>{extraAnnotation}</p>
						<p>{requiresUniversalPlanMessage}</p>
						<p>{requiresUniversalPlanDescription}</p>
					</RichText>
				)}
				popupLayout={(children) => (
					<HintPopupLayout
						ctaElement={requiresUniversalPlanButton}
					>
						{children}
					</HintPopupLayout>
				)}
				popupMaxWidth={280}
				popupSkin={HintPopupSkin.Premium}
				popupVisibility={isSubmitting ? HintPopupVisibility.Always : HintPopupVisibility.OnHover}
			>
				{false}
			</Hint>
		),
	})();
};



export default PremiumFeatureSituation;
