import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import Button, {
	ButtonSize,
	ButtonStyle,
	ButtonWidth,
} from '~/components/patterns/buttons/Button';
import Copy from '../../Copy';
import ListOfFeaturesInUse from '../ListOfFeaturesInUse';
import RichText from '~/components/patterns/typography/RichText';

import useAccountFeaturesUsage from '~/hooks/useAccountFeaturesUsage';
import useAccountSignup from '~/hooks/useAccountSignup';
import useNavigation from '~/hooks/useNavigation';



const messages = defineMessages({
	buttonLabel: {
		id: 'ui.plan.signUpButton.label',
	},
	featureInUseTooltip: {
		id: 'ui.featuresInUseTooltip',
	},
});



type Props = {
	dominant?: boolean,
	accountId: CK.AccountId,
	plan: GraphQL.AccountPlan,
};

const SignUpButton: React.FC<Props> = (props) => {
	const {
		dominant = false,
		accountId,
		plan,
	} = props;

	const {
		keepParameter,
		navigate,
	} = useNavigation();

	const accountFeaturesUsage = useAccountFeaturesUsage(accountId);
	const accountSignup = useAccountSignup(accountId);

	const navigateToSignupModal = React.useCallback(
		() => {
			accountSignup.setProperty('plan', plan);

			navigate({
				routeName: 'account.pricing.signup',
				routeParams: {
					accountId: keepParameter(),
				},
			});
		},
		[
			accountSignup,
			keepParameter,
			navigate,
			plan,
		],
	);

	if (
		accountFeaturesUsage === null
	) {
		return null;
	}

	const planAvailability = accountFeaturesUsage.getPlanAvailability(plan);

	return (
		<Button
			disabled={planAvailability.isAttainable === false}
			onClick={navigateToSignupModal}
			size={dominant ? ButtonSize.Large : ButtonSize.Medium}
			style={ButtonStyle.Action}
			tooltip={planAvailability.isAvailable === false && (
				<RichText>
					<Copy
						{...messages.featureInUseTooltip}
						values={{
							lostFeatures: (
								<ListOfFeaturesInUse
									lostFeatures={planAvailability.conflictingFeatures}
								/>
							),
						}}
					/>
				</RichText>
			)}
			width={dominant ? ButtonWidth.Fullwidth : ButtonWidth.Default}
		>
			<FormattedMessage {...messages.buttonLabel} />
		</Button>
	);
};



export default SignUpButton;
