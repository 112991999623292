import type Immutable from 'immutable';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';

import {
	isFunction,
	isImmutableMap,
} from './typeCheck';



type Filtering<Item, Extra> =
	(items: ReadonlyArray<Item>, value: any, extra?: Extra) => ReadonlyArray<Item>;

type Sorting<Item, Extra> =
	(items: ReadonlyArray<Item>, extra?: Extra) => ReadonlyArray<Item>;



class FilterAndSort<
	Item,
	Extra = never
> {

	private filterClauses: Array<{
		callback: Filtering<Item, Extra>,
		name: string,
	}>;

	private sortByClauses: Array<{
		callback: Sorting<Item, Extra> | null,
		name: string,
	}>;



	constructor() {
		this.filterClauses = [];
		this.sortByClauses = [];
	}



	addField(name: string, {
		filtering = null,
		sorting = null,
	}: {
		filtering?: Filtering<Item, Extra> | null,
		sorting?: Sorting<Item, Extra> | true | null,
	}): void {
		if (filtering !== null) {
			this.filterClauses.push({
				callback: filtering,
				name,
			});
		}

		if (sorting !== null) {
			this.sortByClauses.push({
				callback: isFunction(sorting) ? sorting : null,
				name,
			});
		}
	}



	createRunner() {
		type FilterArg = Record<string, unknown> | Immutable.Map<any, any> | undefined;
		type SortByArg = { key: string, direction: boolean } | Immutable.Map<any, any> | undefined;

		const runner = <RunnerItems extends ReadonlyArray<Item> | null>(
			items: RunnerItems,
			filter: FilterArg,
			sortBy: SortByArg,
			extra?: Extra,
		): RunnerItems => {
			if (items === null) {
				return items;
			}

			let result: ReadonlyArray<Item> = items;

			if (filter) {
				for (const filterClause of this.filterClauses) {
					const {
						callback,
						name,
					} = filterClause;

					if (isImmutableMap(filter)) {
						if (filter.has(name) && filter.get(name) !== null) {
							result = callback(result, filter.get(name), extra);
						}
					} else {
						if (filter[name]) {
							result = callback(result, filter[name], extra);
						}
					}
				}
			}

			if (sortBy) {
				for (const sortByClause of this.sortByClauses) {
					const {
						callback,
						name,
					} = sortByClause;

					const sortKey: string = isImmutableMap(sortBy)
						? sortBy.get('key')
						: sortBy.key;

					const sortDirection: boolean = isImmutableMap(sortBy)
						? sortBy.get('direction')
						: sortBy.direction;

					if (sortKey === name) {
						if (callback !== null) {
							result = callback([...result], extra);
						} else {
							result = _sortBy(result, name);
						}

						if (sortDirection === false) {
							result = _reverse(result);
						}
					}
				}
			}

			return result as RunnerItems;
		};

		return runner;
	}

}



export default FilterAndSort;
