import React from 'react';

import GraphQL from '~/types/graphql';

import DesktopAccountSignup from './DesktopAccountSignup';
import MobileAccountSignup from './MobileAccountSignup';

import useAccountId from '~/hooks/useAccountId';
import useAccountIsOutOfBand from '~/hooks/useAccountIsOutOfBand';
import useAccountMaximumRegularSignupPageBundle from '~/hooks/useAccountMaximumRegularSignupPageBundle';
import useAccountSignup from '~/hooks/useAccountSignup';
import useAccountTariff from '~/hooks/useAccountTariff';
import useAccountTariffCalculator from '~/hooks/useAccountTariffCalculator';
import useAllowedBillingCycles from '~/hooks/useAllowedBillingCycles';
import useAllowedPlans from '~/hooks/useAllowedPlans';
import useViewportWidth from '~/hooks/useViewportWidth';

import {
	BenefitSection,
} from '~/model/benefits';

import {
	Tariff,
} from '~/model/pricing/tariffs';

import {
	DETAIL_PLAN,
} from '~/model/pricing/universal';

import {
	isEnterprisePlan,
} from '~/model/plans';

import {
	getTariffPlans,
} from '~/model/universal';

import matchAndReturn from '~/utilities/matchAndReturn';



const V4Sections = [
	BenefitSection.MainFeatures,
	BenefitSection.AdvancedFeatures,
	BenefitSection.AdvancedAnalysis,
	BenefitSection.Integrations,
	BenefitSection.MonitoringSettings,
	BenefitSection.Collaboration,
	BenefitSection.Services,
	BenefitSection.AccessControl,
];

const C2Sections = [
	BenefitSection.MainFeatures,
	BenefitSection.AdvancedFeatures,
	BenefitSection.AdvancedAnalysis,
	BenefitSection.Integrations,
	BenefitSection.MonitoringSettings,
	BenefitSection.Services,
	BenefitSection.AccessControl,
];

const AccountSignup: React.FC = () => {
	const accountId = useAccountId();

	const accountIsOutOfBand = useAccountIsOutOfBand(accountId);
	const accountMaximumRegularSignupPageBundle = useAccountMaximumRegularSignupPageBundle(accountId);
	const accountSignup = useAccountSignup(accountId);
	const accountTariff = useAccountTariff(accountId);
	const accountTariffCalculator = useAccountTariffCalculator(accountId);
	const allowedBillingCycles = useAllowedBillingCycles(accountId);
	const allowedPlans = useAllowedPlans(accountId);
	const viewportWidth = useViewportWidth();

	if (
		accountId === null
		|| allowedBillingCycles === null
		|| allowedPlans === null
		|| accountTariff === null
		|| accountTariffCalculator.isReady === false
	) {
		return null;
	}

	const showBillingCycleConfigurator = allowedBillingCycles.length > 1;
	const showCurrencyConfigurator = accountIsOutOfBand !== true;
	const showOnlyEnterprisePlan = allowedPlans.every(isEnterprisePlan);

	const sections = matchAndReturn(accountTariff, {
		[Tariff.C1]: [],
		[Tariff.C2]: C2Sections,
		[Tariff.V2]: [],
		[Tariff.V3]: [],
		[Tariff.V4]: V4Sections,
	});

	const plans = matchAndReturn(accountTariff, {
		[Tariff.C1]: getTariffPlans(Tariff.C1),
		[Tariff.C2]: getTariffPlans(Tariff.C2),
		[Tariff.V2]: allowedPlans,
		[Tariff.V3]: allowedPlans,
		[Tariff.V4]: getTariffPlans(Tariff.V4),
	});

	const disabledPlans: {
		[K in GraphQL.AccountPlan]?: 'accountTooLarge' | 'billingCycleUnavailable' | 'disallowed' | undefined
	} = {};

	plans.forEach((plan) => {
		if (isEnterprisePlan(plan)) {
			return;
		}

		if (
			accountSignup.billingCycle === null
			|| accountSignup.pageBundle === null
		) {
			return;
		}

		const purchase = {
			details: {
				[DETAIL_PLAN]: plan,
			},
			numberOfPages: accountSignup.pageBundle,
		};

		if (!allowedPlans.includes(plan)) {
			disabledPlans[plan] = 'disallowed';
		} else if (
			accountMaximumRegularSignupPageBundle !== null
			&& accountSignup.pageBundle > accountMaximumRegularSignupPageBundle
		) {
			disabledPlans[plan] = 'accountTooLarge';
		} else if (accountTariffCalculator.tariff.arePurchaseDetailsCompatible({ billingCycle: accountSignup.billingCycle, purchase }) === false) {
			disabledPlans[plan] = 'billingCycleUnavailable';
		} else if (accountTariffCalculator.tariff.isPagesAmountCompatibleWithPurchase({ billingCycle: accountSignup.billingCycle, purchase }) === false) {
			disabledPlans[plan] = 'accountTooLarge';
		}
	});

	let bestChoicePlan: GraphQL.AccountPlan | null = null;
	let mostPopularPlan: GraphQL.AccountPlan | null = null;

	if (!allowedPlans.includes(GraphQL.AccountPlan.Enterprise)) {
		bestChoicePlan = GraphQL.AccountPlan.Pro;
		mostPopularPlan = GraphQL.AccountPlan.Standard;

		if (disabledPlans[bestChoicePlan] !== undefined) {
			bestChoicePlan = null;
		}

		if (disabledPlans[mostPopularPlan] !== undefined) {
			mostPopularPlan = null;
		}
	}

	if (viewportWidth <= 800) {
		return (
			<MobileAccountSignup
				accountId={accountId}
				bestChoicePlan={bestChoicePlan}
				disabledPlans={disabledPlans}
				mostPopularPlan={mostPopularPlan}
				plans={plans}
				sections={sections}
				showBillingCycleConfigurator={showBillingCycleConfigurator}
				showCurrencyConfigurator={showCurrencyConfigurator}
				showOnlyEnterprisePlan={showOnlyEnterprisePlan}
				tariff={accountTariff}
			/>
		);
	}

	return (
		<DesktopAccountSignup
			accountId={accountId}
			bestChoicePlan={bestChoicePlan}
			disabledPlans={disabledPlans}
			mostPopularPlan={mostPopularPlan}
			plans={plans}
			sections={sections}
			showBillingCycleConfigurator={showBillingCycleConfigurator}
			showCurrencyConfigurator={showCurrencyConfigurator}
			showOnlyEnterprisePlan={showOnlyEnterprisePlan}
			tariff={accountTariff}
		/>
	);
};



export default AccountSignup;
