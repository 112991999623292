import {
	gql,
} from '@apollo/client';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Form from '~/components/atoms/forms/basis/Form';
import MultiselectButton, {
	MultiselectButtonDropdownAttachment,
	MultiselectButtonIconType,
	type MultiselectButtonRef,
} from '~/components/patterns/buttons/MultiselectButton';
import ScopeLabel, {
	ScopeLabelStyle,
} from '~/components/logic/scopes/ScopeLabel';

import {
	useUpdateUserHiddenDashboardScopesMutation,
} from './ScopeSelectionField.gql';

import useApolloCache from '~/hooks/useApolloCache';
import useCurrentUserId from '~/hooks/useCurrentUserId';
import useHiddenDashboardScopeIdentifiers from '~/hooks/useHiddenDashboardScopeIdentifiers';
import useRoyalMode from '~/hooks/useRoyalMode';
import useUserEmail from '~/hooks/useUserEmail';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteScopes from '~/hooks/useWebsiteScopes';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	createScopeIdentifierFromId,
	createScopeIdentifierId,
} from '~/model/scopes';



const messages = defineMessages({
	label: {
		id: 'ui.scopes.multiselectField.label',
	},
	placeholder: {
		id: 'ui.scopes.multiselectField.label.placeholder',
	},
});



const ScopeSelectionField = React.forwardRef<MultiselectButtonRef>((props, ref) => {
	const currentUserId = useCurrentUserId();
	const websiteId = useWebsiteId();

	const apolloCache = useApolloCache();
	const currentUserEmail = useUserEmail(currentUserId);
	const hiddenDashboardScopeIdentifiers = useHiddenDashboardScopeIdentifiers();
	const royalMode = useRoyalMode();
	const scopes = useWebsiteScopes();
	const segments = useWebsiteSegmentDefinitions(websiteId);

	const [
		updateHiddenDashboardScopes,
	] = useUpdateUserHiddenDashboardScopesMutation();

	const isLoading = (
		hiddenDashboardScopeIdentifiers === null
		|| scopes === null
		|| segments.isLoaded === false
	);

	const options = React.useMemo(
		() => {
			if (scopes === null) {
				return [];
			}

			return scopes.map((scope) => ({
				name: createScopeIdentifierId(scope, segments.listAll()),
				title: (
					<ScopeLabel
						scope={scope}
						showHint={false}
						style={ScopeLabelStyle.Label}
					/>
				),
			}));
		},
		[
			scopes,
			segments,
		],
	);

	const defaultValues = React.useMemo(
		() => {
			return {
				scopes: options
					.filter(({ name }) => !hiddenDashboardScopeIdentifiers?.includes(name))
					.map(({ name }) => name),
			};
		},
		[
			hiddenDashboardScopeIdentifiers,
			options,
		],
	);

	const handleFormChange = React.useCallback(
		(field: string, selectedScopeIdentifierIds) => {
			if (
				field !== 'scopes'
				|| !hiddenDashboardScopeIdentifiers
				|| currentUserEmail === null
			) {
				return;
			}

			const newHiddenDashboardScopeIdentifiers = options
				.filter(({ name }) => selectedScopeIdentifierIds.indexOf(name) === -1)
				.map(({ name }) => name);

			const otherHiddenDashboardScopeIdentifiers = hiddenDashboardScopeIdentifiers
				.filter((scopeIdentifierId) => options.find(({ name }) => name === scopeIdentifierId) === undefined);

			const newHiddenDashboardScopes = [
				...newHiddenDashboardScopeIdentifiers,
				...otherHiddenDashboardScopeIdentifiers,
			].map((scopeIdentifierId) => createScopeIdentifierFromId(scopeIdentifierId));

			if (royalMode.isImpersonated === false) {
				updateHiddenDashboardScopes({
					variables: {
						email: currentUserEmail,
						hiddenDashboardScopes: newHiddenDashboardScopes,
					},
				});
			} else {
				apolloCache.writeFragment({
					id: apolloCache.identify({
						__typename: 'User',
						legacyId: currentUserId,
					}),
					fragment: gql`
						fragment Foo on User {
							personalTweaks {
								hiddenDashboardScopes
							}
						}
					`,
					data: {
						personalTweaks: {
							hiddenDashboardScopes: newHiddenDashboardScopes,
						},
					},
				});
			}
		},
		[
			apolloCache,
			currentUserEmail,
			currentUserId,
			hiddenDashboardScopeIdentifiers,
			options,
			royalMode.isImpersonated,
			updateHiddenDashboardScopes,
		],
	);

	const calculatedWidth = options.length >= 10 ? 152 : 135;

	return (
		<Form
			defaultValues={defaultValues}
			isDisabled={isLoading}
			key={isLoading ? 'loading' : 'ready'}
			onChangeCallback={handleFormChange}
		>
			<MultiselectButton
				dropdownAttachment={MultiselectButtonDropdownAttachment.Right}
				iconType={MultiselectButtonIconType.Scope}
				label={({ selectedOptions }) => (
					isLoading ? (
						<FormattedMessage {...messages.placeholder} />
					) : (
						<FormattedMessage
							{...messages.label}
							values={{
								numberOfAllScopes: options.length,
								numberOfSelectedScopes: selectedOptions.length,
							}}
						/>
					)
				)}
				name="scopes"
				options={options}
				ref={ref}
				width={calculatedWidth}
			/>
		</Form>
	);
});



export default ScopeSelectionField;

export {
	MultiselectButtonRef as ScopeSelectionFieldRef,
};
