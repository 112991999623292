import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type GraphQL from '~/types/graphql';

import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import Copy from '~/components/logic/Copy';
import ListOfFeaturesInUse from '~/components/logic/account/ListOfFeaturesInUse';
import PlanName from '~/components/names/PlanName';



const messages = defineMessages({
	content: {
		id: 'ui.downgradeBox.content',
	},
	title: {
		id: 'ui.downgradeBox.title',
	},
});



type Props = {
	lostFeatures: ReadonlyArray<{
		featureAmount: number,
		featureName: GraphQL.AccountFeature,
	}>,
	plan: GraphQL.AccountPlan,
};

const FeaturesInUseWarning: React.FC<Props> = (props) => {
	const {
		lostFeatures,
		plan,
	} = props;

	return (
		<CalloutMessage
			borders={true}
			message={(
				<FormattedMessage {...messages.title} />
			)}
			status={CalloutMessageStatus.Warning}
		>
			<Copy
				{...messages.content}
				values={{
					betterPlan: (
						<PlanName plan={plan} />
					),
					lostFeatures: (
						<ListOfFeaturesInUse
							lostFeatures={lostFeatures}
						/>
					),
				}}
			/>
		</CalloutMessage>
	);
};



export default FeaturesInUseWarning;
