import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BenefitName from './BenefitName';

import {
	Benefit,
} from '~/model/benefits';



const messages = defineMessages({
	adobeAnalyticsIntegration: {
		id: 'ui.pricing.agency.benefits.adobeAnalyticsIntegration',
	},
	cobranding: {
		id: 'ui.pricing.agency.benefits.customLogo',
	},
	coreWebVitalsOriginSummary: {
		id: 'ui.pricing.agency.benefits.coreWebVitalsOriginSummary',
	},
	customAlertTrigger: {
		id: 'ui.pricing.agency.benefits.customAlertTrigger',
	},
	customElements: {
		id: 'ui.pricing.agency.benefits.customElements',
	},
	dataRetentionDays: {
		id: 'ui.pricing.agency.benefits.dataRetentionDays',
	},
	dataRetentionMonths: {
		id: 'ui.pricing.agency.benefits.dataRetentionMonths',
	},
	googleLookerStudio: {
		id: 'ui.pricing.agency.benefits.googleLookerStudio',
	},
	issuesConfiguration: {
		id: 'ui.pricing.agency.benefits.issuesConfiguration',
	},
	lighthouseMonitoring: {
		id: 'ui.pricing.agency.benefits.lighthouseMonitoring',
	},
	logFileAnalysis: {
		id: 'ui.pricing.agency.benefits.logFileAnalysis',
	},
	microsoftTeams: {
		id: 'ui.pricing.agency.benefits.microsoftTeams',
	},
	realtimeIndexNow: {
		id: 'ui.pricing.agency.benefits.realtimeIndexNow',
	},
	rendering: {
		id: 'ui.pricing.agency.benefits.rendering',
	},
	reportingApi: {
		id: 'ui.pricing.agency.benefits.reportingAPI',
	},
	slack: {
		id: 'ui.pricing.agency.benefits.slack',
	},
	unlimitedUsers: {
		id: 'ui.pricing.agency.benefits.unlimitedUsers',
	},
	unlimitedWebsites: {
		id: 'ui.pricing.agency.benefits.unlimitedWebsites',
	},
});



type Props = {
	amount: number,
	disabled: boolean,
	name: Benefit,
};

const BenefitNameInPlan: React.FC<Props> = (props) => {
	const {
		amount,
		disabled,
		name,
	} = props;

	if (name === Benefit.AdobeAnalytics) {
		return (
			<FormattedMessage
				{...messages.adobeAnalyticsIntegration}
				values={{
					enabled: disabled ? 'no' : 'yes',
				}}
			/>
		);
	} else if (name === Benefit.Cobranding) {
		return (
			<FormattedMessage {...messages.cobranding} />
		);
	} else if (name === Benefit.CoreWebVitalsOriginSummary) {
		return (
			<FormattedMessage
				{...messages.coreWebVitalsOriginSummary}
				values={{
					enabled: disabled ? 'no' : 'yes',
				}}
			/>
		);
	} else if (name === Benefit.CustomAlertTrigger) {
		return (
			<FormattedMessage
				{...messages.customAlertTrigger}
				values={{
					enabled: disabled ? 'no' : 'yes',
				}}
			/>
		);
	} else if (name === Benefit.CustomElements) {
		return (
			<FormattedMessage
				{...messages.customElements}
				values={{
					number: amount,
				}}
			/>
		);
	} else if (name === Benefit.DataRetentionDays) {
		return (
			<FormattedMessage
				{...messages.dataRetentionDays}
				values={{
					days: amount,
				}}
			/>
		);
	} else if (name === Benefit.DataRetentionMonths) {
		return (
			<FormattedMessage
				{...messages.dataRetentionMonths}
				values={{
					months: amount,
				}}
			/>
		);
	} else if (name === Benefit.GoogleLookerStudio) {
		return (
			<FormattedMessage
				{...messages.googleLookerStudio}
				values={{
					enabled: disabled ? 'no' : 'yes',
				}}
			/>
		);
	} else if (name === Benefit.IssuesConfiguration) {
		return (
			<FormattedMessage
				{...messages.issuesConfiguration}
				values={{
					enabled: disabled ? 'no' : 'yes',
				}}
			/>
		);
	} else if (name === Benefit.LighthouseMonitoring) {
		return (
			<FormattedMessage
				{...messages.lighthouseMonitoring}
				values={{
					enabled: disabled ? 'no' : 'yes',
				}}
			/>
		);
	} else if (name === Benefit.LogFileAnalysis) {
		return (
			<FormattedMessage
				{...messages.logFileAnalysis}
				values={{
					enabled: disabled ? 'no' : 'yes',
				}}
			/>
		);
	} else if (name === Benefit.MicrosoftTeams) {
		return (
			<FormattedMessage
				{...messages.microsoftTeams}
				values={{
					enabled: disabled ? 'no' : 'yes',
				}}
			/>
		);
	} else if (name === Benefit.RealtimeIndexNow) {
		return (
			<FormattedMessage
				{...messages.realtimeIndexNow}
				values={{
					enabled: disabled ? 'no' : 'yes',
				}}
			/>
		);
	} else if (name === Benefit.Rendering) {
		return (
			<FormattedMessage
				{...messages.rendering}
				values={{
					enabled: disabled ? 'no' : 'yes',
				}}
			/>
		);
	} else if (name === Benefit.ReportingApi) {
		return (
			<FormattedMessage
				{...messages.reportingApi}
				values={{
					enabled: disabled ? 'no' : 'yes',
				}}
			/>
		);
	} else if (name === Benefit.Slack) {
		return (
			<FormattedMessage
				{...messages.slack}
				values={{
					enabled: disabled ? 'no' : 'yes',
				}}
			/>
		);
	} else if (name === Benefit.UnlimitedUsers) {
		return (
			<FormattedMessage {...messages.unlimitedUsers} />
		);
	} else if (name === Benefit.UnlimitedWebsites) {
		return (
			<FormattedMessage {...messages.unlimitedWebsites} />
		);
	}

	return (
		<BenefitName benefit={name} />
	);
};



export default BenefitNameInPlan;
