import React from 'react';



function useDataLoading(
	loadDataCallback: (offset: number, lastSynced: number) => Promise<{
		skip: boolean,
	}>,
	context: ReadonlyArray<any>,
) {
	const loadDataDelay = 500;
	const syncInterval = 1_000;

	const isMounted = React.useRef(true);

	const lastSyncedRef = React.useRef(Date.now());
	const loadTimeoutRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);
	const synchronizationTimeoutRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);
	const offsetRef = React.useRef(0);

	const triggerLoadRef = React.useRef<(offset: number) => Promise<void>>();

	React.useEffect(
		() => {
			offsetRef.current = 0;
			lastSyncedRef.current = Date.now();

			triggerLoadRef.current?.(0);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		context,
	);

	triggerLoadRef.current = React.useCallback(
		async (offset: number) => {
			if (!isMounted.current) {
				return;
			}

			if (synchronizationTimeoutRef.current) {
				clearTimeout(synchronizationTimeoutRef.current);
				synchronizationTimeoutRef.current = null;
			}

			const status = await loadDataCallback(offset, lastSyncedRef.current);

			if (status.skip === false) {
				lastSyncedRef.current = Date.now();
			}

			if (offset === offsetRef.current) {
				synchronizationTimeoutRef.current = setTimeout(
					() => {
						synchronizationTimeoutRef.current = null;

						triggerLoadRef.current?.(offset);
					},
					syncInterval,
				);
			}
		},
		[
			loadDataCallback,
		],
	);

	React.useEffect(
		() => {
			triggerLoadRef.current?.(0);

			return () => {
				isMounted.current = false;

				if (loadTimeoutRef.current) {
					clearTimeout(loadTimeoutRef.current);
				}

				if (synchronizationTimeoutRef.current) {
					clearTimeout(synchronizationTimeoutRef.current);
				}
			};
		},
		[],
	);

	return React.useCallback(
		({ rowIndex, rowStartIndex }: { rowIndex?: number, rowStartIndex?: number }) => {
			const row = rowIndex ?? rowStartIndex ?? null;

			if (row === null) {
				return;
			}

			offsetRef.current = row;

			if (loadTimeoutRef.current) {
				clearTimeout(loadTimeoutRef.current);
			}

			offsetRef.current = row;

			loadTimeoutRef.current = setTimeout(
				() => {
					triggerLoadRef.current?.(row);
				},
				loadDataDelay,
			);
		},
		[],
	);
}



export default useDataLoading;
