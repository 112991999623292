import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import AnnouncementPopup from '~/components/patterns/messages/popup/AnnouncementPopup';
import Button, {
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import Copy from '~/components/logic/Copy';
import InsufficientPermissionMessage from '~/components/app/InsufficientPermissionMessage';

import useAccountCanSelfServiceSignupRemoveWebsiteLimit from '~/hooks/useAccountCanSelfServiceSignupRemoveWebsiteLimit';
import useAccountWebsiteRestrictions from '~/hooks/useAccountWebsiteRestrictions';
import useIntercom from '~/hooks/useIntercom';



const messages = defineMessages({
	insufficientPermissionsForKingdomAdmin: {
		id: 'ui.general.notAllowedBecauseInsufficientRoyaltyLevel',
	},
});

const messagesChurnedAccount = defineMessages({
	button: {
		id: 'ui.websites.new.overlay.churnedAccount.button',
	},
	description: {
		id: 'ui.websites.new.overlay.churnedAccount.description',
	},
	title: {
		id: 'ui.websites.new.overlay.churnedAccount.title',
	},
});

const messagesDisallowedManagement = defineMessages({
	button: {
		id: 'ui.websites.new.overlay.disallowedManagement.button',
	},
	description: {
		id: 'ui.websites.new.overlay.disallowedManagement.description',
	},
	title: {
		id: 'ui.websites.new.overlay.disallowedManagement.title',
	},
});

const messagesMaximumWebsitesReached = defineMessages({
	button: {
		id: 'ui.websites.new.overlay.maximumWebsitesReached.button',
	},
	description: {
		id: 'ui.websites.new.overlay.maximumWebsitesReached.description',
	},
	title: {
		id: 'ui.websites.new.overlay.maximumWebsitesReached.title',
	},
});

const messagesPaymentIssues = defineMessages({
	button: {
		id: 'ui.websites.new.overlay.paymentIssues.button',
	},
	description: {
		id: 'ui.websites.new.overlay.paymentIssues.description',
	},
	title: {
		id: 'ui.websites.new.overlay.paymentIssues.title',
	},
});



type DisabledOverlayDueToChurnedAccountProps = {
	accountId: CK.AccountId,
};

export const DisabledOverlayDueToChurnedAccount: React.FC<DisabledOverlayDueToChurnedAccountProps> = (props) => {
	const {
		accountId,
	} = props;

	return (
		<AnnouncementPopup
			ctaElement={(
				<Button
					linkRouteName="account.pricing"
					linkRouteParams={{
						accountId,
					}}
					style={ButtonStyle.Action}
				>
					<FormattedMessage {...messagesChurnedAccount.button} />

				</Button>
			)}
			title={(
				<FormattedMessage {...messagesChurnedAccount.title} />
			)}
		>
			<Copy {...messagesChurnedAccount.description} />
		</AnnouncementPopup>
	);
};



export const DisabledOverlayDueToDisallowedManagement: React.FC = () => {
	const intercom = useIntercom();

	return (
		<AnnouncementPopup
			ctaElement={(
				<Button
					onClick={intercom.showIntercom}
					style={ButtonStyle.Action}
				>
					<FormattedMessage {...messagesDisallowedManagement.button} />

				</Button>
			)}
			title={(
				<FormattedMessage {...messagesDisallowedManagement.title} />
			)}
		>
			<Copy {...messagesDisallowedManagement.description} />
		</AnnouncementPopup>
	);
};



export const DisabledOverlayDueToInsufficientPermissions: React.FC = () => (
	<AnnouncementPopup
		title={(
			<InsufficientPermissionMessage />
		)}
	/>
);



export const DisabledOverlayDueToInsufficientPermissionsForKingdomAdmin: React.FC = () => (
	<AnnouncementPopup
		title={(
			<FormattedMessage {...messages.insufficientPermissionsForKingdomAdmin} />
		)}
	/>
);



type DisabledOverlayDueToMaximumWebsitesReachedProps = {
	accountId: CK.AccountId,
};

export const DisabledOverlayDueToMaximumWebsitesReached: React.FC<DisabledOverlayDueToMaximumWebsitesReachedProps> = (props) => {
	const {
		accountId,
	} = props;

	const accountWebsiteRestrictions = useAccountWebsiteRestrictions(accountId);
	const canSelfServiceSignupRemoveWebsiteLimit = useAccountCanSelfServiceSignupRemoveWebsiteLimit(accountId);
	const intercom = useIntercom();

	const ctaElement = canSelfServiceSignupRemoveWebsiteLimit ? (
		<Button
			linkRouteName="account.pricing"
			linkRouteParams={{
				accountId,
			}}
			style={ButtonStyle.Action}
		>
			<FormattedMessage {...messagesMaximumWebsitesReached.button} />
		</Button>
	) : (
		<Button
			onClick={intercom.showIntercom}
			style={ButtonStyle.Action}
		>
			<FormattedMessage {...messagesDisallowedManagement.button} />

		</Button>
	);

	return (
		<AnnouncementPopup
			ctaElement={ctaElement}
			title={(
				<FormattedMessage {...messagesMaximumWebsitesReached.title} />
			)}
		>
			<Copy
				{...messagesMaximumWebsitesReached.description}
				values={{
					canSelfServiceSignupRemoveWebsiteLimit: canSelfServiceSignupRemoveWebsiteLimit ? 'yes' : 'no',
					count__maximumWebsites: accountWebsiteRestrictions?.maximumWebsites ?? 0,
				}}
			/>
		</AnnouncementPopup>
	);
};



type DisabledOverlayDueToPaymentIssuesProps = {
	accountId: CK.AccountId,
};

export const DisabledOverlayDueToPaymentIssues: React.FC<DisabledOverlayDueToPaymentIssuesProps> = (props) => {
	const {
		accountId,
	} = props;

	return (
		<AnnouncementPopup
			ctaElement={(
				<Button
					linkRouteName="account.settings.billing"
					linkRouteParams={{
						accountId,
					}}
					style={ButtonStyle.Action}
				>
					<FormattedMessage {...messagesPaymentIssues.button} />
				</Button>
			)}
			title={(
				<FormattedMessage {...messagesPaymentIssues.title} />
			)}
		>
			<Copy {...messagesPaymentIssues.description} />
		</AnnouncementPopup>
	);
};
