import type CK from '~/types/contentking';

import {
	useAccountDefaultCountryQuery,
} from './useAccountDefaultCountry.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountDefaultCountry(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountDefaultCountryQuery,
		accountId,
	);

	return data?.account?.defaultCountry ?? null;
}



export default useAccountDefaultCountry;
