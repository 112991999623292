import type CK from '~/types/contentking';

import {
	useAccountNameQuery,
} from './useAccountName.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountName(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountNameQuery,
		accountId,
	);

	return data?.account?.name ?? null;
}



export default useAccountName;
