import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import OnboardingGuide from '~/components/app/OnboardingGuide';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';

import useAccountId from '~/hooks/useAccountId';
import useCurrentUserId from '~/hooks/useCurrentUserId';
import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';
import useUserAccountMembership from '~/hooks/useUserAccountMembership';
import useUserFirstName from '~/hooks/useUserFirstName';
import useUserHasName from '~/hooks/useUserHasName';



const messages = defineMessages({
	titleWithName: {
		id: 'ui.onboardingGuideModal.titleWithName',
	},
	titleWithoutName: {
		id: 'ui.onboardingGuideModal.titleWithoutName',
	},
});



type Props = {
	closeCallback: () => void,
};



const OnboardingGuideModal: React.FC<Props> = (props) => {
	const {
		closeCallback,
	} = props;

	const kingdomAdminFeatures = useKingdomAdminFeatures();

	const accountId = useAccountId();
	const currentUserId = useCurrentUserId();

	const currentUserAndAccountMembership = useUserAccountMembership(accountId, currentUserId);
	const currentUserFirstName = useUserFirstName(currentUserId);
	const currentUserHasName = useUserHasName(currentUserId);

	if (
		accountId === null
		|| currentUserAndAccountMembership === null
		|| currentUserHasName === null
	) {
		return null;
	}

	return (
		<SimpleModal
			canBeClosed={kingdomAdminFeatures.areVisible}
			closeCallback={closeCallback}
			size={SimpleModalSize.Medium}
			title={(
				currentUserFirstName !== null ? (
					<FormattedMessage
						{...messages.titleWithName}
						values={{ name: currentUserFirstName }}
					/>
				) : (
					<FormattedMessage {...messages.titleWithoutName} />
				)
			)}
		>
			<OnboardingGuide
				accountId={accountId}
				currentUserHasName={currentUserHasName}
				currentUserIsOwner={currentUserAndAccountMembership.isOwner}
				onCloseCallback={closeCallback}
			/>
		</SimpleModal>
	);
};



export default OnboardingGuideModal;
