import type CK from '~/types/contentking';

import {
	useAccountCanSelfServiceSignupRemoveWebsiteLimitQuery,
} from './useAccountCanSelfServiceSignupRemoveWebsiteLimit.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountCanSelfServiceSignupRemoveWebsiteLimit(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountCanSelfServiceSignupRemoveWebsiteLimitQuery,
		accountId,
	);

	return data?.account?.canSelfServiceSignupRemoveWebsiteLimit ?? null;
}



export default useAccountCanSelfServiceSignupRemoveWebsiteLimit;
