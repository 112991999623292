import type Immutable from 'immutable';
import React from 'react';

import MissingValue from '~/components/app/MissingValue';
import WithSeparator from '~/components/atoms/WithSeparator';

import {
	isImmutableList,
} from '~/utilities/typeCheck';



type Props = {
	formatter?: (value: any) => React.ReactNode,
	value: Immutable.List<any> | ReadonlyArray<any> | null,
};

const ConcatenateFormatter: React.FC<Props> = (props) => {
	const {
		formatter,
		value,
	} = props;

	let size: number;

	if (value === null) {
		size = 0;
	} else if (isImmutableList(value)) {
		size = value.size;
	} else {
		size = value.length;
	}

	if (value === null || size === 0) {
		return (
			<MissingValue />
		);
	}

	return (
		<WithSeparator separator=", ">
			{formatter ? value.map((subValue, index) => {
				return (
					<span key={index}>
						{formatter(subValue)}
					</span>
				);
			}) : value}
		</WithSeparator>
	);
};



export default React.memo(ConcatenateFormatter);
