import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import useAccountBillingCycle from '~/hooks/useAccountBillingCycle';
import useAccountCurrency from '~/hooks/useAccountCurrency';
import useAccountDetailedDiscounts from '~/hooks/useAccountDetailedDiscounts';
import useAccountPlan from '~/hooks/useAccountPlan';
import useAccountTariff from '~/hooks/useAccountTariff';
import useAccountTaxRate from '~/hooks/useAccountTaxRate';
import usePageBundle from '~/hooks/usePageBundle';
import useTariffs from '~/hooks/useTariffs';

import {
	DETAIL_PLAN,
} from '~/model/pricing/universal';

import {
	calculatePurchaseCostDetails,
} from '~/model/pricing/costs';



type Input = {
	billingCycle?: GraphQL.Term | null,
	pageBundle?: number | null,
	plan?: GraphQL.AccountPlan | null,
};



function useCalculatePrice(accountId: CK.AccountId | null) {
	const accountBillingCycle = useAccountBillingCycle(accountId);
	const accountCurrency = useAccountCurrency(accountId);
	const accountDiscounts = useAccountDetailedDiscounts(accountId);
	const accountPageBundle = usePageBundle(accountId);
	const accountPlan = useAccountPlan(accountId);
	const accountTariff = useAccountTariff(accountId);
	const accountTax = useAccountTaxRate(accountId);
	const tariffs = useTariffs();

	return React.useCallback(
		({
			billingCycle,
			pageBundle,
			plan,
		}: Input = {}) => {
			if (
				accountCurrency === null
				|| accountDiscounts === null
				|| accountTariff === null
				|| tariffs.isReady === false
			) {
				return null;
			}

			billingCycle ??= accountBillingCycle;
			pageBundle ??= accountPageBundle;
			plan ??= accountPlan;

			if (
				billingCycle === null
				|| pageBundle === null
				|| plan === null
			) {
				return null;
			}

			const discounts = accountDiscounts.map((discount) => {
				if (discount.type === GraphQL.DiscountType.Dollars) {
					if (discount.amount === null) {
						throw new Error(`discount.amount can't be null on Dollars discount`);
					}

					return {
						amount: Object.fromEntries(
							discount.amount.map(({ currency, value }) => [currency, value / 100]),
						) as Record<GraphQL.Currency, number>,
						code: discount.coupon,
						type: discount.type,
					};
				}

				if (discount.percentage === null) {
					throw new Error(`discount.rate can't be null on Percent discount`);
				}

				return {
					code: discount.coupon,
					rate: discount.percentage / 100,
					type: discount.type,
				};
			});

			return calculatePurchaseCostDetails({
				billingCycle,
				currency: accountCurrency,
				discounts,
				purchases: [
					{
						details: {
							[DETAIL_PLAN]: plan,
						},
						numberOfPages: pageBundle,
					},
				],
				tariff: accountTariff,
				tariffs: tariffs.tariffs,
				taxRate: accountTax?.rate ?? 0.0,
				taxType: accountTax?.type ?? null,
			});
		},
		[
			accountBillingCycle,
			accountCurrency,
			accountDiscounts,
			accountPageBundle,
			accountPlan,
			accountTariff,
			accountTax,
			tariffs.isReady,
			tariffs.tariffs,
		],
	);
}



export default useCalculatePrice;
