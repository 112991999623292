import Immutable from 'immutable';
import intersection from 'lodash/intersection';
import _isArray from 'lodash/isArray';
import _isObject from 'lodash/isObject';
import _isFunction from 'lodash/isFunction';



export function isImmutableMap<K = any, V = any>(value: any): value is Immutable.Map<K, V> {
	return value instanceof Immutable.Map;
}

export function isImmutableList<V = any>(value: any): value is Immutable.List<V> {
	return value instanceof Immutable.List;
}

export function isNumber(value: any): value is number {
	return typeof value === 'number';
}

export function isInteger(value: any): value is number {
	return isNumber(value) && Number.isInteger(value);
}

export function isNumerical(value: any): boolean {
	return !isNaN(value);
}

type FunctionLike = ((...rest) => any);
export function isFunction<T = FunctionLike>(value: any): value is T {
	return _isFunction(value);
}

export function isArray<T = any>(value: any): value is Array<T> {
	return _isArray(value);
}

export function isObject<T = any>(value: any): value is Record<string, T> {
	return _isObject(value);
}

export function isString(value: any): value is string {
	return typeof value === 'string';
}

export function isNonEmptyString(value: any): value is string {
	return isString(value) && value.trim().length > 0;
}

export function isDate(value: any): value is Date {
	return value instanceof Date;
}

export function isEquivalent(
	inputA: any,
	inputB: any,
): boolean {
	if (inputA === inputB) {
		return true;
	}

	if (isDate(inputA) && isDate(inputB)) {
		return inputA.getTime() === inputB.getTime();
	}

	// We already checked plain equality in the first step of this method. If we and up here,
	// and one of the inputs is a number or a string, we know that the inputs are not equivalent.
	if (isNumber(inputA) || isNumber(inputB) || isString(inputA) || isString(inputB)) {
		return false;
	}

	const isInputAArrayish = isArray(inputA) || isImmutableList(inputA) || inputA instanceof Set;
	const isInputBArrayish = isArray(inputB) || isImmutableList(inputB) || inputB instanceof Set;

	if (isInputAArrayish && isInputBArrayish) {
		return isEquivalentArray(inputA, inputB);
	}

	const isInputAObjectish = isObject(inputA) || isImmutableMap(inputA) || inputA instanceof Map;
	const isInputBObjectish = isObject(inputB) || isImmutableMap(inputB) || inputB instanceof Map;

	if (isInputAObjectish && isInputBObjectish) {
		return isEquivalentObject(inputA, inputB);
	}

	return false;
}

export function isEquivalentArray(
	inputA: Array<any> | Set<any> | Immutable.List<any>,
	inputB: Array<any> | Set<any> | Immutable.List<any>,
) {
	const arrayA = (
		isImmutableList(inputA)
			? inputA.toArray()
			: inputA instanceof Set
				? Array.from(inputA)
				: isArray(inputA)
					? inputA
					: null
	);

	const arrayB = (
		isImmutableList(inputB)
			? inputB.toArray()
			: inputB instanceof Set
				? Array.from(inputB)
				: isArray(inputB)
					? inputB
					: null
	);

	if (arrayA === null || arrayB === null) {
		return false;
	}

	if (arrayA.length !== arrayB.length) {
		return false;
	}

	return intersection(arrayA, arrayB).length === arrayA.length;
}

export function isEquivalentObject(
	inputA: Record<string, any> | Map<string, any> | Immutable.Map<string, any>,
	inputB: Record<string, any> | Map<string, any> | Immutable.Map<string, any>,
) {
	const keysA = (
		isImmutableMap(inputA)
			? inputA.keySeq().toArray()
			: inputA instanceof Map
				? inputA.keys().toArray()
				: Object.keys(inputA)
	);

	const keysB = (
		isImmutableMap(inputB)
			? inputB.keySeq().toArray()
			: inputB instanceof Map
				? inputB.keys().toArray()
				: Object.keys(inputB)
	);

	if (keysA.length !== keysB.length) {
		return false;
	}

	for (const key of keysA) {
		const valueA = ('get' in inputA) ? inputA.get(key) : inputA[key];
		const valueB = ('get' in inputB) ? inputB.get(key) : inputB[key];

		if (!isEquivalent(valueA, valueB)) {
			return false;
		}
	}

	return true;
}

export function notEmpty<T = any>(value: T | null | undefined): value is T {
	return value !== null && value !== undefined;
}

export function isNotFalse<T = any>(value: T | false): value is T {
	return value !== false;
}

export function assert(condition: unknown, message: string = 'Assertion failed'): asserts condition {
	if (condition === false) {
		throw new Error(message);
	}
}

export function assertDefined<T = any>(value: T): asserts value is NonNullable<T> {
	if (value === undefined || value === null) {
		throw new Error('Value is not defined');
	}
}

export function assertString(value: any): asserts value is string {
	if (!isString(value)) {
		throw new Error('Value is not a string');
	}
}

export function assertProperty(object: any, property: string): asserts object is Record<string, any> {
	if (!(property in object)) {
		throw new Error(`Property "${property}" is missing`);
	}
}
