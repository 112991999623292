import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AuthorizePaymentIframe from '../../AuthorizePaymentIframe';
import BlankSlateOverlay from './BlankSlateOverlay';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import ButtonsGroup from '~/components/patterns/buttons/ButtonsGroup';
import ButtonsLayout, {
	ButtonsLayoutType,
	ButtonsLayoutUsageContext,
} from '~/components/patterns/buttons/ButtonsLayout';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import DatatableBodyCell, {
	DatatableBodyCellSize,
} from '~/components/patterns/tables/datatables/cells/DatatableBodyCell';
import DatatableContainer from '~/components/patterns/tables/datatables/DatatableContainer';
import DatatableFooterBulkActionsLayout from '~/components/patterns/tables/datatables/footer/DatatableFooterBulkActionsLayout';
import DatatableHeaderCell, {
	DatatableHeaderCellSize,
} from '~/components/patterns/tables/datatables/cells/DatatableHeaderCell';
import DatatableLayout from '~/components/patterns/tables/datatables/DatatableLayout';
import DecentText, {
	DecentTextAlignment,
	DecentTextSize,
} from '~/components/patterns/typography/DecentText';
import DomainNotWhitelistedErrorMessage from '../../errorMessages/DomainNotWhitelistedErrorMessage';
import FixedHeaderGrid from '~/components/patterns/tables/datatables/FixedHeaderGrid';
import Form, {
	type FormRef,
} from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import GlobalFormMessage from '~/components/atoms/forms/basis/GlobalFormMessage';
import Hint from '~/components/patterns/hints/hint/Hint';
import MarginsList from '~/components/atoms/lists/MarginsList';
import Measurer from '~/utilities/Measurer';
import MultiStepModalStep, {
	MultiStepModalStepHeaderIconType,
} from '~/components/patterns/modals/MultiStepModalStep';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import PageBundleChangeSummary from '../PageBundleChangeSummary';
import PageCapacityField, {
	PageCapacityFieldSize,
} from '~/components/app/PageCapacityField';
import PaymentAuthorizationContext from '../../PaymentAuthorizationContext';
import PaymentAuthorizationError from '../../PaymentAuthorizationError';
import PaymentCancelButton from '../../PaymentCancelButton';
import PaymentSubmitButton from '../../PaymentSubmitButton';
import RecurlyFormErrorMessages from '~/components/app/RecurlyFormErrorMessages';
import RichText from '~/components/patterns/typography/RichText';
import Sidebar from './Sidebar';
import Spinner, {
	SpinnerSize,
} from '~/components/patterns/loaders/Spinner';
import StatusFlag, {
	StatusFlagStatus,
} from '~/components/patterns/statuses/StatusFlag';
import Spacer, {
	SpacerGap,
} from '~/components/patterns/utils/Spacer';
import TableDeleteButton from '~/components/patterns/tables/datatables/buttons/TableDeleteButton';
import TableLabel from '~/components/patterns/tables/datatables/parts/TableLabel';
import TextualCellValue from '~/components/logic/datatables/cellValues/TextualCellValue';
import UnreliableResponseName from '~/components/names/UnreliableResponseName';
import ValidationStatusMessage from './ValidationStatusMessage';

import useAccountPhase from '~/hooks/useAccountPhase';
import useAccountWebsiteCount from '~/hooks/useAccountWebsiteCount';
import useAccountWebsiteRestrictions from '~/hooks/useAccountWebsiteRestrictions';
import useAccountWebsiteSizes from '~/hooks/useAccountWebsiteSizes';
import useCreateWebsitesInBulk from '~/hooks/useCreateWebsitesInBulk';
import usePageBundle from '~/hooks/usePageBundle';
import useTariffs from '~/hooks/useTariffs';
import useValidationSession from './useValidationSession';

import {
	useDomainsSessonStagingAreaQuery,
	useStageDomainsMutation,
	useUnstageDomainsMutation,
} from './AddMultipleWebsitesStep.gql';

import FormError from '~/utilities/FormError';
import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



enum Columns {
	Status = 0,
	Website = 1,
	PageCapacity = 2,
	Action = 3,
}

export enum ValidationStatus {
	Pending,
	Failed,
	Finished,
}



const messages = defineMessages({
	applyPageCapacityButton: {
		id: 'ui.websites.new.steps.multiple.applyPageCapacityButton',
	},
	authorizationDescription: {
		id: 'ui.3dsecure.prompt',
	},
	breadcrumb: {
		id: 'ui.websites.new.steps.multiple.breadcrumb',
	},
	legalNotice: {
		id: 'ui.websites.new.legal.notice',
	},
	title: {
		id: 'ui.websites.new.steps.multiple.title',
	},
});

const errorMessages = defineMessages({
	alreadyOnOtherAccounts: {
		id: 'ui.websites.new.formErrors.alreadyOnOtherAccounts',
	},
	blacklisted: {
		id: 'ui.websites.new.formErrors.blacklisted',
	},
	targetRedirected: {
		id: 'ui.websites.new.formErrors.targetRedirected',
	},
	tooLargePageBundle: {
		id: 'ui.websites.new.formErrors.tooLargePageBundle',
	},
	maximumPageBundleExceeded: {
		id: 'ui.websites.new.formErrors.maximumPageBundleExceeded',
	},
	maximumPageCapacityExceeded: {
		id: 'ui.websites.new.formErrors.maximumPageCapacityExceeded',
	},
	websitesLimitExceeded: {
		id: 'ui.websites.new.formErrors.websitesLimitExceeded',
	},
	serverError: {
		id: 'ui.general.serverError',
	},
	unknownError: {
		id: 'ui.general.unknownError',
	},
	unreachable: {
		id: 'ui.teamDetail.websites.add.errors.unreachable',
	},
});



type Props = {
	accountId: CK.AccountId | null,
	domains?: Array<string>,
	onContinueCallback: (domains: Array<string>) => void,
};

const AddMultipleWebsitesStep: React.FC<Props> = (props) => {
	const {
		accountId,
		domains,
		onContinueCallback,
	} = props;

	const { tariff } = useTariffs();

	const accountPageBundle = usePageBundle(accountId);
	const accountPhase = useAccountPhase(accountId);
	const accountWebsiteCount = useAccountWebsiteCount(accountId) ?? 0;
	const accountWebsiteSizes = useAccountWebsiteSizes(accountId);
	const accountWebsiteRestrictions = useAccountWebsiteRestrictions(accountId);
	const createWebsitesInBulk = useCreateWebsitesInBulk(accountId);
	const sessionId = useValidationSession(accountId);

	const [stagedDomains, setStagedDomains] = React.useState<Array<string>>(domains ?? []);

	const formRef = React.useRef<FormRef>(null);

	const isLoading = !tariff || !sessionId;

	const maxUnverifiedWebsites = accountWebsiteRestrictions?.maximumUnverifiedWebsites ?? null;
	const maxWebsites = accountWebsiteRestrictions?.maximumWebsites ?? Infinity;

	const isMaxWebsitesLimitReached = accountWebsiteCount + stagedDomains.length >= maxWebsites;

	const [stageDomains] = useStageDomainsMutation();
	const [unstageDomains] = useUnstageDomainsMutation();



	const { startPolling, stopPolling } = useDomainsSessonStagingAreaQuery({
		notifyOnNetworkStatusChange: true,
		variables: { sessionId: sessionId as string, accountId: accountId as number },
		skip: !sessionId || !accountId,
		onCompleted: (data) => {
			if (data.domainsValidationSession) {
				updateStagingArea(data.domainsValidationSession);
			}
		},
	});



	const addDomainsToStagingArea = React.useCallback(
		(domains: Array<string>) => {
			if (!formRef.current) {
				return;
			}

			const values = formRef.current.getValues();
			const defaultPageCapacity = values['defaultPageCapacity'];

			const newStagedDomains: Array<string> = [];
			const newFormValues: Record<string, any> = {};

			const numberOfAvailableSpots = maxWebsites - accountWebsiteCount - stagedDomains.length;

			domains
				.filter((domain) => !stagedDomains.includes(domain))
				.slice(0, numberOfAvailableSpots)
				.forEach((domain) => {
					if (!stagedDomains.includes(domain)) {
						newStagedDomains.push(domain);
						newFormValues[`status_${domain}`] = ValidationStatus.Pending;
						newFormValues[`pageCapacity_${domain}`] = defaultPageCapacity;
					}
				});

			formRef.current.setValues(newFormValues);

			setStagedDomains([
				...stagedDomains,
				...newStagedDomains,
			]);

			if (newStagedDomains.length > 0 && accountId && sessionId) {
				stageDomains({
					variables: {
						accountId,
						domains: [
							...stagedDomains,
							...newStagedDomains,
						],
						sessionId,
					},
				});
			}

			startPolling(3000);
		},
		[
			accountId,
			accountWebsiteCount,
			maxWebsites,
			sessionId,
			stagedDomains,
			stageDomains,
			startPolling,
		],
	);

	function updateStagingArea(stagingArea: GraphQL.StagingAreaOfDomainsValidationSession) {
		const form = formRef.current;

		if (!form) {
			stopPolling();
			return;
		}

		function filterStagedDomains(item: GraphQL.DomainsValidationFailedDomain | GraphQL.DomainsValidationPendingDomain | GraphQL.DomainsValidationFinishedDomain) {
			return stagedDomains.includes(item.domain);
		}

		stagingArea.failedDomains
			.filter(filterStagedDomains)
			.forEach((item) => {
				form.setValue(`status_${item.domain}`, ValidationStatus.Failed);
				form.setValue(`failureReason_${item.domain}`, item.failureReason);
			});

		stagingArea.pendingDomains
			.filter(filterStagedDomains)
			.forEach((item) => {
				form.setValue(`status_${item.domain}`, ValidationStatus.Pending);
			});

		stagingArea.finishedDomains
			.filter(filterStagedDomains)
			.forEach((item) => {
				form.setValue(`status_${item.domain}`, ValidationStatus.Finished);
			});

		stopPolling();

		const stagingAreaDomains = stagingArea.pendingDomains.length + stagingArea.finishedDomains.length + stagingArea.failedDomains.length;

		if (stagingArea.pendingDomains.length > 0 || stagingAreaDomains !== stagedDomains.length) {
			startPolling(1000);
		}
	}

	function updatePageCapacities() {
		if (!formRef.current) {
			return;
		}

		const values = formRef.current.getValues();

		const defaultPageCapacity = values['defaultPageCapacity'];

		stagedDomains.forEach((domain) => {
			const status = values[`status_${domain}`];

			if (status !== ValidationStatus.Failed) {
				formRef.current?.setValue(`pageCapacity_${domain}`, defaultPageCapacity);
			}
		});
	}

	function handleRowDelete(domain: string) {
		if (!accountId || !sessionId) {
			return;
		}

		const index = stagedDomains.indexOf(domain);

		setStagedDomains([
			...stagedDomains.slice(0, index),
			...stagedDomains.slice(index + 1),
		]);

		unstageDomains({
			variables: {
				accountId,
				sessionId,
				domains: [domain],
			},
		});
	}

	const calculateMinimumPageBundle = React.useCallback(
		(currentPageBundleUsage: number, values) => {
			if (tariff === null) {
				throw new Error(`tariff can't be null`);
			}

			const additionalPageCapacity = stagedDomains
				.filter((domain) => values[`status_${domain}`] === ValidationStatus.Finished)
				.map((domain) => values[`pageCapacity_${domain}`])
				.reduce((total, pageCapacity) => total + pageCapacity);

			return tariff.normalizePagesAmount(currentPageBundleUsage + additionalPageCapacity);
		},
		[
			stagedDomains,
			tariff,
		],
	);

	const handleSubmit = React.useCallback(
		async ({ threeDSecureToken, values }) => {
			if (
				accountId === null
				|| accountPageBundle === null
				|| accountWebsiteSizes === null
				|| sessionId === undefined
			) {
				return;
			}

			const domains = stagedDomains
				.filter((domain) => values[`status_${domain}`] === ValidationStatus.Finished)
				.map((domain) => ({
					domain,
					pageCapacity: values[`pageCapacity_${domain}`],
				}));

			let newPageBundle: number | undefined = undefined;

			const minimumPageBundle = calculateMinimumPageBundle(
				accountWebsiteSizes.totalPageCapacity,
				values,
			);

			if (minimumPageBundle > accountPageBundle) {
				newPageBundle = minimumPageBundle;
			}

			await createWebsitesInBulk({
				acceptedLegalDocuments: [GraphQL.LegalDocumentType.Woc],
				domains,
				pageBundle: newPageBundle,
				sessionId,
				threeDSecureToken,
			});

			onContinueCallback(
				domains.map((domain) => domain.domain),
			);
		},
		[
			accountId,
			accountPageBundle,
			accountWebsiteSizes,
			calculateMinimumPageBundle,
			createWebsitesInBulk,
			onContinueCallback,
			sessionId,
			stagedDomains,
		],
	);

	return (
		<MultiStepModalStep
			breadcrumb={(
				<FormattedMessage {...messages.breadcrumb} />
			)}
			headerIconType={MultiStepModalStepHeaderIconType.Plus}
			name="add-multiple-websites"
			sidebar={(
				<Sidebar
					accountId={accountId}
					addDomainsToStagingArea={addDomainsToStagingArea}
					isMaxWebsitesLimitReached={isMaxWebsitesLimitReached}
					maxUnverifiedWebsites={maxUnverifiedWebsites}
					maxWebsites={maxWebsites}
					sessionId={sessionId}
					stagedDomains={stagedDomains}
				/>
			)}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<PaymentAuthorizationContext
				accountId={accountId}
				formRef={formRef}
				onError={FormError.fromApolloError}
				onSubmit={handleSubmit}
				useModal={false}
			>
				{({ handleFormSuccess, isAuthorizing }) => {
					const defaultPageCapacity = tariff?.normalizePageCapacity(accountPhase === GraphQL.AccountPhase.Trial ? 25_000 : 1_000);
					const defaultValues = {
						defaultPageCapacity,
					};

					stagedDomains.forEach((domain) => {
						defaultValues[`status_${domain}`] = ValidationStatus.Pending;
						defaultValues[`pageCapacity_${domain}`] = defaultPageCapacity;
					});

					return (
						<Form
							defaultDataHasChanged={true}
							defaultValues={defaultValues}
							ignoreFieldUnmounts={true}
							isDisabled={isLoading}
							key={isLoading ? 'loading' : 'ready'}
							onSuccess={handleFormSuccess}
							ref={formRef}
						>
							{({ values, isSubmitting }) => {
								let pendingDomains = 0;
								let finishedDomains = 0;
								let totalPageCapacity = 0;

								stagedDomains.forEach((domain) => {
									const status = values[`status_${domain}`];

									if (status === ValidationStatus.Pending) {
										pendingDomains += 1;
									} else if (status === ValidationStatus.Finished) {
										finishedDomains += 1;
										totalPageCapacity += values[`pageCapacity_${domain}`] || 1000;
									}
								});

								const isSubmittable = (
									pendingDomains === 0
									&& finishedDomains > 0
									&& tariff !== null
									&& accountWebsiteSizes !== null
									&& calculateMinimumPageBundle(
										accountWebsiteSizes.totalPageCapacity,
										values,
									) <= tariff.maximumPagesAmount
								);

								return (
									<>
										<div style={{ display: isAuthorizing ? 'none' : 'block' }}>
											<MarginsList>
												<DatatableLayout
													footerCTA={(
														<DatatableFooterBulkActionsLayout
															form={(
																<PageCapacityField
																	accountId={accountId}
																	countSliderSize={PageCapacityFieldSize.Small}
																	name="defaultPageCapacity"
																	showHighlightedValue={false}
																	sliderInfoWidth={88}
																	width={218}
																/>
															)}
															formWidth={235}
															message={(
																<ValidationStatusMessage
																	stagedDomains={stagedDomains}
																	values={values}
																/>
															)}
															messageWidth={321}
															submitButton={(
																<Button
																	onClick={updatePageCapacities}
																	size={ButtonSize.XXSmall}
																	style={ButtonStyle.Link}
																>
																	<FormattedMessage {...messages.applyPageCapacityButton} />
																</Button>
															)}
															submitButtonWidth={60}
														/>
													)}
												>
													<DatatableContainer overlay={(stagedDomains.length === 0 && <BlankSlateOverlay />)}>
														<Measurer>
															{({ containerWidth }) => (
																<FixedHeaderGrid
																	bodyCellRenderer={(props) => (
																		<BodyCellRenderer
																			{...props}
																			accountId={accountId}
																			onDeleteClickCallback={handleRowDelete}
																			stagedDomains={stagedDomains}
																			values={values}
																		/>
																	)}
																	columnCount={4}
																	columnWidth={columnWidth}
																	headerCellRenderer={headerCellRenderer}
																	headerHeight={40}
																	height={320}
																	rowCount={Math.max(stagedDomains.length, 7)}
																	rowHeight={40}
																	width={containerWidth}
																/>
															)}
														</Measurer>
													</DatatableContainer>
												</DatatableLayout>

												<PageBundleChangeSummary
													accountId={accountId}
													pageCapacity={totalPageCapacity}
													websites={finishedDomains}
												/>

											</MarginsList>
										</div>

										<RecurlyFormErrorMessages />

										<FormErrorMessages />

										{isAuthorizing && (
											<ModalTextSection>
												<RichText>
													<FormattedMessage {...messages.authorizationDescription} />
												</RichText>
											</ModalTextSection>
										)}

										{isAuthorizing && (
											<AuthorizePaymentIframe />
										)}

										{!isAuthorizing && !isSubmitting && (
											<CenteredFormWrapper>
												{Object.keys(errorMessages).map((name) => (
													<GlobalFormMessage
														key={name}
														name={name}
													>
														<FormattedMessage {...errorMessages[name]} />
													</GlobalFormMessage>
												))}

												<PaymentAuthorizationError />
											</CenteredFormWrapper>
										)}

										<Spacer top={SpacerGap.Default}>
											<DecentText
												alignment={DecentTextAlignment.Left}
												size={DecentTextSize.Small}
											>
												<FormattedMessage {...messages.legalNotice} />
											</DecentText>
										</Spacer>
										<ButtonsLayout
											layout={ButtonsLayoutType.Steps}
											usageContext={ButtonsLayoutUsageContext.InModals}
										>
											<PaymentCancelButton />

											<PaymentSubmitButton
												isDisabled={!isSubmittable}
											>
												Continue
											</PaymentSubmitButton>
										</ButtonsLayout>
									</>
								);
							}}
						</Form>
					);
				}}
			</PaymentAuthorizationContext>
		</MultiStepModalStep>
	);
};



type BodyCellProps = {
	accountId: CK.AccountId,
	columnIndex: number,
	onDeleteClickCallback: (domain: string) => void,
	key: string,
	rowIndex: number,
	style: React.CSSProperties,
	stagedDomains: Array<string>,
	values: Record<string, string | ValidationStatus>,
};

const BodyCellRenderer: React.FC<BodyCellProps> = (props) => {
	const {
		accountId,
		columnIndex,
		onDeleteClickCallback,
		rowIndex,
		style,
		stagedDomains,
		values,
	} = props;

	let content;

	if (stagedDomains[rowIndex] !== undefined) {
		const domain = getArrayItemAtSafeIndex(stagedDomains, rowIndex);
		const status = values[`status_${domain}`];

		switch (columnIndex) {

			case Columns.Status: {
				if (status === ValidationStatus.Pending) {
					content = <Spinner size={SpinnerSize.XSmall} />;
				} else if (status === ValidationStatus.Failed) {
					const reason = values[`failureReason_${domain}`];

					let popup: React.ReactNode = reason;
					if (reason === 'blacklisted_domain') {
						popup = (
							<FormattedMessage {...errorMessages.blacklisted} />
						);
					} else if (reason === 'target_redirected') {
						popup = (
							<FormattedMessage {...errorMessages.targetRedirected} />
						);
					} else if (reason === 'website_already_monitored') {
						popup = (
							<FormattedMessage {...errorMessages.alreadyOnOtherAccounts} />
						);
					} else if (reason === 'not_whitelisted_domain') {
						popup = (
							<DomainNotWhitelistedErrorMessage
								accountId={accountId}
								generic={true}
							/>
						);
					} else if (
						reason === 'connect'
						|| reason === 'empty_response'
						|| reason === 'size_limit'
						|| reason === 'time_limit'
						|| reason === 'waf'
					) {
						popup = (
							<FormattedMessage
								{...errorMessages.unreachable}
								values={{
									reason: (
										<UnreliableResponseName reason={reason} />
									),
								}}
							/>
						);
					}

					content = (
						<Hint popup={popup}>
							<StatusFlag status={StatusFlagStatus.Critical} />
						</Hint>
					);
				} else if (status === ValidationStatus.Finished) {
					content = <StatusFlag status={StatusFlagStatus.Normal} />;
				}

				break;
			}

			case Columns.Website: {
				content = (
					<TextualCellValue
						value={domain}
					/>
				);

				break;
			}

			case Columns.PageCapacity: {
				content = (
					<PageCapacityField
						accountId={accountId}
						countSliderSize={PageCapacityFieldSize.Small}
						isDisabled={status === ValidationStatus.Failed}
						name={`pageCapacity_${domain}`}
						showHighlightedValue={false}
						sliderInfoWidth={88}
						width={218}
					/>
				);

				break;
			}

			case Columns.Action:
				content = (
					<ButtonsGroup>
						<TableDeleteButton
							onClick={() => {
								onDeleteClickCallback(domain);
							}}
						/>
					</ButtonsGroup>
				);
				break;

		}
	}

	return (
		<DatatableBodyCell
			cssStyle={style}
			key={`${rowIndex}_${columnIndex}`}
			rowIndex={rowIndex}
			separator={columnIndex !== 0 && columnIndex < 4}
			size={DatatableBodyCellSize.Small}
		>
			{content}
		</DatatableBodyCell>
	);
};



type HeaderCellProps = {
	columnIndex: number,
};

const headerCellRenderer: React.FC<HeaderCellProps> = ({ columnIndex }) => {
	let label;
	let explanatoryTooltip;

	switch (columnIndex) {

		case Columns.Website:
			label = 'Website';
			break;

		case Columns.PageCapacity:
			label = 'Page capacity';
			break;

	}

	return (
		<DatatableHeaderCell
			key={'header_' + columnIndex}
			separator={columnIndex !== 0}
			size={DatatableHeaderCellSize.Small}
			width={columnWidth({ index: columnIndex })}
		>
			<TableLabel
				explanatoryTooltip={explanatoryTooltip}
				label={label}
			/>
		</DatatableHeaderCell>
	);
};



function columnWidth({ index }) {
	switch (index) {

		case Columns.Status:
			return 40;
		case Columns.Website:
			return 285;
		case Columns.PageCapacity:
			return 235;
		case Columns.Action:
			return 60;
		default:
			return 0;

	}
}



export default AddMultipleWebsitesStep;
