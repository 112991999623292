import type CK from '~/types/contentking';

import {
	useAllowedBillingCyclesQuery,
} from './useAllowedBillingCycles.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAllowedBillingCycles(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAllowedBillingCyclesQuery,
		accountId,
	);

	return data?.account?.allowedBillingCycles ?? null;
}



export default useAllowedBillingCycles;
