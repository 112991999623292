import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import type CK from '~/types/contentking';

import Button, {
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import Copy from '~/components/logic/Copy';
import FeaturesInUseWarning from '~/components/app/SignupModal/FeaturesInUseWarning';
import MarginsList, {
	SIZE_LARGE as MARGINS_SIZE_LARGE,
} from '~/components/atoms/lists/MarginsList';
import ModalButtonsLayout from '~/components/patterns/modals/parts/ModalButtonsLayout';
import PaymentServicesList from '~/components/atoms/lists/PaymentServicesList';
import PlanName from '~/components/names/PlanName';
import TwinButtons from '~/components/logic/signup/TwinButtons';

import useAccountFeaturesUsage from '~/hooks/useAccountFeaturesUsage';
import useAccountOutOfBandPlans from '~/hooks/useAccountOutOfBandPlans';
import useAccountSignup from '~/hooks/useAccountSignup';
import useIntercom from '~/hooks/useIntercom';
import useSignupPrice from '~/hooks/useSignupPrice';



const messages = defineMessages({
	back: {
		id: 'ui.signup.featuresInUse.back',
	},
	chooseBetterPlanLabel: {
		id: 'ui.signup.featuresInUse.chooseBetterPlan.label',
	},
	chooseBetterPlanNote: {
		id: 'ui.signup.featuresInUse.chooseBetterPlan.note',
	},
	contactUsToChooseBetterPlanLabel: {
		id: 'ui.signup.featuresInUse.contactUsToChooseBetterPlan.label',
	},
	keepCurrentPlanLabel: {
		id: 'ui.signup.featuresInUse.keepCurrentPlan.label',
	},
	keepCurrentPlanNote: {
		id: 'ui.signup.featuresInUse.keepCurrentPlan.note',
	},
});



type Props = {
	accountId: CK.AccountId,
	onCancelCallback: () => void,
	onChooseBetterPlan: () => void,
	onKeepCurrentPlan: () => void,
};

const FeaturesInUseSignupModalStep: React.FC<Props> = (props) => {
	const {
		accountId,
		onCancelCallback,
		onChooseBetterPlan,
		onKeepCurrentPlan,
	} = props;

	const accountFeaturesUsage = useAccountFeaturesUsage(accountId);
	const accountOutOfBandPlans = useAccountOutOfBandPlans(accountId);
	const accountSignup = useAccountSignup(accountId);
	const intercom = useIntercom();

	const intl = useIntl();

	const {
		calculatePrice,
		currency: signupCurrency,
	} = useSignupPrice(accountId, false);

	const currentPlan = accountSignup.plan;

	if (
		accountFeaturesUsage === null
		|| accountOutOfBandPlans === null
		|| currentPlan === null
	) {
		return null;
	}

	const neededPlan = accountFeaturesUsage.getNecessaryPlan();

	if (neededPlan === null) {
		return null;
	}

	const isOutOfBandPlan = accountOutOfBandPlans.includes(neededPlan);

	const planAvailability = accountFeaturesUsage.getPlanAvailability(currentPlan);

	let extra = 0;

	if (isOutOfBandPlan === false && calculatePrice !== null) {
		extra = calculatePrice({
			plan: neededPlan,
		}).priceWithDiscountsMonthly - calculatePrice({
			plan: currentPlan,
		}).priceWithDiscountsMonthly;
	}

	return (
		<MarginsList size={MARGINS_SIZE_LARGE}>
			<MarginsList size={MARGINS_SIZE_LARGE}>
				<FeaturesInUseWarning
					lostFeatures={planAvailability.conflictingFeatures}
					plan={neededPlan}
				/>


				<TwinButtons
					choiceAButton={isOutOfBandPlan ? (
						<Button
							onClick={intercom.showIntercom}
							style={ButtonStyle.Action}
						>
							<FormattedMessage
								{...messages.contactUsToChooseBetterPlanLabel}
								values={{
									plan: (
										<PlanName plan={neededPlan} />
									),
								}}
							/>
						</Button>
					) : (
						<Button
							onClick={() => {
								accountSignup.setProperty('plan', neededPlan);
								onChooseBetterPlan();
							}}
							style={ButtonStyle.Action}
						>
							<FormattedMessage
								{...messages.chooseBetterPlanLabel}
								values={{
									plan: (
										<PlanName plan={neededPlan} />
									),
								}}
							/>
						</Button>
					)}
					choiceANote={isOutOfBandPlan ? '' : (
						<Copy
							{...messages.chooseBetterPlanNote}
							values={{
								amount: intl.formatNumber(extra, {
									currency: signupCurrency,
									maximumFractionDigits: 2,
									style: 'currency',
								}),
							}}
						/>
					)}
					choiceBButton={(
						<Button
							onClick={onKeepCurrentPlan}
							style={ButtonStyle.Hollow}
						>
							<FormattedMessage
								{...messages.keepCurrentPlanLabel}
								values={{
									plan: (
										<PlanName plan={currentPlan} />
									),
								}}
							/>
						</Button>
					)}
					choiceBNote={(
						<Copy
							{...messages.keepCurrentPlanNote}
							values={{
								plan: (
									<PlanName plan={currentPlan} />
								),
							}}
						/>
					)}
				/>
			</MarginsList>

			<ModalButtonsLayout>
				<span />

				<Button
					onClick={onCancelCallback}
					style={ButtonStyle.Link}
				>
					<FormattedMessage {...messages.back} />
				</Button>

				<span />
			</ModalButtonsLayout>

			<PaymentServicesList />
		</MarginsList>
	);
};



export default FeaturesInUseSignupModalStep;
