import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import BlankValue from '~/components/patterns/values/BlankValue';
import CancelButton from '~/components/app/CancelButton';
import CheckboxField from '~/components/atoms/forms/components/CheckboxField';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import SelectField from '~/components/atoms/forms/components/SelectField';
import StaticText from '~/components/atoms/forms/components/StaticText';
import TextField, {
	TextFieldType,
} from '~/components/atoms/forms/components/TextField';

import {
	useAdministerAccountMainSettingsMutation,
	useMainSettingsFormQuery,
} from './MainSettingsForm.gql';

import useAccountAccessRestrictions from '~/hooks/useAccountAccessRestrictions';
import useAccountCanSignup from '~/hooks/useAccountCanSignup';
import useAccountCustomerType from '~/hooks/useAccountCustomerType';
import useAccountEnabledFeatures from '~/hooks/useAccountEnabledFeatures';
import useAccountIsManaged from '~/hooks/useAccountIsManaged';
import useAccountIsOutOfBand from '~/hooks/useAccountIsOutOfBand';
import useAccountPhase from '~/hooks/useAccountPhase';
import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';
import useAccountState from '~/hooks/useAccountState';
import useAccountTariff from '~/hooks/useAccountTariff';
import useAccountType from '~/hooks/useAccountType';
import useAccountUserRestrictions from '~/hooks/useAccountUserRestrictions';
import useAccountWebsiteRestrictions from '~/hooks/useAccountWebsiteRestrictions';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';

import {
	doesAccountTypeHavePageBundle,
} from '~/model/accounts';



type Props = {
	accountId: CK.AccountId,
};

const MainSettingsForm: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const accountAccessRestrictions = useAccountAccessRestrictions(accountId);
	const accountCanSignup = useAccountCanSignup(accountId);
	const accountCustomerType = useAccountCustomerType(accountId);
	const accountEnabledFeatures = useAccountEnabledFeatures(accountId);
	const accountIsManaged = useAccountIsManaged(accountId);
	const accountIsOutOfBand = useAccountIsOutOfBand(accountId);
	const accountPhase = useAccountPhase(accountId);
	const accountState = useAccountState(accountId);
	const accountTariff = useAccountTariff(accountId);
	const accountType = useAccountType(accountId);
	const accountUserRestrictions = useAccountUserRestrictions(accountId);
	const accountWebsiteRestrictions = useAccountWebsiteRestrictions(accountId);

	const { data } = useAccountPropertiesQuery(
		useMainSettingsFormQuery,
		accountId,
	);

	const isAllowedToManageInternals = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.ManageAdminMainSettings,
	);

	const [administerAccountMainSettings] = useAdministerAccountMainSettingsMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			if (values.customerType === 'none') {
				values.customerType = null;
			}

			if (accountCanSignup === false) {
				values.isOutOfBand = null;
			}

			await administerAccountMainSettings({
				variables: {
					accountId,
					...values,
				},
			});
		},
		[
			accountCanSignup,
			accountId,
			administerAccountMainSettings,
		],
	);

	if (
		accountAccessRestrictions === null
		|| accountEnabledFeatures === null
		|| accountUserRestrictions === null
		|| accountWebsiteRestrictions === null
	) {
		return null;
	}

	function getCustomerTypesOptions(accountCustomerType) {
		const options: Array<{
			label: React.ReactNode,
			name: string,
		}> = [];

		if (accountCustomerType === null) {
			options.push({
				label: (
					<BlankValue>none</BlankValue>
				),
				name: 'none',
			});
		}

		[
			GraphQL.CustomerType.Agency,
			GraphQL.CustomerType.Ecommerce,
			GraphQL.CustomerType.Marketplace,
			GraphQL.CustomerType.Publisher,
			GraphQL.CustomerType.Travel,
			GraphQL.CustomerType.Other,
		].map((customerType) => {
			options.push({
				label: customerType,
				name: customerType,
			});
		});

		return options;
	}

	const campaignSource = data?.account?.adminSettings?.campaignSource ?? null;
	const maximumUnverifiedWebsites = data?.account?.adminSettings?.maximumUnverifiedWebsites ?? null;
	const maximumWebsites = data?.account?.adminSettings?.maximumWebsites ?? null;
	const referringPartnerId = data?.account?.adminSettings?.referringPartnerId ?? null;

	return (
		<EditableFormWrapper
			isAllowed={isAllowedToManageInternals}
			isForAdmins={true}
			title="Account"
		>
			<DisplayPart>
				<FormRows>
					<FormRow label="Account ID">
						<StaticText>
							{accountId}
						</StaticText>
					</FormRow>

					<FormRow label="Activated">
						<StaticText>
							{accountState !== GraphQL.AccountState.Preactive ? 'yes' : 'no'}
						</StaticText>
					</FormRow>

					<FormRow label="Out of band">
						<StaticText focusTarget="isOutOfBand">
							{accountIsOutOfBand ? 'yes' : 'no'}
						</StaticText>
					</FormRow>

					<PremiumFeatureSituation
						featureName={GraphQL.AccountFeature.SingleSignOn}
						hideIfUnattainable={true}
						style={PremiumFeatureSituationStyle.Ribbon}
					>
						{() => (
							<FormRow label="Conductor Organization ID">
								<StaticText>
									{data?.account?.conductorOrganizationId ?? '-'}
								</StaticText>
							</FormRow>
						)}
					</PremiumFeatureSituation>

					<FormRow label="Customer Type">
						<StaticText focusTarget="customerType">
							{accountCustomerType ?? <BlankValue>none</BlankValue>}
						</StaticText>
					</FormRow>

					<FormRow label="Tariff">
						<StaticText>
							{accountTariff}
						</StaticText>
					</FormRow>

					<FormRow label="Managed account">
						<StaticText focusTarget="isManaged">
							{accountIsManaged ? 'yes' : 'no'}
						</StaticText>
					</FormRow>

					<FormRow label="Max. pending invitations">
						<StaticText focusTarget="maximumInvitations">
							{accountUserRestrictions.maximumInvitations}
						</StaticText>
					</FormRow>

					<FormRow label="Max. non-verified websites">
						<StaticText focusTarget="maximumUnverifiedWebsites">
							{maximumUnverifiedWebsites ?? <BlankValue>none</BlankValue>}
						</StaticText>
					</FormRow>

					<FormRow label="Max. non-verified + verified websites">
						<StaticText focusTarget="maximumWebsites">
							{maximumWebsites ?? <BlankValue>none</BlankValue>}
						</StaticText>
					</FormRow>

					{doesAccountTypeHavePageBundle(accountType) && (
						<FormRow label="Max. page bundle">
							<StaticText focusTarget="maximumPageBundle">
								{accountWebsiteRestrictions.maximumPageBundle ?? <BlankValue>none</BlankValue>}
							</StaticText>
						</FormRow>
					)}

					<FormRow label="Max. page capacity">
						<StaticText focusTarget="maximumPageCapacity">
							{accountWebsiteRestrictions.maximumPageCapacity || <BlankValue>none</BlankValue>}
						</StaticText>
					</FormRow>

					{accountPhase === GraphQL.AccountPhase.Trial && (
						<FormRow label="Can change connection direction">
							<StaticText focusTarget="canModifyConnectionDirectionDuringTrial">
								{accountAccessRestrictions.canModifyConnectionDirectionDuringTrial ? 'yes' : 'no'}
							</StaticText>
						</FormRow>
					)}

					{accountEnabledFeatures.includes(GraphQL.AccountFeature.ConnectToClient) && (
						<FormRow label="Max. connected trial accounts">
							<StaticText focusTarget="maximumAllowedConnectedTrialAccounts">
								{accountAccessRestrictions.maximumAllowedConnectedTrialAccounts}
							</StaticText>
						</FormRow>
					)}

					{accountEnabledFeatures.includes(GraphQL.AccountFeature.ConnectToClient) && (
						<FormRow label="Can create connected account without a user">
							<StaticText focusTarget="canCreateConnectedClientsWithoutUsers">
								{accountAccessRestrictions.canCreateConnectedClientsWithoutUsers ? 'yes' : 'no'}
							</StaticText>
						</FormRow>
					)}

					<FormRow label="Referring Partner">
						<StaticText focusTarget="referringPartnerId">
							{referringPartnerId ?? <BlankValue>none</BlankValue>}
						</StaticText>
					</FormRow>

					<FormRow label="Campaign source">
						<StaticText>
							{campaignSource ?? <BlankValue>none</BlankValue>}
						</StaticText>
					</FormRow>
				</FormRows>
			</DisplayPart>

			<EditablePart>
				<Form
					defaultValues={{
						canCreateConnectedClientsWithoutUsers: accountAccessRestrictions.canCreateConnectedClientsWithoutUsers,
						canModifyConnectionDirectionDuringTrial: accountAccessRestrictions.canModifyConnectionDirectionDuringTrial,
						customerType: accountCustomerType,
						isManaged: accountIsManaged,
						maximumAllowedConnectedTrialAccounts: accountAccessRestrictions.maximumAllowedConnectedTrialAccounts,
						maximumInvitations: accountUserRestrictions.maximumInvitations,
						maximumPageBundle: accountWebsiteRestrictions.maximumPageBundle,
						maximumPageCapacity: accountWebsiteRestrictions.maximumPageCapacity,
						maximumUnverifiedWebsites,
						maximumWebsites,
						referringPartnerId,
					}}
					onSuccess={handleSubmit}
				>
					{({ values }) => {
						return (
							<>
								<FormRows>
									<FormRow label="Account ID">
										<StaticText>
											{accountId}
										</StaticText>
									</FormRow>

									<FormRow label="Activated">
										<StaticText>
											{accountState !== GraphQL.AccountState.Preactive ? 'yes' : 'no'}
										</StaticText>
									</FormRow>

									<FormRow
										htmlFor="isOutOfBand"
										label="Out of band"
									>
										<StaticText>
											{accountIsOutOfBand ? 'yes' : 'no'}
										</StaticText>
									</FormRow>

									<PremiumFeatureSituation
										featureName={GraphQL.AccountFeature.SingleSignOn}
										hideIfUnattainable={true}
										style={PremiumFeatureSituationStyle.Ribbon}
									>
										{() => (
											<FormRow label="Conductor Organization ID">
												<StaticText>
													{data?.account?.conductorOrganizationId ?? '-'}
												</StaticText>
											</FormRow>
										)}
									</PremiumFeatureSituation>

									<FormRow
										htmlFor="customerType"
										label="Customer Type"
									>
										<FieldStatus name="customerType">
											<SelectField
												name="customerType"
												options={getCustomerTypesOptions(accountCustomerType)}
											/>
										</FieldStatus>
									</FormRow>

									<FormRow label="Tariff">
										<StaticText>
											{accountTariff}
										</StaticText>
									</FormRow>

									<FormRow
										htmlFor="isManaged"
										label="Managed account"
									>
										<CheckboxField
											label="yes"
											name="isManaged"
											width={false}
										/>
									</FormRow>

									<FormRow
										htmlFor="maximumInvitations"
										label="Max. pending invitations"
									>
										<FieldStatus name="maximumInvitations">
											<TextField
												name="maximumInvitations"
												placeholder="no restriction"
												trimValue={true}
												type={TextFieldType.Number}
											/>
										</FieldStatus>
									</FormRow>

									<FormRow
										htmlFor="maximumUnverifiedWebsites"
										label="Max. non-verified websites"
									>
										<FieldStatus name="maximumUnverifiedWebsites">
											<TextField
												emptyAsNull={true}
												name="maximumUnverifiedWebsites"
												placeholder="no restriction"
												trimValue={true}
												type={TextFieldType.Number}
											/>
										</FieldStatus>
									</FormRow>

									<FormRow
										htmlFor="maximumWebsites"
										label="Max. non-verified + verified websites"
									>
										<FieldStatus name="maximumWebsites">
											<TextField
												emptyAsNull={true}
												name="maximumWebsites"
												placeholder="no restriction"
												trimValue={true}
												type={TextFieldType.Number}
											/>
										</FieldStatus>
									</FormRow>

									{doesAccountTypeHavePageBundle(accountType) && (
										<FormRow
											htmlFor="maximumPageBundle"
											label="Max. page bundle"
										>
											<FieldStatus name="maximumPageBundle">
												<TextField
													emptyAsNull={true}
													name="maximumPageBundle"
													placeholder="no restriction"
													trimValue={true}
													type={TextFieldType.Number}
												/>
											</FieldStatus>
										</FormRow>
									)}

									<FormRow
										htmlFor="maximumPageCapacity"
										label="Max. page capacity"
									>
										<FieldStatus name="maximumPageCapacity">
											<TextField
												attributes={{
													max: 10_000_000,
												}}
												name="maximumPageCapacity"
												placeholder="no restriction"
												trimValue={true}
												type={TextFieldType.Number}
											/>
										</FieldStatus>
									</FormRow>

									{accountPhase === GraphQL.AccountPhase.Trial && (
										<FormRow
											htmlFor="canModifyConnectionDirectionDuringTrial"
											label="Can change connection direction"
										>
											<CheckboxField
												label="yes"
												name="canModifyConnectionDirectionDuringTrial"
												width={false}
											/>
										</FormRow>
									)}

									{accountEnabledFeatures.includes(GraphQL.AccountFeature.ConnectToClient) && (
										<FormRow
											htmlFor="maximumAllowedConnectedTrialAccounts"
											label="Max. connected trial accounts"
										>
											<FieldStatus name="maximumAllowedConnectedTrialAccounts">
												<TextField
													attributes={{
														max: 1_000,
													}}
													emptyAsNull={true}
													name="maximumAllowedConnectedTrialAccounts"
													placeholder="no restriction"
													trimValue={true}
													type={TextFieldType.Number}
												/>
											</FieldStatus>
										</FormRow>
									)}

									{accountEnabledFeatures.includes(GraphQL.AccountFeature.ConnectToClient) && (
										<FormRow
											htmlFor="canCreateConnectedClientsWithoutUsers"
											label="Can create connected account without a user"
										>
											<CheckboxField
												label="yes"
												name="canCreateConnectedClientsWithoutUsers"
												width={false}
											/>
										</FormRow>
									)}

									{referringPartnerId === null ? (
										<FormRow
											description={values.referringPartnerId && (
												<div
													style={{
														color: 'red',
													}}
												>
													CAUTION: this will give commission for THIS account to entered partner.
												</div>
											)}
											htmlFor="referringPartnerId"
											label="Referring Partner"
										>
											<TextField
												name="referringPartnerId"
												trimValue={true}
											/>
										</FormRow>
									) : (
										<FormRow label="Referring Partner">
											<StaticText focusTarget="referringPartnerId">
												{referringPartnerId}
											</StaticText>
										</FormRow>
									)}

									<FormRow label="Campaign source">
										<StaticText>
											{campaignSource ?? <BlankValue>none</BlankValue>}
										</StaticText>
									</FormRow>
								</FormRows>

								<ButtonsLayout>
									<CancelButton />
									<SaveSubmitButton />
								</ButtonsLayout>
							</>
						);
					}}
				</Form>
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default MainSettingsForm;
