import type CK from '~/types/contentking';

import {
	useAccountDetailedDiscountsQuery,
} from './useAccountDetailedDiscounts.gql';



function useAccountDetailedDiscounts(accountId: CK.AccountId | null) {
	const { data } = useAccountDetailedDiscountsQuery({
		skip: accountId === null,
		variables: {
			accountId: accountId ?? 0,
		},
	});

	return data?.account?.discounts ?? null;
}



export default useAccountDetailedDiscounts;
