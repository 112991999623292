import Immutable from 'immutable';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import BillingCycleField from '~/components/logic/formFields/BillingCycleField';
import BillingCycleName from '~/components/names/BillingCycleName';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import ChangeSummary, {
	PAGES_DIFF_BUNDLE,
} from '~/components/logic/pricing/ChangeSummary';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import EndUserPlanName from '~/components/names/EndUserPlanName';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import PaymentAuthorizationContext from '~/components/logic/PaymentAuthorizationContext';
import PaymentAuthorizationError from '~/components/logic/PaymentAuthorizationError';
import PaymentCancelButton from '~/components/logic/PaymentCancelButton';
import PaymentSubmitButton from '~/components/logic/PaymentSubmitButton';
import StaticText from '~/components/atoms/forms/components/StaticText';
import WhenAccountActionAllowed from '~/components/app/WhenAccountActionAllowed';

import {
	useUpdateEndUserPlanMutation,
} from './EndUserPackageForm.gql';

import useAccountBillingCycle from '~/hooks/useAccountBillingCycle';
import useAccountCurrency from '~/hooks/useAccountCurrency';
import useAccountEndUserPlan from '~/hooks/useAccountEndUserPlan';
import useAccountPhase from '~/hooks/useAccountPhase';
import useAccountTariffCalculator from '~/hooks/useAccountTariffCalculator';
import useAccountWebsiteSizes from '~/hooks/useAccountWebsiteSizes';
import useAllowedBillingCycles from '~/hooks/useAllowedBillingCycles';
import useLegacyBillingDetails from '~/hooks/useLegacyBillingDetails';

import {
	DETAIL_END_USER_PLAN,
	DETAIL_END_USER_PLAN_WEBSITES,
	DETAIL_END_USER_PLAN_WEBSITES_PAGES,
} from '~/model/pricing/endUser';



const messages = defineMessages({
	billingCycleLabel: {
		id: 'ui.teamDetail.billing.agencyPackage.billingCycle',
	},
	contactBoxTitle: {
		id: 'ui.billing.needHelpPickingPlan.header',
	},
	plan: {
		id: 'ui.websiteSettings.plan.header.endUser',
	},
	saveButton: {
		id: 'ui.teamDetail.billing.package.confirmationButton',
	},
	title: {
		id: 'ui.teamDetail.subscriptionForm.header',
	},
});



type Props = {
	accountId: CK.AccountId | null,
};

const EndUserPackageForm: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const accountAllowedBillingCycles = useAllowedBillingCycles(accountId);
	const accountBillingCycle = useAccountBillingCycle(accountId);
	const accountCurrency = useAccountCurrency(accountId);
	const accountEndUserPlan = useAccountEndUserPlan(accountId);
	const accountPhase = useAccountPhase(accountId);
	const accountTariffCalculator = useAccountTariffCalculator(accountId);
	const accountWebsiteSizes = useAccountWebsiteSizes(accountId);
	const teamBilling = useLegacyBillingDetails(accountId);

	const [updateEndUserPlan] = useUpdateEndUserPlanMutation();

	const calculatePrice = React.useCallback(
		(billingCycle: GraphQL.Term) => {
			if (
				accountEndUserPlan === null
				|| accountTariffCalculator.isReady === false
				|| accountWebsiteSizes === null
			) {
				return null;
			}

			const discounts = teamBilling.get('discounts').filter((discount) => discount.get('type') !== 'dollars');
			const taxRate = teamBilling.get('tax');

			return accountTariffCalculator.tariff.calculatePurchaseCostDetails({
				billingCycle,
				discounts,
				purchases: [
					{
						details: {
							[DETAIL_END_USER_PLAN]: accountEndUserPlan,
							[DETAIL_END_USER_PLAN_WEBSITES]: Immutable.List(accountWebsiteSizes.websites.map((website) => {
								return Immutable.Map({
									[DETAIL_END_USER_PLAN_WEBSITES_PAGES]: website.pageCapacity,
								});
							})),
						},
						numberOfPages: 0,
					},
				],
				taxRate,
			});
		},
		[
			accountEndUserPlan,
			accountTariffCalculator,
			accountWebsiteSizes,
			teamBilling,
		],
	);

	const handleSubmit = React.useCallback(
		async ({ threeDSecureToken, values }) => {
			if (accountId === null) {
				throw new Error(`accountId can't be null`);
			}

			if (accountEndUserPlan === null) {
				throw new Error(`accountEndUserPlan can't be null`);
			}

			await updateEndUserPlan({
				variables: {
					accountId,
					billingCycle: values.billingCycle,
					endUserPlan: accountEndUserPlan,
					threeDSecureToken,
				},
			});
		},
		[
			accountEndUserPlan,
			accountId,
			updateEndUserPlan,
		],
	);

	const renderChangesOverview = (values) => {
		if (
			!teamBilling
			|| !teamBilling.get('details')
			|| teamBilling.get('details').size === 0
			|| !teamBilling.has('discounts')
			|| !teamBilling.has('tax')
			|| accountAllowedBillingCycles === null
			|| accountBillingCycle === null
			|| accountCurrency === null
		) {
			return null;
		}

		if (!values.billingCycle || (accountBillingCycle === values.billingCycle)) {
			return null;
		}

		const priceOld = calculatePrice(accountBillingCycle);
		const priceNew = calculatePrice(values.billingCycle ?? accountBillingCycle);

		if (priceNew === null || priceOld === null) {
			return null;
		}

		return (
			<ChangeSummary
				accountId={accountId}
				allowedBillingCycles={accountAllowedBillingCycles}
				billingCycleNew={values.billingCycle ?? accountBillingCycle}
				billingCycleOld={accountBillingCycle}
				currency={accountCurrency}
				hidePrice={false}
				pagesDiff={PAGES_DIFF_BUNDLE}
				pagesNew={0}
				pagesOld={0}
				priceNew={priceNew.subtotal}
				priceOld={priceOld.subtotal}
				taxType={priceNew.tax > 0 ? GraphQL.TaxType.Vat : null}
			/>
		);
	};

	const defaultValues = React.useMemo(
		() => ({
			billingCycle: accountBillingCycle,
		}),
		[
			accountBillingCycle,
		],
	);

	if (
		accountAllowedBillingCycles === null
		|| accountBillingCycle === null
		|| accountEndUserPlan === null
	) {
		return null;
	}

	if (accountPhase === GraphQL.AccountPhase.Customer && !teamBilling) {
		return null;
	}

	return (
		<WhenAccountActionAllowed
			accountId={accountId}
			action={GraphQL.ActionWithAccount.ManageDetails}
			showMessage={false}
		>
			{({ isAllowed }) => (
				<EditableFormWrapper
					isAllowed={isAllowed}
					key={accountId}
					title={(
						<FormattedMessage {...messages.title} />
					)}
				>
					<DisplayPart>
						<FormRows>
							<FormRow
								label={(
									<FormattedMessage {...messages.billingCycleLabel} />
								)}
							>
								<StaticText>
									<BillingCycleName billingCycle={accountBillingCycle} />
								</StaticText>
							</FormRow>

							<FormRow
								label={(
									<FormattedMessage {...messages.plan} />
								)}
							>
								<StaticText>
									<EndUserPlanName
										plan={accountEndUserPlan}
									/>
								</StaticText>
							</FormRow>
						</FormRows>
					</DisplayPart>

					<EditablePart>
						<PaymentAuthorizationContext
							onSubmit={handleSubmit}
							useModal={true}
						>
							{({ formRef, handleFormSuccess }) => (
								<Form
									defaultValues={defaultValues}
									onSuccess={handleFormSuccess}
									ref={formRef}
								>
									{({ values }) => {
										return (
											<>
												<FormRows>
													{accountAllowedBillingCycles.length > 1 && (
														<FormRow
															htmlFor="billingCycle"
															label={(
																<FormattedMessage {...messages.billingCycleLabel} />
															)}
														>
															<BillingCycleField
																allowedBillingCycles={accountAllowedBillingCycles}
																name="billingCycle"
															/>
														</FormRow>
													)}

													<FormRow
														label={(
															<FormattedMessage {...messages.plan} />
														)}
													>
														<StaticText>
															<EndUserPlanName
																plan={accountEndUserPlan}
															/>
														</StaticText>
													</FormRow>

													{renderChangesOverview(values)}
												</FormRows>

												<PaymentAuthorizationError />

												<ButtonsLayout>
													<PaymentCancelButton />

													<PaymentSubmitButton>
														<FormattedMessage {...messages.saveButton} />
													</PaymentSubmitButton>
												</ButtonsLayout>
											</>
										);
									}}
								</Form>
							)}
						</PaymentAuthorizationContext>
					</EditablePart>
				</EditableFormWrapper>
			)}
		</WhenAccountActionAllowed>
	);
};



export default EndUserPackageForm;
