import React from 'react';
import {
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
} from 'react-redux';

import type CK from '~/types/contentking';

import CarouselBlocks from '~/components/patterns/structures/CarouselBlocks';
import ChooseCustomerTypeModal from '~/components/app/ChooseCustomerTypeModal';
import Copy from '~/components/logic/Copy';
import GuidedTour from '~/components/patterns/tours/GuidedTour';
import NumberOfEmployeesForm from '~/components/app/NumberOfEmployeesForm';
import OnboardingGuideWelcomeStep from '~/components/app/OnboardingGuideWelcomeStep';
import OnboardingNameForm from '~/components/app/OnboardingNameForm';
import OnboardingPasswordForm from '~/components/app/OnboardingPasswordForm';

import {
	useFinishOnboardingMutation,
} from './OnboardingGuide.gql';

import useAccountCustomerType from '~/hooks/useAccountCustomerType';
import useAccountIsNumberOfEmployeesEnoughForOutOfBand from '~/hooks/useAccountIsNumberOfEmployeesEnoughForOutOfBand';

import {
	pendo,
} from '~/globals';

import {
	tryLoadUserData,
} from '~/model/authentication';

import {
	GUIDE_ONBOARDING,
	getPendoGuideName,
} from '~/model/pendoGuides';

import matchAndReturn from '~/utilities/matchAndReturn';



enum Step {
	CustomerType,
	IsNumberOfEmployeesEnoughForOutOfBand,
	Name,
	Password,
	Welcome,
}

const messages = defineMessages({
	passwordStepBodyTextNameProvided: {
		id: 'ui.onboarding.passwordText',
	},
	passwordStepBodyTextNoNameProvided: {
		id: 'ui.onboarding.missingNameText',
	},
	step1BodyText: {
		id: 'ui.onboarding.step1.bodyText',
	},
});



type Props = {
	accountId: CK.AccountId,
	currentUserHasName: boolean,
	currentUserIsOwner: boolean,
	onCloseCallback: () => void,
};

const OnboardingGuide: React.FC<Props> = (props) => {
	const {
		accountId,
		currentUserHasName,
		currentUserIsOwner,
		onCloseCallback,
	} = props;

	const accountCustomerType = useAccountCustomerType(accountId);
	const accountIsNumberOfEmployeesEnoughForOutOfBand = useAccountIsNumberOfEmployeesEnoughForOutOfBand(accountId);

	const [finishOnboarding] = useFinishOnboardingMutation();

	const dispatch = useDispatch();

	const determineSteps = React.useCallback(
		(previousSteps: ReadonlyArray<Step>) => {
			const stepConditions = [
				{
					step: Step.CustomerType,
					condition: (
						currentUserIsOwner === true
						&& accountCustomerType === null
					),
				},
				{
					step: Step.IsNumberOfEmployeesEnoughForOutOfBand,
					condition: (
						currentUserIsOwner === true
						&& accountIsNumberOfEmployeesEnoughForOutOfBand === null
					),
				},
				{
					step: Step.Name,
					condition: (
						currentUserHasName === false
					),
				},
				{
					step: Step.Password,
					condition: (
						currentUserIsOwner === false
					),
				},
			];

			const result = stepConditions
				.filter(({ condition, step }) => previousSteps.includes(step) || condition)
				.map(({ step }) => step);

			if (result.length > 0) {
				result.unshift(Step.Welcome);
			}

			return result;
		},
		[
			accountCustomerType,
			accountIsNumberOfEmployeesEnoughForOutOfBand,
			currentUserHasName,
			currentUserIsOwner,
		],
	);

	const [activeSlideIndex, setActiveSlideIndex] = React.useState(0);
	const [steps, setSteps] = React.useState<ReadonlyArray<Step>>(() => determineSteps([]));

	React.useEffect(
		() => {
			setSteps(
				(steps) => determineSteps(steps),
			);
		},
		[
			determineSteps,
		],
	);

	const closeOnboardingTour = React.useCallback(
		async () => {
			await finishOnboarding();

			dispatch(
				tryLoadUserData(),
			);

			onCloseCallback();

			setTimeout(
				() => {
					pendo().then((pendo) => {
						pendo.loadGuides().then(() => {
							const onboardingGuide = pendo.findGuideByName(
								getPendoGuideName(
									GUIDE_ONBOARDING,
									'en-US',
								),
							);

							if (onboardingGuide && !onboardingGuide.isComplete()) {
								onboardingGuide.show();
							}
						});
					});
				},
				1000,
			);
		},
		[
			dispatch,
			finishOnboarding,
			onCloseCallback,
		],
	);

	const nextStepCallback = React.useCallback(
		() => {
			const nextSlideIndex = activeSlideIndex + 1;

			if (nextSlideIndex >= steps.length) {
				closeOnboardingTour();
			} else {
				setActiveSlideIndex(nextSlideIndex);
			}
		},
		[
			activeSlideIndex,
			closeOnboardingTour,
			steps,
		],
	);

	React.useEffect(
		() => {
			if (steps.length === 0) {
				closeOnboardingTour();
			}
		},
		[
			closeOnboardingTour,
			steps,
		],
	);

	const renderStep = (step: Step) => {
		return matchAndReturn(step, {
			[Step.CustomerType]: () => (
				<ChooseCustomerTypeModal
					onSubmitCallback={nextStepCallback}
				/>
			),
			[Step.IsNumberOfEmployeesEnoughForOutOfBand]: () => (
				<NumberOfEmployeesForm
					onSubmitCallback={nextStepCallback}
				/>
			),
			[Step.Name]: () => (
				<GuidedTour
					form={(
						<OnboardingNameForm
							onCancelCallback={nextStepCallback}
							onSubmitCallback={nextStepCallback}
						/>
					)}
				>
					<Copy
						{...messages.step1BodyText}
						values={{
							showFinally: steps[steps.length - 1] === Step.Name ? 'yes' : 'no',
						}}
					/>
				</GuidedTour>
			),
			[Step.Password]: () => (
				<GuidedTour
					form={(
						<OnboardingPasswordForm
							cancelCallback={nextStepCallback}
							submitCallback={nextStepCallback}
						/>
					)}
				>
					{currentUserHasName ? (
						<Copy {...messages.passwordStepBodyTextNameProvided} />
					) : (
						<Copy {...messages.passwordStepBodyTextNoNameProvided} />
					)}
				</GuidedTour>
			),
			[Step.Welcome]: () => (
				<OnboardingGuideWelcomeStep
					isForOwner={currentUserIsOwner}
					onSubmit={nextStepCallback}
				/>
			),
		})();
	};

	if (steps.length === 0) {
		return null;
	}

	return (
		<CarouselBlocks
			activeSlideIndex={activeSlideIndex}
			adaptiveHeight={true}
			animatedHeightChange={false}
			draggable={false}
			selectableText={true}
			swipe={false}
		>
			{steps.map((step) => (
				<React.Fragment key={step}>
					{renderStep(step)}
				</React.Fragment>
			))}
		</CarouselBlocks>
	);
};



export default OnboardingGuide;
