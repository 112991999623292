import React from 'react';
import FormFieldsContext from '~/components/atoms/forms/basis/FormFieldsContext';

import useFormContext from '~/hooks/useFormContext';



type Props = {
	children?: React.ReactNode,
	convertIn: (value: any) => any,
	convertOut: (value: any) => any,
	name: string,
};

const DefaultFilterValueHandler: React.FC<Props> = (props) => {
	const {
		children,
		convertIn,
		convertOut,
		name,
	} = props;

	const formContext = useFormContext();

	const originalOnChangeHandler = formContext.onChangeHandler;

	const onChangeHandler = React.useCallback(
		(field, value, options) => {
			return originalOnChangeHandler(
				field,
				convertOut(value),
				options,
			);
		},
		[
			convertOut,
			originalOnChangeHandler,
		],
	);

	const valuesSelector = React.useCallback(
		(values) => {
			return {
				...values,
				...{
					[name]: convertIn(values[name]),
				},
			};
		},
		[
			convertIn,
			name,
		],
	);

	const modifiedFormContext = React.useMemo(
		() => {
			return {
				...formContext,
				...{
					valuesSelector,
					onChangeHandler,
				},
			};
		},
		[
			formContext,
			onChangeHandler,
			valuesSelector,
		],
	);

	return (
		<FormFieldsContext context={modifiedFormContext}>
			{children}
		</FormFieldsContext>
	);
};



export default React.memo(DefaultFilterValueHandler);
