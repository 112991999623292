import memoizee from 'memoizee';



class Steps {

	constructor(steps) {
		this.steps = steps;

		this.getValueOfStep = this.getValueOfStep.bind(this);
		this.getStepForValue = this.getStepForValue.bind(this);
		this.size = this.size.bind(this);
	}



	getValueOfStep(step) {
		return this.steps[step];
	}



	getStepForValue(value) {
		const step = this.steps.indexOf(value);

		if (step !== -1) {
			return step;
		}

		for (const choice of this.steps) {
			if (choice >= value) {
				return this.steps.indexOf(choice);
			}
		}
	}



	size() {
		return this.steps.length;
	}

}



export function calculateAllPointsInRange(min, max, roundingCallback) {
	const allPoints = [
		min,
	];

	let position = min;
	let previousPosition;
	let stepDistance = 1;

	while (position < max) {
		previousPosition = position;

		position = roundingCallback(position + stepDistance);

		if (position > max) {
			break;
		}

		if (position === undefined || position === null) {
			break;
		}

		allPoints.push(position);

		if (previousPosition !== min) {
			stepDistance = position - previousPosition;
		}
	}

	return allPoints;
}



export const createPageBundleRange = memoizee((allPoints) => {
	const result = [];

	if (allPoints.length < 90) {
		return new Steps(allPoints);
	}

	allPoints.forEach((value) => {
		let pass = false;

		if (value <= 1000000) {
			pass = (value % 10000) === 0;
		} else if (value <= 2000000) {
			pass = (value % 100000) === 0;
		} else {
			pass = (value % 500000) === 0;
		}

		if (pass && result.indexOf(value) === -1) {
			result.push(value);
		}
	});

	return new Steps(result);
});



export const createPageCapacityRange = memoizee((allPoints) => {
	if (allPoints.length < 90) {
		return new Steps(allPoints);
	}

	const result = [];

	allPoints.forEach((value) => {
		let pass = false;

		if (value <= 1500) {
			pass = (value % 100) === 0;
		} else if (value <= 10000) {
			pass = (value % 500) === 0;
		} else if (value <= 50000) {
			pass = (value % 1000) === 0;
		} else if (value <= 200000) {
			pass = (value % 10000) === 0;
		} else if (value <= 600000) {
			pass = (value % 25000) === 0;
		} else if (value <= 1000000) {
			pass = (value % 50000) === 0;
		} else if (value <= 3000000) {
			pass = (value % 100000) === 0;
		} else {
			pass = (value % 250000) === 0;
		}

		if (pass && result.indexOf(value) === -1) {
			result.push(value);
		}
	});

	return new Steps(result);
});
