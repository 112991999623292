import Immutable from 'immutable';
import React from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import ChangeSummary, {
	PAGES_DIFF_CAPACITY,
} from '~/components/logic/pricing/ChangeSummary';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Form from '~/components/atoms/forms/basis/Form';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import PageCapacityField from '~/components/app/PageCapacityField';
import PaymentAuthorizationContext from '~/components/logic/PaymentAuthorizationContext';
import PaymentAuthorizationError from '~/components/logic/PaymentAuthorizationError';
import PaymentCancelButton from '~/components/logic/PaymentCancelButton';
import PaymentSubmitButton from '~/components/logic/PaymentSubmitButton';
import StaticText from '~/components/atoms/forms/components/StaticText';
import WithPermission from '~/components/logic/access/WithPermission';

import useAccountBillingCycle from '~/hooks/useAccountBillingCycle';
import useAccountCurrency from '~/hooks/useAccountCurrency';
import useAccountEndUserPlan from '~/hooks/useAccountEndUserPlan';
import useAccountPhase from '~/hooks/useAccountPhase';
import useAccountTariffCalculator from '~/hooks/useAccountTariffCalculator';
import useAllowedBillingCycles from '~/hooks/useAllowedBillingCycles';
import useLegacyBillingDetails from '~/hooks/useLegacyBillingDetails';
import useWebsiteAccountId from '~/hooks/useWebsiteAccountId';
import useWebsitePageCapacity from '~/hooks/useWebsitePageCapacity';
import useWebsitePagesCount from '~/hooks/useWebsitePagesCount';

import {
	ObjectType,
} from '~/model/permissions';

import {
	DETAIL_END_USER_PLAN,
	DETAIL_END_USER_PLAN_WEBSITES,
	DETAIL_END_USER_PLAN_WEBSITES_PAGES,
} from '~/model/pricing/endUser';



const messages = defineMessages({
	pagesLabel: {
		id: 'ui.websiteSettings.plan.pageCapacity',
	},
	saveButton: {
		id: 'ui.general.saveButton',
	},
	title: {
		id: 'ui.websiteSettings.plan.header.endUser',
	},
});



type Props = {
	submitCallback: (values: {
		threeDSecureToken: string | null,
		values: {
			pageCapacity: number,
		},
	}) => Promise<void>,
	websiteId: CK.WebsiteId,
};

const EditEndUserWebsiteSubscriptionForm: React.FC<Props> = (props) => {
	const {
		submitCallback,
		websiteId,
	} = props;

	const websiteAccountId = useWebsiteAccountId(websiteId);

	const accountAllowedBillingCycles = useAllowedBillingCycles(websiteAccountId);
	const accountBillingCycle = useAccountBillingCycle(websiteAccountId);
	const accountCurrency = useAccountCurrency(websiteAccountId);
	const accountEndUserPlan = useAccountEndUserPlan(websiteAccountId);
	const accountPhase = useAccountPhase(websiteAccountId);
	const accountTariffCalculator = useAccountTariffCalculator(websiteAccountId);
	const teamBilling = useLegacyBillingDetails(websiteAccountId);
	const websitePageCapacity = useWebsitePageCapacity(websiteId);
	const websitePagesCount = useWebsitePagesCount(websiteId);

	const [selectedPageCapacity, setSelectedPageCapacity] = React.useState<number | null>(null);

	const calculatePrice = React.useCallback(
		(pageCapacity: number) => {
			if (
				accountBillingCycle === null
				|| accountEndUserPlan === null
				|| accountTariffCalculator.isReady === false
				|| teamBilling === null
			) {
				return null;
			}

			const discounts = teamBilling.get('discounts').filter((discount) => discount.get('type') !== 'dollars');
			const taxRate = teamBilling.get('tax');

			return accountTariffCalculator.tariff.calculatePurchaseCostDetails({
				billingCycle: accountBillingCycle,
				discounts,
				purchases: [
					{
						details: {
							[DETAIL_END_USER_PLAN]: accountEndUserPlan,
							[DETAIL_END_USER_PLAN_WEBSITES]: Immutable.List([
								Immutable.Map({
									[DETAIL_END_USER_PLAN_WEBSITES_PAGES]: pageCapacity,
								}),
							]),
						},
						numberOfPages: pageCapacity,
					},
				],
				taxRate,
			});
		},
		[
			accountBillingCycle,
			accountEndUserPlan,
			accountTariffCalculator,
			teamBilling,
		],
	);

	const handleFormChange = React.useCallback(
		(field: string, value: number) => {
			if (field === 'pageCapacity') {
				setSelectedPageCapacity(value);
			}
		},
		[],
	);

	const handleToggleEditMode = React.useCallback(
		(editMode) => {
			if (editMode) {
				setSelectedPageCapacity(
					(state) => state ?? websitePageCapacity,
				);
			} else {
				setSelectedPageCapacity(null);
			}
		},
		[
			websitePageCapacity,
		],
	);

	const renderChangesOverview = () => {
		if (
			accountAllowedBillingCycles === null
			|| accountBillingCycle === null
			|| accountCurrency === null
			|| accountPhase !== GraphQL.AccountPhase.Customer
			|| selectedPageCapacity === null
			|| !teamBilling.get('tax')
			|| websitePageCapacity === null
			|| websitePageCapacity === selectedPageCapacity
		) {
			return null;
		}

		const priceOld = calculatePrice(websitePageCapacity);
		const priceNew = calculatePrice(selectedPageCapacity);

		if (priceNew === null || priceOld === null) {
			return false;
		}

		return (
			<ChangeSummary
				accountId={websiteAccountId}
				allowedBillingCycles={accountAllowedBillingCycles}
				billingCycleNew={accountBillingCycle}
				billingCycleOld={accountBillingCycle}
				currency={accountCurrency}
				hidePrice={false}
				pagesDiff={PAGES_DIFF_CAPACITY}
				pagesNew={selectedPageCapacity}
				pagesOld={websitePageCapacity}
				priceNew={priceNew.subtotal}
				priceOld={priceOld.subtotal}
				taxType={priceNew.tax > 0 ? GraphQL.TaxType.Vat : null}
			/>
		);
	};

	const defaultValues = React.useMemo(
		() => ({
			pageCapacity: websitePageCapacity,
		}),
		[
			websitePageCapacity,
		],
	);

	return (
		<WithPermission
			action={GraphQL.ActionWithWebsite.UpdatePageCapacity}
			objectId={websiteId}
			objectType={ObjectType.Website}
			showMessage={false}
		>
			{({ isAllowed }) => (
				<EditableFormWrapper
					footer={({ editMode }) => editMode && renderChangesOverview()}
					isAllowed={isAllowed}
					key={websiteId}
					onEnterEditModeCallback={handleToggleEditMode}
					title={(
						<FormattedMessage {...messages.title} />
					)}
				>
					<DisplayPart>
						<FormRows>
							<FormRow
								label={(
									<FormattedMessage {...messages.pagesLabel} />
								)}
							>
								{websitePageCapacity !== null && websitePagesCount !== null && (
									<StaticText>
										<FormattedNumber value={Math.min(websitePagesCount, websitePageCapacity)} />
										&nbsp;/&nbsp;
										<FormattedNumber value={websitePageCapacity} />
										&nbsp;
										<span className="percentage">
											({(
												<FormattedNumber
													maximumFractionDigits={2}
													minimumFractionDigits={1}
													style="percent"
													value={Math.min(websitePagesCount, websitePageCapacity) / websitePageCapacity}
												/>
											)})
										</span>
									</StaticText>
								)}
							</FormRow>
						</FormRows>
					</DisplayPart>

					<EditablePart>
						<PaymentAuthorizationContext
							onSubmit={submitCallback}
							useModal={true}
						>
							{({ formRef, handleFormSuccess }) => (
								<Form
									defaultFocus="pageCapacity"
									defaultValues={defaultValues}
									onChangeCallback={handleFormChange}
									onSuccess={handleFormSuccess}
									ref={formRef}
								>
									<FormRows>
										<FormRow
											htmlFor="pageCapacity"
											label={(
												<FormattedMessage {...messages.pagesLabel} />
											)}
										>
											<PageCapacityField
												accountId={websiteAccountId}
												name="pageCapacity"
												numberOfPages={
													(websitePagesCount !== null && websitePageCapacity !== null)
														? Math.min(websitePagesCount, websitePageCapacity)
														: undefined
												}
											/>
										</FormRow>
									</FormRows>

									<PaymentAuthorizationError />

									<ButtonsLayout>
										<PaymentCancelButton />

										<PaymentSubmitButton>
											<FormattedMessage {...messages.saveButton} />
										</PaymentSubmitButton>
									</ButtonsLayout>
								</Form>
							)}
						</PaymentAuthorizationContext>
					</EditablePart>
				</EditableFormWrapper>
			)}
		</WithPermission>
	);
};



export default EditEndUserWebsiteSubscriptionForm;
