import React from 'react';

import AdobeAnalyticsIcon, {
	AdobeAnalyticsIconVariants,
} from './builders/iconsData/basicIcon/AdobeAnalytics';
import AkamaiIcon, {
	AkamaiIconVariants,
} from './builders/iconsData/basicIcon/Akamai';
import AlertsIcon from './builders/iconsData/basicIcon/Alerts';
import APIIcon from './builders/iconsData/basicIcon/API';
import ArrowLeftIcon from './builders/iconsData/basicIcon/ArrowLeft';
import ArrowRightIcon from './builders/iconsData/basicIcon/ArrowRight';
import AvailableIcon from './builders/iconsData/basicIcon/Available';
import AWSCloudFrontIcon, {
	AWSCloudFrontIconVariants,
} from './builders/iconsData/basicIcon/AWSCloudFront';
import BingIcon from './builders/iconsData/basicIcon/Bing';
import BlockIcon from './builders/iconsData/basicIcon/Block';
import BugFixedIcon from './builders/iconsData/basicIcon/BugFixed';
import BugOpenedIcon from './builders/iconsData/basicIcon/BugOpened';
import CalendarIcon from './builders/iconsData/basicIcon/Calendar';
import CancelIcon from './builders/iconsData/basicIcon/Cancel';
import CancelReversedIcon from './builders/iconsData/basicIcon/CancelReversed';
import ClipboardIcon from './builders/iconsData/basicIcon/Clipboard';
import CloudflareIcon, {
	CloudflareIconVariants,
} from './builders/iconsData/basicIcon/Cloudflare';
import CogWheelIcon from './builders/iconsData/basicIcon/CogWheel';
import ColumnsIcon from './builders/iconsData/basicIcon/Columns';
import CopyURLIcon from './builders/iconsData/basicIcon/CopyURL';
import CoreWebVitalsIcon from './builders/iconsData/basicIcon/CoreWebVitals';
import CoreWebVitalsMonitoringIcon from './builders/iconsData/basicIcon/CoreWebVitalsMonitoring';
import CrawlingIcon from './builders/iconsData/basicIcon/Crawling';
import DeleteIcon from './builders/iconsData/basicIcon/Delete';
import DeleteUndoIcon from './builders/iconsData/basicIcon/DeleteUndo';
import DesktopIcon from './builders/iconsData/basicIcon/Desktop';
import DownloadIcon from './builders/iconsData/basicIcon/Download';
import ExclamationIcon from './builders/iconsData/basicIcon/Exclamation';
import ExclusionListIcon from './builders/iconsData/basicIcon/ExclusionList';
import ExportIcon from './builders/iconsData/basicIcon/Export';
import ExternalLinkIcon from './builders/iconsData/basicIcon/ExternalLink';
import FastlyIcon, {
	FastlyIconVariants,
} from './builders/iconsData/basicIcon/Fastly';
import FilterIcon from './builders/iconsData/basicIcon/Filter';
import GlobeIcon from './builders/iconsData/basicIcon/Globe';
import GoogleAnalyticsIcon, {
	GoogleAnalyticsIconVariants,
} from './builders/iconsData/basicIcon/GoogleAnalytics';
import GoogleIcon from './builders/iconsData/basicIcon/Google';
import GoogleSearchConsoleIcon, {
	GoogleSearchConsoleIconVariants,
} from './builders/iconsData/basicIcon/GoogleSearchConsole';
import GraphsIcon from './builders/iconsData/basicIcon/Graphs';
import GridIcon from './builders/iconsData/basicIcon/Grid';
import HeartIcon, {
	HeartIconVariants,
} from './builders/iconsData/basicIcon/Heart';
import HistoryIcon from './builders/iconsData/basicIcon/History';
import HourglassIcon from './builders/iconsData/basicIcon/Hourglass';
import ChromeIcon from './builders/iconsData/basicIcon/Chrome';
import ChromeWebStoreIcon, {
	ChromeWebStoreIconVariants,
} from './builders/iconsData/basicIcon/ChromeWebStore';
import ImportIcon from './builders/iconsData/basicIcon/Import';
import JavaScriptIcon from './builders/iconsData/basicIcon/JavaScript';
import LinkIcon from './builders/iconsData/basicIcon/Link';
import LockIcon from './builders/iconsData/basicIcon/Lock';
import LookerStudioIcon, {
	LookerStudioIconVariants,
} from './builders/iconsData/basicIcon/LookerStudio';
import MagicWandIcon from './builders/iconsData/basicIcon/MagicWand';
import MessagingAppIcon from './builders/iconsData/basicIcon/MessagingApp';
import MicrosoftTeamsIcon, {
	MicrosoftTeamsIconVariants,
} from './builders/iconsData/basicIcon/MicrosoftTeams';
import MobileIcon from './builders/iconsData/basicIcon/Mobile';
import NewTabIcon from './builders/iconsData/basicIcon/NewTab';
import PagesIcon from './builders/iconsData/basicIcon/Pages';
import PasswordHideIcon from './builders/iconsData/basicIcon/PasswordHide';
import PasswordShowIcon from './builders/iconsData/basicIcon/PasswordShow';
import PauseIcon from './builders/iconsData/basicIcon/Pause';
import PeopleIcon from './builders/iconsData/basicIcon/People';
import PersonIcon from './builders/iconsData/basicIcon/Person';
import PlusIcon from './builders/iconsData/basicIcon/Plus';
import PriorityIcon from './builders/iconsData/basicIcon/Priority';
import ProtectedIcon from './builders/iconsData/basicIcon/Protected';
import RealtimeIcon from './builders/iconsData/basicIcon/Realtime';
import RealtimeIndexNowIcon, {
	RealtimeIndexNowIconVariants,
} from './builders/iconsData/basicIcon/RealtimeIndexNow';
import RecheckIcon from './builders/iconsData/basicIcon/Recheck';
import ResetIcon from './builders/iconsData/basicIcon/Reset';
import ScopeIcon from './builders/iconsData/basicIcon/Scope';
import SegmentIcon from './builders/iconsData/basicIcon/Segment';
import SendIcon from './builders/iconsData/basicIcon/Send';
import SensitivityIcon from './builders/iconsData/basicIcon/Sensitivity';
import SheetIcon from './builders/iconsData/basicIcon/Sheet';
import SlackIcon, {
	SlackIconVariants,
} from './builders/iconsData/basicIcon/Slack';
import SmileyIcon from './builders/iconsData/basicIcon/Smiley';
import SnapshotIcon, {
	SnapshotIconVariants,
} from './builders/iconsData/basicIcon/Snapshot';
import SpinnerIcon from './builders/iconsData/basicIcon/Spinner';
import StarIcon from './builders/iconsData/basicIcon/Star';
import SummaryIcon from './builders/iconsData/basicIcon/Summary';
import TeamIcon from './builders/iconsData/basicIcon/Team';
import ThumbsDownIcon from './builders/iconsData/basicIcon/ThumbsDown';
import ThumbsUpIcon from './builders/iconsData/basicIcon/ThumbsUp';
import TickIcon from './builders/iconsData/basicIcon/Tick';
import UnavailableIcon from './builders/iconsData/basicIcon/Unavailable';
import UploadIcon from './builders/iconsData/basicIcon/Upload';
import VersionsIcon from './builders/iconsData/basicIcon/Versions';
import WordPressIcon, {
	WordPressIconVariants,
} from './builders/iconsData/basicIcon/WordPress';

import InlineIcon, {
	InlineIconSpinning as BasicIconSpinning,
} from './builders/InlineIcon.part';

import {
	type Merge,
} from '~/types/utilities';



export enum BasicIconType {
	AdobeAnalytics = 'adobe-analytics',
	Akamai = 'akamai',
	Alerts = 'alerts',
	Api = 'api',
	ArrowLeft = 'arrow-left',
	ArrowRight = 'arrow-right',
	Available = 'available',
	AWSCloudFront = 'aws-cloud-front',
	Bing = 'bing',
	Block = 'block',
	BugFixed = 'bug-fixed',
	BugOpened = 'bug-opened',
	Calendar = 'calendar',
	Cancel = 'cancel',
	CancelReversed = 'cancel-reversed',
	Clipboard = 'clipboard',
	Cloudflare = 'cloudflare',
	CogWheel = 'cog-wheel',
	Columns = 'columns',
	CopyURL = 'copy-url',
	CoreWebVitals = 'core-web-vitals',
	CoreWebVitalsMonitoring = 'core-web-vitals-monitoring',
	Crawling = 'crawling',
	Delete = 'delete',
	DeleteUndo = 'delete-undo',
	Desktop = 'desktop',
	Download = 'download',
	Exclamation = 'exclamation',
	ExclusionList = 'exclusion-list',
	Export = 'export',
	ExternalLink = 'external-link',
	Fastly = 'fastly',
	Filter = 'filter',
	Globe = 'globe',
	Google = 'google',
	GoogleAnalytics = 'google-analytics',
	GoogleSearchConsole = 'google-search-console',
	Graphs = 'graphs',
	Grid = 'grid',
	Heart = 'heart',
	History = 'history',
	Hourglass = 'hourglass',
	Chrome = 'chrome',
	ChromeWebStore = 'chrome-web-store',
	Import = 'import',
	JavaScript = 'javascript',
	Link = 'link',
	Lock = 'lock',
	LookerStudio = 'looker-studio',
	MagicWand = 'magic-wand',
	MessagingApp = 'messaging-app',
	MicrosoftTeams = 'ms-teams',
	Mobile = 'mobile',
	NewTab = 'new-tab',
	Pages = 'pages',
	PasswordHide = 'password-hide',
	PasswordShow = 'password-show',
	Pause = 'pause',
	People = 'people',
	Person = 'person',
	Plus = 'plus',
	Priority = 'priority',
	Protected = 'protected',
	Realtime = 'Realtime',
	RealtimeIndexNow = 'real-time-index-now',
	Recheck = 'recheck',
	Reset = 'reset',
	Scope = 'scope',
	Segment = 'segment',
	Send = 'send',
	Sensitivity = 'sensitivity',
	Sheet = 'sheet',
	Slack = 'slack',
	Smiley = 'smiley',
	Snapshot = 'snapshot',
	Spinner = 'spinner',
	Star = 'star',
	Summary = 'summary',
	Team = 'team',
	ThumbsDown = 'thumbs-down',
	ThumbsUp = 'thumbs-up',
	Tick = 'tick',
	Unavailable = 'unavailable',
	Upload = 'upload',
	Versions = 'versions',
	WordPress = 'wordpress',
}

const componentMapping = {
	[BasicIconType.AdobeAnalytics]: AdobeAnalyticsIcon,
	[BasicIconType.Akamai]: AkamaiIcon,
	[BasicIconType.Alerts]: AlertsIcon,
	[BasicIconType.Api]: APIIcon,
	[BasicIconType.ArrowLeft]: ArrowLeftIcon,
	[BasicIconType.ArrowRight]: ArrowRightIcon,
	[BasicIconType.Available]: AvailableIcon,
	[BasicIconType.AWSCloudFront]: AWSCloudFrontIcon,
	[BasicIconType.Bing]: BingIcon,
	[BasicIconType.Block]: BlockIcon,
	[BasicIconType.BugFixed]: BugFixedIcon,
	[BasicIconType.BugOpened]: BugOpenedIcon,
	[BasicIconType.Calendar]: CalendarIcon,
	[BasicIconType.Cancel]: CancelIcon,
	[BasicIconType.CancelReversed]: CancelReversedIcon,
	[BasicIconType.Clipboard]: ClipboardIcon,
	[BasicIconType.Cloudflare]: CloudflareIcon,
	[BasicIconType.CogWheel]: CogWheelIcon,
	[BasicIconType.Columns]: ColumnsIcon,
	[BasicIconType.CopyURL]: CopyURLIcon,
	[BasicIconType.CoreWebVitals]: CoreWebVitalsIcon,
	[BasicIconType.CoreWebVitalsMonitoring]: CoreWebVitalsMonitoringIcon,
	[BasicIconType.Crawling]: CrawlingIcon,
	[BasicIconType.Delete]: DeleteIcon,
	[BasicIconType.DeleteUndo]: DeleteUndoIcon,
	[BasicIconType.Desktop]: DesktopIcon,
	[BasicIconType.Download]: DownloadIcon,
	[BasicIconType.Exclamation]: ExclamationIcon,
	[BasicIconType.ExclusionList]: ExclusionListIcon,
	[BasicIconType.Export]: ExportIcon,
	[BasicIconType.ExternalLink]: ExternalLinkIcon,
	[BasicIconType.Fastly]: FastlyIcon,
	[BasicIconType.Filter]: FilterIcon,
	[BasicIconType.Globe]: GlobeIcon,
	[BasicIconType.Google]: GoogleIcon,
	[BasicIconType.GoogleAnalytics]: GoogleAnalyticsIcon,
	[BasicIconType.GoogleSearchConsole]: GoogleSearchConsoleIcon,
	[BasicIconType.Graphs]: GraphsIcon,
	[BasicIconType.Grid]: GridIcon,
	[BasicIconType.Heart]: HeartIcon,
	[BasicIconType.History]: HistoryIcon,
	[BasicIconType.Hourglass]: HourglassIcon,
	[BasicIconType.Chrome]: ChromeIcon,
	[BasicIconType.ChromeWebStore]: ChromeWebStoreIcon,
	[BasicIconType.Import]: ImportIcon,
	[BasicIconType.JavaScript]: JavaScriptIcon,
	[BasicIconType.Link]: LinkIcon,
	[BasicIconType.Lock]: LockIcon,
	[BasicIconType.LookerStudio]: LookerStudioIcon,
	[BasicIconType.MagicWand]: MagicWandIcon,
	[BasicIconType.MessagingApp]: MessagingAppIcon,
	[BasicIconType.MicrosoftTeams]: MicrosoftTeamsIcon,
	[BasicIconType.Mobile]: MobileIcon,
	[BasicIconType.NewTab]: NewTabIcon,
	[BasicIconType.Pages]: PagesIcon,
	[BasicIconType.PasswordHide]: PasswordHideIcon,
	[BasicIconType.PasswordShow]: PasswordShowIcon,
	[BasicIconType.Pause]: PauseIcon,
	[BasicIconType.People]: PeopleIcon,
	[BasicIconType.Person]: PersonIcon,
	[BasicIconType.Plus]: PlusIcon,
	[BasicIconType.Priority]: PriorityIcon,
	[BasicIconType.Protected]: ProtectedIcon,
	[BasicIconType.Realtime]: RealtimeIcon,
	[BasicIconType.RealtimeIndexNow]: RealtimeIndexNowIcon,
	[BasicIconType.Recheck]: RecheckIcon,
	[BasicIconType.Reset]: ResetIcon,
	[BasicIconType.Scope]: ScopeIcon,
	[BasicIconType.Segment]: SegmentIcon,
	[BasicIconType.Send]: SendIcon,
	[BasicIconType.Sensitivity]: SensitivityIcon,
	[BasicIconType.Sheet]: SheetIcon,
	[BasicIconType.Slack]: SlackIcon,
	[BasicIconType.Smiley]: SmileyIcon,
	[BasicIconType.Snapshot]: SnapshotIcon,
	[BasicIconType.Spinner]: SpinnerIcon,
	[BasicIconType.Star]: StarIcon,
	[BasicIconType.Summary]: SummaryIcon,
	[BasicIconType.ThumbsDown]: ThumbsDownIcon,
	[BasicIconType.ThumbsUp]: ThumbsUpIcon,
	[BasicIconType.Team]: TeamIcon,
	[BasicIconType.Tick]: TickIcon,
	[BasicIconType.Unavailable]: UnavailableIcon,
	[BasicIconType.Upload]: UploadIcon,
	[BasicIconType.Versions]: VersionsIcon,
	[BasicIconType.WordPress]: WordPressIcon,
} as const;



type BaseProps<IconType extends BasicIconType = BasicIconType> = {
	/** Custom icon color */
	color?: string,
	/** Show icon in grayscale */
	grayscale?: boolean,
	/** Square size of icon */
	size?: number,
	/** Spinning animation */
	spinning?: BasicIconSpinning,
	/** Possibility to change speed of spinning animation */
	spinningAnimationSpeed?: number,
	/** Type of icon */
	type: IconType,
};

type IconProps<IconType extends BasicIconType = BasicIconType> = (
	Parameters<typeof componentMapping[IconType]['icon']>[0]
);

type Props<IconType extends BasicIconType = BasicIconType> = Merge<IconProps<IconType>, BaseProps<IconType>>;

const BasicIcon = <IconType extends BasicIconType>(props: Props<IconType>): React.ReactElement => {
	const {
		grayscale = false,
		color,
		size = 24,
		spinning = BasicIconSpinning.Disabled,
		spinningAnimationSpeed = 1,
		type,
		...rest
	} = props;

	const icon = React.useMemo(
		() => {
			return componentMapping[type].icon({
				color,
				size,
				...rest,
			});
		},
		[
			color,
			rest,
			size,
			type,
		],
	);

	return (
		<InlineIcon
			gap={true}
			grayscale={grayscale}
			icon={icon}
			size={size}
			spinning={spinning}
			spinningAnimationSpeed={spinningAnimationSpeed}
			viewBoxHeight={componentMapping[type].viewBoxHeight}
			viewBoxWidth={componentMapping[type].viewBoxWidth}
		/>
	);
};



export {
	AdobeAnalyticsIconVariants,
	AkamaiIconVariants,
	AWSCloudFrontIconVariants,
	BasicIconSpinning,
	CloudflareIconVariants,
	FastlyIconVariants,
	GoogleAnalyticsIconVariants,
	GoogleSearchConsoleIconVariants,
	HeartIconVariants,
	ChromeWebStoreIconVariants,
	LookerStudioIconVariants,
	MicrosoftTeamsIconVariants,
	RealtimeIndexNowIconVariants,
	SlackIconVariants,
	SnapshotIconVariants,
	WordPressIconVariants,
};

// We need to cast the memoized component to the original component type
// to preserve the typing of extra props (mostly `variant`) based on the
// `type` prop.
const MemoBasicIcon = React.memo(BasicIcon) as typeof BasicIcon;

export default MemoBasicIcon;
