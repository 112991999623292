import GraphQL from '~/types/graphql';

import {
	type Tariff,
	choosePlan,
	convertDetailsToAddons,
	convertPagesToAddons,
} from './tariffs';

import roundWithPrecision from '~/utilities/roundWithPrecision';



export type TariffDefinition = {
	plans: Record<
		string,
		Record<
			string,
			Record<GraphQL.Currency, number>
		>
	>,
};

export type Discount = {
	code: string,
	rate: number,
	type: GraphQL.DiscountType.Percent,
} | {
	amount: Record<GraphQL.Currency, number>,
	code: string,
	type: GraphQL.DiscountType.Dollars,
};

export type Purchase<TDetails extends Record<string, any>> = {
	details: TDetails,
	numberOfPages: number,
};



export function getAddonPrice({ addon, currency, plan, tariff, tariffs }: {
	addon: string,
	currency: GraphQL.Currency,
	plan: string,
	tariff: Tariff,
	tariffs: Record<Tariff, TariffDefinition>,
}) {
	return tariffs[tariff].plans[plan]?.[addon]?.[currency];
}



export function getPlanPrice({ currency, plan, tariff, tariffs }: {
	currency: GraphQL.Currency,
	plan: string,
	tariff: Tariff,
	tariffs: Record<Tariff, TariffDefinition>,
}) {
	return tariffs[tariff].plans[plan]?.base?.[currency];
}



function normalizeDiscounts(discounts: Array<Discount>, currency: GraphQL.Currency) {
	discounts = discounts.slice(0);

	discounts.sort((discountA, discountB) => {
		if (discountA.type === GraphQL.DiscountType.Percent && discountB.type === GraphQL.DiscountType.Percent) {
			if (discountA.rate === discountB.rate) {
				return 0;
			}

			return discountA.rate > discountB.rate ? -1 : 1;
		}

		if (discountA.type === GraphQL.DiscountType.Dollars && discountB.type === GraphQL.DiscountType.Dollars) {
			if (discountA.amount[currency] === discountB.amount[currency]) {
				return 0;
			}

			return discountA.amount[currency] > discountB.amount[currency] ? -1 : 1;
		}

		if (discountA.type === GraphQL.DiscountType.Percent && discountB.type === GraphQL.DiscountType.Dollars) {
			return -1;
		}

		if (discountB.type === GraphQL.DiscountType.Percent && discountA.type === GraphQL.DiscountType.Dollars) {
			return 1;
		}

		return 0;
	});

	return discounts;
}



export function calculatePurchaseCost({ billingCycle, currency, discounts = [], purchase, tariff, tariffs }: {
	billingCycle: GraphQL.Term,
	currency: GraphQL.Currency,
	discounts: Array<Discount>,
	purchase: Purchase<Record<string, any>>,
	tariff: Tariff,
	tariffs: Record<Tariff, TariffDefinition>,
}) {
	const {
		details,
		numberOfPages,
	} = purchase;

	const addons = Object.assign(
		convertPagesToAddons({
			numberOfPages,
			tariff,
		}),
		convertDetailsToAddons({
			details,
			tariff,
		}),
	);

	const plan = choosePlan({
		billingCycle,
		details,
		tariff,
	});

	return calculateCost({
		addons,
		currency,
		discounts,
		plan,
		tariff,
		tariffs,
	});
}



function calculateCost({ addons, currency, discounts = [], plan, tariff, tariffs }: {
	addons: Record<string, number>,
	currency: GraphQL.Currency,
	discounts: Array<Discount>,
	plan: string,
	tariff: Tariff,
	tariffs: Record<Tariff, TariffDefinition>,
}) {
	const planPrice = getPlanPrice({
		currency,
		plan,
		tariff,
		tariffs,
	});

	if (planPrice === undefined) {
		return null;
	}

	let pagesCost = 0;

	for (const [addon, addonAmount] of Object.entries(addons)) {
		const addonPrice = getAddonPrice({
			addon,
			currency,
			plan,
			tariff,
			tariffs,
		});

		if (addonPrice === undefined) {
			return null;
		}

		pagesCost += addonPrice * addonAmount;
	}

	let totalCost = planPrice + pagesCost;

	discounts.forEach((discount) => {
		if (discount.type === GraphQL.DiscountType.Dollars) {
			totalCost -= discount.amount[currency];
		}
	});

	totalCost = Math.max(0, totalCost);

	discounts.forEach((discount) => {
		if (discount.type === GraphQL.DiscountType.Percent) {
			totalCost -= roundWithPrecision(totalCost * discount.rate, 2);
		}
	});

	return totalCost;
}



export function calculatePurchaseCostDetails({
	billingCycle,
	currency,
	discounts = [],
	purchases,
	tariff,
	tariffs,
	taxRate = 0.0,
	taxType,
}: {
	billingCycle: GraphQL.Term,
	currency: GraphQL.Currency,
	discounts: Array<Discount>,
	purchases: Array<Purchase<Record<string, any>>>,
	tariff: Tariff,
	tariffs: Record<Tariff, TariffDefinition>,
	taxRate: number,
	taxType: GraphQL.TaxType | null,
}) {
	discounts = normalizeDiscounts(discounts, currency);

	let costWithCommitmentDiscount = 0.0;

	for (const purchase of purchases) {
		const purchaseCost = calculatePurchaseCost({
			billingCycle,
			currency,
			discounts: [],
			purchase,
			tariff,
			tariffs,
		});

		if (purchaseCost === null) {
			throw new Error(`purchaseCost can't be null`);
		}

		costWithCommitmentDiscount += purchaseCost;
	}

	let baseForDiscountValues = costWithCommitmentDiscount;

	const resultDiscounts = discounts.map((discount) => {
		let discountValue = 0;

		if (discount.type === GraphQL.DiscountType.Percent) {
			discountValue = roundWithPrecision(baseForDiscountValues * discount.rate, 2);
		} else {
			discountValue = discount.amount[currency];
		}

		baseForDiscountValues -= discountValue;

		if (discount.type === GraphQL.DiscountType.Percent) {
			return {
				code: discount.code,
				rate: discount.rate,
				reason: 'custom',
				type: discount.type,
				value: discountValue,
			};
		}

		return {
			amount: discount.amount[currency],
			code: discount.code,
			reason: 'custom',
			type: discount.type,
			value: discountValue,
		};
	});

	const subtotal = baseForDiscountValues;
	const tax = roundWithPrecision(taxRate * subtotal, 2);
	const resultTaxType = tax > 0 ? (taxType ?? null) : null;
	const total = subtotal + tax;

	return {
		billingCycle,
		currency,
		cost: costWithCommitmentDiscount,
		discounts: resultDiscounts,
		subtotal,
		tax,
		taxRate,
		taxType: resultTaxType,
		total,
	};
}
