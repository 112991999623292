import React from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import BillingCycleField from '~/components/logic/formFields/BillingCycleField';
import BillingCycleName from '~/components/names/BillingCycleName';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import Copy from '~/components/logic/Copy';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms//basis/EditablePart';
import Form from '~/components/atoms/forms//basis/Form';
import FormRow from '~/components/atoms/forms//basis/FormRow';
import FormRows from '~/components/atoms/forms//basis/FormRows';
import PageBundleField from '~/components/app/PageBundleField';
import PlanField from '~/components/app/PlanField';
import PlanName from '~/components/names/PlanName';
import Parenthesis from '~/components/logic/Parenthesis';
import PaymentAuthorizationContext from '~/components/logic/PaymentAuthorizationContext';
import PaymentAuthorizationError from '~/components/logic/PaymentAuthorizationError';
import PaymentCancelButton from '~/components/logic/PaymentCancelButton';
import PaymentFailureFormError from '~/components/app/PaymentFailureFormError';
import PaymentSubmitButton from '~/components/logic/PaymentSubmitButton';
import StaticText from '~/components/atoms/forms/components/StaticText';
import SubscriptionChangeSummary from '~/components/app/SubscriptionChangeSummary';

import {
	useUpdateSubscriptionMutation,
} from './SubscriptionForm.gql';

import useAccountBillingCycle from '~/hooks/useAccountBillingCycle';
import useAccountFeaturesUsage from '~/hooks/useAccountFeaturesUsage';
import useAccountIsOutOfBand from '~/hooks/useAccountIsOutOfBand';
import useAccountOutOfBandPlans from '~/hooks/useAccountOutOfBandPlans';
import useAccountPaymentStatus from '~/hooks/useAccountPaymentStatus';
import useAccountPlan from '~/hooks/useAccountPlan';
import useAccountTariff from '~/hooks/useAccountTariff';
import useAccountWebsiteSizes from '~/hooks/useAccountWebsiteSizes';
import useAllowedBillingCycles from '~/hooks/useAllowedBillingCycles';
import useAllowedPlans from '~/hooks/useAllowedPlans';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';
import usePageBundle from '~/hooks/usePageBundle';
import useRequestAdminConfirmationIfApplicable from '~/hooks/useRequestAdminConfirmationIfApplicable';

import {
	Tariff,
} from '~/model/pricing/tariffs';



const messages = defineMessages({
	agencyPlanLabel: {
		id: 'ui.teamDetail.billing.agencyPackage.plan',
	},
	billingCycleLabel: {
		id: 'ui.teamDetail.billing.agencyPackage.billingCycle',
	},
	cannotSubmitDueToPaymentIssues: {
		id: 'ui.subscriptionForm.cannotSubmitDueToPaymentIssues',
	},
	cannotSubmitDueToSelectingOutOfBandPlan: {
		id: 'ui.subscriptionForm.cannotSubmitDueToSelectingOutOfBandPlan',
	},
	contactBoxTitle: {
		id: 'ui.billing.needHelpPickingPlan.header',
	},
	downgradeBoxDescription: {
		id: 'ui.downgradeBox.message',
	},
	downgradeBoxTitle: {
		id: 'ui.downgradeBox.title',
	},
	formErrorsBlank: {
		id: 'ui.formErrors.blank',
	},
	pageBundleLabel: {
		id: 'ui.teamDetail.billing.agencyPackage.pageBundle',
	},
	saveButton: {
		id: 'ui.teamDetail.billing.package.confirmationButton',
	},
	title: {
		id: 'ui.teamDetail.subscriptionForm.header',
	},
});



type Props = {
	accountId: CK.AccountId | null,
	onEnterEditModeCallback: (editMode: boolean) => void,
	preselectedPlan: GraphQL.AccountPlan | null,
};

const SubscriptionForm: React.FC<Props> = (props) => {
	const {
		accountId,
		onEnterEditModeCallback,
		preselectedPlan,
	} = props;

	const accountBillingCycle = useAccountBillingCycle(accountId);
	const accountFeaturesUsage = useAccountFeaturesUsage(accountId);
	const accountIsOutOfBand = useAccountIsOutOfBand(accountId);
	const accountOutOfBandPlans = useAccountOutOfBandPlans(accountId);
	const accountPageBundle = usePageBundle(accountId);
	const accountPaymentStatus = useAccountPaymentStatus(accountId);
	const accountPlan = useAccountPlan(accountId);
	const accountTariff = useAccountTariff(accountId);
	const accountWebsiteSizes = useAccountWebsiteSizes(accountId);
	const allowedBillingCycles = useAllowedBillingCycles(accountId);
	const allowedPlans = useAllowedPlans(accountId);
	const requestAdminConfirmationIfApplicable = useRequestAdminConfirmationIfApplicable(accountId);

	const isAllowedToSpendMoney = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.SpendMoney,
	);

	const [updateSubscription] = useUpdateSubscriptionMutation();

	const handleSubmit = React.useCallback(
		async ({ values }) => {
			if (accountId === null || accountTariff === null) {
				return;
			}

			await requestAdminConfirmationIfApplicable();

			await updateSubscription({
				variables: {
					accountId,
					billingCycle: values.billingCycle,
					pageBundle: values.pageBundle,
					plan: values.plan,
					tariff: accountTariff,
				},
			});
		},
		[
			accountId,
			accountTariff,
			requestAdminConfirmationIfApplicable,
			updateSubscription,
		],
	);

	if (
		accountId === null
		|| accountFeaturesUsage === null
		|| accountBillingCycle === null
		|| accountOutOfBandPlans === null
		|| accountPageBundle === null
		|| accountPaymentStatus === null
		|| accountPlan === null
		|| accountTariff === null
		|| accountWebsiteSizes === null
		|| allowedBillingCycles === null
		|| allowedPlans === null
	) {
		return null;
	}

	return (
		<EditableFormWrapper
			isAllowed={isAllowedToSpendMoney}
			isOpen={preselectedPlan === null}
			key={accountId}
			onEnterEditModeCallback={onEnterEditModeCallback}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<DisplayPart>
				<FormRows>
					<FormRow
						label={(
							<FormattedMessage {...messages.pageBundleLabel} />
						)}
					>
						<StaticText>
							<FormattedNumber value={accountWebsiteSizes.totalPageCapacity} />
							&nbsp;/&nbsp;
							<FormattedNumber value={accountPageBundle} />
							&nbsp;
							<span className="percentage">
								<Parenthesis
									content={(
										<FormattedNumber
											maximumFractionDigits={2}
											minimumFractionDigits={1}
											style="percent"
											value={accountWebsiteSizes.totalPageCapacity / accountPageBundle}
										/>
									)}
								/>
							</span>
						</StaticText>
					</FormRow>

					{(accountIsOutOfBand === false || accountTariff !== Tariff.C1) && (
						<>
							<FormRow
								label={(
									<FormattedMessage {...messages.billingCycleLabel} />
								)}
							>
								<StaticText>
									<BillingCycleName billingCycle={accountBillingCycle} />
								</StaticText>
							</FormRow>

							<FormRow
								label={(
									<FormattedMessage {...messages.agencyPlanLabel} />
								)}
							>
								<StaticText>
									<PlanName plan={accountPlan} />
								</StaticText>
							</FormRow>
						</>
					)}
				</FormRows>
			</DisplayPart>

			<EditablePart>
				<PaymentAuthorizationContext
					accountId={accountId}
					onSubmit={handleSubmit}
					useModal={true}
				>
					{({ formRef, handleFormSuccess }) => {
						const defaultValues = {
							billingCycle: accountBillingCycle,
							pageBundle: accountPageBundle,
							plan: preselectedPlan ?? accountPlan,
						};

						return (
							<Form
								defaultDataHasChanged={preselectedPlan === null}
								defaultFocus="pageBundle"
								defaultValues={defaultValues}
								onSuccess={handleFormSuccess}
								ref={formRef}
							>
								{({ values }) => {
									const isPlanAvailable = accountFeaturesUsage
										.getPlanAvailability(values.plan || accountPlan)
										.isAvailable;

									const isBillingCycleEditable = allowedBillingCycles.length > 1;
									const isPlanEditable = allowedPlans.length > 1 && accountPlan !== GraphQL.AccountPlan.Enterprise;

									let cannotSubmitExplanation: React.ReactNode = null;

									if (accountPaymentStatus.isOkay === false) {
										cannotSubmitExplanation = (
											<Copy {...messages.cannotSubmitDueToPaymentIssues} />
										);
									} else if (
										values.plan !== accountPlan
										&& accountIsOutOfBand === false
										&& accountOutOfBandPlans.includes(values.plan)
									) {
										cannotSubmitExplanation = (
											<Copy {...messages.cannotSubmitDueToSelectingOutOfBandPlan} />
										);
									} else if (
										values.plan !== accountPlan
										&& accountIsOutOfBand
										&& accountOutOfBandPlans.includes(values.plan) === false
									) {
										cannotSubmitExplanation = 'Account has to be unsigned up and customer needs to proceed with regular signup in order to switch to in-band plan.';
									}

									return (
										<>
											<FormRows>
												<FormRow
													htmlFor="pageBundle"
													label={(
														<FormattedMessage {...messages.pageBundleLabel} />
													)}
												>
													<PageBundleField
														accountId={accountId}
														name="pageBundle"
													/>
												</FormRow>

												<FormRow
													htmlFor="billingCycle"
													label={(
														<FormattedMessage {...messages.billingCycleLabel} />
													)}
												>
													{isBillingCycleEditable ? (
														<BillingCycleField
															allowedBillingCycles={allowedBillingCycles}
															name="billingCycle"
														/>
													) : (
														<StaticText>
															<BillingCycleName billingCycle={accountBillingCycle} />
														</StaticText>
													)}
												</FormRow>
												<FormRow
													htmlFor="plan"
													label={(
														<FormattedMessage {...messages.agencyPlanLabel} />
													)}
												>
													{isPlanEditable ? (
														<PlanField
															accountId={accountId}
															name="plan"
														/>
													) : (
														<StaticText>
															<PlanName plan={accountPlan} />
														</StaticText>
													)}
												</FormRow>

												{!isPlanAvailable && (
													<FormRow fullwidth={true}>
														<CalloutMessage
															borders={true}
															message={(
																<FormattedMessage {...messages.downgradeBoxTitle} />
															)}
															status={CalloutMessageStatus.Warning}
														>
															<p>
																<FormattedMessage
																	{...messages.downgradeBoxDescription}
																	values={{
																		text__pricingPlan: (
																			<PlanName plan={values.plan || accountPlan} />
																		),
																	}}
																/>
															</p>
														</CalloutMessage>
													</FormRow>
												)}

												<SubscriptionChangeSummary
													accountId={accountId}
													billingCycle={values.billingCycle}
													hidePrice={accountIsOutOfBand || accountOutOfBandPlans.includes(values.plan)}
													pageBundle={values.pageBundle}
													plan={values.plan}
												/>
											</FormRows>

											<PaymentFailureFormError
												errorCode="failedPayment"
											/>

											<PaymentAuthorizationError />

											<ButtonsLayout>
												<PaymentCancelButton />

												<PaymentSubmitButton
													isDisabled={cannotSubmitExplanation !== null}
													tooltip={cannotSubmitExplanation}
												>
													<FormattedMessage {...messages.saveButton} />
												</PaymentSubmitButton>
											</ButtonsLayout>
										</>
									);
								}}
							</Form>
						);
					}}
				</PaymentAuthorizationContext>
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default SubscriptionForm;
