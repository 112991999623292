import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import FormsGroupsList from '../../atoms/lists/FormsGroupsList';
import PanelNavigation from '../accountSection/PanelNavigation';
import RecordDetailLayout from '../../atoms/layouts/records/RecordDetailLayout';
import SectionContent from './SectionContent';

import useUrlState from '~/hooks/useUrlState';



const messages = defineMessages({
	actionsTitle: {
		id: 'ui.general.actions',
	},
});



type Props<Section extends string | number> = {
	sections: ReadonlyArray<Section>,
} & {
	actions?: React.ReactNode,
	getSectionRoute: (section: Section) => string,
	overview?: React.ReactNode,
	renderNoSection?: () => React.ReactNode,
	renderSectionContent: (section: Section) => React.ReactNode,
	renderSectionTitle: (section: Section) => React.ReactNode,
};

function Sections<Section extends string | number>(props: Props<Section>) {
	const {
		actions,
		getSectionRoute,
		overview,
		renderNoSection,
		renderSectionContent,
		renderSectionTitle,
		sections,
	} = props;

	const urlState = useUrlState();

	const sectionRefs = React.useRef<{ [P in Section]?: React.RefObject<any> }>({});

	sections.forEach((section) => {
		if (!sectionRefs.current[section]) {
			sectionRefs.current[section] = React.createRef();
		}
	});

	const sectionsSortedBySpecificity = sections.slice(0).sort((sectionA, sectionB) => {
		const routeA = getSectionRoute(sectionA);
		const routeB = getSectionRoute(sectionB);

		if (routeA.length === routeB.length) {
			return 0;
		}

		return routeA.length > routeB.length ? -1 : 1;
	});

	const navigationItems = sections.map((section) => {
		const matchingRoutes = sectionsSortedBySpecificity.filter((sectionX) => urlState.name.indexOf(getSectionRoute(sectionX)) === 0);

		return {
			isActive: matchingRoutes[0] === section,
			label: renderSectionTitle(section),
			name: section,
			routeName: getSectionRoute(section),
			routeParams: urlState.params,
		};
	});

	return (
		<RecordDetailLayout
			additionalCTAElements={actions}
			additionalCTAElementsTitle={(
				<FormattedMessage {...messages.actionsTitle} />
			)}
			navigation={(
				<PanelNavigation
					items={navigationItems}
					sectionRefs={sectionRefs.current}
				/>
			)}
			overview={overview}
		>
			{({ compactLayout }) => {
				if (compactLayout) {
					return (
						<FormsGroupsList>
							{sections.map((section) => (
								<SectionContent
									key={section}
									ref={sectionRefs.current[section]}
									showTitle={true}
									title={renderSectionTitle(section)}
								>
									{renderSectionContent(section)}
								</SectionContent>
							))}
						</FormsGroupsList>
					);
				}

				let activeSection;

				sections.forEach((section) => {
					if (urlState.name === getSectionRoute(section)) {
						activeSection = section;
					}
				});

				return (
					<SectionContent
						ref={sectionRefs.current[activeSection]}
						showTitle={false}
					>
						{activeSection !== undefined ? (
							renderSectionContent(activeSection)
						) : renderNoSection !== undefined ? (
							renderNoSection()
						) : (
							null
						)}
					</SectionContent>
				);
			}}
		</RecordDetailLayout>
	);
}



export default Sections;
